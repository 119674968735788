import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import {change, touch as touchFormAction, updateSyncErrors} from 'redux-form';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
   PAYMENT_FREQUENCY,
   PERSONAL_INFO_KEYS,
   SECTIONS,
   SIMPLE_ANSWER,
} from '../../../shared/constants';
import {fetchMunicipalities} from '../../../shared/references';
import {api, toastHandler} from '../../../shared/services';
import {
   adaptPolicyHolderPersonalInfo,
   convertPhoneNumber,
   EMAIL_KEY,
   parsePhoneNumbers,
   PHONE_KEYS,
   scrollToField,
   slicePhoneNumber,
} from '../../../shared/services/utils';
import {
   ibanFormatValidation,
   isIbanBelgium,
   isOldForPeriodicPayment,
} from '../../../shared/services/validators';
import {setGeneralError} from '../../App/reducer';
import {selectActiveFormValues, selectId} from '../../FormWrapper/selectors';
import {fetchCalculationData} from '../../PremiumBox/reducer';
import {fetchInsuredAdults} from '../Insured/reducer';
import {selectFirstInsuredAdult, selectSecondInsuredAdult} from '../Insured/selectors';
import {fetchMetadata} from '../reducer';
import {handleProposalGeneralError} from '../sagas';
import {selectMetadata, selectProposalId} from '../selectors';

import {
   containerId as policyHolderContainerId,
   fetchPolicyHolder,
   removeFirstInsuredAdultFormValues,
   savePolicyHolder,
   validateIban,
} from './reducer';
import {
   BIC_FIELD_NAME,
   IBAN_FIELD_NAME,
   selectIsSepa,
   selectParticipatingAsInsuredAdultFormValues,
   selectPaymentFrequencyFormValue,
   selectPolicyHolderFormValues,
} from './selectors';

export const RESIDENTIAL_ADDRESS_SAVE_ERROR = 'residential_address_has_incorrect_values';

export const hasResidentialAddressError = (error) =>
   error?.errors?.some((e) => e.reason === RESIDENTIAL_ADDRESS_SAVE_ERROR);

export function* setResidentialAddressSaveError(containerId) {
   yield put(touchFormAction(containerId, ['policyHolder.residentialAddress.streetName']));
   yield put(
      updateSyncErrors(containerId, {
         policyHolder: {
            residentialAddress: {streetName: {id: 'invalidResidentialAddress'}},
         },
      })
   );
   yield call(scrollToField, 'policyHolder.residentialAddress.streetName');
}

export function* fetchPolicyHolderSaga({payload}) {
   try {
      let id = get(payload, 'id');
      if (!id) {
         id = yield select(selectId);
      }

      const {
         residentialAddress,
         contactInformation,
         participatingAsInsuredAdult,
         ...restData
      } = yield call(api.proposal.getPolicyHolder, id);
      const {postalCode} = residentialAddress;
      if (postalCode && postalCode.length === 4) {
         yield put(fetchMunicipalities.request({postalCode, containerId: policyHolderContainerId}));
      }

      const {mobileNumber, landlineNumber} = contactInformation;
      const {
         phoneNumberCode: mobileNumberCode,
         phoneNumber: modifiedMobileNumber,
      } = parsePhoneNumbers(mobileNumber);
      const {
         phoneNumberCode: landlineNumberCode,
         phoneNumber: modifiedLandlineNumber,
      } = parsePhoneNumbers(landlineNumber);

      yield put(
         fetchPolicyHolder.success({
            participatingAsInsuredAdult: participatingAsInsuredAdult
               ? SIMPLE_ANSWER.YES
               : SIMPLE_ANSWER.NO,
            residentialAddress: {...omit(residentialAddress, 'country')},
            contactInformation: {
               ...contactInformation,
               mobileNumber: modifiedMobileNumber,
               mobileNumberCode,
               landlineNumber: modifiedLandlineNumber,
               landlineNumberCode,
            },
            ...restData,
         })
      );
   } catch (error) {
      yield put(fetchPolicyHolder.failure(error));
      yield call(handleProposalGeneralError, error);
   }
}

export const rejectMergedErrors = (reject, oldError, newError) => {
   reject(
      merge(
         {
            policyHolder: {
               financialInformation: newError,
            },
         },
         oldError
      )
   );
};

export const handleValidateResult = (resolve, reject, asyncErrors) =>
   !isEmpty(asyncErrors) ? reject(asyncErrors) : resolve();

export function* validateIbanSaga({payload}) {
   const {values, resolve, reject, props} = payload;
   let {asyncErrors, change, touch} = props;
   const isIbanAsyncErrorExists = get(asyncErrors, IBAN_FIELD_NAME, false);
   let response;

   try {
      const {iban} = values.policyHolder.financialInformation;
      if (iban && !ibanFormatValidation(iban)) {
         response = yield (isIbanBelgium(iban) ? call(api.validation.bicRetrieve, {iban}) : call(api.validation.iban, {iban}));

         const {bic, error, warning} = response;

         if (error || warning) {
            rejectMergedErrors(reject, asyncErrors, {iban: {id: 'ibanIsInvalid'}});
            yield put(touch(IBAN_FIELD_NAME));
         } else if (bic) {
            yield put(change(BIC_FIELD_NAME, bic));
         }
      }

      if (isIbanAsyncErrorExists) {
         asyncErrors = omit(asyncErrors, IBAN_FIELD_NAME);
      }

      handleValidateResult(resolve, reject, asyncErrors);
   } catch (error) {
      const {status} = error;
      if (status >= 500) {
         yield put(change(IBAN_FIELD_NAME, null));
         yield put(change(BIC_FIELD_NAME, null));
         asyncErrors = omit(asyncErrors, [IBAN_FIELD_NAME, BIC_FIELD_NAME]);
         toastHandler.uniqueWarning({id: 'toasts.warning.bankServiceUnavailableError'});
      }
      handleValidateResult(resolve, reject, asyncErrors);
   }
}

export const createPrepareDataSavePolicyHolderSaga = (selectIsSepaSelector) => function* () {
      const formValues = yield select(selectActiveFormValues);
      let data = {...formValues};
      const isSepa = yield select(selectIsSepaSelector);

      if (!isSepa) {
         data = omit(data, 'policyHolder.financialInformation.desiredDay');
         yield put(
            change(policyHolderContainerId, 'policyHolder.financialInformation.desiredDay', null)
         );
      }

      let contactInformation = get(formValues, 'policyHolder.contactInformation');
      contactInformation = convertPhoneNumber({...contactInformation}, 'landlineNumber');
      contactInformation = convertPhoneNumber(contactInformation, 'mobileNumber');

      const participatingAsInsuredAdult = get(
         formValues,
         'policyHolder.participatingAsInsuredAdult'
      );

      return {
         ...data,
         policyHolder: {
            ...data.policyHolder,
            contactInformation,
            participatingAsInsuredAdult: participatingAsInsuredAdult === SIMPLE_ANSWER.YES,
         },
      };
   };

export const prepareDataSavePolicyHolderSaga = createPrepareDataSavePolicyHolderSaga(selectIsSepa);

export function* savePolicyHolderSaga() {
   const data = yield call(prepareDataSavePolicyHolderSaga);
   const proposalId = yield select(selectProposalId);
   try {
      yield call(api.proposal.patchPolicyHolder, proposalId, data);
      yield call(updatePhoneNumberFormValuesSaga);
      yield call(updateFirstInsuredAdultSaga, data);
      yield call(updateMetadataSaga);
      yield put(savePolicyHolder.success());
   } catch (error) {
      yield put(savePolicyHolder.failure(error));
      if (hasResidentialAddressError(error)) {
         yield call(setResidentialAddressSaveError, policyHolderContainerId);
         return;
      }
      yield put(setGeneralError.action(error));
   }
}

export function* updatePhoneNumberFormValuesSaga() {
   const policyHolderValues = yield select(selectPolicyHolderFormValues);
   const {contactInformation} = policyHolderValues;
   const {mobileNumber, landlineNumber} = contactInformation;

   const updatedMobileNumber = slicePhoneNumber(mobileNumber);
   const updatedLandlineNumber = slicePhoneNumber(landlineNumber);

   yield put(
      change(
         policyHolderContainerId,
         `policyHolder.contactInformation.mobileNumber`,
         updatedMobileNumber
      )
   );

   yield put(
      change(
         policyHolderContainerId,
         `policyHolder.contactInformation.landlineNumber`,
         updatedLandlineNumber
      )
   );

   yield put(
      fetchPolicyHolder.update({
         ...policyHolderValues,
         contactInformation: {
            ...contactInformation,
            mobileNumber: updatedMobileNumber,
            landlineNumber: updatedLandlineNumber,
         },
      })
   );
}

export function* updateFirstInsuredAdultSaga(payload) {
   const firstInsuredAdultData = get(payload, SECTIONS.FIRST_INSURED_ADULT);
   const firstInsuredValues = yield select(selectFirstInsuredAdult);
   const secondInsuredValues = yield select(selectSecondInsuredAdult);
   const paymentFrequency = yield select(selectPaymentFrequencyFormValue);
   const isOneTimeOnlyPaymentFrequency = paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;

   if (firstInsuredAdultData && isOneTimeOnlyPaymentFrequency) {
      yield put(removeFirstInsuredAdultFormValues.action());
   }

   const payUntilAge = firstInsuredAdultData
      ? firstInsuredAdultData.payUntilAge
      : firstInsuredValues.payUntilAge;

   const participatingAsInsuredAdult = yield select(selectParticipatingAsInsuredAdultFormValues);
   if (participatingAsInsuredAdult === SIMPLE_ANSWER.NO) {
      return yield put(
         fetchInsuredAdults.update([
            {
               ...firstInsuredValues,
               payUntilAge: isOneTimeOnlyPaymentFrequency ? null : payUntilAge,
               participatingAsPolicyHolder: SIMPLE_ANSWER.NO,
            },
            secondInsuredValues,
         ])
      );
   }
   const policyHolder = yield select(selectPolicyHolderFormValues);
   const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);
   const proposalId = yield select(selectProposalId);

   const personalInformation = {
      firstName: policyHolderObject[PERSONAL_INFO_KEYS.FIRST_NAME],
      surname: policyHolderObject[PERSONAL_INFO_KEYS.SURNAME],
      gender: policyHolderObject[PERSONAL_INFO_KEYS.GENDER],
      birthDate: policyHolderObject[PERSONAL_INFO_KEYS.BIRTH_DATE],
      nationalRegisterNumber: policyHolderObject[PERSONAL_INFO_KEYS.NATIONAL_REGISTER_NUMBER],
   };

   yield put(
      fetchInsuredAdults.update([
         {
            ...firstInsuredValues,
            payUntilAge: isOneTimeOnlyPaymentFrequency ? null : payUntilAge,
            email: policyHolderObject[EMAIL_KEY.EMAIL],
            mobileNumber: policyHolderObject[PHONE_KEYS.MOBILE_NUMBER],
            mobileNumberCode: policyHolderObject[PHONE_KEYS.MOBILE_NUMBER_CODE],
            personalInformation,
            participatingAsPolicyHolder: SIMPLE_ANSWER.YES,
         },
         secondInsuredValues,
      ])
   );
   yield put(fetchCalculationData.request({id: proposalId}));
}

export function* updateMetadataSaga() {
   const policyHolderValues = yield select(selectPolicyHolderFormValues);
   const policyHolderFirstName = get(policyHolderValues, 'personalInformation.firstName');
   const policyHolderSurname = get(policyHolderValues, 'personalInformation.surname');
   const policyHolderbirthDate = get(policyHolderValues, 'personalInformation.birthDate');

   const metadata = yield select(selectMetadata);
   const paymentFrequencyValues = yield select(selectPaymentFrequencyFormValue);
   const participatingAsInsuredAdult = yield select(selectParticipatingAsInsuredAdultFormValues);

   const isOldForPeriodicPaymentErrorExist = isOldForPeriodicPayment(policyHolderbirthDate);

   yield put(
      fetchMetadata.update({
         ...metadata,
         paymentFrequencyDisabled:
            participatingAsInsuredAdult === SIMPLE_ANSWER.YES
               ? !!isOldForPeriodicPaymentErrorExist
               : metadata.paymentFrequencyDisabled,
         paymentFrequency: paymentFrequencyValues,
         policyHolderFirstName,
         policyHolderSurname,
      })
   );
}

export default function* policyHolderSagas() {
   yield all([
      takeLatest(fetchPolicyHolder.REQUEST, fetchPolicyHolderSaga),

      takeLatest(savePolicyHolder.REQUEST, savePolicyHolderSaga),
      takeLatest(validateIban.ACTION, validateIbanSaga),
   ]);
}
