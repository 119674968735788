import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {showPopover, toggleAnimation} from '../../../containers/PopoverContainer/reducer';
import {BUTTON_ANIMATION_PROP} from '../../../containers/PopoverContainer/sagas';
import {selectPopoverAnimation} from '../../../containers/PopoverContainer/selectors';
import {FormattedMessage} from '../FormattedMessage';
import './styles.scss';

export const HowItWorksButton = ({
   hasPopoverAnimation,
   onToggleAnimation,
   popoverId,
   showPopover,
   className,
}) => {
   const handleAnimationEnd = () =>
      onToggleAnimation({popoverId, animationProp: BUTTON_ANIMATION_PROP});
   const handleShowPopover = () => showPopover(popoverId);

   return (
      <div className={classNames('how-it-works-button-wrapper', className)}>
         <button onClick={handleShowPopover} type="button" className="how-it-works-button">
            <span
               className={classNames('how-it-works-icon', {
                  'how-it-works-animation': hasPopoverAnimation,
               })}
               onAnimationEnd={handleAnimationEnd}
            >
               <FormattedMessage id="popovers.howItWorksPanel.title" />
            </span>
         </button>
      </div>
   );
};

HowItWorksButton.propTypes = {
   hasPopoverAnimation: PropTypes.bool,
   onToggleAnimation: PropTypes.func,
   popoverId: PropTypes.string.isRequired,
   showPopover: PropTypes.func,
   className: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
   hasPopoverAnimation: selectPopoverAnimation(state, ownProps.popoverId, BUTTON_ANIMATION_PROP),
});

export default connect(mapStateToProps, {
   onToggleAnimation: toggleAnimation.action,
   showPopover: showPopover.action,
})(HowItWorksButton);
