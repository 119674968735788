import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import some from 'lodash/some';
import {
   getFormAsyncErrors,
   getFormSyncErrors,
   getFormValues,
   isAsyncValidating,
   isDirty,
   isInvalid,
   isPristine,
   isValid,
} from 'redux-form';
import {createSelector} from '@reduxjs/toolkit';
import {selectActionData, selectIsInProgressStatus} from '../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../shared/reducers/selectors';
import {containerId as offerDetailsContainerId} from '../OfferDetails/reducer';
import {
   selectIsSaving as selectOfferDetailsFormIsSaving,
   selectIsSavingButtonDisabled as selectIsOfferDetailsSavingButtonDisabled,
   selectMetadata as selectOfferMetadata,
} from '../OfferDetails/selectors';
import {containerId as additionalInformationContainerId} from '../ProposalDetails/AdditionalInformation/reducer';
import {
   selectIsSaving as selectAdditionalInformationFormIsSaving,
   selectIsSavingButtonDisabled as selectIsAdditionalInformationSavingButtonDisabled,
} from '../ProposalDetails/AdditionalInformation/selectors';
import {containerId as beneficiariesContainerId} from '../ProposalDetails/Beneficiaries/reducer';
import {
   selectIsSaving as selectBeneficiariesFormIsSaving,
   selectIsSavingButtonDisabled as selectIsBeneficiariesSavingButtonDisabled,
} from '../ProposalDetails/Beneficiaries/selectors';
import {containerId as healthDeclarationContainerId} from '../ProposalDetails/HealthDeclaration/reducer';
import {
   selectIsLocalFormDisabled,
   selectIsSaving,
} from '../ProposalDetails/HealthDeclaration/selectors';
import {containerId as insuredContainerId} from '../ProposalDetails/Insured/reducer';
import {
   selectIsSaving as selectInsuredFormIsSaving,
   selectIsSavingButtonDisabled as selectIsInsuredSavingButtonDisabled,
} from '../ProposalDetails/Insured/selectors';
import {containerId as needsAndDesiresContainerId} from '../ProposalDetails/NeedsAndDesires/reducer';
import {
   selectIsSaving as selectNeedsAndDesiresFormIsSaving,
   selectIsSavingButtonDisabled as selectIsNeedsAndDesiresSavingButtonDisabled,
} from '../ProposalDetails/NeedsAndDesires/selectors';
import {containerId as policyHolderContainerId} from '../ProposalDetails/PolicyHolder/reducer';
import {
   selectIsSaving as selectPolicyHolderFormIsSaving,
   selectIsSavingButtonDisabled as selectIsPolicyHolderSavingButtonDisabled,
} from '../ProposalDetails/PolicyHolder/selectors';
import {submitProposal} from '../ProposalDetails/reducer';
import {
   getCurrentState as proposalDetailsState,
   selectIsMetadataBlocked,
   selectMetadata as selectProposalMetadata,
} from '../ProposalDetails/selectors';
import {
   containerId as formWrapperContainerId,
   setActiveFormAction,
   setFormSavingAction,
} from './reducer';

export const getCurrentState = getStateByContainerId(formWrapperContainerId);

export const selectActiveFormName = (state) =>
   selectActionData(getCurrentState(state), setActiveFormAction.type);

export const selectRequestingDocumentsStateStatus = (state) =>
   selectIsInProgressStatus(proposalDetailsState(state), submitProposal.type);

export const selectIsNeedsAndDesiresForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === needsAndDesiresContainerId;
};

export const selectMetadata = (state) => {
   const isOffer = selectIsOfferDetailsForm(state);
   if (isOffer) {
      return selectOfferMetadata(state);
   }
   return selectProposalMetadata(state);
};

export const selectSalesProcessState = createSelector(selectMetadata, (metadata) =>
   get(metadata, 'state')
);

export const selectIsInOfferState = createSelector(
   selectSalesProcessState,
   (salesProcessState) => salesProcessState === 'OFFER'
);

export const selectIsInProposalState = createSelector(
   selectSalesProcessState,
   (salesProcessState) => salesProcessState === 'PROPOSAL'
);

export const selectIsOfferDetailsForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === offerDetailsContainerId;
};

export const selectIsPolicyHolderForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === policyHolderContainerId;
};

export const selectIsInsuredForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === insuredContainerId;
};

export const selectIsBeneficiariesForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === beneficiariesContainerId;
};

export const selectIsHealthDeclarationForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === healthDeclarationContainerId;
};

export const selectIsAdditionalInformationForm = (state) => {
   const formName = selectActiveFormName(state);
   return formName === additionalInformationContainerId;
};

export const selectTitle = (state) => {
   const isOffer = selectIsOfferDetailsForm(state);
   if (isOffer) {
      return 'misc.offer';
   }
   return 'misc.proposal';
};

export const selectId = (state) => {
   const metadata = selectMetadata(state);
   const formName = selectActiveFormName(state);
   return formName === offerDetailsContainerId
      ? get(metadata, 'offerId')
      : get(metadata, 'proposalId');
};

export const selectNumber = (state) => {
   const metadata = selectMetadata(state);
   return get(metadata, 'number');
};

export const selectShowGoToProposalButton = (state) => {
   const isInProposalState = selectIsInProposalState(state);
   const isOffer = selectIsOfferDetailsForm(state);
   return isInProposalState && isOffer;
};

export const selectShowNavigationPrompt = (state) => {
   const isDirty = selectIsFormDirty(state);
   const isSaving = selectIsFormSaving(state);
   return isDirty && !isSaving;
};

export const selectShowInfo = createSelector(
   [selectIsNeedsAndDesiresForm, selectIsMetadataBlocked],
   (isNeedsAndDesiresForm, isBlocked) => isNeedsAndDesiresForm && !isBlocked
);

export const selectIsSavingButtonDisabled = (state) => {
   const isPristine = selectIsFormPristine(state);
   const isDisabled = selectIsDisabled(state);
   return isPristine || isDisabled;
};

export const selectIsFormAsyncValidating = (state) => {
   const formName = selectActiveFormName(state);
   return isAsyncValidating(formName)(state);
};

export const selectIsFormDirty = (state) => {
   const formName = selectActiveFormName(state);
   return isDirty(formName)(state);
};

export const selectIsFormPristine = (state) => {
   const formName = selectActiveFormName(state);
   return isPristine(formName)(state);
};

export const selectIsFormValid = (state) => {
   const formName = selectActiveFormName(state);
   return isValid(formName)(state);
};

export const selectShouldSkipValidation = createSelector(
   [selectActiveFormName, selectIsFormPristine, selectIsFormValid],
   (formName, isPristine, isVaid) => {
      if (formName === needsAndDesiresContainerId) {
         return isVaid;
      }
      return isVaid || isPristine;
   }
);

export const createFormSelector = (selectorMap) => (state) => {
   const isSavingFormInProgress = selectIsSavingFormInProgress(state);
   if (isSavingFormInProgress) {
      return true;
   }
   const formName = selectActiveFormName(state);
   const selector = selectorMap.get(formName);

   if (!selector) {
      return false;
   }

   return selector(state);
};

const isFormDisabledSelectorToFormName = new Map([
   [offerDetailsContainerId, selectIsOfferDetailsSavingButtonDisabled],
   [needsAndDesiresContainerId, selectIsNeedsAndDesiresSavingButtonDisabled],
   [policyHolderContainerId, selectIsPolicyHolderSavingButtonDisabled],
   [insuredContainerId, selectIsInsuredSavingButtonDisabled],
   [healthDeclarationContainerId, selectIsLocalFormDisabled],
   [beneficiariesContainerId, selectIsBeneficiariesSavingButtonDisabled],
   [additionalInformationContainerId, selectIsAdditionalInformationSavingButtonDisabled],
]);
export const selectIsDisabled = (state) =>
   createFormSelector(isFormDisabledSelectorToFormName)(state);

const isFormSavingSelectorToFormName = new Map([
   [offerDetailsContainerId, selectOfferDetailsFormIsSaving],
   [needsAndDesiresContainerId, selectNeedsAndDesiresFormIsSaving],
   [policyHolderContainerId, selectPolicyHolderFormIsSaving],
   [insuredContainerId, selectInsuredFormIsSaving],
   [healthDeclarationContainerId, selectIsSaving],
   [beneficiariesContainerId, selectBeneficiariesFormIsSaving],
   [additionalInformationContainerId, selectAdditionalInformationFormIsSaving],
]);

export const selectIsFormSaving = (state) =>
   createFormSelector(isFormSavingSelectorToFormName)(state);

const routesToFormName = new Map([
   [
      needsAndDesiresContainerId,
      {
         next: '/policy-holder',
      },
   ],
   [
      policyHolderContainerId,
      {
         prev: '/needs-and-desires',
         next: '/insured',
      },
   ],
   [
      insuredContainerId,
      {
         prev: '/policy-holder',
         next: '/beneficiaries',
      },
   ],
   [
      beneficiariesContainerId,
      {
         prev: '/insured',
         next: '/health-declaration',
      },
   ],
   [
      healthDeclarationContainerId,
      {
         prev: '/beneficiaries',
         next: '/additional-information',
      },
   ],
   [
      additionalInformationContainerId,
      {
         prev: '/health-declaration',
         next: '/documents',
      },
   ],
]);

export const selectActiveFormData = (state) => {
   const formName = selectActiveFormName(state);
   return {
      formName,
      isInvalid: isInvalid(formName)(state),
      isPristine: isPristine(formName)(state),
   };
};

export const selectActiveFormValues = (state) => {
   const formName = selectActiveFormName(state);
   return getFormValues(formName)(state);
};

export const isBeneficiariesErrorWithEmptyValues = (errorObject) => {
   const firstInsuredAdult = get(errorObject, 'firstInsuredAdult');
   const secondInsuredAdult = get(errorObject, 'secondInsuredAdult');

   if (!firstInsuredAdult && !secondInsuredAdult) {
      return false;
   }

   return some(errorObject, (elem) =>
      elem.beneficiaries.some((item) => {
         if (!item) {
            return false;
         }
         const {beneficiaryId, ...props} = item;

         return isEmpty(props);
      })
   );
};

export const isPolyHolderErrorWithEmptyValues = (errorObject) => {
   const errors = get(errorObject, 'policyHolder.financialInformation');
   return isPlainObject(errors) && isEmpty(errors);
};

export const isInsuredErrorWithEmptyValues = (errorObject) => {
   const insuredErrorsValues = get(errorObject, 'insuredAdults');

   if (!insuredErrorsValues) {
      return false;
   }

   return insuredErrorsValues.every((item) => {
      return !item || isEmpty(item);
   });
};

export const selectFormErrors = (state) => {
   const formName = selectActiveFormName(state);
   const syncFormErrors = getFormSyncErrors(formName)(state);
   const asyncFormErrors = getFormAsyncErrors(formName)(state);
   let errors = syncFormErrors;

   if (isEmpty(syncFormErrors)) {
      errors = asyncFormErrors;
   }

   //TODO: Insured form has initial sync redux-form errors in redux state:
   // {insuredAdults: [null, {}]}
   // Beneficiaries form has unexpected errors after removing all beneficiaries:
   // {firstInsuredAdult: { beneficiaries: [ {} ] } }
   // should be removed after refactoring of Insured Form or redux-form removing
   if (
      (formName === policyHolderContainerId && isPolyHolderErrorWithEmptyValues(errors)) ||
      (formName === insuredContainerId && isInsuredErrorWithEmptyValues(errors)) ||
      (formName === beneficiariesContainerId && isBeneficiariesErrorWithEmptyValues(errors))
   ) {
      return null;
   }

   return errors;
};
const isOnlyOneFieldExist = (object, name) => {
   if (!object || isEmpty(object) || !get(object, name)) {
      return false;
   }
   const values = Object.values(object);
   return values.length === 1;
};

export const selectRoutes = createSelector(
   [selectMetadata, selectActiveFormData, selectFormErrors],
   (metadata, formData, errors) => {
      const {formName, isInvalid, isPristine} = formData;
      const routes = routesToFormName.get(formName);
      if (!routes || !metadata) {
         return {};
      }

      const {blocked, offerId, proposalId} = metadata;

      const proposalDefaultPath = `/sales-process/proposals/${proposalId}`;
      const offerDefaultPath = `/sales-process/offer/${offerId}`;

      const isNeedsAndDesiresForm = formName === needsAndDesiresContainerId;
      const isAdditionalInformationForm = formName === additionalInformationContainerId;

      const prev = `${proposalDefaultPath}${routes.prev}`;
      const next = `${proposalDefaultPath}${routes.next}`;

      if (isAdditionalInformationForm) {
         return {
            prev,
            next: !blocked ? undefined : next,
         };
      }

      if (isNeedsAndDesiresForm) {
         const isOnlyInsuredProductBasisErrorExist =
            isOnlyOneFieldExist(errors, 'advice') &&
            isOnlyOneFieldExist(get(errors, 'advice'), 'insuredProductBasis');
         return {
            prev: offerId ? offerDefaultPath : undefined,
            next:
               isInvalid && isPristine && !isOnlyInsuredProductBasisErrorExist ? undefined : next,
         };
      }

      return {
         prev,
         next,
      };
   }
);

export const selectIsSavingFormInProgress = (state) =>
   selectActionData(getCurrentState(state), setFormSavingAction.type);
