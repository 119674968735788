import React from 'react';
import { useDispatch } from 'react-redux';

import { openDrawerPanel } from 'v2/features/app-drawer/appDrawerSlice';
import { AppDrawerPanel } from 'v2/features/app-drawer/types';

export function useCalculator() {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(openDrawerPanel(AppDrawerPanel.CalculatePremium));
  }, [dispatch]);
}
