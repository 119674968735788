import { FormattedMessage } from 'react-intl';

import { Widget } from '../Widget';

export const Infoblock = () => (
  <Widget classPrefix="infoblock">
    <div className="infoblock-title">
      <FormattedMessage id="homepage.infoblock.title" />
    </div>
    <div className="infoblock-text">
      <FormattedMessage
        id="homepage.infoblock.text"
        values={{
          slogan: (
            <span>
              <FormattedMessage id="homepage.infoblock.slogan" />
            </span>
          ),
        }}
      />
    </div>
  </Widget>
);
