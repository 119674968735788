import {bool} from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {selectShowInfo} from '../selectors';
import {FormattedMessage} from '../../../shared/components/FormattedMessage';

export const FormWrapperInfoPanel = ({showInfo}) => {
   if (!showInfo) {
      return null;
   }

   return (
      <div className="send-notice">
         <i className="iconfont icon-circle-exclamation" />
         <FormattedMessage id="needsAndDesires.requiredNeedsAndDesiresInfo" />
      </div>
   );
};

const mapStateToProps = (state) => ({
   showInfo: selectShowInfo(state),
});

FormWrapperInfoPanel.propTypes = {
   showInfo: bool,
};

export default connect(mapStateToProps)(FormWrapperInfoPanel);
