import get from 'lodash/get';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import {
   formValueSelector,
   getFormAsyncErrors,
   getFormSyncErrors,
   isSubmitting,
   getFormMeta,
} from 'redux-form';
import {createSelector} from '@reduxjs/toolkit';
import {
   PAYMENT_FREQUENCY,
   PRODUCT_VERSIONS,
   EMAIL_DELIVERY_STATUS,
   SIMPLE_ANSWER,
} from '../../shared/constants';
import {
   selectActionData,
   selectAsyncActionData,
   selectIsFailedStatus,
   selectIsInProgressStatus,
} from '../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../shared/reducers/selectors';
import {selectPaymentFrequencies as selectPaymentFrequenciesReferences} from '../../shared/references/selectors';
import {
   adaptInsuredPersonalInfo,
   adaptPolicyHolderPersonalInfo,
   ageIsOlder,
   areRequiredFieldsTouched,
   checkPersonalEquality,
   disableOneTimeOnlyOption,
   filterPaymentFrequencies,
   showEmailsEqualityNotification,
} from '../../shared/services/utils';
import {
   containerId,
   convertToProposal,
   fetchOffer,
   saveOffer,
   setOfferMetadata,
   setPreviousPaymentFrequency,
   shareByEmail,
   shareByPrinting,
} from './reducer';
import {setFormSavingAction, getCurrentState as getFormWrapperState} from '../FormWrapper/reducer';

const selectFormValues = formValueSelector(containerId);
const selectFormErrorsValues = getFormSyncErrors(containerId);
const selectAsyncFormErrorsValues = getFormAsyncErrors(containerId);
const selectFormMeta = getFormMeta(containerId);

export const getCurrentState = getStateByContainerId(containerId);

export const selectOffer = (state) =>
   selectAsyncActionData(getCurrentState(state), fetchOffer.type);

export const selectDeliveryStatus = (state) => get(selectOffer(state), 'emailDeliveryStatus');

export const selectEmailDeliveryStatus = createSelector(selectDeliveryStatus, (deliveryStatus) =>
   Boolean(deliveryStatus)
);

export const selectEmailDeliveryUndeliveredStatus = createSelector(
   selectDeliveryStatus,
   (deliveryStatus) => deliveryStatus === EMAIL_DELIVERY_STATUS.UNDELIVERED
);

export const selectMetadata = (state) =>
   selectActionData(getCurrentState(state), setOfferMetadata.type);

export const selectBlocked = (state) => get(selectMetadata(state), 'blocked');

export const selectInsuredChildrenPresent = (state) =>
   get(selectMetadata(state), 'insuredChildrenPresent');

export const selectIndexation = (state) => get(selectMetadata(state), 'indexation');

export const selectOfferProductVersion = (state) => get(selectMetadata(state), 'productVersion');

export const selectIsFetching = (state) =>
   selectIsInProgressStatus(getCurrentState(state), fetchOffer.type);

export const selectIsSaving = (state) =>
   selectIsInProgressStatus(getCurrentState(state), saveOffer.type);

export const selectIsPrintingStatus = (state) =>
   selectIsInProgressStatus(getCurrentState(state), shareByPrinting.type);

export const selectIsSendingStatus = (state) =>
   selectIsInProgressStatus(getCurrentState(state), shareByEmail.type);

export const selectIsSendingFailedStatus = (state) =>
   selectIsFailedStatus(getCurrentState(state), shareByEmail.type);

export const selectIsPrintingFailedStatus = (state) =>
   selectIsFailedStatus(getCurrentState(state), shareByPrinting.type);

export const selectFailedStatusAndEmailDeliveryStatus = createSelector(
   [selectEmailDeliveryUndeliveredStatus, selectIsSendingFailedStatus, selectIsSendingStatus],
   (emailDeliveryUndeliveredStatus, isSendingFailedStatus, isSendingStatus) =>
      (emailDeliveryUndeliveredStatus || isSendingFailedStatus) && !isSendingStatus
);

export const selectConvertingToProposalStatus = (state) =>
   selectIsInProgressStatus(getCurrentState(state), convertToProposal.type);

export const selectPolicyHolder = (state) => get(selectOffer(state), 'policyHolder');
export const selectPolicyHolderBirthDate = (state) =>
   get(selectOffer(state), 'policyHolder.personalInformation.birthDate');

export const selectSalesProcessId = (state) => get(selectOffer(state), 'salesProcess.id');
export const selectId = (state) => get(selectOffer(state), 'id');
export const selectFirstInsuredAdult = (state) => get(selectOffer(state), 'firstInsuredAdult');
export const selectSecondInsuredAdult = (state) => get(selectOffer(state), 'secondInsuredAdult');
export const selectPaymentFrequency = (state) => get(selectOffer(state), 'paymentFrequency');
export const selectCareClause = (state) => get(selectOffer(state), 'salesProcess.careClause');
export const selectSharedByPrinting = (state) => get(selectOffer(state), 'sharedByPrinting');
export const selectSharedByEmail = (state) => get(selectOffer(state), 'sharedByEmail');
export const selectParticipatingAsInsuredAdult = (state) =>
   get(selectOffer(state), 'policyHolder.participatingAsInsuredAdult');
export const selectFirstInsuredAdultEmail = (state) =>
   get(selectOffer(state), 'firstInsuredAdult.email');

export const selectFirstInsuredParticipatingAsPolicyHolder = (state) =>
   get(selectOffer(state), 'firstInsuredAdult.participatingAsPolicyHolder');

export const selectPreviousPaymentFrequency = (state) =>
   selectActionData(getCurrentState(state), setPreviousPaymentFrequency.type);

const selectPolicyHolderFormValue = (state) => selectFormValues(state, 'policyHolder');
export const selectPaymentFrequencyFormValue = (state) =>
   selectFormValues(state, 'paymentFrequency');
export const selectInsuredAdultsFormValue = (state) =>
   selectFormValues(state, 'insuredAdults') || [];
export const selectSharedByPrintingFormValue = (state) =>
   selectFormValues(state, 'sharedByPrinting');
export const selectSharedByEmailFormValue = (state) => selectFormValues(state, 'sharedByEmail');

export const selectPolicyHolderFirstNameFormValue = (state) =>
   selectFormValues(state, 'policyHolder.personalInformation.firstName');
export const selectPolicyHolderSurnameFormValue = (state) =>
   selectFormValues(state, 'policyHolder.personalInformation.surname');

export const selectParticipatingAsInsuredAdultFormValue = (state) =>
   selectFormValues(state, 'policyHolder.participatingAsInsuredAdult');

export const selectFirstInsuredAdultNationalRegisterNumberFormValue = (state) =>
selectFormValues(state, 'insuredAdults[0].personalInformation.nationalRegisterNumber');

export const selectSecondInsuredAdultNationalRegisterNumberFormValue = (state) =>
selectFormValues(state, 'insuredAdults[1].personalInformation.nationalRegisterNumber');

export const selectInsuredIsPolicyHolder = (state) =>
   selectFormValues(state, 'policyHolder.participatingAsInsuredAdult') === SIMPLE_ANSWER.YES;

export const makeSelectPolicyHolderCopyMap = () => {
   return createSelector(
      [
         selectPolicyHolderFirstNameFormValue,
         selectPolicyHolderSurnameFormValue,
         selectPolicyHolderBirthDate,
      ],
      (firstName, surname, birthDate) => {
         return {
            'insuredAdults[0].personalInformation.firstName': firstName,
            'insuredAdults[0].personalInformation.surname': surname,
            'insuredAdults[0].personalInformation.birthDate': birthDate,
         };
      }
   );
};

export const selectFormErrors = (state) => selectFormErrorsValues(state);

export const makeSelectIsOneAdult = () => {
   return createSelector(selectInsuredAdultsFormValue, (insuredAdults) => {
      // TODO: check if isOneInsuredAdult should be false if insuredAdults does not exist
      return insuredAdults.length === 1;
   });
};

export const makeSelectIsOneTimeOnly = () => {
   return createSelector(selectPaymentFrequencyFormValue, (paymentFrequency) => {
      return paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;
   });
};

export const makeSelectIsOneTimeOnlyViolation = () => {
   return createSelector(
      [makeSelectIsOneTimeOnly(), makeSelectIsOneAdult()],
      (isOneTimeOnly, isOneAdult) => {
         return isOneTimeOnly && !isOneAdult;
      }
   );
};

export const makeSelectInitialValues = () => {
   return createSelector(
      [
         selectOffer,
         selectSalesProcessId,
         selectId,
         selectPolicyHolder,
         selectFirstInsuredAdult,
         selectSecondInsuredAdult,
         selectPaymentFrequency,
         selectCareClause,
         selectSharedByPrinting,
         selectSharedByEmail,
         selectParticipatingAsInsuredAdult,
         selectFirstInsuredParticipatingAsPolicyHolder,
      ],
      (
         offer,
         salesProcessId,
         id,
         policyHolder,
         firstInsuredAdult,
         secondInsuredAdult,
         paymentFrequency,
         careClause,
         sharedByPrinting,
         sharedByEmail,
         participatingAsInsuredAdult,
         firstInsuredParticipatingAsPolicyHolder
      ) => {
         if (!offer) {
            return null;
         }
         const initialValues = {
            salesProcessId,
            id,
            //omitting financial information and country because there are no such field on form
            policyHolder: {
               ...omit(policyHolder, 'financialInformation'),
               residentialAddress: omit(policyHolder.residentialAddress, 'country'),
               participatingAsInsuredAdult: participatingAsInsuredAdult
                  ? SIMPLE_ANSWER.YES
                  : SIMPLE_ANSWER.NO,
            },
            insuredAdults: [
               {
                  // Omitting first insured's email to avoid the form being always dirty when IA=PH and changing share offer email (BROKP-8119)
                  ...omit(firstInsuredAdult, 'email'),
                  participatingAsPolicyHolder: firstInsuredParticipatingAsPolicyHolder
                     ? SIMPLE_ANSWER.YES
                     : SIMPLE_ANSWER.NO,
               },
            ],
            paymentFrequency,
            careClause,
            sharedByPrinting,
            sharedByEmail,
         };

         if (secondInsuredAdult) {
            const {participatingAsPolicyHolder, ...restData} = secondInsuredAdult;
            initialValues.insuredAdults.push(restData);
         }
         return initialValues;
      }
   );
};

export const selectPaymentFrequencies = createSelector(
   [selectMetadata, selectPaymentFrequenciesReferences],
   (metadata, paymentFrequencies) => {
      if (!metadata) {
         return;
      }
      const {productVersion, insuredChildrenPresent} = metadata;

      if (productVersion === PRODUCT_VERSIONS.V_2020_01) {
         paymentFrequencies = paymentFrequencies.filter(filterPaymentFrequencies);
      }

      if (insuredChildrenPresent) {
         paymentFrequencies = paymentFrequencies.map(disableOneTimeOnlyOption);
      }

      return paymentFrequencies;
   }
);
export const selectPostalCodeFormValue = (state) =>
   selectFormValues(state, 'policyHolder.residentialAddress.postalCode');

export const selectMunicipalityFormValue = (state) =>
   selectFormValues(state, 'policyHolder.residentialAddress.municipality');

export const selectStreetNameFormValue = (state) =>
   selectFormValues(state, 'policyHolder.residentialAddress.streetName');

export const selectIsPostalCodeValid = createSelector(
   [selectPostalCodeFormValue, selectAsyncFormErrorsValues],
   (postalCode, formAsyncErrors) => {
      return postalCode
         ? postalCode.length === 4 &&
              !get(formAsyncErrors, 'policyHolder.residentialAddress.postalCode', false)
         : false;
   }
);

export const selectIsMunicipalityDisabled = createSelector(
   [selectBlocked, selectIsPostalCodeValid],
   (blocked, isPostalCodeValid) => {
      return blocked || !isPostalCodeValid;
   }
);

export const selectIsStreetNameDisabled = createSelector(
   [selectIsMunicipalityDisabled, selectMunicipalityFormValue],
   (isMunicipalityDisabled, selectedMunicipality) => {
      return isMunicipalityDisabled || !selectedMunicipality;
   }
);

export const selectIsHouseNumberDisabled = createSelector(
   [selectIsMunicipalityDisabled, selectStreetNameFormValue],
   (isMunicipalityDisabled, selectedStreetName) => {
      return isMunicipalityDisabled || !selectedStreetName;
   }
);

export const selectIsAgeOlder = createSelector(
   [selectInsuredAdultsFormValue, selectFormErrors],
   (insuredAdults, formErrors) => {
      if (!isArray(insuredAdults) || !insuredAdults[0]) {
         return false;
      }
      const MAX = 75;
      const birthDate = get(insuredAdults, '[0].personalInformation.birthDate');
      const isBirthDateInvalid = get(
         formErrors,
         'insuredAdults[0].personalInformation.birthDate',
         false
      );
      return !isBirthDateInvalid && ageIsOlder(birthDate, MAX);
   }
);

export const selectIsShowBirthDateInfo = createSelector(
   [selectMetadata, selectIsAgeOlder],
   (metadata, isAgeOlder) => {
      if (!metadata) {
         return false;
      }
      const {blocked, proposalId, needsAndDesiresCompleted} = metadata;

      return !blocked && proposalId && needsAndDesiresCompleted && isAgeOlder;
   }
);

export const selectIsSavingFormInProgress = (state) =>
   selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsFormFetching = createSelector(
   [selectIsFetching, selectIsSavingFormInProgress],
   (isFetching, isSavingFormInProgress) => isFetching && !isSavingFormInProgress
);

export const selectIsSubmitting = (state) => isSubmitting(containerId)(state);

export const selectIsSavingButtonDisabled = createSelector(
   [selectIsSaving, selectIsSubmitting, makeSelectIsOneTimeOnlyViolation()],
   (isSaving, isSubmitting, isOneTimeOnlyViolation) =>
      isSaving || isSubmitting || isOneTimeOnlyViolation
);

export const selectIsEmailNotificationShown = createSelector(
   [selectPolicyHolderFormValue, selectInsuredAdultsFormValue, selectFirstInsuredAdultEmail],
   (policyHolder, insuredAdults, firstInsuredAdultEmail) => {
      const policyHolderEmail = get(policyHolder, 'contactInformation.email');

      if (!policyHolderEmail) {
         return false;
      }

      const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);
      // Including first insured adult's email to be able to check equality (BROKP-8164)
      const firstInsuredObject = adaptInsuredPersonalInfo({
         email: firstInsuredAdultEmail,
         ...insuredAdults[0],
      });
      const secondInsuredObject = adaptInsuredPersonalInfo(insuredAdults[1]);

      return showEmailsEqualityNotification(policyHolderObject, [
         firstInsuredObject,
         secondInsuredObject,
      ]);
   }
);

export const selectInsuredDuplicationError = createSelector(
   [selectInsuredAdultsFormValue, selectFormMeta],
   (insuredAdults, meta) => {
      const firstInsuredObject = adaptInsuredPersonalInfo(insuredAdults[0]);
      const secondInsuredObject = adaptInsuredPersonalInfo(insuredAdults[1]);

      if (!checkPersonalEquality(secondInsuredObject, firstInsuredObject)) {
         return null;
      }

      return areRequiredFieldsTouched(
         [
            'insuredAdults[0].personalInformation.firstName',
            'insuredAdults[0].personalInformation.surname',
            'insuredAdults[0].personalInformation.birthDate',
            'insuredAdults[1].personalInformation.firstName',
            'insuredAdults[1].personalInformation.surname',
            'insuredAdults[1].personalInformation.birthDate',
         ],
         meta
      )
         ? 'validate.insuredAdultAreEqual'
         : null;
   }
);

export const selectHasResidentialAddressSaveError = createSelector(
   selectFormErrors,
   (formErrors) => {
      const residentialAddressError = get(formErrors, 'policyHolder.residentialAddress.streetName');
      return residentialAddressError?.id === 'invalidResidentialAddress';
   }
);
