import {MessageParams} from 'yup/es/types';

export type AgeBoundaries = {
   minAge: number;
   maxAge: number;
};

const validationMessages = {
   required: () => ({id: 'validate.required'}),
   range: (min: number, max: number) => () => ({
      id: 'validate.benefitInRange',
      values: {
         min,
         max,
      },
   }),
   checkSuspiciousSymbols: () => ({id: 'validate.invalidCharacter'}),
   isPayUntilUnderRangeForPeriodicPayment: () => ({id: 'validate.insuredAdultPayUntilUnderRange'}),
   isPayUntilOverRangeForPeriodicPayment: (params: MessageParams & AgeBoundaries) => ({
      id: 'validate.insuredAdultPayUntilOverRange',
      values: {min: params.minAge, max: params.maxAge},
   }),
   invalidDate: () => ({id: 'validate.invalidDate'}),
   isYoungForCoverage: () => ({id: 'validate.insuredAdultUnderAge'}),
   isOldForOneTimePayment: (maxAge: number) => ({
      id: 'validate.insuredAdultOverAgeOneTime',
      values: {max: maxAge},
   }),
   isOldForPeriodicPayment: (maxAge: number) => ({
      id: 'validate.insuredAdultOverAgePeriodical',
      values: {max: maxAge},
   }),
};

export default validationMessages;
