import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {TransitionGroup} from 'react-transition-group';
import {
   NotificationStatus,
   setNotificationsFirstPage,
   useMarkAsRead,
   useNotifications,
} from 'v2/common/api/queries/notifications';
import Notification from 'v2/features/Notifications/components/Notification';

const Notifications = () => {
   const theme = useTheme();
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const close = () => {
      setAnchorEl(null);
      setNotificationsFirstPage();
   };

   const {
      data,
      error,
      isLoading,
      fetchNextPage,
      isFetching,
      isFetchingNextPage,
      hasNextPage,
   } = useNotifications();
   const {mutate: markAsRead} = useMarkAsRead();

   const {notifications, hasUnreadNotification} = React.useMemo(() => {
      const flatNotifications = data?.pages.flat();
      return {
         notifications: flatNotifications,
         hasUnreadNotification:
            flatNotifications?.some(
               (notification) => notification.notificationStatus === NotificationStatus.Unread
            ) ?? false,
      };
   }, [data?.pages]);

   const hasNotifications = notifications?.length !== 0;
   const handleUpdate = (e: React.UIEvent<HTMLUListElement>) => {
      if (isFetching || !hasNextPage || !!error) {
         return;
      }

      const {scrollTop, scrollHeight, clientHeight} = e.currentTarget;
      const pad = 10; // 20px of the bottom
      // Will be greater than 1 if we are about to reach the bottom
      if ((scrollTop + pad) / (scrollHeight - clientHeight) > 1) {
         fetchNextPage();
      }
   };

   const onToggle = (event: React.MouseEvent<HTMLElement>) => {
      if (open) {
         close();
      } else {
         const [firstNotification] = notifications || [];
         if (firstNotification && hasUnreadNotification) {
            markAsRead({toDateTime: firstNotification.created});
         }
         setAnchorEl(event.currentTarget);
      }
   };

   return (
      <Box className="notifications">
         <IconButton
            size="small"
            color="inherit"
            sx={{
               padding: '6px',
               borderRadius: 1,
               backgroundColor: open ? 'primary.dark' : undefined,
               '&:hover': {
                  backgroundColor: 'primary.dark',
               },
            }}
            onClick={onToggle}
         >
            <Badge
               color={!isFetching && hasUnreadNotification ? 'error' : undefined}
               overlap="circular"
               badgeContent={isFetching ? <CircularProgress size={7} color="inherit" /> : ' '}
               variant={isFetching ? 'standard' : 'dot'}
            >
               {!!error ? (
                  <NotificationsOffRoundedIcon data-testid="notifications-off" />
               ) : (
                  <NotificationsRoundedIcon />
               )}
            </Badge>
         </IconButton>
         <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            style={{marginTop: theme.spacing(2)}}
            BackdropProps={{invisible: false}}
            onClose={close}
         >
            <Paper
               sx={{
                  color: 'primary.contrastText',
                  backgroundColor: 'primary.main',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
               }}
            >
               <Typography variant="body2" color="inherit" paddingX={2} paddingY={1}>
                  <FormattedMessage id="notifications.notificationsPanel.title" />
                  {isFetchingNextPage && (
                     <CircularProgress color="inherit" sx={{marginLeft: 1}} size={12} />
                  )}
               </Typography>
            </Paper>
            <List
               onScroll={handleUpdate}
               dense
               sx={{
                  width: 570,
                  backgroundColor: 'background.paper',
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  maxHeight: 500,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                     width: 5,
                  },
                  '&::-webkit-scrollbar-track': {
                     backgroundColor: 'background.paper',
                     borderRadius: 2,
                  },
                  '&::-webkit-scrollbar-thumb': {
                     backgroundColor: 'primary.main',
                     borderRadius: 10,
                  },
               }}
            >
               {isLoading && (
                  <ListItem sx={{justifyContent: 'center'}}>
                     <CircularProgress size={32} />
                  </ListItem>
               )}
               {!isLoading && !hasNotifications && (
                  <ListItem>
                     <ListItemText primaryTypographyProps={{color: 'primary', textAlign: 'center'}}>
                        <FormattedMessage id="notifications.notificationsPanel.emptyNotificationsPlaceholder" />
                     </ListItemText>
                  </ListItem>
               )}
               <TransitionGroup component={null}>
                  {notifications?.map((notification, index, list) => (
                     <Collapse key={notification.id}>
                        <Notification data={notification} hasError={!!error} onClick={close} />
                        <Divider component="li">
                           {list.length === index + 1 && hasNextPage && (
                              <ExpandMoreRoundedIcon color="primary" />
                           )}
                        </Divider>
                     </Collapse>
                  ))}
               </TransitionGroup>
            </List>
         </Popover>
      </Box>
   );
};

export default Notifications;
