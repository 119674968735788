import {Notification} from 'v2/common/api/queries/notifications';
import {logIn as logInAction} from 'containers/App/reducer';
import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
   DOCUMENT_SIGN_STATUS,
   DOCUMENT_STATUS,
   NOTIFICATION_TYPE,
   PARTY_TYPES,
   STEPS,
} from 'shared/constants';
import {getAgentDataCache} from 'v2/common/api/queries/agent';
import {selectProducerAccount} from 'v2/pages/Login/producerAccountSlice';

const notificationDescriptionMap = new Map([
   [
      NOTIFICATION_TYPE.PROPOSAL_IS_WAITING_FOR_SIGNATURE,
      'notifications.notificationsPanel.description.healthDeclaration.insured.completed',
   ],
   [
      NOTIFICATION_TYPE.REMOTE_HEALTH_DECLARATION_COMPLETED,
      'notifications.notificationsPanel.description.healthDeclaration.absentInsured',
   ],
   [
      NOTIFICATION_TYPE.SHARE_OFFER_EMAIL_UNDELIVERED,
      'notifications.notificationsPanel.description.offer.reshare',
   ],
   [
      NOTIFICATION_TYPE.PROPOSAL_WILL_BE_DISCARDED,
      'notifications.notificationsPanel.description.discardMessage',
   ],
]);

const policyPartyLocalizationMap = new Map([
   [
      PARTY_TYPES.FIRST_INSURED_ADULT,
      'notifications.notificationsPanel.description.policyParty.insuredAdult',
   ],
   [
      PARTY_TYPES.SECOND_INSURED_ADULT,
      'notifications.notificationsPanel.description.policyParty.insuredAdult',
   ],
   [
      PARTY_TYPES.POLICY_HOLDER,
      'notifications.notificationsPanel.description.policyParty.policyHolder',
   ],
]);

const documentsSignedMap = new Map([
   [
      PARTY_TYPES.FIRST_INSURED_ADULT,
      new Map([
         [
            DOCUMENT_STATUS.SIGNED,
            'notifications.notificationsPanel.description.signingDocuments.absentInsuredAdultSigned',
         ],
         [
            DOCUMENT_STATUS.REJECTED,
            'notifications.notificationsPanel.description.signingDocuments.absentInsuredAdultRejected',
         ],
         [
            DOCUMENT_STATUS.FAILED,
            'notifications.notificationsPanel.description.signingDocuments.failed',
         ],
      ]),
   ],
   [
      PARTY_TYPES.SECOND_INSURED_ADULT,
      new Map([
         [
            DOCUMENT_STATUS.SIGNED,
            'notifications.notificationsPanel.description.signingDocuments.absentInsuredAdultSigned',
         ],
         [
            DOCUMENT_STATUS.REJECTED,
            'notifications.notificationsPanel.description.signingDocuments.absentInsuredAdultRejected',
         ],
         [
            DOCUMENT_STATUS.FAILED,
            'notifications.notificationsPanel.description.signingDocuments.failed',
         ],
      ]),
   ],
   [
      PARTY_TYPES.POLICY_HOLDER,
      new Map([
         [
            DOCUMENT_STATUS.SIGNED,
            'notifications.notificationsPanel.description.signingDocuments.signed',
         ],
         [
            DOCUMENT_STATUS.REJECTED,
            'notifications.notificationsPanel.description.signingDocuments.rejected',
         ],
         [
            DOCUMENT_STATUS.FAILED,
            'notifications.notificationsPanel.description.signingDocuments.failed',
         ],
      ]),
   ],
]);

const documentsSentToDelaMap = new Map([
   [
      DOCUMENT_SIGN_STATUS.SUCCESSFUL,
      'notifications.notificationsPanel.description.signingDocuments.submittedToDela',
   ],
   [
      DOCUMENT_SIGN_STATUS.REJECTED,
      'notifications.notificationsPanel.description.signingDocuments.notSubmittedToDela',
   ],
]);

export function useNotificationDescription(notification: Notification) {
   const intl = useIntl();
   const {type, discardDate, policyPartyType, status} = notification;
   return React.useMemo(() => {
      if (notificationDescriptionMap.has(type)) {
         const descriptionId = notificationDescriptionMap.get(type);
         const isDiscardType = type === NOTIFICATION_TYPE.PROPOSAL_WILL_BE_DISCARDED;
         return intl.formatMessage(
            {id: descriptionId},
            {discardDate: isDiscardType ? discardDate : undefined}
         );
      }
      if (policyPartyType && documentsSignedMap.has(policyPartyType)) {
         const signedByPartyDescription = documentsSignedMap.get(policyPartyType)!;
         if (status && signedByPartyDescription.has(status)) {
            const signedDescriptionId = signedByPartyDescription.get(status);
            const policyPartyId = policyPartyLocalizationMap.get(policyPartyType);
            const policyParty = intl.formatMessage({id: policyPartyId});
            return intl.formatMessage({id: signedDescriptionId}, {policyParty});
         }
      }
      if (status && documentsSentToDelaMap.has(status)) {
         const sentDescriptionId = documentsSentToDelaMap.get(status);
         return intl.formatMessage({id: sentDescriptionId});
      }
      return null;
   }, [discardDate, intl, policyPartyType, status, type]);
}

export function useNotificationRoute(notification: Notification, onNavigate: () => void) {
   const {type, producerAccountNumber, salesProcessStep, offerId, proposalId} = notification;
   const dispatch = useDispatch();
   const history = useHistory();
   const selectedProducerAccount = useSelector(selectProducerAccount);
   const isOffer = type === NOTIFICATION_TYPE.SHARE_OFFER_EMAIL_UNDELIVERED;
   return React.useCallback(() => {
      const agent = getAgentDataCache();
      if (!agent || !selectedProducerAccount) {
         return;
      }
      let route = isOffer
         ? `/sales-process/offer/${offerId}`
         : `/sales-process/proposals/${proposalId}`;
      if (!isOffer && salesProcessStep && STEPS.hasOwnProperty(salesProcessStep)) {
         route = route.concat(`/${STEPS[salesProcessStep]}`);
      }
      if (producerAccountNumber !== selectedProducerAccount.value) {
         const notificationProducerAccount = agent.producerAccounts.find(
            (account) => account.value === producerAccountNumber
         );
         if (!notificationProducerAccount) {
            return;
         }
         dispatch(logInAction.request(notificationProducerAccount));
      }
      history.push(route);
      onNavigate();
   }, [
      dispatch,
      history,
      isOffer,
      offerId,
      onNavigate,
      selectedProducerAccount,
      producerAccountNumber,
      proposalId,
      salesProcessStep,
   ]);
}
