import SwapVertIcon from '@mui/icons-material/SwapVert';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { usePortfolioParams } from 'v2/pages/Portfolio/hooks';

export const SortIcon = ({ sortingParameter }) => {
  const [{ type, search, page, sort }, setSearchParams] = usePortfolioParams();

  const onClick = () => {
    setSearchParams({ type, page, sort: !sort ? sortingParameter : '' });
  };

  return (
    <SwapVertIcon
      data-testid="sort-icon"
      className={classNames({
        'sort-icon-active': sort,
        'sort-icon-hidden': search,
      })}
      onClick={onClick}
    />
  );
};

SortIcon.propTypes = {
  sortingParameter: PropTypes.string,
};
