import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import {LOADING_MESSAGE} from '../../PremiumBox';

type PremiumBoxItemProps = {
   label: React.ReactNode;
   value?: React.ReactNode;
   inProgress: boolean;
   loadingMessage?: React.ReactNode;
};

export const PremiumBoxItem = ({
   label,
   value = null,
   inProgress,
   loadingMessage = LOADING_MESSAGE,
}: PremiumBoxItemProps) => {
   return (
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
         <Typography variant="inherit">{label}</Typography>
         <Typography variant="inherit" flexShrink={0} fontWeight="bold">
            {inProgress ? loadingMessage : value}
         </Typography>
      </Box>
   );
};

export default PremiumBoxItem;
