import isUndefined from 'lodash/isUndefined';

import { isTrue } from '../services/utils';

const resolveFeatureVisibility = (featureName, defaultValue = true) => {
  // Should show features in local environment. In this case environment variable will be undefined
  if (isUndefined(featureName)) {
    return defaultValue;
  }

  return isTrue(featureName);
};

// temporary solution for automation testing
const testEnvNotification = () => {
  const localStorageValue = localStorage.getItem('_testEnvNotification');

  if (localStorageValue !== null) {
    return isTrue(localStorageValue);
  }

  return resolveFeatureVisibility(process.env.REACT_APP_FEATURES_TEST_ENV_NOTIFICATION, false);
};

// Toggle features depending on environment variables
export const FeatureToggling = {
  policyMigration: resolveFeatureVisibility(process.env.REACT_APP_FEATURES_POLICY_MIGRATION, false),
  testEnvNotification: testEnvNotification(),
  reactAppProductionLocales: resolveFeatureVisibility(
    process.env.REACT_APP_PRODUCTION_LOCALES,
    false
  ),
};
