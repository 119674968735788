import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setGeneralError} from '../../../containers/App/reducer';
import {selectServerError} from '../../../containers/App/selectors';
import {history} from '../../../history';
import {serverErrorsShape} from '../../constants/propTypes';
import AccessDenied from './AccessDenied';
import GeneralError from './GeneralError';
import NotFound from './NotFound';

class ErrorBoundary extends Component {
   state = {hasError: false};

   static propTypes = {
      error: PropTypes.shape({
         status: PropTypes.number,
         errors: serverErrorsShape,
      }),
      clearServerError: PropTypes.func,
   };

   componentDidMount() {
      const {clearServerError} = this.props;
      this.unlisten = history.listen(() => {
         if (this.props.error) {
            clearServerError();
         }
         if (this.state.hasError) {
            this.setState({hasError: false});
         }
      });
   }

   static getDerivedStateFromError() {
      return {hasError: true};
   }

   componentDidCatch(error, info) {
      console.error({error, info});
   }

   componentWillUnmount() {
      this.unlisten();
   }

   render() {
      const {error, children} = this.props;
      const {hasError} = this.state;
      const status = get(error, 'status');

      if (!hasError && !error) {
         return children;
      }

      let errorComponent;
      switch (status) {
         case 401:
         case 403:
            errorComponent = <AccessDenied />;
            break;
         case 404:
            errorComponent = <NotFound />;
            break;
         default:
            errorComponent = <GeneralError />;
      }

      return errorComponent;
   }
}

const mapStateToProps = (state) => ({
   error: selectServerError(state),
});

export default connect(mapStateToProps, {
   clearServerError: setGeneralError.clear,
})(ErrorBoundary);
