import isString from 'lodash/isString';
import pickBy from 'lodash/pickBy';

// eslint-disable-next-line import/no-unresolved, import/extensions
import { getToken } from '../../v2/common/providers/AuthProvider/utils';
import { AUTH_STORAGE_KEY } from '../constants';

export const isJsonContentType = (type) => type?.includes('json');

export const isTextContentType = (type) => type?.includes('text');

const handleResponseType = (response) => {
  const type = response.headers.get('content-type');
  let promise;
  if (isJsonContentType(type)) {
    promise = response.json();
  } else if (isTextContentType(type)) {
    promise = response.text();
  } else {
    promise = response.blob();
  }
  return promise;
};

const removeHateoasLinks = (data) =>
  data !== null && typeof data === 'object' && !Array.isArray(data) && !(data instanceof Blob)
    ? pickBy(data, (value, key) => key !== '_links')
    : data;

async function prepareAuthorizedRequest(headers) {
  const agentNumber = sessionStorage.getItem(AUTH_STORAGE_KEY);
  if (agentNumber) {
    headers.append('Agent-Number', agentNumber);
  }

  const token = await getToken();
  headers.append('Authorization', `Bearer ${token}`);
}

export async function makeRequest(url, body, { method, headers, options }) {
  const response = await fetch(url.toString(), {
    body,
    method,
    headers,
    ...options,
  });

  return new Promise((resolve, reject) => {
    const promise = handleResponseType(response);
    // eslint-disable-next-line promise/catch-or-return
    promise.then((data) => {
      // eslint-disable-next-line promise/always-return
      if (response.status < 400) {
        resolve(removeHateoasLinks(data));
        return;
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({ status: response.status, ...data });
    });
  });
}

export async function makeAuthorizedRequest(url, body, { method, headers, options }) {
  await prepareAuthorizedRequest(headers);
  return makeRequest(url, body, { method, headers, options });
}

export class ApiClient {
  constructor(createRequest) {
    this.makeRequest = createRequest;
  }

  async get(url, body, params) {
    return this.request(url, body, { method: 'GET', ...params });
  }

  async post(url, body, params) {
    return this.request(url, body, { method: 'POST', ...params });
  }

  async put(url, body, params) {
    return this.request(url, body, { method: 'PUT', ...params });
  }

  async delete(url, body, params) {
    return this.request(url, body, { method: 'DELETE', ...params });
  }

  async patch(url, body, params) {
    return this.request(url, body, { method: 'PATCH', ...params });
  }

  async request(url, body, params) {
    if (body) {
      // eslint-disable-next-line no-param-reassign
      body = body instanceof FormData || isString(body) ? body : JSON.stringify(body);
    }

    const headers = new Headers({
      pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    });
    // eslint-disable-next-line no-unused-expressions
    !(body instanceof FormData) &&
      headers.append('Content-type', 'application/json; charset=UTF-8');

    return this.makeRequest(url, body, { headers, ...params });
  }
}

export const apiClient = new ApiClient(makeRequest);
export const authApiClient = new ApiClient(makeAuthorizedRequest);
