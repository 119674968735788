import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export const Routes = {
   root: '/',
   portfolio: '/portfolio',
   commissions: '/commissions',
   proposal: (id: string) => ({
      documents: `/sales-process/proposals/${id}/documents`,
   }),
};

export function useNavigate() {
   const history = useHistory();
   return React.useCallback((path: string) => history.push(path), [history]);
}

export function useSearchParams<SearchParamsType extends ReturnType<typeof Object.fromEntries>>(): [
   Record<keyof SearchParamsType, string>,
   (params: SearchParamsType) => void
] {
   const history = useHistory();
   const {search} = useLocation();

   const setSearchParams = React.useCallback(
      (params: SearchParamsType) => {
         const searchString = new URLSearchParams(params as Record<string, string>).toString();
         history.push({search: searchString});
      },
      [history]
   );

   const searchParams = React.useMemo(() => {
      const params = new URLSearchParams(search);
      return Object.fromEntries(params.entries()) as Record<keyof SearchParamsType, string>;
   }, [search]);

   return [searchParams, setSearchParams];
}
