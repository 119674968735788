import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { formValueSelector } from 'redux-form';

import { CONFIRMATION_TYPES, PROCESS_STATUS, SIGNING_METHODS } from '../../../shared/constants';
import {
  selectActionData,
  selectAsyncActionData,
  selectIsInProgressStatus,
  selectIsSuccessStatus,
} from '../../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../../shared/reducers/selectors';
import { selectIsPolicyHolderAsInsuredAdult } from '../PolicyHolder/selectors';
import { selectIsHealthDeclarationRequired } from '../selectors';

import {
  confirmIdentity,
  containerId,
  fetchData,
  fetchIdentity,
  fetchSigningMethod,
  IdentityConfirmationFormId,
  pollDocuments,
  requestSigningUrl,
  resetSignerDocuments,
  saveSigningMode,
  sendEmailForDownload,
  setActiveSigner,
  setExternalReference,
  setPollingMessageId,
  setProposalStatus,
  setSavingSigningMode,
  setSigners,
  setSigningError,
  SigningMethodFormId,
  submitSigningMethod,
  uploadFirstSideOfIdCard,
  uploadSecondSideOfIdCard,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);
const selectSigningMethodFormValues = formValueSelector(SigningMethodFormId);
const selectIdentityConfirmationFormValues = formValueSelector(IdentityConfirmationFormId);

export const selectIsDataFetching = (state) =>
  selectIsInProgressStatus(getCurrentState(state), fetchData.type);

export const selectIsPolling = (state) =>
  selectIsInProgressStatus(getCurrentState(state), pollDocuments.type);
export const selectIsSubmittingSigningMethod = (state) =>
  selectIsInProgressStatus(getCurrentState(state), submitSigningMethod.type);
export const selectIsSavingSigningMode = (state) =>
  selectIsInProgressStatus(getCurrentState(state), saveSigningMode.type);
export const selectIsSigningModeSaved = (state) =>
  selectIsSuccessStatus(getCurrentState(state), saveSigningMode.type);
export const selectProposalStatus = (state) =>
  selectActionData(getCurrentState(state), setProposalStatus.type);
export const selectPollingMessageId = (state) =>
  selectActionData(getCurrentState(state), setPollingMessageId.type);

export const selectIdentityConfirmationValues = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchIdentity.type);

export const selectIdCardDocuments = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchIdentity.type, 'idCardDocuments');

export const selectIsIdentityConfirmed = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchIdentity.type, 'identityConfirmed');

export const selectIdentityConfirmationType = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchIdentity.type, 'identityConfirmationType');

export const selectActiveSigner = (state) =>
  selectActionData(getCurrentState(state), setActiveSigner.type);

export const selectIdentityConfirmationTypeFormValue = (state) =>
  selectIdentityConfirmationFormValues(state, 'identityConfirmationType');

export const selectIsFirstIdUploading = (state) =>
  selectIsInProgressStatus(getCurrentState(state), uploadFirstSideOfIdCard.type);

export const selectIsSecondIdUploading = (state) =>
  selectIsInProgressStatus(getCurrentState(state), uploadSecondSideOfIdCard.type);

export const selectIsConfirmingIdentity = (state) =>
  selectIsInProgressStatus(getCurrentState(state), confirmIdentity.type);

export const selectIsResetting = (state) =>
  selectIsInProgressStatus(getCurrentState(state), resetSignerDocuments.type);

export const selectSigningMethodFormValue = (state) =>
  selectSigningMethodFormValues(state, 'signingMethod');

export const selectSigningMethodData = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchSigningMethod.type);

export const selectSigners = (state) => selectActionData(getCurrentState(state), setSigners.type);

export const selectIsRequestSigningUrlErrorExist = (state) =>
  selectActionData(getCurrentState(state), setSigningError.type);

export const selectIsSentEmailForDownload = (state) =>
  selectIsSuccessStatus(getCurrentState(state), sendEmailForDownload.type);

export const selectIsSendingEmailForDownload = (state) =>
  selectIsInProgressStatus(getCurrentState(state), sendEmailForDownload.type);

export const selectSignersHasPostponedDocuments = createSelector([selectSigners], (signers) => {
  if (!signers) {
    return false;
  }

  const signersProps = Object.values(signers);

  return signersProps.some((singer) => singer.hasPostponedDocuments);
});

export const selectIsAbsentFlow = createSelector([selectSigners], (signers) => {
  if (!signers) {
    return false;
  }
  const signersProps = Object.values(signers);

  const areEmailsProvided = signersProps.every((signer) =>
    get(signer, 'personalInfo.email', false)
  );

  if (!areEmailsProvided) {
    return false;
  }

  return signersProps.some((signer) => signer.absentPolicyParty);
});

export const selectPolicyHolderSigningMethod = createSelector([selectSigners], (signers) =>
  get(signers, 'POLICY_HOLDER.signingMode')
);

export const selectIsPolicyHolderAbsentPolicyParty = createSelector([selectSigners], (signers) =>
  get(signers, 'POLICY_HOLDER.absentPolicyParty')
);

export const selectShouldShowOnlyRemotelySigningMethodForPH = createSelector(
  [
    selectIsPolicyHolderAbsentPolicyParty,
    selectIsHealthDeclarationRequired,
    selectIsPolicyHolderAsInsuredAdult,
  ],
  (isPolicyHolderAbsentPolicyParty, isHealthDeclarationRequired, isPolicyHolderAsInsuredAdult) =>
    isPolicyHolderAbsentPolicyParty && isHealthDeclarationRequired && isPolicyHolderAsInsuredAdult
);

export const selectProcessStatusSubmissionPostponed = createSelector(
  selectProposalStatus,
  (status) => status === PROCESS_STATUS.SUBMISSION_POSTPONED
);

export const selectShowSigningOptions = createSelector(
  [
    selectIsAbsentFlow,
    selectSignersHasPostponedDocuments,
    selectSigningMethodData,
    selectProcessStatusSubmissionPostponed,
  ],
  (
    isAbsentFlow,
    isSignersHasPostponedDocuments,
    signingMethodData,
    isProcessStatusSubmissionPostponed
  ) =>
    !!signingMethodData &&
    ((!isAbsentFlow && isSignersHasPostponedDocuments) || isProcessStatusSubmissionPostponed)
);

export const selectSigningMethod = (state) => get(selectSigningMethodData(state), 'signingMethod');

export const selectIsRequestingSigningUrl = (state) =>
  selectIsInProgressStatus(getCurrentState(state), requestSigningUrl.type);

export const selectIsSigningMethodDefault = createSelector(
  [selectSigningMethod],
  (signingMethod) => signingMethod === SIGNING_METHODS.DEFAULT
);

export const selectIsSigningMethodFormValueDefault = createSelector(
  [selectSigningMethodFormValue],
  (signingMethod) => signingMethod === SIGNING_METHODS.DEFAULT
);

export const selectIsSigningMethodFormValueRemotely = createSelector(
  [selectSigningMethodFormValue],
  (signingMethod) => signingMethod === SIGNING_METHODS.REMOTELY
);

export const selectInitialValuesSigningMethodForm = createSelector(
  [selectSigningMethodData, selectIsPolicyHolderAsInsuredAdult],
  (signingMethodData, isPolicyHolderInsuredAdult) => {
    if (!signingMethodData) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return isPolicyHolderInsuredAdult
      ? omit(signingMethodData, 'firstInsuredAdult')
      : signingMethodData;
  }
);

export const selectIsShowButton = createSelector(
  [
    selectIsSigningMethodFormValueDefault,
    selectIsSigningMethodDefault,
    selectSigningMethodFormValue,
  ],
  (isSigningMethodFormValueDefault, isSigningMethodDefault, signingMethodData) =>
    !isSigningMethodFormValueDefault && isSigningMethodDefault && !!signingMethodData
);

export const selectShouldShowSuccessPanel = createSelector(
  [selectProposalStatus, selectIsRequestSigningUrlErrorExist],
  (status, isRequestSigningUrlErrorExist) =>
    status === PROCESS_STATUS.IN_REVIEW_BY_DELA && !isRequestSigningUrlErrorExist
);

export const selectShouldShowInfoPanel = createSelector(
  [selectProposalStatus, selectIsRequestSigningUrlErrorExist],
  (status, isRequestSigningUrlErrorExist) =>
    status !== PROCESS_STATUS.SUBMISSION_POSTPONED &&
    status !== PROCESS_STATUS.IN_REVIEW_BY_DELA &&
    !isRequestSigningUrlErrorExist
);

export const selectIdentityConfirmationInitialFormValues = createSelector(
  selectIdentityConfirmationType,
  (identityConfirmationType) =>
    identityConfirmationType
      ? { identityConfirmationType }
      : { identityConfirmationType: CONFIRMATION_TYPES.SIGN_WITH_QUALIFIED_ELECTRONIC_SIGNATURE }
);

export const selectIsConfirmationTypeQualifiedElectronicSignature = createSelector(
  selectIdentityConfirmationTypeFormValue,
  (type) => type === CONFIRMATION_TYPES.SIGN_WITH_QUALIFIED_ELECTRONIC_SIGNATURE
);

export const selectIsConfirmationTypeIDCard = createSelector(
  selectIdentityConfirmationTypeFormValue,
  (type) => type === CONFIRMATION_TYPES.UPLOAD_COPY_OF_ID_CARD
);

export const selectIsIdCardRequired = createSelector(
  [selectIdentityConfirmationTypeFormValue, selectIdCardDocuments],
  (type, idCardDocuments) =>
    type === CONFIRMATION_TYPES.UPLOAD_COPY_OF_ID_CARD && isEmpty(idCardDocuments)
);

export const selectShouldShowConfirmButton = createSelector(
  [selectIdentityConfirmationTypeFormValue, selectIsIdentityConfirmed],
  (type, isIdentityConfirmed) => type !== null && !isIdentityConfirmed
);

export const selectIdCardUploadingStatuses = createSelector(
  [selectIsFirstIdUploading, selectIsSecondIdUploading],
  (isFirstIdUploading, isSecondIdUploading) => [isFirstIdUploading, isSecondIdUploading]
);

export const selectIsSignBlocked = createSelector(
  [selectProposalStatus],
  (proposalStatus) => proposalStatus === PROCESS_STATUS.REJECTED_BY_POLICY_PARTY
);

export const selectIsSignDisabled = createSelector(
  [
    selectIsRequestingSigningUrl,
    selectIsResetting,
    selectIsSavingSigningMode,
    selectSignersHasPostponedDocuments,
    selectIsSendingEmailForDownload,
  ],
  (
    isRequesting,
    isResetting,
    isSavingSigningMode,
    isSignersHasPostponedDocuments,
    isSendingEmailForDownload
  ) =>
    isRequesting ||
    isResetting ||
    isSavingSigningMode ||
    isSignersHasPostponedDocuments ||
    isSendingEmailForDownload
);

// ResignForm selectors
export const selectResignFormInitialValues = (policyPartyType) =>
  createSelector(selectSigners, (signers) => {
    const email = get(signers, `${policyPartyType}.personalInfo.email`);

    return { documentSigner: policyPartyType, email };
  });

// SigningModeForm selectors
export const selectSigningModeFormInitialValues = (policyPartyType) =>
  createSelector(selectSigners, (signers) => {
    const email = get(signers, `${policyPartyType}.personalInfo.email`);
    const signingMode = get(signers, `${policyPartyType}.signingMode`);

    return { documentSigner: policyPartyType, email, signingMode };
  });

export const selectSavingSigningMode = (state) =>
  selectActionData(getCurrentState(state), setSavingSigningMode.type);

export const selectSavingSigningModeByPolicyParty = (policyPartyType) =>
  createSelector(
    selectSavingSigningMode,
    (savingSigningMode) => savingSigningMode[policyPartyType]
  );

export const selectIsSigningModeFormValueRemotely = (policyPartyType) => (state) => {
  const form = `${policyPartyType}/signingModeForm`;
  const formSelector = formValueSelector(form);

  return formSelector(state, 'signingMode') === SIGNING_METHODS.REMOTELY;
};

export const selectExternalReferenceValue = (state) =>
  selectActionData(getCurrentState(state), setExternalReference.type);
