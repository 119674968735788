import { FormattedMessage } from 'react-intl';

type PortfolioHeaderProps = {
  goToPortfolio: () => void;
};

export const PortfolioHeader = ({ goToPortfolio }: PortfolioHeaderProps) => (
  <>
    <button type="button" className="link portfolio-title" onClick={goToPortfolio}>
      <FormattedMessage id="header.portfolio" />
    </button>
    <button type="button" className="link" onClick={goToPortfolio}>
      <FormattedMessage id="homepage.viewMore" />
    </button>
  </>
);
