import React from 'react';
import { useIntl } from 'react-intl';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

type MenuPanelContentProps = {
  title: React.ReactNode;
  text: React.ReactNode;
  linkText?: React.ReactNode;
  hrefId?: string;
  children?: React.ReactNode;
};

const MenuPanelContent = ({ title, text, linkText, hrefId, children }: MenuPanelContentProps) => {
  const intl = useIntl();

  return (
    <Box sx={{ paddingRight: 1 }}>
      <Typography fontWeight="bold" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {text}
      </Typography>
      {hrefId && (
        <Typography variant="caption" gutterBottom>
          <Link
            color="inherit"
            href={intl.formatMessage({ id: hrefId })}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
          >
            {linkText}
            <ChevronRightRoundedIcon fontSize="inherit" />
          </Link>
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default MenuPanelContent;
