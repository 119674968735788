import { benefitLowerRangeValue } from 'shared/constants/fields';
import { PaymentFrequency } from 'v2/common/api/queries/premiumCalculation';
import validationMessages from 'v2/common/utils/validationMessages';
import Yup from 'v2/common/utils/yup';
import {
  PremiumCalculationFormValues,
  PremiumCalculationFormValuesOldFormat,
} from 'v2/features/PremiumCalculation/components/PremiumCalculation/PremiumCalculation';
import { SchemaOf } from 'yup';

const benefitSchema = Yup.object().shape({
  amount: Yup.number().nullable().range(benefitLowerRangeValue, 10000).required(),
});
const birthDateSchema = Yup.date()
  .typeError(validationMessages.invalidDate())
  .format()
  .required()
  .isYoungForCoverage();
const payUntilAgeSchema = Yup.number().required();

export const premiumCalculationSchema: SchemaOf<PremiumCalculationFormValues> = Yup.object().shape({
  insuredAdults: Yup.array()
    .of(
      Yup.object().shape({
        benefit: benefitSchema,
        personalInformation: Yup.object().shape({
          birthDate: birthDateSchema,
        }),
        payUntilAge: payUntilAgeSchema,
      })
    )
    .when('paymentFrequency', {
      is: (paymentFrequency: PaymentFrequency) => paymentFrequency === PaymentFrequency.OneTimeOnly,
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              benefit: benefitSchema,
              personalInformation: Yup.object().shape({
                birthDate: birthDateSchema.isOldForOneTimePayment(),
              }),
              payUntilAge: payUntilAgeSchema.nullable(),
            })
          )
          .length(1),
      otherwise: (schema) =>
        schema
          .of(
            Yup.object().shape({
              benefit: benefitSchema,
              personalInformation: Yup.object().shape({
                birthDate: birthDateSchema.isOldForPeriodicPayment(),
              }),
              payUntilAge: payUntilAgeSchema
                .isPayUntilUnderRangeForPeriodicPayment()
                .isPayUntilOverRangeForPeriodicPayment(),
            })
          )
          .min(1)
          .max(2),
    })
    .required(),
  paymentFrequency: Yup.mixed().oneOf(Object.values(PaymentFrequency)).required(),
});

// TODO Should be removed in https://delanet.atlassian.net/browse/BROKP-8873
export const premiumCalculationOldFormatSchema: SchemaOf<PremiumCalculationFormValuesOldFormat> =
  Yup.object().shape({
    insuredAdults: Yup.array()
      .of(
        Yup.object().shape({
          benefit: benefitSchema,
          birthDate: birthDateSchema,
          payUntilAge: payUntilAgeSchema,
        })
      )
      .required(),
    paymentFrequency: Yup.mixed().oneOf(Object.values(PaymentFrequency)).required(),
  });
