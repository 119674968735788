import Link, {LinkProps} from '@mui/material/Link';
import React from 'react';

type EmailLinkProps = Omit<LinkProps, 'href'> & {
   email: string;
};

const EmailLink = ({email, ...restProps}: EmailLinkProps) => {
   return (
      <Link href={`mailto:${email}`} underline="always" color="inherit" {...restProps}>
         {email}
      </Link>
   );
};

export default EmailLink;
