import {Action} from '../../shared/services/ActionHelpers';
import {createReducer} from '../../shared/reducers/createReducer';

export const containerId = 'Popovers';

export const initializePopover = new Action(`${containerId}/INITIALIZE_POPOVER`, {});
export const resetPopover = new Action(`${containerId}/RESET_POPOVER`);

export const showPopover = new Action(`${containerId}/SHOW`);
export const hidePopover = new Action(`${containerId}/HIDE`);

export const nextPopoverStep = new Action(`${containerId}/NEXT_STEP`);
export const prevPopoverStep = new Action(`${containerId}/PREV_STEP`);

export const setPopover = new Action(`${containerId}/SET_POPOVER`);
export const setPopoverShow = new Action(`${containerId}/SET_POPOVER_SHOW`);
export const setPopoverStep = new Action(`${containerId}/SET_POPOVER_STEP`);

export const toggleAnimation = new Action(`${containerId}/ANIMATION_TOGGLE`);

const actionToHandlerMap = new Map([
   [
      setPopover.ACTION,
      (draft, {payload: {popoverId, popover}}) => {
         draft[popoverId] = popover;
      },
   ],
   [
      setPopoverShow.ACTION,
      (draft, {payload: {popoverId, show}}) => {
         draft[popoverId].show = show;
      },
   ],
   [
      setPopoverStep.ACTION,
      (draft, {payload: {popoverId, step}}) => {
         draft[popoverId].step = step;
      },
   ],
   [
      toggleAnimation.ACTION,
      (draft, {payload: {popoverId, animationProp}}) => {
         draft[popoverId][animationProp] = !draft[popoverId][animationProp];
      },
   ],
]);

export default createReducer(null, null, actionToHandlerMap);
