import ThemeProvider from 'common/providers/ThemeProvider';
import MainContent from 'containers/App/components/MainContent';
import ProposalProducerAccount from 'containers/App/components/ProposalProducerAccount/ProposalProducerAccount';
import React from 'react';
import {useSelector} from 'react-redux';
import {Route, Router, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocalizationProvider from 'v2/common/providers/LocalizationProvider';
import QueryProvider from 'v2/common/providers/QueryProvider/QueryProvider';
import Login from 'v2/pages/Login';
import {selectProducerAccount} from 'v2/pages/Login/producerAccountSlice';
import {history} from '../../history';
import {toastHandler} from '../../shared/services';
import {selectLocale} from './appSlice';

const App = () => {
   const producerAccount = useSelector(selectProducerAccount);
   const locale = useSelector(selectLocale);

   return (
      <div className="App">
         <Router history={history}>
            <LocalizationProvider locale={locale}>
               <ToastContainer {...(toastHandler.options as any)} />
               <QueryProvider>
                  <ThemeProvider>
                     {!producerAccount ? (
                        <Login />
                     ) : (
                        <Switch>
                           <Route path="/documents-signed/:id" exact>
                              <ProposalProducerAccount />
                           </Route>
                           <Route>
                              <MainContent />
                           </Route>
                        </Switch>
                     )}
                  </ThemeProvider>
               </QueryProvider>
            </LocalizationProvider>
         </Router>
      </div>
   );
};

export default App;
