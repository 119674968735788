import {all, spawn} from 'redux-saga/effects';
import applicationSagas from './containers/App/sagas';
import initiateSalesProcessSagas from './containers/CalculatePremium/InitiateSalesProcess/sagas';
import formWrapperSagas from './containers/FormWrapper/sagas';
import formWrapperBaseSagas from './containers/FormWrapper/sagas-base';
import navigationPaneSagas from './containers/NavigationPane/sagas';
import offerDetailsSagas from './containers/OfferDetails/sagas';
import popoverContainerSagas from './containers/PopoverContainer/sagas';
import premiumBoxSagas from './containers/PremiumBox/sagas';
import AdditionalInformationSagas from './containers/ProposalDetails/AdditionalInformation/sagas';
import beneficiariesSagas from './containers/ProposalDetails/Beneficiaries/sagas';
import documentsSagas from './containers/ProposalDetails/Documents/sagas';
import healthDeclarationSagas from './containers/ProposalDetails/HealthDeclaration/sagas';
import insuredSagas from './containers/ProposalDetails/Insured/sagas';
import needsAndDesiresSagas from './containers/ProposalDetails/NeedsAndDesires/sagas';
import policyHolderSagas from './containers/ProposalDetails/PolicyHolder/sagas';
import proposalDetailsSagas from './containers/ProposalDetails/sagas';
import referencesSagas from './shared/references/sagas';

export default function* rootSaga() {
   const sagas = [
      offerDetailsSagas,
      premiumBoxSagas,
      navigationPaneSagas,
      proposalDetailsSagas,
      needsAndDesiresSagas,
      policyHolderSagas,
      insuredSagas,
      beneficiariesSagas,
      healthDeclarationSagas,
      AdditionalInformationSagas,
      documentsSagas,
      initiateSalesProcessSagas,
      applicationSagas,
      referencesSagas,
      popoverContainerSagas,
      formWrapperBaseSagas,
      formWrapperSagas,
   ];

   yield all(sagas.map((saga) => spawn(saga)));
}
