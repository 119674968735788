import {Action, AsyncAction} from '../../shared/services/ActionHelpers';
import {createReducer} from '../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../shared/reducers/selectors';

export const containerId = 'FormWrapper';
export const getCurrentState = getStateByContainerId(containerId);

export const setActiveFormAction = new Action(`${containerId}/SET_ACTIVE_FORM`);

export const handleNavigationButtonClickAction = new AsyncAction(
   `${containerId}/HANDLE_NAVIGATION_BUTTON_CLICK`
);
export const proposalNavigate = new Action(`${containerId}/NAVIGATION_BUTTON_CLICK`);

export const handleConvertToOfferButtonClickAction = new AsyncAction(
   `${containerId}/HANDLE_CONVERT_TO_OFFER_BUTTON_CLICK`
);
export const convertToOfferButtonClickAction = new Action(
   `${containerId}/CONVERT_TO_OFFER_BUTTON_CLICK`
);

export const handleConvertToProposalButtonClickAction = new AsyncAction(
   `${containerId}/HANDLE_CONVERT_TO_PROPOSAL_BUTTON_CLICK`
);
export const convertToProposalButtonClickAction = new Action(
   `${containerId}/CONVERT_TO_PROPOSAL_BUTTON_CLICK`
);

export const handleSubmitButtonClickAction = new AsyncAction(
   `${containerId}/HANDLE_SUBMIT_BUTTON_CLICK`
);
export const submitButtonClickAction = new Action(`${containerId}/SUBMIT_BUTTON_CLICK`);

export const handleSaveButtonClickAction = new AsyncAction(
   `${containerId}/HANDLE_SAVE_BUTTON_CLICK`
);
export const saveButtonClickAction = new Action(`${containerId}/SAVE_BUTTON_CLICK`);

export const validateFormAction = new AsyncAction(`${containerId}/VALIDATE_FORM`);
export const showSuccessToast = new Action(`${containerId}/SHOW_SUCCESS_TOAST`);
export const setFormSavingAction = new Action(`${containerId}/SET_FORM_SAVING`, false);

export default createReducer([setActiveFormAction, setFormSavingAction]);
