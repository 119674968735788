import {useQuery, UseQueryOptions} from 'react-query';
import {ENDPOINTS} from 'shared/constants';
import {useAxios} from 'v2/common/providers/AxiosProvider';

export interface IStatistics {
   offersCount: number;
   proposalsCount: number;
}

export function useStatistics(options: UseQueryOptions<unknown, unknown, unknown, 'statistics'>) {
   const axios = useAxios();
   return useQuery(
      'statistics',
      () => {
         return axios
            .get<IStatistics>(`${ENDPOINTS.salesProcesses}/statistics`)
            .then((result) => result.data);
      },
      options
   );
}
