import React from 'react';
import {toast, Zoom} from 'react-toastify';
import ToastBody from './components/ToastBody';

export const options = {
   position: 'top-center',
   autoClose: false,
   newestOnTop: true,
   closeOnClick: false,
   transition: Zoom,
   draggable: true,
   draggablePercent: 40,
   hideProgressBar: true,
   pauseOnHover: false,
   className: 'toast-container',
   toastClassName: 'toast-item',
   bodyClassName: 'toast-item-body',
   closeButton: false,
};

export const TOAST_AUTOCLOSE_TIME = {
   SUCCESS: 5000,
   WARNING: 7000,
   ERROR: 9000,
};

function uniqueToast(messageProps, handler) {
   close(messageProps.id);
   return handler(messageProps);
}

export function success(messageProps, options) {
   return toast.success(<ToastBody messageProps={messageProps} type={toast.TYPE.SUCCESS} />, {
      autoClose: TOAST_AUTOCLOSE_TIME.SUCCESS,
      ...options,
   });
}

export function uniqueSuccess(messageProps, options) {
   return uniqueToast(messageProps, (props) => success(props, {...options, toastId: props.id}));
}

export function warning(messageProps, options) {
   return toast.warn(<ToastBody messageProps={messageProps} type={toast.TYPE.WARNING} />, {
      autoClose: TOAST_AUTOCLOSE_TIME.WARNING,
      ...options,
   });
}

export function uniqueWarning(messageProps, options) {
   return uniqueToast(messageProps, (props) => warning(props, {...options, toastId: props.id}));
}

export function error(messageProps, options) {
   return toast.error(<ToastBody messageProps={messageProps} type={toast.TYPE.ERROR} />, {
      autoClose: TOAST_AUTOCLOSE_TIME.ERROR,
      ...options,
   });
}

export function uniqueError(messageProps, options) {
   return uniqueToast(messageProps, (props) => error(props, {...options, toastId: props.id}));
}

export function successSave() {
   return uniqueSuccess({id: 'toasts.success.successSave'});
}

export function unexpectedIssue() {
   return uniqueWarning({id: 'toasts.warning.unexpectedIssue'});
}

export function close(toastId) {
   if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
   }
}

export function closeAll() {
   toast.dismiss();
}
