import { useMutation, useQuery } from 'react-query';

import { ENDPOINTS } from 'shared/constants';
import { downloadFile } from 'shared/services/sagaHelpers';
import { useAxios } from 'v2/common/providers/AxiosProvider';

import { LANGUAGES } from '../../../../shared/constants';
import { toastHandler } from '../../../../shared/services';
import { transformPolicyUpdatesResponse } from '../../utils/apiTransformations';

export enum LOCALES {
  NL = 'nl-BE',
  FR = 'fr-FR',
}

export interface PolicyUpdatesResponse {
  blobReference: string;
  index: number;
  language: keyof typeof LANGUAGES;
  name: string;
  product: string;
}

export interface PolicyUpdatesDoc {
  index: number;
  [LOCALES.NL]: PolicyUpdatesDocInfo;
  [LOCALES.FR]: PolicyUpdatesDocInfo;
}

export interface PolicyUpdatesDocInfo {
  reference: string;
  name: string;
}

export function usePolicyUpdates() {
  const axios = useAxios();

  return useQuery<ReturnType<typeof transformPolicyUpdatesResponse>>('policyUpdates', async () => {
    const { data } = await axios.get<PolicyUpdatesResponse[]>(
      `${ENDPOINTS.policyUpdates}/policies/mapping`
    );

    return transformPolicyUpdatesResponse(data);
  });
}

export function useDownloadPolicyUpdates() {
  const axios = useAxios();

  return useMutation(
    async ({ reference }: PolicyUpdatesDocInfo) => {
      const { data } = await axios.post<Blob>(
        `${ENDPOINTS.policyUpdates}/policy/content`,
        { reference },
        { responseType: 'blob' }
      );

      return data;
    },
    {
      onSuccess: (data, variables) => {
        downloadFile(data, variables.name);
        toastHandler.success({
          id: 'toasts.success.documentDownloaded',
          values: { title: variables.name },
        });
      },
    }
  );
}
