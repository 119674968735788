import {createReducer} from '../../shared/reducers/createReducer';
import {AsyncAction, Action} from '../../shared/services/ActionHelpers';

export const containerId = 'PremiumBox';
export const fetchCalculationData = new AsyncAction(`${containerId}/CALCULATION_DATA_FETCH`);
export const recalculatePremium = new AsyncAction(`${containerId}/PREMIUM_RECALCULATE`);
export const setCalculationData = new Action(`${containerId}/CALCULATION_DATA_SET`);
export const checkIfCalculationOutdated = new Action(`${containerId}/CALCULATION_OUTDATED_CHECK`);
export const updatePersonalInformation = new Action(`${containerId}/PERSONAL_INFORMATION_UPDATE`);
export const updateCalculationOnPolicyHolderPage = new Action(
   `${containerId}/CALCULATION_DATA_ON_POLICY_PAGE_UPDATE`
);

export default createReducer([fetchCalculationData, recalculatePremium, setCalculationData]);
