import {createSelector} from '@reduxjs/toolkit';
import get from 'lodash/get';
import {PRODUCT_VERSIONS} from '../../shared/constants';
import {selectActionData, selectIsInProgressStatus} from '../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../shared/reducers/selectors';
import {selectOfferProductVersion} from '../OfferDetails/selectors';
import {selectProposalProductVersion} from '../ProposalDetails/selectors';
import {containerId, fetchCalculationData, recalculatePremium, setCalculationData} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectCalculationData = (state) => {
   return selectActionData(getCurrentState(state), setCalculationData.type);
};

export const selectFirstInsuredAdult = createSelector(selectCalculationData, (calculationData) =>
   get(calculationData, 'firstInsuredAdult')
);

export const selectSecondInsuredAdult = createSelector(selectCalculationData, (calculationData) =>
   get(calculationData, 'secondInsuredAdult')
);

export const selectFetchingStatus = (state) =>
   selectIsInProgressStatus(getCurrentState(state), fetchCalculationData.type);

export const selectCalculatingStatus = (state) =>
   selectIsInProgressStatus(getCurrentState(state), recalculatePremium.type);

export const selectProductVersion = createSelector(
   [selectProposalProductVersion, selectOfferProductVersion],
   (proposalProductVersion, offerProductVersion) => {
      return proposalProductVersion || offerProductVersion;
   }
);

export const selectShowCareClause = createSelector(
   [selectProductVersion, selectCalculationData],
   (productVersion, calculationData) => {
      if (calculationData?.secondInsuredAdult) {
         return productVersion === PRODUCT_VERSIONS.V_2019_10;
      }
      return false;
   }
);

export const selectFirstInsuredAdultBirthDate = (state) =>
   selectActionData(
      getCurrentState(state),
      setCalculationData.type,
      'firstInsuredAdult.personalInformation.birthDate'
   );
