import React, {useMemo} from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import {bool, object, string, node, oneOfType, element} from 'prop-types';
import Tooltip from '../../../../v2/common/components/Tooltip';
import FieldInfo from '../FieldInfo';
import FieldMeta from '../FieldMeta';
import {resolveError} from '../formUtils';
import FieldError from '../FieldError';
import classes from './styles.module.scss';

export const Checkbox = ({
   name,
   label,
   hint,
   required,
   className,
   inputClassName,
   labelClassName,
   rounded,
   ...restProps
}) => {
   const id = useMemo(() => `cb-${name}-${uniqueId()}`, [name]);

   return (
      <div className={classNames(classes.checkboxWrapper, className, {[classes.rounded]: rounded})}>
         <input
            id={id}
            name={name}
            className={classNames(classes.checkbox, inputClassName)}
            type="checkbox"
            {...restProps}
         />
         <label htmlFor={id} className={classNames(classes.label, labelClassName)}>
            <span className={classNames(classes.labelText, {[classes.required]: required})}>
               {label}
            </span>
            {hint && <Tooltip>{hint}</Tooltip>}
         </label>
      </div>
   );
};

Checkbox.propTypes = {
   name: string,
   label: string,
   hint: node,
   required: bool,
   labelClassName: string,
   inputClassName: string,
};

const CheckboxControl = ({
   id,
   input,
   meta,
   label,
   hint,
   info = false,
   required = false,
   disabled = false,
   metaHeightAuto = false,
   hideMeta,
   serverError,
   hasServerError,
   labelClassName,
   inputClassName,
}) => {
   const inputName = id ?? input?.name;
   const error = !disabled && resolveError(input.value, meta, hasServerError, serverError);
   const isFieldInfoShown = !error && info;

   return (
      <div className={classes.root}>
         <Checkbox
            name={inputName}
            label={label}
            hint={hint}
            className={classNames({
               [classes.hasError]: Boolean(error),
            })}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            disabled={disabled}
            required={required}
            {...input}
         />
         {!hideMeta && (
            <FieldMeta metaHeightAuto={metaHeightAuto}>
               {error && <FieldError message={error} />}
               {isFieldInfoShown && <FieldInfo message={info} />}
            </FieldMeta>
         )}
      </div>
   );
};

CheckboxControl.propTypes = {
   id: string,
   input: object,
   meta: object,
   label: string,
   hint: node,
   info: oneOfType([bool, element, string]),
   required: bool,
   disabled: bool,
   metaHeightAuto: bool,
   hideMeta: bool,
   serverError: oneOfType([bool, object]),
   hasServerError: bool,
   labelClassName: string,
   inputClassName: string,
   rounded: bool,
};

export default CheckboxControl;
