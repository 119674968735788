import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import PopoverContainer, {POSITION} from '../../../PopoverContainer';
import {selectIsPolicyHolderForm} from '../../selectors';
import './styles.scss';

export const SUBMIT_BUTTON_POPOVER_ID = 'SubmitButtonPopover';

export const SubmitButtonPopover = ({children, shouldShowPopover}) => {
   if (!shouldShowPopover) {
      return children;
   }

   const titleMessage = <FormattedMessage id="popovers.howItWorksPanel.title" />;
   const contentMessage = (
      <FormattedMessage
         id="popovers.howItWorksPanel.policyHolder.content.step-1"
         values={{br: <br />}}
      />
   );

   return (
      <div className="submit-button-popover">
         <PopoverContainer
            content={contentMessage}
            popoverId={SUBMIT_BUTTON_POPOVER_ID}
            position={POSITION.RIGHT}
            title={titleMessage}
         >
            {children}
         </PopoverContainer>
      </div>
   );
};

SubmitButtonPopover.propTypes = {
   children: PropTypes.node.isRequired,
   shouldShowPopover: PropTypes.bool,
};

const mapStatesToProps = (state) => ({
   shouldShowPopover: selectIsPolicyHolderForm(state),
});

export default connect(mapStatesToProps)(SubmitButtonPopover);
