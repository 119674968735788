import React from 'react';
import './styles.scss';

const CirclesLoader = () => (
   <div className="circles-loader">
      <span />
      <span />
      <span />
   </div>
);

export default CirclesLoader;
