import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { setExternalReference } from 'containers/ProposalDetails/Documents/reducer';
import Spinner from 'shared/components/Spinner';
import { useProposalProducerAccount } from 'v2/common/api/queries/proposal/producerAccount';

const ProposalProducerAccount = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const externalReference = new URLSearchParams(search).get('ExternalReference');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  dispatch(setExternalReference.action(externalReference));

  useProposalProducerAccount(id);

  return <Spinner fixed />;
};

export default ProposalProducerAccount;
