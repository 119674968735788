import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CirclesLoader from '../CirclesLoader';

import './styles.scss';

const ActionButton = ({
   type = 'button',
   className,
   action = false,
   disabled = false,
   onClick = () => {},
   children,
   ...otherProps
}) => (
   <button
      {...otherProps}
      type={type}
      className={classNames('button', className, {handling: action})}
      disabled={action || disabled}
      onClick={onClick}
   >
      {action && <CirclesLoader />}
      {children}
   </button>
);

ActionButton.propTypes = {
   id: PropTypes.string,
   type: PropTypes.string,
   className: PropTypes.string,
   action: PropTypes.bool,
   disabled: PropTypes.bool,
   onClick: PropTypes.func,
   children: PropTypes.node,
};

export default ActionButton;
