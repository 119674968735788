import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {CommissionsIcon} from 'common/styles/icons/CommissionsIcon';
import {PolicyUpdatesIcon} from 'common/styles/icons/PolicyUpdatesIcon';
import {PortfolioIcon} from 'common/styles/icons/PortfolioIcon';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useOpenDrawerPanel} from 'v2/features/app-drawer/hooks';
import {AppDrawerPanel} from 'v2/features/app-drawer/types';
import {selectIsSaving} from 'v2/features/app-header/selectors';
import Notifications from 'v2/features/Notifications';
import {usePermissions} from 'v2/features/permissions/hooks';
import {Permission} from 'v2/features/permissions/types';
import {selectProducerAccountLabel} from 'v2/pages/Login/producerAccountSlice';
import {AppToolbar, DelaLogo, NavLink} from './components';

const portfolioPermissions = [Permission.OfferList, Permission.ProposalList, Permission.PolicyList];

const Logo = styled(DelaLogo)({alignSelf: 'center'});

const AppHeader = () => {
   const intl = useIntl();
   const saving = useSelector(selectIsSaving);
   const hasPortfolioPermission = usePermissions(portfolioPermissions, 'some');
   const hasPolicyTemplatesListPermission = usePermissions(Permission.PolicyTemplatesList);
   const hasCommissionsListPermission = usePermissions(Permission.CommissionsList);
   const hasNotificationsListPermission = usePermissions(Permission.NotificationsList);
   const hasPremiumCalculationPermission = usePermissions(Permission.PremiumCalculation);

   return (
      <AppToolbar inProgress={saving}>
         <Stack direction="row" spacing={1} flexGrow={1} flexWrap="nowrap" alignItems="center">
            <Logo />
            <NavLink exact to="/">
               <HomeRoundedIcon titleAccess={intl.formatMessage({id: 'icons.homepage'})} />
            </NavLink>
            {hasPortfolioPermission && (
               <NavLink exact to="/portfolio" icon={<PortfolioIcon fontSize="small" />}>
                  <FormattedMessage id="header.portfolio" />
               </NavLink>
            )}
            {hasPolicyTemplatesListPermission && (
               <NavLink exact to="/policy-updates" icon={<PolicyUpdatesIcon fontSize="small" />}>
                  <FormattedMessage id="header.policyUpdates" />
               </NavLink>
            )}
            {hasCommissionsListPermission && (
               <NavLink exact to="/commissions" icon={<CommissionsIcon fontSize="small" />}>
                  <FormattedMessage id="header.commissions" />
               </NavLink>
            )}
         </Stack>
         <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
            <ProducerAccountLabel />
            {hasNotificationsListPermission && <Notifications />}
            {hasPremiumCalculationPermission && <CalculatePremiumButton />}
         </Stack>
         <MenuButton />
      </AppToolbar>
   );
};

const ProducerAccountLabel = () => {
   const producerAccount = useSelector(selectProducerAccountLabel);

   if (!producerAccount) {
      return null;
   }

   const {name, type} = producerAccount;

   return (
      <Box marginRight={1} textAlign="right">
         <Typography variant="body2" noWrap>
            {name}
            {type && (
               <>
                  <br />
                  <FormattedMessage id={`accountTypes.${type}`} />
               </>
            )}
         </Typography>
      </Box>
   );
};

const MenuButton = () => {
   const openPanel = useOpenDrawerPanel();
   const handleClick = () => {
      openPanel(AppDrawerPanel.Menu);
   };
   return (
      <Button
         variant="outlined"
         sx={{marginLeft: 2, backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}}}
         startIcon={<MenuRoundedIcon />}
         disableRipple
         onClick={handleClick}
      >
         <FormattedMessage id="header.menu" />
      </Button>
   );
};

const CalculatePremiumButton = () => {
   const openPanel = useOpenDrawerPanel();
   const handleClick = () => {
      openPanel(AppDrawerPanel.CalculatePremium);
   };

   return (
      <IconButton
         size="small"
         color="inherit"
         sx={{borderRadius: 1, padding: '6px', '&:hover': {backgroundColor: 'primary.dark'}}}
         disableRipple
         onClick={handleClick}
      >
         <CalculateRoundedIcon />
      </IconButton>
   );
};

export default AppHeader;
