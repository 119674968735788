import {createReducer} from '../../../shared/reducers/createReducer';
import {AsyncAction} from '../../../shared/services/ActionHelpers';

export const containerId = 'NeedsAndDesires';
export const NEEDS_AND_DESIRES_SECOND_VERSION = 2;

export const fetchNeedsAndDesires = new AsyncAction(`${containerId}/NEEDS_DESIRES_FETCH`);
export const saveNeedsAndDesires = new AsyncAction(`${containerId}/NEEDS_DESIRES_SAVE`);

export default createReducer([fetchNeedsAndDesires, saveNeedsAndDesires]);
