import omit from 'lodash/omit';
import {createReducer} from '../../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../../shared/services/ActionHelpers';
import {SECTIONS} from '../../../shared/constants';

export const containerId = 'Insured';
export const saveInsured = new AsyncAction(`${containerId}/INSURED_SAVE`);
export const removeInsuredChild = new Action(`${containerId}/INSURED_CHILD_REMOVE`);

export const fetchInsuredAdults = new AsyncAction(`${containerId}/INSURED_ADULTS_FETCH`);
export const fetchInsuredChildren = new AsyncAction(`${containerId}/INSURED_CHILDREN_FETCH`);

export const omitInsuredAdultServerErrors = new Action(
   `${containerId}/INSURED_ADULT_SERVER_ERRORS_OMIT`
);

export const omitInsuredChildServerErrors = new Action(
   `${containerId}/INSURED_CHILD_SERVER_ERRORS_OMIT`
);

const actionToHandlerMap = new Map([
   [
      fetchInsuredAdults.UPDATE,
      (draft, {payload}) => {
         draft[fetchInsuredAdults.type].data = {
            [SECTIONS.FIRST_INSURED_ADULT]: payload[0],
            [SECTIONS.SECOND_INSURED_ADULT]: payload[1],
         };
      },
   ],
   [
      removeInsuredChild.ACTION,
      (draft, {payload}) => {
         draft[fetchInsuredChildren.type].data = draft[
            fetchInsuredChildren.type
         ].data.map((el, index) => (index === payload ? omit(el, 'insuredChildId') : el));
      },
   ],
]);

export default createReducer(
   [saveInsured, fetchInsuredAdults, fetchInsuredChildren],
   null,
   actionToHandlerMap
);
