export enum Permission {
  PolicyAKListReportDownload = 'POLICY_AK_LIST_REPORT_DOWNLOAD',
  AnalysisPerformer = 'UI_NEEDS_AND_DESIRES_ANALYSIS_PERFORMER',
  BadgeNumber = 'UI_ADDITIONAL_INFORMATION_BADGE_NUMBER',
  CommissionsList = 'COMMISSIONS_LIST',
  Contacts = 'UI_SIDEPANEL_CONTACTS',
  CreateOffer = 'OFFER_CREATE',
  CreateProposal = 'PROPOSAL_CREATE',
  DELActive = 'UI_SIDEPANEL_DELA_ACTIVE',
  DeleteNotifications = 'NOTIFICATIONS_DELETE',
  DENTAgent = 'PRODUCTION_DENT',
  ExternalAgent = 'PRODUCTION_EXTERNAL',
  ExternalReference = 'UI_ADDITIONAL_INFORMATION_EXTERNAL_REFERENCE',
  NotificationsList = 'NOTIFICATIONS_LIST',
  OfferList = 'OFFER_LIST',
  PolicyList = 'POLICY_LIST',
  PolicyTemplatesList = 'POLICY_TEMPLATES_LIST',
  PremiumCalculation = 'UI_SIDEPANEL_CALCULATE',
  ProductInfo = 'UI_SIDEPANEL_PRODUCTINFO',
  ProposalList = 'PROPOSAL_LIST',
  ReadCommissions = 'COMMISSIONS_READ',
  ReadOffer = 'OFFER_READ',
  ReadPolicy = 'POLICY_READ',
  ReadPolicyTemplates = 'POLICY_TEMPLATES_READ',
  ReadProposal = 'PROPOSAL_READ',
  SalesProcessList = 'SALES_PROCESS_LIST',
  SalesProcessStatistics = 'SALES_PROCESS_STATISTICS',
  Tools = 'UI_SIDEPANEL_TOOLS',
  NotSet = 'NOT_SET',
  UpdateOffer = 'OFFER_UPDATE',
  UpdateProposal = 'PROPOSAL_UPDATE',
}
