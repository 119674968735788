export const transformValueFromStringToNumber = (stringValue) =>
  Number(String(stringValue).replace(',', '.'));

export const transformValueFromNumberToString = (numberValue) =>
  String(numberValue).replace('.', ',');

const transformWeightValue = (weight, isTransformToNumber) =>
  isTransformToNumber
    ? transformValueFromStringToNumber(weight)
    : transformValueFromNumberToString(weight);

const transformInsuredAdult = (insuredAdult, isTransformToNumber) => ({
  ...insuredAdult,
  healthDeclaration: insuredAdult.healthDeclaration && {
    ...insuredAdult.healthDeclaration,
    weight:
      insuredAdult.healthDeclaration.weight &&
      transformWeightValue(insuredAdult.healthDeclaration.weight, isTransformToNumber),
  },
});

export const transformHealthDeclarationForm = ({ formValues, isTransformToNumber = false }) => ({
  ...formValues,
  firstInsuredAdult:
    formValues.firstInsuredAdult &&
    transformInsuredAdult(formValues.firstInsuredAdult, isTransformToNumber),
  secondInsuredAdult:
    formValues.secondInsuredAdult &&
    transformInsuredAdult(formValues.secondInsuredAdult, isTransformToNumber),
});
