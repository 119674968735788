import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { history } from '../../history';
import {
  selectActionData,
  selectAsyncActionData,
  selectAsyncActionError,
  selectIsInProgressStatus,
  selectIsSuccessStatus,
} from '../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../shared/reducers/selectors';

import {
  containerId,
  convertToOffer,
  fetchFormData,
  fetchMetadata,
  fetchPolicyPartyData,
  setServerErrors,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectIsPolicyPartyDataFetching = (state) =>
  selectIsInProgressStatus(getCurrentState(state), fetchPolicyPartyData.type);

export const selectIsFormDataFetched = (state) =>
  selectIsSuccessStatus(getCurrentState(state), fetchFormData.type);

export const selectIsPolicyPartyDataFetched = (state) =>
  selectIsSuccessStatus(getCurrentState(state), fetchPolicyPartyData.type);

export const selectIsInitialFormDataFetched = createSelector(
  [selectIsPolicyPartyDataFetched, selectIsFormDataFetched],
  (isPolicyPartyDataFetched, isFormDataFetched) => isPolicyPartyDataFetched && isFormDataFetched
);

export const selectMetadata = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type);

export const selectMetadataError = (state) =>
  selectAsyncActionError(getCurrentState(state), fetchMetadata.type);

export const selectFetchingMetadata = (state) =>
  selectIsInProgressStatus(getCurrentState(state), fetchMetadata.type);

export const selectIsMetadataBlocked = (state) => {
  const metadata = selectMetadata(state);

  return get(metadata, 'blocked');
};

export const selectIsOneAdult = (state) => {
  const metadata = selectMetadata(state);

  return get(metadata, 'oneAdult');
};

export const selectConvertingToOfferStatus = (state) =>
  selectIsInProgressStatus(getCurrentState(state), convertToOffer.type);

export const selectServerErrors = (state) =>
  selectActionData(getCurrentState(state), setServerErrors.type) || {};

export const selectProposalStatus = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type, 'status');

export const selectProposalProductVersion = (state) => get(selectMetadata(state), 'productVersion');

export const selectShouldShowIdentityConfirmation = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type, 'idCardRequired');

export const selectIsHealthDeclarationRequired = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type, 'healthDeclarationRequired');

export const selectProposalId = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type, 'proposalId');

export const selectPaymentFrequencyDisabled = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMetadata.type, 'paymentFrequencyDisabled');

export const selectErrorSource = createSelector(selectProposalId, (proposalId) =>
  !proposalId ? history.location.pathname : proposalId.toString()
);
