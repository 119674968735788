import React from 'react';
import {useIntl} from 'react-intl';
import accessDenied from '../../../../assets/images/403.svg';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import './styles.scss';

const AccessDenied = () => {
   const intl = useIntl();

   return (
      <div className="access-denied">
         <div className="access-denied-container">
            <img
               src={accessDenied}
               alt={intl.formatMessage({
                  id: 'errorPage.accessDenied.title',
               })}
               draggable={false}
            />
         </div>
         <h2>
            <FormattedMessage id="errorPage.accessDenied.title" />
         </h2>
         <p>
            <FormattedMessage id="errorPage.accessDenied.description" values={{br: <br />}} />
         </p>
      </div>
   );
};

export default AccessDenied;
