import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import Select, {components as comp} from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import NoOptionsMessage from './NoOptionsMessage';
import LoadingMessage from './LoadingMessage';
import LoadingIndicator from './LoadingIndicator';
import './styles.scss';
import FormControl from '../FormControl';

const DropdownControl = (props) => {
   return (
      <FormControl {...props}>
         <Dropdown />
      </FormControl>
   );
};

const SelectInput = (props) => <comp.Input {...props} name={props.selectProps.name} />;

export const Dropdown = ({
   id,
   name,
   value,
   options = [],
   isClearable = true,
   hideSelectedOptions = false,
   backspaceRemovesValue = true,
   placeholder = '',
   disabled = false,
   className,
   hasError,
   onChange,
   onBlur,
   isLoading = false,
   isSearchable = false,
   postfix,
   components,
   isAsync = false,
   loadOptions,
}) => {
   const _value = value || null;
   const _onChange = (e) => {
      const value = e?.value || null;
      onChange && onChange(value);
   };

   const _onBlur = () => {
      onBlur && onBlur(_value);
   };

   const getValue = () => {
      if (isAsync) {
         return {value: _value, label: _value};
      } else {
         return options.find((option) => option.value === _value) || null;
      }
   };

   const commonProps = {
      'aria-labelledby': id,
      name,
      className: 'dropdown-input',
      classNamePrefix: 'dropdown-input',
      value: getValue(),
      placeholder,
      isClearable,
      isDisabled: disabled,
      isSearchable,
      onChange: _onChange,
      onBlur: _onBlur,
      blurInputOnSelect: false,
      backspaceRemovesValue,
      components: {
         ClearIndicator: null,
         NoOptionsMessage,
         LoadingIndicator,
         LoadingMessage,
         Input: SelectInput,
         ...components,
      },
   };
   return (
      <div className={classNames('input-dropdown-wrapper', className, {hasError})}>
         {isAsync ? (
            <AsyncSelect
               loadOptions={debounce(loadOptions, 500)}
               openMenuOnClick={false}
               openMenuOnFocus={false}
               {...commonProps}
            />
         ) : (
            <Select
               options={options}
               isLoading={isLoading}
               hideSelectedOptions={hideSelectedOptions}
               isOptionDisabled={({disabled}) => disabled}
               {...commonProps}
            />
         )}
         {postfix}
      </div>
   );
};

Dropdown.propTypes = {
   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   options: PropTypes.arrayOf(
      PropTypes.shape({
         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
         label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      })
   ),
   isClearable: PropTypes.bool,
   hideSelectedOptions: PropTypes.bool,
   placeholder: PropTypes.string,
   disabled: PropTypes.bool,
   className: PropTypes.string,
   onChange: PropTypes.func,
   isLoading: PropTypes.bool,
   isSearchable: PropTypes.bool,
   postfix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
   components: PropTypes.object,
   isAsync: PropTypes.bool,
   loadOptions: PropTypes.func,
};

export default DropdownControl;
