import { rest } from 'msw';

import { IAgent } from 'v2/common/api/queries/agent';
import { NotificationSeverity, NotificationStatus } from 'v2/common/api/queries/notifications';
import {
  PremiumCalculationParams,
  PremiumCalculationResult,
} from 'v2/common/api/queries/premiumCalculation';
import { createAgentData } from 'v2/mocks/data/agent';
import { commissionsData, RECEIPT_ID } from 'v2/mocks/data/commissions';
import { createNotification } from 'v2/mocks/data/notifications';
import { createPolicyMetadata } from 'v2/mocks/data/policyDetails/metadata';
import { createPolicyPolicyHolder } from 'v2/mocks/data/policyDetails/policyHolder';
import { offersList, policyList, proposalList } from 'v2/mocks/data/portfolio';
import { createPremiumResult } from 'v2/mocks/data/premiumCalculation';
import { statisticsData } from 'v2/mocks/data/statistics';
import policyUpdateDocument from 'v2/mocks/files/policy update.pdf';
import receipt from 'v2/mocks/files/receipt.pdf';
import statement from 'v2/mocks/files/statement.pdf';
import { jsonResolver, voidResolver } from 'v2/mocks/handlers/common';

import { createBeneficiaries } from './data/beneficiaries';
import { policyUpdatesData } from './data/policyUpdates';
import { MOCK_POLICY_API_MAP } from './constants';

export const b2cVerify = rest.post(MOCK_POLICY_API_MAP.b2cVerify, (_req, res, context) =>
  res(context.delay(), context.status(200))
);

export const createGetAgentMock = (overrides?: Partial<IAgent>) =>
  rest.get(MOCK_POLICY_API_MAP.agentNumbers, (_req, res, context) =>
    res(context.delay(), context.status(200), context.json(createAgentData(overrides)))
  );

export const getAgent = createGetAgentMock();

export const getStatistics = rest.get(MOCK_POLICY_API_MAP.statistics, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(statisticsData))
);

export const getOffers = rest.get(MOCK_POLICY_API_MAP.offers, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(offersList))
);

export const getProposals = rest.get(MOCK_POLICY_API_MAP.proposals, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(proposalList))
);

export const getPolicies = rest.get(MOCK_POLICY_API_MAP.policies, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(policyList))
);

export const getCommissions = rest.get(MOCK_POLICY_API_MAP.commissions, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(commissionsData))
);

export const downloadCommission = rest.get(
  MOCK_POLICY_API_MAP.commissionsDownload,
  async (req, res, context) => {
    const { id } = req.params;
    const file = id === RECEIPT_ID ? receipt : statement;
    const image = await fetch(file);
    const imageBuffer = await image.arrayBuffer();

    return res(
      context.delay(),
      context.set('Content-Length', imageBuffer.byteLength.toString()),
      context.set('Content-Type', 'application/pdf'),
      context.body(imageBuffer)
    );
  }
);

export const getPolicyUpdates = rest.get(MOCK_POLICY_API_MAP.policyUpdates, (_req, res, context) =>
  res(context.delay(), context.status(200), context.json(policyUpdatesData))
);

export const downloadPolicyUpdateDoc = rest.post(
  MOCK_POLICY_API_MAP.policyUpdatesDownload,
  async (_req, res, context) => {
    const image = await fetch(policyUpdateDocument);
    const imageBuffer = await image.arrayBuffer();

    return res(
      context.delay(),
      context.set('Content-Length', imageBuffer.byteLength.toString()),
      context.set('Content-Type', 'application/pdf'),
      context.body(imageBuffer)
    );
  }
);

export const getNotifications = rest.get(
  MOCK_POLICY_API_MAP.notifications,
  jsonResolver([
    createNotification({
      notificationStatus: NotificationStatus.Unread,
      severity: NotificationSeverity.Warn,
    }),
  ])
);

export const removeNotification = rest.delete(
  MOCK_POLICY_API_MAP.notificationsRemove,
  voidResolver()
);

export const markNotificationAsRead = rest.post(
  MOCK_POLICY_API_MAP.notificationsStatus,
  voidResolver()
);

export const getPolicyMetadata = rest.get(
  MOCK_POLICY_API_MAP.metadata,
  jsonResolver(createPolicyMetadata())
);

export const getPolicyPolicyHolder = rest.get(
  MOCK_POLICY_API_MAP.policyHolder,
  jsonResolver(createPolicyPolicyHolder())
);

export const getPolicyBeneficiaries = rest.get(
  MOCK_POLICY_API_MAP.beneficiaries,
  jsonResolver(createBeneficiaries())
);

export const calculatePremium = rest.post<PremiumCalculationParams>(
  MOCK_POLICY_API_MAP.premiumCalculation,
  (req, res, context) => {
    const { body } = req;

    return jsonResolver<PremiumCalculationResult>(createPremiumResult(body))(req, res, context);
  }
);

export const initiateOffer = rest.post(MOCK_POLICY_API_MAP.offers, jsonResolver({ id: '12345' }));

export const initiateProposal = rest.post(
  MOCK_POLICY_API_MAP.proposals,
  jsonResolver({ id: '12345' })
);

export const handlers = [
  b2cVerify,
  getAgent,
  getStatistics,
  getOffers,
  getProposals,
  getPolicies,
  getCommissions,
  downloadCommission,
  getPolicyUpdates,
  downloadPolicyUpdateDoc,
  getNotifications,
  markNotificationAsRead,
  removeNotification,
  getPolicyMetadata,
  getPolicyPolicyHolder,
  getPolicyBeneficiaries,
  calculatePremium,
  initiateOffer,
  initiateProposal,
];
