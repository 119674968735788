import Typography from '@mui/material/Typography';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import MenuPanelContent from 'v2/features/app-drawer/components/MenuPanelContent';
import ScrollableStack from 'v2/features/app-drawer/components/ScrollableStack/ScrollableStack';

const ProductInfoContent = () => {
   return (
      <ScrollableStack spacing={3}>
         <MenuPanelContent
            title={<FormattedMessage id="appDrawer.productInfo.1.title" />}
            text={<FormattedMessage id="appDrawer.productInfo.1.content" />}
            linkText={<FormattedMessage id="appDrawer.productInfo.1.link.text" />}
            hrefId="appDrawer.productInfo.1.link.href"
         />
         <MenuPanelContent
            title={<FormattedMessage id="appDrawer.productInfo.2.title" />}
            text={<FormattedMessage id="appDrawer.productInfo.2.content" />}
            linkText={<FormattedMessage id="appDrawer.productInfo.2.link.text" />}
            hrefId="appDrawer.productInfo.2.link.href"
         />
         <MenuPanelContent
            title={<FormattedMessage id="appDrawer.productInfo.3.title" />}
            text={<FormattedMessage id="appDrawer.productInfo.3.content" />}
            linkText={<FormattedMessage id="appDrawer.productInfo.3.link.text" />}
            hrefId="appDrawer.productInfo.3.link.href"
         />
         <MenuPanelContent
            title={<FormattedMessage id="appDrawer.productInfo.4.title" />}
            text={<FormattedMessage id="appDrawer.productInfo.4.content" />}
            linkText={<FormattedMessage id="appDrawer.productInfo.4.link.text" />}
            hrefId="appDrawer.productInfo.4.link.href"
         />
         <MenuPanelContent
            title={<FormattedMessage id="appDrawer.productInfo.5.title" />}
            text={<FormattedMessage id="appDrawer.productInfo.5.content" />}
            linkText={<FormattedMessage id="appDrawer.productInfo.5.link.text" />}
            hrefId="appDrawer.productInfo.5.link.href"
         />
         <Typography variant="h6" fontWeight="bold" textAlign="center" marginTop={2}>
            <FormattedMessage id="appDrawer.productInfo.footer" />
         </Typography>
      </ScrollableStack>
   );
};

export default ProductInfoContent;
