import { useMutation } from 'react-query';
import Axios from 'axios';

import { API_URL, ENDPOINTS } from 'shared/constants';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { transformInitiateSalesProcessFormValues } from 'v2/common/utils/apiTransformations';
import { InitiateSalesProcessFormValues } from 'v2/features/PremiumCalculation/components/InitiateSalesProcess/InitiateSalesProcess';

import { toastHandler } from '../../../../shared/services';

export enum PaymentFrequency {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
  OneTimeOnly = 'ONE_TIME_ONLY',
}

export interface PremiumAmount {
  amount: number;
}

export interface PremiumDetails {
  acquisitionCosts: PremiumAmount;
  administrativeCosts: PremiumAmount;
  capital: PremiumAmount;
  premium: PremiumAmount;
  tax: PremiumAmount;
}

export interface PremiumCalculationResult {
  calculationResult: PremiumDetails[];
}

export interface PremiumCalculationInsuredParams {
  benefit: { amount: number };
  personalInformation: { birthDate: Date | string };
  payUntilAge: number | null;
}

// TODO Should be removed in https://delanet.atlassian.net/browse/BROKP-8873
export interface PremiumCalculationInsuredParamsOldFormat {
  benefit: { amount: number };
  birthDate: Date | string;
  payUntilAge: number | null;
}

export interface PremiumCalculationParams {
  careClause: boolean;
  paymentFrequency: PaymentFrequency;
  firstInsuredAdult: PremiumCalculationInsuredParams;
  secondInsuredAdult?: PremiumCalculationInsuredParams;
}

export type InitiateSalesProcessParams = PremiumCalculationParams & {
  policyHolder: {
    participatingAsInsuredAdult: boolean;
    personalInformation: {
      firstName: string;
      surname: string;
    };
  };
};

export type InitiateSalesProcessMutationFn = {
  values: InitiateSalesProcessFormValues;
  onSuccess: (id: string) => void;
};

export function usePremiumCalculation() {
  return useMutation('premiumCalculation', async (values: PremiumCalculationParams) => {
    toastHandler.closeAll();
    const { data } = await Axios.post<PremiumCalculationResult>(
      API_URL + ENDPOINTS.premiumCalculation,
      values
    );

    return data;
  });
}

export function useInitiateProposal() {
  const axios = useAxios();

  return useMutation(
    'initiateProposal',
    async ({ values }: InitiateSalesProcessMutationFn) => {
      const { data } = await axios.post(
        ENDPOINTS.proposals,
        transformInitiateSalesProcessFormValues(values)
      );

      return data;
    },
    {
      onSuccess: (data, variables) => {
        variables.onSuccess(data.id);
      },
      onError: toastHandler.unexpectedIssue,
    }
  );
}

export function useInitiateOffer() {
  const axios = useAxios();

  return useMutation(
    'initiateOffer',
    async ({ values }: InitiateSalesProcessMutationFn) => {
      const { data } = await axios.post(
        ENDPOINTS.offers,
        transformInitiateSalesProcessFormValues(values)
      );

      return data;
    },
    {
      onSuccess: (data, variables) => {
        variables.onSuccess(data.id);
      },
      onError: toastHandler.unexpectedIssue,
    }
  );
}
