import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Divider = ({disabled}) => <div className={classNames('divider', {disabled})} />;

Divider.propTypes = {
   disabled: PropTypes.bool,
};

export default Divider;
