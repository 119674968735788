import {
   PremiumCalculationParams,
   PremiumCalculationResult,
} from 'v2/common/api/queries/premiumCalculation';

export const createPremiumResult = (
   payload: PremiumCalculationParams
): PremiumCalculationResult => {
   const res: PremiumCalculationResult = {
      calculationResult: [
         {
            premium: {
               amount: 12.42,
            },
            capital: {
               amount: +payload.firstInsuredAdult.benefit,
            },
            administrativeCosts: {
               amount: 1.71,
            },
            acquisitionCosts: {
               amount: 1.61,
            },
            tax: {
               amount: 0.24,
            },
         },
      ],
   };
   if (payload?.secondInsuredAdult) {
      res.calculationResult.push({
         premium: {
            amount: 8.33,
         },
         capital: {
            amount: +payload.secondInsuredAdult.benefit,
         },
         administrativeCosts: {
            amount: 0,
         },
         acquisitionCosts: {
            amount: 0,
         },
         tax: {
            amount: 0.16,
         },
      });
   }
   return res;
};
