import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { api } from '../../../shared/services';
import { takeAction } from '../../../shared/services/sagaHelpers';
import { setGeneralError } from '../../App/reducer';
import { selectActiveFormValues } from '../../FormWrapper/selectors';
import { fetchFormData } from '../reducer';
import { handleProposalGeneralError } from '../sagas';
import { selectProposalId } from '../selectors';

import { fetchAdditionalInformation, saveAdditionalInformation } from './reducer';

export const createFetchAdditionalInformationSaga = (takeActionHandler) =>
  function* ({ payload }) {
    try {
      let id = payload;
      if (!id) {
        id = yield select(selectProposalId);
      }

      yield put(fetchFormData.request(id));
      const result = yield takeActionHandler([fetchFormData, fetchAdditionalInformation]);

      if (result.type !== fetchFormData.SUCCESS) {
        yield put(fetchAdditionalInformation.failure());
        return;
      }

      const data = yield call(api.proposal.getAdditionalInformation, id);
      yield put(fetchAdditionalInformation.success(data));
    } catch (error) {
      yield put(fetchAdditionalInformation.failure(error));
      yield call(handleProposalGeneralError, error);
    }
  };

export const fetchAdditionalInformationSaga = createFetchAdditionalInformationSaga(takeAction);

export function* saveAdditionalInformationSaga() {
  const formValues = yield select(selectActiveFormValues);
  const proposalId = yield select(selectProposalId);

  try {
    yield call(api.proposal.patchAdditionalInformation, proposalId, formValues);
    yield put(saveAdditionalInformation.success());
  } catch (error) {
    yield put(saveAdditionalInformation.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export default function* additionalInformationSagas() {
  yield all([
    takeLatest(fetchAdditionalInformation.REQUEST, fetchAdditionalInformationSaga),
    takeLatest(saveAdditionalInformation.REQUEST, saveAdditionalInformationSaga),
  ]);
}
