import { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import { bool, func, node, string } from 'prop-types';
import { Form } from 'redux-form';

import NavigationPrompt from '../../shared/components/NavigationPrompt';

import FormWrapperButtonsPanel from './FormWrapperButtonsPanel';
import FormWrapperInfoPanel from './FormWrapperInfoPanel';
import FormWrapperTitle from './FormWrapperTitle';
import { handleNavigationButtonClickAction, setActiveFormAction } from './reducer';
import { selectShowNavigationPrompt } from './selectors';

import './styles.scss';

export class FormWrapper extends PureComponent {
  componentDidMount() {
    const { setActiveForm, formName } = this.props;
    setActiveForm(formName);
  }

  componentWillUnmount() {
    const { clearActiveForm } = this.props;
    clearActiveForm();
  }

  handleNavigationButtonClick = (unblockCallback) => {
    const { handleNavigation } = this.props;
    handleNavigation({ onFormSubmittedCallback: unblockCallback });
  };

  render() {
    const { children, showNavigationPrompt, handleSubmit = noop } = this.props;

    return (
      <div className="form-wrapper">
        <NavigationPrompt
          show={showNavigationPrompt}
          submitForm={this.handleNavigationButtonClick}
        />
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <FormWrapperTitle />
          <FormWrapperButtonsPanel />
          <FormWrapperInfoPanel />
          {children}
          <FormWrapperButtonsPanel />
        </Form>
      </div>
    );
  }
}

const makeMapStateToProps = (state) => ({
  showNavigationPrompt: selectShowNavigationPrompt(state),
});

FormWrapper.propTypes = {
  children: node,
  formName: string.isRequired,

  setActiveForm: func,
  clearActiveForm: func,

  showNavigationPrompt: bool,
  handleNavigation: func,
  handleSubmit: func,
};

export default injectIntl(
  connect(makeMapStateToProps, {
    setActiveForm: setActiveFormAction.action,
    clearActiveForm: setActiveFormAction.clear,

    handleNavigation: handleNavigationButtonClickAction.request,
  })(FormWrapper)
);
