import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import HowItWorksButton from '../../shared/components/HowItWorksButton';
import { DOCUMENTS_POPOVER_ID } from '../../shared/constants';
import SubmitButton from '../FormWrapper/FormWrapperButtonsPanel/SubmitButton';
import NavigationPane from '../NavigationPane';
import {
  selectIsDataFetching,
  selectIsPolling,
  selectShouldShowOnlyRemotelySigningMethodForPH,
} from '../ProposalDetails/Documents/selectors';

import './styles.scss';

const SalesProcessPage = ({ children, location: { pathname } }) => {
  const isDocumentPage = pathname.endsWith('/documents');
  const isDataFetching = useSelector(selectIsDataFetching);
  const isPolling = useSelector(selectIsPolling);
  const shouldShowOnlyRemotelySigningMethod = useSelector(
    selectShouldShowOnlyRemotelySigningMethodForPH
  );

  const shouldShowHowItWorksButton =
    !isDataFetching && !isPolling && isDocumentPage && !shouldShowOnlyRemotelySigningMethod;

  return (
    <Grid container spacing={2} wrap="nowrap" className="sales-process-page">
      <Grid item className="nav-container-sticky">
        <NavigationPane />
        <SubmitButton />
        {shouldShowHowItWorksButton && (
          <HowItWorksButton
            popoverId={DOCUMENTS_POPOVER_ID}
            className="documents-how-it-works-button-wrapper"
          />
        )}
      </Grid>
      <Grid item xs={10}>
        <div className="sales-process-page-content">{children}</div>
      </Grid>
    </Grid>
  );
};

SalesProcessPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default withRouter(SalesProcessPage);
