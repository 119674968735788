import transform from 'lodash/transform';

/**
 * This class could automatically generate url string with parameters from object
 *
 * new Url('http://someUrl', {param1: 'a', param2: 'b'})
 * // => 'http://someUrl?param1=a&param2=b'
 */
export class Url {
   constructor(url, params) {
      this.url = url;
      this.params = params;
   }

   addParams(params) {
      Object.assign(this.params, params);
   }

   toString() {
      let paramsStr = this.params ? '?' : '';

      paramsStr += transform(
         this.params,
         (result, value, key) => {
            result.push(`${key}=${value}`);
         },
         []
      ).join('&');

      return this.url + paramsStr;
   }
}
