import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Widget } from '../Widget';

type CommissionsProps = {
  goToCommissions: () => void;
};

export const Commissions = ({ goToCommissions }: CommissionsProps) => (
  <Widget classPrefix="commissions">
    <button type="button" className="link commissions-title" onClick={goToCommissions}>
      <FormattedMessage id="homepage.commissions" />
    </button>
    <div className="commissions-text">
      <FormattedMessage id="homepage.receiptsAndCommissions" />
    </div>
    <button type="button" className="link" onClick={goToCommissions}>
      <FormattedMessage id="homepage.viewMore" />
    </button>
  </Widget>
);

Commissions.propTypes = {
  goToCommissions: PropTypes.func,
};
