import React from 'react';
import {FormattedMessage} from '../../../../FormattedMessage';

const NoDiseases = () => (
   <div className="no-diseases">
      <p>
         <FormattedMessage id="healthDeclaration.summary.noDataFirstRow" />
      </p>
      <p>
         <FormattedMessage id="healthDeclaration.summary.noDataSecondRow" />
      </p>
   </div>
);

export default NoDiseases;
