import get from 'lodash/get';
import {omit} from 'lodash';
import {change} from 'redux-form';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {SECTIONS} from '../../../shared/constants/index';
import {api} from '../../../shared/services';
import {addIndex, devideEqually, getInsuredAdultIndex} from '../../../shared/services/utils';
import {setGeneralError} from '../../App/reducer';
import {handleProposalGeneralError} from '../sagas';
import {selectProposalId, selectServerErrors} from '../selectors';
import {
   checkShareInsuredBenefit,
   containerId as beneficiariesContainerId,
   copyFromPolicyParty,
   fetchBeneficiaries,
   saveBeneficiaries,
   uncheckShareInsuredBenefit,
   omitBeneficiaryServerErrors,
} from './reducer';
import {clearServerError, fetchFormData, setServerErrors} from '../reducer';
import {selectBeneficiariesBySection, selectBeneficiaryActiveTabKeys} from './selectors';
import {selectActiveFormValues} from '../../FormWrapper/selectors';
import {takeAction} from '../../../shared/services/sagaHelpers';
import {HANDLED_SERVER_ERRORS, SERVER_ERROR_TYPES} from '../../../shared/constants';

export function* uncheckShareInsuredBenefitSaga({payload: section}) {
   yield put(change(beneficiariesContainerId, `${section}.shareBenefitEqually`, false));
}

export function* checkShareInsuredBenefitSaga({payload: {section, value: isChecked}}) {
   if (!isChecked) {
      return;
   }

   const beneficiaries = yield select(selectBeneficiariesBySection, section);
   const numberOfBeneficiaries = beneficiaries.length;

   const {all, last} = devideEqually(numberOfBeneficiaries);

   for (let i = 0; i < numberOfBeneficiaries; i++) {
      yield put(
         change(
            beneficiariesContainerId,
            `${section}.beneficiaries[${i}].share`,
            i + 1 === numberOfBeneficiaries ? last : all
         )
      );
   }

   const insuredIndex = getInsuredAdultIndex(section);
   const serverErrors = yield select(selectServerErrors);

   const result = {};
   HANDLED_SERVER_ERRORS.forEach((errorType) => {
      if (errorType === SERVER_ERROR_TYPES.VALIDATION_ERRORS) {
         const beneficiariesErrors = get(
            serverErrors,
            `${errorType}.insuredAdults[${insuredIndex}].beneficiaries`
         );
         if (beneficiariesErrors) {
            const insuredAdults = get(serverErrors, `${errorType}.insuredAdults`, []);
            return (result[errorType] = {
               insuredAdults: insuredAdults.map((insured, currentIndex) => {
                  if (currentIndex !== insuredIndex) {
                     return insured;
                  }
                  return {
                     ...insured,
                     beneficiariesTotalShareError: false,
                     beneficiaries: beneficiariesErrors.map((beneficiary) => {
                        return {...beneficiary, share: false};
                     }),
                  };
               }),
            });
         }
      }
      return (result[errorType] = serverErrors[errorType]);
   });
   yield put(setServerErrors.action(result));
}

const transformBeneficiariesResponse = (beneficiaries) => {
   for (let key in beneficiaries) {
      if (beneficiaries[key]) {
         beneficiaries[key] = {
            ...beneficiaries[key],
            beneficiaries: beneficiaries[key].beneficiaries?.map((beneficiary) =>
               omit(beneficiary, ['clauseType', 'clauseValue'])
            ),
         };
      }
   }
   return beneficiaries;
};

export const createFetchBeneficiariesSaga = (takeActionHandler) =>
   function* ({payload}) {
      try {
         let id = payload;
         if (!id) {
            id = yield select(selectProposalId);
         }

         yield put(fetchFormData.request(id));
         const result = yield takeActionHandler([fetchFormData, fetchBeneficiaries]);

         if (result.type !== fetchFormData.SUCCESS) {
            yield put(fetchBeneficiaries.failure());
            return;
         }

         const beneficiaries = yield call(api.proposal.getBeneficiaries, id);

         yield put(fetchBeneficiaries.success(transformBeneficiariesResponse(beneficiaries)));
      } catch (error) {
         yield put(fetchBeneficiaries.failure(error));
         yield call(handleProposalGeneralError, error);
      }
   };

const fetchBeneficiariesSaga = createFetchBeneficiariesSaga(takeAction);

const getBeneficiaryData = (insuredAdultBeneficiaries) => {
   const insuredAdultBeneficiary = get(insuredAdultBeneficiaries, 'beneficiaries', []);
   return {
      beneficiaries: insuredAdultBeneficiary.map(addIndex),
      paymentToUndertaker: get(insuredAdultBeneficiaries, 'paymentToUndertaker'),
      shareBenefitEqually: get(insuredAdultBeneficiaries, 'shareBenefitEqually', false),
   };
};

export const prepareDataSaveBeneficiariesSaga = (data) => {
   const firstInsuredAdultBeneficiaries = get(data, SECTIONS.FIRST_INSURED_ADULT);
   const secondInsuredAdultBeneficiaries = get(data, SECTIONS.SECOND_INSURED_ADULT);

   return {
      ...data,
      [SECTIONS.FIRST_INSURED_ADULT]: getBeneficiaryData(firstInsuredAdultBeneficiaries),
      [SECTIONS.SECOND_INSURED_ADULT]: getBeneficiaryData(secondInsuredAdultBeneficiaries),
   };
};

function* saveBeneficiariesSaga() {
   const formValues = yield select(selectActiveFormValues);
   const data = prepareDataSaveBeneficiariesSaga(formValues);
   const proposalId = yield select(selectProposalId);
   try {
      yield call(api.proposal.patchBeneficiaries, proposalId, data);
      yield put(saveBeneficiaries.success());
   } catch (error) {
      yield put(saveBeneficiaries.failure(error));
      yield put(setGeneralError.action(error));
   }
}

function* clearServerErrorSaga(value, section, beneficiaryIndex, path) {
   if (value) {
      const insuredAdultIndex = getInsuredAdultIndex(section);
      yield put(
         clearServerError.action(
            `insuredAdults[${insuredAdultIndex}].beneficiaries[${beneficiaryIndex}].${path}`
         )
      );
   }
}

export function* copyFormPolicyPartiesSaga({payload}) {
   const {value, section} = payload;
   const activeTabs = yield select(selectBeneficiaryActiveTabKeys);
   const index = get(activeTabs, section);
   const fieldsPath = `${section}.beneficiaries[${index}].personalInformation`;
   yield put(change(beneficiariesContainerId, `${fieldsPath}.firstName`, value.firstName));
   yield call(
      clearServerErrorSaga,
      value.firstName,
      section,
      index,
      'personalInformation.firstName'
   );

   yield put(change(beneficiariesContainerId, `${fieldsPath}.surname`, value.surname));
   yield call(clearServerErrorSaga, value.surname, section, index, 'personalInformation.surname');

   yield put(change(beneficiariesContainerId, `${fieldsPath}.gender`, value.gender));
   yield call(clearServerErrorSaga, value.gender, section, index, 'personalInformation.gender');

   yield put(change(beneficiariesContainerId, `${fieldsPath}.birthDate`, value.birthDate));
   yield call(
      clearServerErrorSaga,
      value.birthDate,
      section,
      index,
      'personalInformation.birthDate'
   );
}

export function* omitBeneficiaryServerErrorsSaga({payload}) {
   const {insuredAdultIndex, index} = payload;
   const serverErrors = yield select(selectServerErrors);
   const result = {};

   HANDLED_SERVER_ERRORS.forEach((errorType) => {
      if (
         errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS ||
         errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN
      ) {
         return (result[errorType] = serverErrors[errorType] || {});
      }

      if (
         errorType === SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES ||
         errorType === SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES
      ) {
         return (result[errorType] = {});
      }

      const insuredAdults = get(serverErrors, `${errorType}.insuredAdults`);
      if (!insuredAdults) {
         return (result[errorType] = serverErrors[errorType] || {});
      }

      result[errorType] = {
         ...serverErrors[errorType],
         insuredAdults: insuredAdults.map((insured, currentIndex) => {
            if (currentIndex !== insuredAdultIndex) {
               return insured;
            }

            const beneficiaries = [...get(insured, 'beneficiaries', [])];

            beneficiaries.splice(index, 1);

            const isBeneficiaryErrorExist = beneficiaries.length !== 0;
            if (isBeneficiaryErrorExist) {
               return {
                  ...insured,
                  beneficiaries,
               };
            }

            return errorType === SERVER_ERROR_TYPES.VALIDATION_ERRORS
               ? {
                    ...insured,
                    beneficiaries,
                    beneficiariesTotalShare: false,
                 }
               : {};
         }),
      };
   });
   yield put(setServerErrors.action(result));
}

export default function* beneficiariesSagas() {
   yield all([
      takeLatest(saveBeneficiaries.REQUEST, saveBeneficiariesSaga),
      takeLatest(fetchBeneficiaries.REQUEST, fetchBeneficiariesSaga),
      takeLatest(checkShareInsuredBenefit.ACTION, checkShareInsuredBenefitSaga),
      takeLatest(uncheckShareInsuredBenefit.ACTION, uncheckShareInsuredBenefitSaga),
      takeLatest(copyFromPolicyParty.ACTION, copyFormPolicyPartiesSaga),
      takeLatest(omitBeneficiaryServerErrors.ACTION, omitBeneficiaryServerErrorsSaga),
   ]);
}
