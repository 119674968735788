import {createSelector} from '@reduxjs/toolkit';
import {selectIsFetching as selectBeneficiariesIsFetching} from '../../../ProposalDetails/Beneficiaries/selectors';
import {selectIsFetching as selectAdditionalInformationIsFetching} from '../../../ProposalDetails/AdditionalInformation/selectors';
import {selectIsFetching as selectHealthDeclarationIsFetching} from '../../../ProposalDetails/HealthDeclaration/selectors';
import {
   selectIsPolicyHolderForm,
   selectIsInsuredForm,
   selectIsBeneficiariesForm,
   selectIsHealthDeclarationForm,
   selectIsAdditionalInformationForm,
} from '../../selectors';
import {
   selectIsMetadataBlocked,
   selectIsPolicyPartyDataFetching,
} from '../../../ProposalDetails/selectors';

export const selectProposalDetailsIsFetching = createSelector(
   [
      selectBeneficiariesIsFetching,
      selectHealthDeclarationIsFetching,
      selectAdditionalInformationIsFetching,
      selectIsPolicyPartyDataFetching,
   ],
   (
      beneficiariesIsFetching,
      healthDeclarationIsFetching,
      additionalInformationIsFetching,
      policyPartyDataIsFetching
   ) =>
      beneficiariesIsFetching ||
      healthDeclarationIsFetching ||
      additionalInformationIsFetching ||
      policyPartyDataIsFetching
);

export const selectIsShowSubmitProposalButton = createSelector(
   [
      selectIsMetadataBlocked,
      selectIsPolicyHolderForm,
      selectIsInsuredForm,
      selectIsBeneficiariesForm,
      selectIsHealthDeclarationForm,
      selectIsAdditionalInformationForm,
   ],
   (
      isMetadataBlocked,
      isPolicyHolderForm,
      isInsuredForm,
      isBeneficiariesForm,
      isHealthDeclarationForm,
      isAdditionalInformationForm
   ) =>
      !isMetadataBlocked &&
      (isPolicyHolderForm ||
         isInsuredForm ||
         isBeneficiariesForm ||
         isHealthDeclarationForm ||
         isAdditionalInformationForm)
);
