import PropTypes from 'prop-types';

import StatusIndicator from '../StatusIndicator';
import { setName } from '../Table';

export const SalesProcessTitle = ({ title, firstName, surname, id, status }) => (
  <div className="sales-process-title">
    <h1>
      {title} {setName(firstName)} {setName(surname)} #{id}
    </h1>
    <StatusIndicator status={status} />
  </div>
);

SalesProcessTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  firstName: PropTypes.string,
  surname: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
};
