import React from 'react';
import classNames from 'classnames';
import { bool, element, node, object, oneOfType, shape, string } from 'prop-types';

import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';
import FieldMeta from '../FieldMeta';
import { resolveError } from '../formUtils';
import Label from '../Label';

import classes from './styles.module.scss';

const FormControl = ({
  className,
  input,
  meta,
  id,
  label,
  disabled,
  required,
  hint,
  info,
  hideMeta,
  metaHeightAuto = false,
  serverError,
  hasServerError,
  labelClassName,
  inputClassName,
  children,
  hideLoading,
  onChange,
  ...restProps
}) => {
  const _id = id ?? input?.name;
  const error = !disabled && resolveError(input.value, meta, hasServerError, serverError);
  const isFieldInfoShown = !error && info;

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <Label
          controlId={_id}
          value={label}
          hint={hint}
          required={required}
          className={labelClassName}
        />
      )}
      <div className={classes.inputWrapper}>
        {React.cloneElement(children, {
          ...input,
          id: _id,
          disabled,
          loading: !hideLoading && meta.asyncValidating,
          hasError: Boolean(error),
          className: inputClassName,
          ...restProps,
        })}
        {!hideMeta && (
          <FieldMeta metaHeightAuto={metaHeightAuto}>
            {error && !error.isHidden && <FieldError message={error} />}
            {isFieldInfoShown && <FieldInfo message={info} />}
          </FieldMeta>
        )}
      </div>
    </div>
  );
};

FormControl.propTypes = {
  className: string,
  input: object,
  meta: object,
  id: string,
  label: node,
  disabled: bool,
  required: bool,
  hint: oneOfType([bool, element, string, node]),
  info: oneOfType([bool, element, string]),
  hideMeta: bool,
  metaHeightAuto: bool,
  serverError: oneOfType([bool, object]),
  hasServerError: oneOfType([bool, shape({ messageId: string })]),
  children: element.isRequired,
  hideLoading: bool,
  labelClassName: string,
  inputClassName: string,
};

export default FormControl;
