import {
  getInitialLocale,
  getIntlContext,
} from '../../v2/common/providers/LocalizationProvider/utils.ts';
import { createReducer } from '../reducers/createReducer';
import { Action, AsyncAction } from '../services/ActionHelpers';

import {
  adviceType,
  confirmationTypes,
  contactLanguages,
  fillHealthDeclarationAnswers,
  funeralInsuranceStatuses,
  genders,
  insuranceIntroducer,
  insuredProductBasis,
  paymentFrequencies,
  paymentMethods,
  phoneCodes,
  signingMethods,
  smokingAnswers,
  sourceOfInformation,
  yesNo,
} from './types';

export const containerId = 'references';

const localizeReference = ({ reference, referenceName, locale }) => {
  const intl = getIntlContext(locale);

  return reference.map((value) => ({
    value,
    label: intl.formatMessage({ id: `references.${referenceName}.${value}` }),
  }));
};

const getLocalizedReferences = (payload) => ({
  confirmationTypes: localizeReference({
    reference: confirmationTypes,
    referenceName: 'confirmationTypes',
    locale: payload,
  }),
  paymentFrequencies: localizeReference({
    reference: paymentFrequencies,
    referenceName: 'paymentFrequencies',
    locale: payload,
  }),
  contactLanguages: localizeReference({
    reference: contactLanguages,
    referenceName: 'contactLanguages',
    locale: payload,
  }),
  paymentMethods: localizeReference({
    reference: paymentMethods,
    referenceName: 'paymentMethods',
    locale: payload,
  }),
  yesNo: localizeReference({
    reference: yesNo,
    referenceName: 'yesNo',
    locale: payload,
  }),
  smokingAnswers: localizeReference({
    reference: smokingAnswers,
    referenceName: 'smokingAnswers',
    locale: payload,
  }),
  fillHealthDeclarationAnswers: localizeReference({
    reference: fillHealthDeclarationAnswers,
    referenceName: 'fillHealthDeclarationAnswers',
    locale: payload,
  }),
  genders: localizeReference({
    reference: genders,
    referenceName: 'genders',
    locale: payload,
  }),
  funeralInsuranceStatuses: localizeReference({
    reference: funeralInsuranceStatuses,
    referenceName: 'funeralInsuranceStatuses',
    locale: payload,
  }),
  insuredProductBasis: localizeReference({
    reference: insuredProductBasis,
    referenceName: 'insuredProductBasis',
    locale: payload,
  }),
  adviceType: localizeReference({
    reference: adviceType,
    referenceName: 'adviceType',
    locale: payload,
  }),
  sourceOfInformation: localizeReference({
    reference: sourceOfInformation,
    referenceName: 'sourceOfInformation',
    locale: payload,
  }),
  followAdvice: localizeReference({
    reference: yesNo,
    referenceName: 'followAdvice',
    locale: payload,
  }),
  signingMethods: localizeReference({
    reference: signingMethods,
    referenceName: 'signingMethods',
    locale: payload,
  }),
  phoneCodes: localizeReference({
    reference: phoneCodes,
    referenceName: 'phoneCodes',
    locale: payload,
  }),
  insuranceIntroducer: localizeReference({
    reference: insuranceIntroducer,
    referenceName: 'insuranceIntroducer',
    locale: payload,
  }),
});

export const initialState = getLocalizedReferences(getInitialLocale());

export const localizeReferences = new Action(`${containerId}/REFERENCES_LOCALIZE`, initialState);
export const fetchMunicipalities = new AsyncAction(`${containerId}/MUNICIPALITIES_FETCH`);
export const fetchStreets = new Action(`${containerId}/STREETS_FETCH`);
export const validatePostalCode = new Action(`${containerId}/POSTALCODE_VALIDATE`);

const actionToHandlerMap = new Map([
  [
    localizeReferences.ACTION,
    (draft, { payload }) => {
      draft[localizeReferences.type] = getLocalizedReferences(payload);
    },
  ],
]);

export default createReducer([localizeReferences, fetchMunicipalities], null, actionToHandlerMap);
