import {
   AuthError,
   EventType,
   IPublicClientApplication,
   PublicClientApplication,
} from '@azure/msal-browser';
import {EventMessage} from '@azure/msal-browser/dist/event/EventMessage';
import {AuthenticationResult} from '@azure/msal-common';
import {MsalProvider} from '@azure/msal-react';
import React from 'react';
import {authConfig, b2cPolicies} from 'v2/common/providers/AuthProvider/authConfig';

const FORGOT_PASSWORD_ERROR_CODE = 'AADB2C90118';

type AuthProviderProps = {
   instance?: IPublicClientApplication;
   children: React.ReactNode;
};

export const msalInstance = new PublicClientApplication(authConfig);

const AuthProvider = ({instance = msalInstance, children}: AuthProviderProps) => {
   React.useEffect(() => {
      const state = new URLSearchParams(window.location.hash.substring(1)).get('state');
      if (state && state === b2cPolicies.names.invitation) {
         instance.loginRedirect({scopes: [authConfig.auth.clientId], prompt: 'select_account'});
      }
   }, [instance]);

   React.useEffect(() => {
      const callbackId = instance.addEventCallback((event: EventMessage) => {
         if (event.eventType === EventType.LOGIN_FAILURE) {
            if (
               event.error &&
               (event.error as AuthError).errorMessage.indexOf(FORGOT_PASSWORD_ERROR_CODE) > -1
            ) {
               instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
            }
         }
         if (
            event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
         ) {
            const payload = event.payload as AuthenticationResult;
            if (payload) {
               const idTokenClaims = payload.idTokenClaims as any;
               /**
                * We need to reject id tokens that were not issued with the default sign-in policy.
                * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                */
               if (idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
                  return instance.logout();
               }
            }
         }
      });

      return () => {
         if (callbackId) {
            instance.removeEventCallback(callbackId);
         }
      };
   }, [instance]);
   return <MsalProvider instance={instance}>{children}</MsalProvider>;
};

export default AuthProvider;
