import React from 'react';
import TextField from 'v2/common/components/TextField';
import {TextFieldProps} from 'v2/common/components/TextField/TextField';

type NumberFieldProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
   value: number | null;
   onChange?: (value: number) => void;
};

const NumberField = React.forwardRef<any, NumberFieldProps>((props, ref) => {
   const {value: formValue, onChange, ...restProps} = props;
   const value =
      formValue === null || isNaN(formValue) || formValue === 0 ? '' : formValue.toString();

   const handleChange: TextFieldProps['onChange'] = (e) => {
      const output = parseInt(e.target.value, 10);
      onChange?.(isNaN(output) ? 0 : output);
   };

   return <TextField {...restProps} ref={ref} value={value} onChange={handleChange} />;
});

export default NumberField;
