import moment from 'moment';

import {PRODUCER_ACCOUNT_TYPES} from 'shared/constants';
import {parsePhoneNumbers, sortAlphabetically} from 'shared/services/utils';
import {IAgent, ProducerAccount} from 'v2/common/api/queries/agent';
import {Commission, CommissionType} from 'v2/common/api/queries/commissions';
import {INotification, Notification} from 'v2/common/api/queries/notifications';
import {
   LOCALES,
   PolicyUpdatesDoc,
   PolicyUpdatesResponse,
} from 'v2/common/api/queries/policyUpdates';
import {IPortfolio} from 'v2/common/api/queries/portfolio';
import {
   InitiateSalesProcessParams,
   PaymentFrequency,
   PremiumCalculationParams,
} from 'v2/common/api/queries/premiumCalculation';
import {InitiateSalesProcessFormValues} from 'v2/features/PremiumCalculation/components/InitiateSalesProcess/InitiateSalesProcess';
import {PremiumCalculationFormValues} from 'v2/features/PremiumCalculation/components/PremiumCalculation/PremiumCalculation';

import {LANGUAGES} from '../../../shared/constants';

export function transformAgentResponse(response: IAgent) {
   const {
      firstName,
      surname,
      dateOfBirth,
      email,
      mobileNumber,
      contactNumber,
      contactLanguage,
      id,
      vaultId,
      tenantId,
      activeDirectoryUserId,
      type,
      producerAccountNumbers,
      ...rest
   } = response;
   const {phoneNumberCode, phoneNumber} = parsePhoneNumbers(mobileNumber);
   const producerAccounts = producerAccountNumbers.map<ProducerAccount>((account) => {
      const accountType = PRODUCER_ACCOUNT_TYPES[account.type];
      const label =
         accountType === PRODUCER_ACCOUNT_TYPES.LEADGENERATOR
            ? account.linkedLeadGenerator?.name
            : `${account.intermed.name} ${account.intermed.legalForm}`;

      return {
         value: account.number.toString(),
         label: label || '',
         type: account.type,
         permissions: account.permissions,
      };
   });
   producerAccounts.sort((a, b) => {
      if (a.label === b.label) {
         return +a.value - +b.value;
      }
      return a.label.localeCompare(b.label, undefined, {sensitivity: 'base'});
   });

   return {
      metadata: {id, vaultId, tenantId, activeDirectoryUserId, type},
      personalInformation: {
         firstName,
         surname,
         dateOfBirth,
      },
      contactInformation: {
         email,
         mobileNumberCode: phoneNumberCode,
         mobileNumber: phoneNumber,
         contactNumber,
         contactLanguage,
      },
      producerAccounts,
      ...rest,
   };
}

export function transformPortfolioResponse(response: IPortfolio) {
   const {page, _embedded} = response;

   return {page, items: _embedded ? _embedded.salesProcessItems : []};
}

export function transformCommissionsResponse(response: Commission[]) {
   const statements: Record<string, Commission[]> = {};
   const receipts: Record<string, Commission[]> = {};

   response.sort((current, next) => +new Date(next.createDate) - +new Date(current.createDate));
   response.forEach((commission) => {
      const addByYear = (type: Record<number, Commission[]>, value: typeof commission) =>
         type[value.year] ? type[value.year].push(value) : (type[value.year] = [value]);

      if (commission.type === CommissionType.Statement) {
         return addByYear(statements, commission);
      }
      return addByYear(receipts, commission);
   });

   return {receipts, statements};
}

export function transformPolicyUpdatesResponse(response: PolicyUpdatesResponse[]) {
   const policyUpdatesByIndex: Record<number, any> = {};
   const dutchProductsMap = new Map<string, Array<PolicyUpdatesDoc>>([
      ['Wijziging bestaande polis Uitvaartzorgplan', []],
      ['Wijziging bestaande polis natura', []],
      ['Wijziging bestaande polis Hooghenraed', []],
      ['Wijziging bestaande polis Lilas', []],
   ]);

   const frenchProductsMap = new Map<string, Array<PolicyUpdatesDoc>>([
      ['Modification police existante Plan de Prévoyance obsèques', []],
      ['Modification police existante - nature', []],
      ['Modification police existante Hooghenraed', []],
      ['Modification police existante Lilas', []],
   ]);

   response.forEach((item) => {
      policyUpdatesByIndex[item.index] = {
         name: {
            ...policyUpdatesByIndex?.[item.index]?.name,
            [item.language]: item.name,
         },
         product: {
            ...policyUpdatesByIndex?.[item.index]?.product,
            [item.language]: item.product,
         },
         reference: {
            ...policyUpdatesByIndex?.[item.index]?.reference,
            [item.language]: item.blobReference,
         },
      };
   });

   Object.values(policyUpdatesByIndex).forEach((item, index) => {
      dutchProductsMap.get(item.product.DUTCH)!.push({
         index,
         [LOCALES.NL]: {
            reference: item.reference[LANGUAGES.DUTCH],
            name: item.name[LANGUAGES.DUTCH],
         },
         [LOCALES.FR]: {
            reference: item.reference[LANGUAGES.FRENCH],
            name: item.name[LANGUAGES.FRENCH],
         },
      });
      frenchProductsMap.get(item.product.FRENCH)!.push({
         index,
         [LOCALES.FR]: {
            reference: item.reference[LANGUAGES.FRENCH],
            name: item.name[LANGUAGES.FRENCH],
         },
         [LOCALES.NL]: {
            reference: item.reference[LANGUAGES.DUTCH],
            name: item.name[LANGUAGES.DUTCH],
         },
      });
   });

   dutchProductsMap.forEach((entry) => {
      entry.sort((current, next) =>
         sortAlphabetically(current[LOCALES.NL].name, next[LOCALES.NL].name)
      );
   });

   frenchProductsMap.forEach((entry) => {
      entry.sort((current, next) =>
         sortAlphabetically(current[LOCALES.FR].name, next[LOCALES.FR].name)
      );
   });

   return {
      [LOCALES.NL]: dutchProductsMap,
      [LOCALES.FR]: frenchProductsMap,
   };
}

export function transformNotificationsResponse(response: INotification[]): Notification[] {
   return response.map((notification) => {
      const {
         recipientId,
         salesProcessId,
         data,
         modified,
         producerAccount,
         policyHolder,
         ...rest
      } = notification;

      return {
         ...rest,
         policyPartyType: data.policyPartyType,
         discardDate: data.discardDate,
         status: data.status,
         policyHolderName: `${policyHolder.firstName} ${policyHolder.surname}`,
         producerAccountNumber: producerAccount.number.toString(),
         producerAccountType: producerAccount.type,
      };
   });
}

export function transformPremiumCalculationFormValues(
   values: PremiumCalculationFormValues
): PremiumCalculationParams {
   const {insuredAdults, paymentFrequency, ...rest} = values;
   const [firstInsuredAdult, secondInsuredAdult] = insuredAdults.map((insured) => ({
      ...insured,
      personalInformation: {
         birthDate: moment(insured.personalInformation.birthDate).format('DD/MM/YYYY'),
      },
   }));

   if (paymentFrequency === PaymentFrequency.OneTimeOnly) {
      firstInsuredAdult.payUntilAge = null;
   }
   const result: PremiumCalculationParams = {
      ...rest,
      careClause: false,
      firstInsuredAdult,
      paymentFrequency,
   };
   if (secondInsuredAdult) {
      result.secondInsuredAdult = secondInsuredAdult;
   }
   return result;
}

export function transformInitiateSalesProcessFormValues(
   values: InitiateSalesProcessFormValues
): InitiateSalesProcessParams {
   const {
      policyHolder: {participatingAsInsuredAdult, personalInformation},
      ...rest
   } = values;

   return {
      policyHolder: {
         participatingAsInsuredAdult: participatingAsInsuredAdult === 'YES',
         personalInformation,
      },
      ...rest,
   };
}
