import React from 'react';
import classNames from 'classnames';
import {string, object, oneOfType, bool} from 'prop-types';
import {NavLink} from 'react-router-dom';

const NavigationLink = ({path, title, active = false, error}) => (
   <NavLink
      to={path}
      activeClassName={classNames({active})}
      className={classNames('nav-item-button', {error})}
   >
      {title}
   </NavLink>
);

NavigationLink.propTypes = {
   path: string,
   title: oneOfType([object, string]),
   active: bool,
   error: bool,
};

export default NavigationLink;
