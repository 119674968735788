import React from 'react';
import classNames from 'classnames';
import {string, bool} from 'prop-types';
import {FormattedMessage} from '../../../../FormattedMessage';
import {QUESTIONNAIRE_STEP_TYPES} from '../../../../../constants/index';

export const SUMMARY_SECTION_TITLES = {
   GENERIC: QUESTIONNAIRE_STEP_TYPES.GENERIC,
   UNCATEGORIZED: 'UNCATEGORIZED',
};

const SummarySectionTitle = ({code, active}) => {
   if (!code) {
      return (
         <div className={classNames('summary-section-title', {active})}>
            <FormattedMessage id="healthDeclaration.healthQuestionnaire.generic" />
         </div>
      );
   }

   return (
      <div className={classNames('summary-section-title', {active})}>
         {code === SUMMARY_SECTION_TITLES.UNCATEGORIZED ? (
            <FormattedMessage id="healthDeclaration.healthQuestionnaire.uncategorized" />
         ) : (
            <FormattedMessage
               id="healthDeclaration.healthQuestionnaire.category"
               values={{category: code}}
            />
         )}
      </div>
   );
};

SummarySectionTitle.propTypes = {
   code: string,
   active: bool,
};

export default SummarySectionTitle;
