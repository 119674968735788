import moment from 'moment';

import { DATE_FORMAT, GENDER } from '../constants';

/**
 * https://nl.wikipedia.org/wiki/Rijksregisternummer
 * https://github.com/moeriki/be-nrn
 */

const LENGTH_VALID_NRN = 11; // Eg. 86081441359

const matchesNrnInterface = (nrn) => Boolean(nrn && nrn.birthDate && nrn.serial && nrn.checksum);

const mod97 = (input) => String(97 - (Number(input) % 97)).padStart(2, '0');

function getBirthDay(birthDate) {
  return Number.parseInt(birthDate[2], 10);
}

function getBirthMonth(birthDate) {
  return Number.parseInt(birthDate[1], 10);
}

export function getBirthDate(nrn) {
  const parsedNrn = parse(nrn);
  const year = getBirthYear(parsedNrn); // Eg. '86' from '860814'
  const month = getBirthMonth(parsedNrn.birthDate); // Eg. 8 from '860814'
  const day = getBirthDay(parsedNrn.birthDate); // Eg. 14 from '860814'
  const parsedBirthDate = moment(`${year}-${month}-${day}`, 'YYYY-M-D', true);
  if (!parsedBirthDate.isValid()) {
    throw new Error('Birth date is unknown');
  }
  return parsedBirthDate.format(DATE_FORMAT);
}

export function getBirthYear(nrn) {
  const { birthDate, serial, checksum } = parse(nrn);
  const partialYear = birthDate[0]; // Eg. '86' from '860814'
  let year;
  const checksum19 = mod97(`${birthDate.join('')}${serial}`);
  const checksum20 = mod97(`2${birthDate.join('')}${serial}`);
  if (checksum19 === checksum) {
    year = Number(`19${partialYear}`);
  } else if (checksum20 === checksum) {
    year = Number(`20${partialYear}`);
  } else {
    throw new Error(
      `Could not calculate birthDate with invalid checksum of "${checksum}", expected "${checksum19}" for 1900 or "${checksum20}" for 2000`
    );
  }
  return year;
}

export function isBiologicalFemale(nrn) {
  const { serial } = parse(nrn);

  return Number(serial) % 2 === 0;
}

export function isBiologicalMale(nrn) {
  const { serial } = parse(nrn);

  return Number(serial) % 2 === 1;
}

export function normalize(nrn) {
  if (typeof nrn === 'string') {
    return nrn.replace(/\D+/g, '');
  }
  if (matchesNrnInterface(nrn)) {
    return `${nrn.birthDate.join('')}${nrn.serial}${nrn.checksum}`;
  }
  throw new Error('Could not normalize nrn of invalid type');
}

export function parse(nrn) {
  if (typeof nrn === 'string') {
    const normalizedNrn = normalize(nrn);
    if (normalizedNrn.length !== LENGTH_VALID_NRN) {
      throw new Error('Could not parse nrn of invalid length');
    }
    const birthDateString = normalizedNrn.slice(0, 6); // Eg. '860814' from '86081441359'
    const birthDate = [
      birthDateString.slice(0, 2),
      birthDateString.slice(2, 4),
      birthDateString.slice(4),
    ]; // Eg. ['86', '08', '14'] from '860814'
    const serial = normalizedNrn.slice(6, 9); // Eg. '413' from '86081441359'
    const checksum = normalizedNrn.slice(9, 11); // Eg. '59' from '86081441359'

    return { birthDate, serial, checksum };
  }
  if (matchesNrnInterface(nrn)) {
    return nrn;
  }
  throw new Error('Could not parse nrn of invalid type');
}

export function isValidNRN(nrn) {
  try {
    getBirthDate(nrn);
    return true;
  } catch {
    return false;
  }
}

export function matchesPerson(nrn, personData) {
  const { birthDate, gender } = personData;
  try {
    const isValidBirthDate = moment(birthDate, DATE_FORMAT, true).isValid();
    let isBirthDateMatches = !isValidBirthDate;
    let isGenderMatches = !gender;
    if (isValidBirthDate) {
      const nrnBirthDate = getBirthDate(nrn);
      isBirthDateMatches = nrnBirthDate === birthDate;
    }
    if (gender) {
      isGenderMatches = gender === GENDER.MALE ? isBiologicalMale(nrn) : isBiologicalFemale(nrn);
    }
    return isBirthDateMatches && isGenderMatches;
  } catch {
    return false;
  }
}
