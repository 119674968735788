import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const OverflowTooltip = ({tooltip = undefined, children, className = undefined}) => {
   const showTooltip = (e) => {
      const el = e.currentTarget;
      if (el.offsetWidth < el.scrollWidth) {
         el.title = tooltip || el.textContent;
      } else {
         el.title = '';
      }
   };
   return (
      <div className={classNames('overflow-tooltip', className)} onMouseEnter={showTooltip}>
         {children}
      </div>
   );
};

export default OverflowTooltip;
