import { API_URL, API_VERSION, ENDPOINTS } from '../constants';

import { apiClient, authApiClient } from './apiClient';
import { Url } from './url';

export const calculatePremium = {
  post: (clientInfo) =>
    apiClient.post(new Url(`${API_URL}${ENDPOINTS.premiumCalculation}`), clientInfo),
  getCalculationInfo: (endpoint, id) =>
    authApiClient.get(
      new Url(`${API_URL}${API_VERSION.v1}/${endpoint}/${id}/calculation-info`),
      null
    ),
};

export const offer = {
  initiate: (data) => authApiClient.post(new Url(`${API_URL}${ENDPOINTS.offers}`), data),
  get: (id) => authApiClient.get(new Url(`${API_URL}${ENDPOINTS.offers}/${id}`), null),
  getMetadata: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.offers}/${id}/metadata`), null),
  put: (id, data) => authApiClient.put(new Url(`${API_URL}${ENDPOINTS.offers}/${id}`), data),
  convertToProposal: (id) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.offers}/${id}/proposal`), null),
  getDocument: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.offers}/${id}/printing-document`), null),
  generateDocument: (id) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.offers}/${id}/printing-document`), null),
  getDocumentGenerationStatus: (id) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.offers}/${id}/document-generation-status`),
      null
    ),
  shareByEmail: (id) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.offers}/${id}/email-document`), null),
};

export const proposal = {
  initiate: (data) => authApiClient.post(new Url(`${API_URL}${ENDPOINTS.proposals}`), data),
  get: (id) => authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}`), null),
  getMetadata: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/metadata`), null),
  getStatus: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/status`), null),
  convertToOffer: (id) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/offer`), null),
  fetchNeedsAndDesires: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/needs-desires`), null),
  patchNeedsAndDesires: ({ id, data }) =>
    authApiClient.patch(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/needs-desires`), data),
  getPolicyHolder: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/policy-holder`), null),
  patchPolicyHolder: (id, data) =>
    authApiClient.patch(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/policy-holder`), data),
  getInsuredAdults: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/insured-adults`), null),
  getInsuredChildren: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/insured-children`), null),
  patchInsured: (id, data) =>
    authApiClient.patch(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/insured-parties`), data),
  getBeneficiaries: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/beneficiaries`), null),
  patchBeneficiaries: (id, data) =>
    authApiClient.patch(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/beneficiaries`), data),
  getHealthDeclaration: (proposalId) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/health-declaration`),
      null
    ),
  getDiseasesList: () =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.healthDeclaration}/diseases`), null),
  getCategoriesList: () =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.healthDeclaration}/categories`), null),
  patchHealthDeclaration: (id, payload) =>
    authApiClient.patch(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/health-declaration`),
      payload
    ),
  shareHealthDeclaration: ({ id, payload }) =>
    authApiClient.post(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/health-declaration/sharing`),
      payload
    ),
  fetchHealthQuestionnaire: ({ id, payload }) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/health-declaration/questionnaire`, {
        partyType: payload,
      }),
      null
    ),
  validateHealthQuestionnaire: (id, partyType) =>
    authApiClient.post(
      new Url(
        `${API_URL}${ENDPOINTS.proposals}/${id}/health-declaration/questionnaire/validation`,
        {
          partyType,
        }
      ),
      null
    ),
  getAdditionalInformation: (id) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/additional-information`),
      null
    ),
  patchAdditionalInformation: (id, data) =>
    authApiClient.patch(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/additional-information`),
      data
    ),
  getSigners: (proposalId) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/documents-signers`),
      null
    ),
  submitProposal: (id) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/submission`), null),
  requestSigningUrl: (proposalId, signer) =>
    authApiClient.post(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/signingUrl`),
      signer
    ),
  resetSignerDocuments: (proposalId, signer) =>
    authApiClient.post(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/reset-documents`),
      signer
    ),
  saveSigningMode: (proposalId, data) =>
    authApiClient.patch(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/signer-signing-mode`),
      data
    ),
  uploadIdCard: ({ id, file }) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/documents/id-card`), file),
  downloadDocument: (proposalId, documentId) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/documents/${documentId}`),
      null
    ),
  errors: (proposalId) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/errors`), null),
  fetchIdentity: (proposalId) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/identity-confirmation`),
      null
    ),
  confirmIdentity: (id, payload) =>
    authApiClient.post(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/identity-confirmation`),
      payload
    ),
  getSigningMethod: (id) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/signing-method`), null),
  saveSigningMethod: (id, data) =>
    authApiClient.patch(new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/signing-method`), data),
  getProducerAccount: (proposalId) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${proposalId}/producer-account`),
      null
    ),
  sendSignedDocuments: (id, data) =>
    authApiClient.post(
      new Url(`${API_URL}${ENDPOINTS.proposals}/${id}/send-signed-documents`),
      data
    ),
};

export const navigationPane = {
  getCompleteness: (endpoint, id) =>
    authApiClient.get(new Url(`${API_URL}${API_VERSION.v1}/${endpoint}/${id}/completeness`), null),
};

export const validation = {
  iban: (payload) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.bankService}/iban/validate`), payload),
  bic: (payload) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.bankService}/bic/validate`), payload),
  bicRetrieve: (payload) =>
    authApiClient.post(new Url(`${API_URL}${ENDPOINTS.bankService}/bic/retrieve`), payload),
};

export const references = {
  getMunicipalityByPostalCode: (postalCode) =>
    authApiClient.get(new Url(`${API_URL}${ENDPOINTS.address}/municipality`, { postalCode }), null),
  getStreetByMunicipalityIdAndName: ({ municipalityId, impreciseStreetName }) =>
    authApiClient.get(
      new Url(`${API_URL}${ENDPOINTS.address}/street`, { municipalityId, impreciseStreetName }),
      null
    ),
};
