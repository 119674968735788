import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { transformValueFromStringToNumber } from 'containers/ProposalDetails/HealthDeclaration/utils';

import {
  DATE_FORMAT,
  EMAIL_REGEX,
  PAYMENT_FREQUENCY,
  PHONE_CODES,
  phoneValueToCountryCodes,
  SIMPLE_ANSWER,
  SMOKING_ANSWER,
  specialSymbolsRegexpFirstname,
  specialSymbolsRegexpSurname,
  suspiciousSymbolsRegexp,
} from '../constants';

import * as nrnUtils from './nrnUtils';
import {
  adaptInsuredChildrenPersonalInfo,
  adaptInsuredPersonalInfo,
  checkPersonalEquality,
  getCompleteYearsOfInsured,
  getCurrentMoment,
  isDateInPast,
  removeSpaces,
  roundNumber,
} from './utils';

export const phoneUtil = PhoneNumberUtil.getInstance();

export const required = (value) => (isNil(value) || value === '' ? { id: 'required' } : undefined);

export const existed = (value) => (!!value ? required(value) : { id: 'required' });

export const requiredCheckboxGroup = (value) =>
  isEmpty(value) || value === '' ? { id: 'required' } : undefined;

export const moreThan = (min) => (value) =>
  !isNil(value) && value <= min ? { id: 'moreThan', values: { min } } : undefined;

export const lessThan = (max) => (value) =>
  !isNil(value) && value > max ? { id: 'lessThan', values: { max } } : undefined;

export const dateIsValid = (value) => {
  if (!value) {
    return;
  }
  const date = moment(value, DATE_FORMAT, true);

  return date.isValid() && date.year() > 0 ? undefined : { id: 'invalidDate' };
};

export const dateInPast = (value) =>
  value && !isDateInPast(value) ? { id: 'dateInPast' } : undefined;

export const minLengthOrEmpty = (min) => (value) =>
  !value || value.length === 0 || value.length >= min
    ? undefined
    : { id: 'minLengthOrEmpty', values: { min } };

export const ageBetween = (min, max) => (value) => {
  const age = getCurrentMoment().diff(moment(value, DATE_FORMAT), 'years');

  return value && (pastDate(value) || age < min || age > max)
    ? {
        id: 'ageBetween',
        values: { min, max },
      }
    : undefined;
};

export const ageUnder = (max) => (value) => {
  const age = getCurrentMoment().diff(moment(value, DATE_FORMAT), 'years');

  return value && (pastDate(value) || age >= max) ? { id: 'ageUnder', values: { max } } : undefined;
};

export const numberBetweenExcludingMinIncludingMax = (min, max) => (value) => {
  const transformedValue = value && transformValueFromStringToNumber(value);

  return transformedValue !== '' &&
    !isNil(transformedValue) &&
    (transformedValue - 1 < min || transformedValue > max)
    ? {
        id: 'numberBetweenIncludingMax',
        values: { min, max },
      }
    : undefined;
};

export const numberBetweenIncludingMax = (min, max) => (value) => {
  return value !== '' && !isNil(value) && (value <= min || value > max)
    ? {
        id: 'numberBetweenIncludingMax',
        values: { min, max },
      }
    : undefined;
};

export const benefitInRange = (min, max) => (value) => {
  return !isNil(value) && (value < min || value > max)
    ? {
        id: 'benefitInRange',
        values: { min, max },
      }
    : undefined;
};

export const percentageIsValid = (value) => {
  return value < 0 || value > 100
    ? {
        id: 'percentageIsValid',
      }
    : undefined;
};

export const checkField = (fieldName) => (value, allValues) => {
  return get(allValues, fieldName, false);
};

export const checkSmokingAge = (fieldName) => (value, allValues) =>
  !get(allValues, fieldName) ? undefined : required(value);

export const checkSmokingUntilAge = (path) => (value, allValues) => {
  const wasSmoking = get(allValues, `${path}.isSmoking`) === SMOKING_ANSWER.QUIT;
  if (!wasSmoking) {
    return undefined;
  }
  return required(value);
};

export const pastDate = (value) => {
  return value && getCurrentMoment().diff(moment(value, DATE_FORMAT)) < 0
    ? `The age must be more than 0`
    : undefined;
};

export const withPrecondition = (precondition, validator) => (value, allValues) => {
  return precondition(value, allValues) ? validator(value, allValues) : undefined;
};

export const checkInsuredSurname = (id) => (value, allValues) => {
  return get(allValues, `insuredAdults[${id}].personalInformation.firstName`, false) ||
    get(allValues, `insuredAdults[${id}].personalInformation.nationalRegisterNumber`, false) ||
    get(allValues, `insuredAdults[${id}].email`, false) ||
    get(allValues, `insuredAdults[${id}].mobileNumber`, false)
    ? required(value)
    : undefined;
};

export const checkInsuredFirstName = (id) => (value, allValues) => {
  return get(allValues, `insuredAdults[${id}].personalInformation.surname`, false) ||
    get(allValues, `insuredAdults[${id}].personalInformation.nationalRegisterNumber`, false) ||
    get(allValues, `insuredAdults[${id}].email`, false) ||
    get(allValues, `insuredAdults[${id}].mobileNumber`, false)
    ? required(value)
    : undefined;
};

export const checkInsuredAreEqual = (id) => (value, allValues) => {
  const currentInsured = get(allValues, `insuredAdults[${id}]`);
  const otherId = id === 0 ? 1 : 0;
  const otherInsured = get(allValues, `insuredAdults[${otherId}]`);

  const currentInsuredObject = adaptInsuredPersonalInfo(currentInsured);
  const otherInsuredObject = adaptInsuredPersonalInfo(otherInsured);

  return checkPersonalEquality(otherInsuredObject, currentInsuredObject)
    ? {
        id: 'insuredAdultAreEqual',
        isHidden: true,
      }
    : undefined;
};

export const checkInsuredChildrenAreEqual = (index) => (value, allValues) => {
  const currentChild = get(allValues, `insuredChildren[${index}]`, {});
  const adaptedCurrentChild = adaptInsuredChildrenPersonalInfo(currentChild);

  const insuredChildren = get(allValues, 'insuredChildren', []);
  const result = insuredChildren
    .map((child) => adaptInsuredChildrenPersonalInfo(child))
    .some((child, childIndex) => {
      if (childIndex === index) {
        return false;
      }
      return checkPersonalEquality(child, adaptedCurrentChild);
    });

  return result
    ? {
        id: 'insuredChildrenAreEqual',
        isHidden: true,
      }
    : undefined;
};

export const checkInsuredValueNotRemoved =
  (index, field) =>
  (value, allValues, { initialValues }) => {
    if (get(allValues, `insuredAdults.${index}.id`, null) === null) {
      return undefined;
    }

    let initialValue = get(initialValues, `insuredAdults[${index}].${field}`, undefined);
    if (
      get(allValues, 'insuredAdults[0].id', null) ===
      get(initialValues, 'insuredAdults[1].id', null)
    ) {
      initialValue = get(initialValues, `insuredAdults[1].${field}`, undefined);
    }

    return initialValue ? required(value) : undefined;
  };

export const checkInsuredIsAssignedAsBeneficiary =
  (insured, section) => (index) => (value, allValues) => {
    const currentBeneficiary = get(allValues, `${section}.beneficiaries[${index}]`);

    const beneficiaryObject = adaptInsuredChildrenPersonalInfo(currentBeneficiary);
    const insuredObject = adaptInsuredPersonalInfo(insured);

    return checkPersonalEquality(beneficiaryObject, insuredObject)
      ? {
          id: 'insuredAdultsAssignedAsBeneficiaries',
          isHidden: true,
        }
      : undefined;
  };

export const checkInsuredIsBeneficiary = (beneficiaries) => (index) => (value, allValues) => {
  if (isEmpty(beneficiaries)) {
    return undefined;
  }
  const currentInsured = get(allValues, `insuredAdults[${index}]`);
  const currentInsuredObject = adaptInsuredPersonalInfo(currentInsured);
  const result = beneficiaries.some((beneficiary) =>
    checkPersonalEquality(adaptInsuredChildrenPersonalInfo(beneficiary), currentInsuredObject)
  );

  return result
    ? {
        id: 'insuredAdultsAssignedAsBeneficiaries',
        isHidden: true,
      }
    : undefined;
};

export const checkBeneficiariesAreEqual = (section) => (index) => (value, allValues) => {
  const currentBeneficiary = get(allValues, `${section}.beneficiaries[${index}]`);

  const beneficiaryObject = adaptInsuredChildrenPersonalInfo(currentBeneficiary);
  const beneficiaries = get(allValues, `${section}.beneficiaries`, []);

  const result = beneficiaries
    .map((beneficiary) => adaptInsuredChildrenPersonalInfo(beneficiary))
    .some((beneficiary, beneficiaryIndex) => {
      if (beneficiaryIndex === index) {
        return false;
      }
      return checkPersonalEquality(beneficiary, beneficiaryObject);
    });

  return result
    ? {
        id: 'beneficiaryAreEqual',
        isHidden: true,
      }
    : undefined;
};

export const benefitsAreEqual = (value, allValues) => {
  return allValues.paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY ||
    !allValues.careClause ||
    !allValues.insuredAdults[0] ||
    !allValues.insuredAdults[1] ||
    +get(allValues, 'insuredAdults[0].benefit.amount') ===
      +get(allValues, 'insuredAdults[1].benefit.amount')
    ? undefined
    : { id: 'benefitsAreEqual' };
};

export const ageDiffLess = (max) => (value, allValues) => {
  if (!allValues.careClause || !allValues.insuredAdults[0] || !allValues.insuredAdults[1]) {
    return undefined;
  }

  const diff = moment(allValues.insuredAdults[0].personalInformation.birthDate, DATE_FORMAT).diff(
    moment(allValues.insuredAdults[1].personalInformation.birthDate, DATE_FORMAT),
    'years'
  );

  return allValues.paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY || Math.abs(diff) < max
    ? undefined
    : {
        id: 'ageDiffLess',
        values: { max },
      };
};

export const checkValueNotRemovedById = (field) => (value, allValues) => {
  return get(allValues, field, false) ? required(value) : undefined;
};

const sepaCountriesMap = new Map([
  ['AT', /^AT\d{18}$/], //                            Austria         - 16n -- 20
  ['BE', /^BE\d{14}$/], //                            Belgium         - 12n -- 16
  ['BG', /^BG\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}$/], //     Bulgaria        - 4a,6n,8c -- 22
  ['CH', /^CH\d{7}[A-Z0-9]{12}$/], //                 Switzerland     - 5n,12c -- 21
  ['CY', /^CY\d{10}[A-Z0-9]{16}$/], //                Cyprus          - 8n,16c -- 28
  ['CZ', /^CZ\d{22}$/], //                            Czech Republic  - 20n -- 24
  ['DE', /^DE\d{20}$/], //                            Germany         - 18n -- 22
  ['DK', /^DK\d{16}$/], //                            Denmark         - 14n -- 18
  ['EE', /^EE\d{18}$/], //                            Estonia         - 16n -- 20
  ['ES', /^ES\d{22}$/], //                            Spain           - 20n -- 24
  ['FI', /^FI\d{16}$/], //                            Finland         - 14n -- 18
  ['FR', /^FR\d{12}[A-Z0-9]{11}\d{2}$/], //           France          - 10n,11c,2n -- 27
  ['GB', /^GB\d{2}[A-Z]{4}\d{14}$/], //               United Kingdom  - 4a,14n -- 22
  ['GI', /^GI\d{2}[A-Z]{4}[A-Z0-9]{15}$/], //         Gibraltar       - 4a,15c -- 23
  ['GR', /^GR\d{9}[A-Z0-9]{16}$/], //                 Greece          - 7n,16c -- 27
  ['HR', /^HR\d{19}$/], //                            Croatia         - 17n -- 21
  ['HU', /^HU\d{26}$/], //                            Hungary         - 24n -- 28
  ['IE', /^IE\d{2}[A-Z0-9]{4}\d{14}$/], //            Ireland         - 4c,14n -- 22
  ['IS', /^IS\d{24}$/], //                            Iceland         - 22n -- 26
  ['IT', /^IT\d{2}[A-Z]\d{10}[A-Z0-9]{12}$/], //      Italy           - 1a,10n,12c -- 27
  ['LI', /^LI\d{7}[A-Z0-9]{12}$/], //                 Liechtenstein (Principality of) - 5n,12c -- 21
  ['LT', /^LT\d{18}$/], //                            Lithuania       - 16n -- 20
  ['LU', /^LU\d{5}[A-Z0-9]{13}$/], //                 Luxembourg      - 3n,13c -- 20
  ['LV', /^LV\d{2}[A-Z]{4}[A-Z0-9]{13}$/], //         Latvia          - 4a,13c -- 21
  ['MC', /^MC\d{12}[A-Z0-9]{11}\d{2}$/], //           Monaco          - 10n,11c,2n -- 27
  ['MT', /^MT\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}$/], //    Malta           - 4a,5n,18c -- 31
  ['NL', /^NL\d{2}[A-Z]{4}\d{10}$/], //               The Netherlands - 4a,10n -- 18
  ['NO', /^NO\d{13}$/], //                            Norway          - 11n -- 15
  ['PL', /^PL\d{26}$/], //                            Poland          - 24n -- 28
  ['PT', /^PT\d{23}$/], //                            Portugal        - 21n -- 25
  ['RO', /^RO\d{2}[A-Z]{4}[A-Z0-9]{16}$/], //         Romania         - 4a,16c -- 24
  ['SE', /^SE\d{22}$/], //                            Sweden          - 20n -- 24
  ['SI', /^SI\d{17}$/], //                            Slovenia        - 15n -- 19
  ['SK', /^SK\d{22}$/], //                            Slovak Republic - 20n -- 24
  ['SM', /^SM\d{2}[A-Z]\d{10}[A-Z0-9]{12}$/], //      San Marino      - 1a,10n,12c -- 27
]);

export const ibanFormatValidation = (value) => {
  if (!value) {
    return;
  }

  const firstTwoChars = value.slice(0, 2);
  const incorrectFormat = { id: 'incorrectFormat' };

  if (!sepaCountriesMap.has(firstTwoChars)) {
    return incorrectFormat;
  }

  const regEx = sepaCountriesMap.get(firstTwoChars);
  return !regEx.test(value) ? incorrectFormat : undefined;
};

export const isIbanBelgium = (iban) => /BE|^B$/g.test(iban);

export const isBICRequired = (value, allValues) => {
  const iban = get(allValues, 'policyHolder.financialInformation.iban');
  if (!iban) {
    return;
  }
  return !isIbanBelgium(iban) ? required(value) : undefined;
};

export const IsBICLengthValid = (value) => {
  if (!value) {
    return;
  }
  const BICLength = value.length;
  return BICLength === 8 || BICLength === 11 ? undefined : { id: 'IsBICLengthValid' };
};

export const emailIsValid = (agentEmail) => (value) => {
  if (!value) {
    return;
  }
  if (!EMAIL_REGEX.test(value)) {
    return { id: 'incorrectFormat' };
  }
  const [userName, serverName] = value.split('@');
  if (value.length > 100 || userName.length > 64 || serverName.length > 48) {
    return { id: 'tooLong' };
  }
  if (agentEmail && value.toLocaleLowerCase() === agentEmail.toLocaleLowerCase()) {
    return { id: 'sameEmailAgent' };
  }
};

export const policyHoldersEmail = (value, allValues) => !value &&
    allValues.policyHolder &&
    (get(allValues, 'policyHolder.contactInformation.enabledEmailAddressForDocumentReceiving') ===
      SIMPLE_ANSWER.YES ||
      get(allValues, 'policyHolder.contactInformation.enabledEmailAddressForMarketing') ===
        SIMPLE_ANSWER.YES)
    ? required(value)
    : undefined;

export const policyHoldersResidentialAddress = (value, allValues) =>
  value ||
  (allValues.policyHolder &&
    !get(allValues, 'policyHolder.residentialAddress.postalCode', false) &&
    !get(allValues, 'policyHolder.residentialAddress.municipality', false) &&
    !get(allValues, 'policyHolder.residentialAddress.streetName', false) &&
    !get(allValues, 'policyHolder.residentialAddress.houseNumber', false))
    ? undefined
    : required(value);

export const HOUSE_NUMBER = /^\d+-\d+$|^\d+[A-Z]$|^\d+\/\d$|^\d+$/;

export const validateHouseNumber = (value) =>
  value === null || HOUSE_NUMBER.test(value) ? undefined : { id: 'invalidHouseNumber' };

export const proposalResidentialAddressHouseNumber = (value, allValues) => {
  const houseNumberRequiredValidationResult = policyHoldersResidentialAddress(value, allValues);

  return houseNumberRequiredValidationResult || validateHouseNumber(value);
};

export const offerResidentialAddressHouseNumber = (value) =>
  required(value) ? { id: 'required' } : validateHouseNumber(value);

export const checkSurname = (path, index) => (value, allValues) =>
  !get(allValues, path + `[${index}].personalInformation.firstName`) ? undefined : required(value);

export const checkFirstName = (path, index) => (value, allValues) =>
  !get(allValues, path + `[${index}].personalInformation.surname`) ? undefined : required(value);

export const checkDependentField = (fieldName) => (value, allValues) =>
  !get(allValues, fieldName) ? undefined : required(value);

export const checkSuspiciousSymbols = (value, regex) => {
  if (!value) {
    return;
  }
  const words = value.split(/\s+/);

  return suspiciousSymbolsRegexp.test(value) || words.some((word) => regex.test(word))
    ? { id: 'invalidCharacter' }
    : undefined;
};

export const checkSuspiciousSymbolsFirstname = (value) =>
  checkSuspiciousSymbols(value, specialSymbolsRegexpFirstname);

export const checkSuspiciousSymbolsSurname = (value) =>
  checkSuspiciousSymbols(value, specialSymbolsRegexpSurname);

export const checkTotalShare = (path) => (value, allValues) => {
  const totalShare = get(allValues, path, []).reduce((total, ben) => {
    if (!ben.share) {
      return total;
    }
    const parsedShared = parseFloat(ben.share);
    return roundNumber(total + parsedShared);
  }, 0);
  return totalShare > 100 ? { id: 'Total share should be equal from 0% to 100%' } : undefined;
};

export const validatePhoneNumber = (value, codeValue) => {
  if (!value) {
    return { isValid: true };
  }

  if (value.length < 2 || value.length > 17) {
    return { isValid: false };
  }

  const phoneNumber = codeValue + value;
  const countryCode = phoneValueToCountryCodes[codeValue];
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
  const internationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

  return {
    number,
    isValid:
      phoneUtil.isValidNumberForRegion(number, countryCode) &&
      (codeValue === PHONE_CODES.BELGIUM || phoneNumber === removeSpaces(internationalPhoneNumber)),
  };
};

const getPolicyHolderMobileCodeValue = (allValues) =>
  get(allValues, 'policyHolder.contactInformation.mobileNumberCode');

const getInsuredMobileCodeValue = (id) => (allValues) => {
  return get(allValues, `insuredAdults[${id}].mobileNumberCode`);
};

export const policyHolderLandlinePhoneNumberFormat = (value, allValues) => {
  const error = { id: 'landlinePhoneNumberFormat' };
  const codeValue = get(allValues, 'policyHolder.contactInformation.landlineNumberCode');
  const { isValid } = validatePhoneNumber(value, codeValue);

  return isValid ? undefined : error;
};

export const mobilePhoneNumberFormat = (getCodeValue, error) => (value, allValues) => {
  const codeValue = getCodeValue(allValues);

  const { isValid, number } = validatePhoneNumber(value, codeValue);

  if (!isValid) {
    return error;
  }

  if (!number) {
    return undefined;
  }

  const isMobile = phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE;

  return isMobile ? undefined : error;
};

export const policyHolderMobilePhoneNumberFormat = mobilePhoneNumberFormat(
  getPolicyHolderMobileCodeValue,
  { id: 'mobilePhoneNumberFormat' }
);
export const insuredMobilePhoneNumberFormat = (id) =>
  mobilePhoneNumberFormat(getInsuredMobileCodeValue(id), { id: 'mobilePhoneNumberFormat' });

export const getPolicyBeginDate = () => getCurrentMoment().startOf('month').add(1, 'months');

export const isPolicyHolderAgeUnderLimit = (value) => {
  const max = 150;
  const years = getCompleteYearsOfInsured(value);
  return value && years >= max
    ? {
        id: 'policyHolderAgeUnderLimit',
      }
    : undefined;
};

export const isYoungForCoverage = (errorMessage) => (value) => {
  const birthDate = moment(value, DATE_FORMAT);
  const age = getCurrentMoment().diff(birthDate, 'years');

  return value && age < 18
    ? {
        id: errorMessage,
      }
    : undefined;
};

export const isFreeCoverageAge = (value) => {
  const eligibilityExpirationDate = moment(value, DATE_FORMAT);

  const age = getPolicyBeginDate().diff(eligibilityExpirationDate, 'years');
  return value && age >= 18
    ? {
        id: 'insuredChildAge',
      }
    : undefined;
};

export const isOldForOneTimePayment = (value) => {
  const max = 108;
  const years = getCompleteYearsOfInsured(value);
  return value && years >= max
    ? {
        id: 'insuredAdultOverAgeOneTime',
        values: { max },
      }
    : undefined;
};

export const isOldForPeriodicPayment = (value) => {
  const max = 69;
  const validationMessage = 'insuredAdultOverAgePeriodical';

  const years = getCompleteYearsOfInsured(value);
  return value && years >= max
    ? {
        id: validationMessage,
        values: { max },
      }
    : undefined;
};

export const payUntilAgeOnPolicyHolder = (value, all, props) => {
  const isParticipatingAsInsuredAdult =
    get(all, 'policyHolder.participatingAsInsuredAdult', false) === SIMPLE_ANSWER.YES;

  const birthDate = isParticipatingAsInsuredAdult
    ? get(all, 'policyHolder.personalInformation.birthDate')
    : get(props, 'firstInsuredAdultBirthDate');

  const allValues = { insuredAdults: [{ personalInformation: { birthDate } }] };

  let result = required(value);

  if (!result) {
    result = isPayUntilUnderRangeForPeriodicPayment(0)(value, allValues);
  }
  if (!result) {
    result = isPayUntilOverRangeForPeriodicPayment(0)(value, allValues);
  }
  return result;
};

export const isPayUntilUnderRangeForPeriodicPayment = (id) => (value, allValues) => {
  const fieldValue = get(allValues, `insuredAdults[${id}].personalInformation.birthDate`, false);

  if (!fieldValue) {
    return;
  }

  const policyBeginYear = getPolicyBeginDate().year();
  const endOfPaymentYear = moment(fieldValue, DATE_FORMAT).add(value, 'years').year();

  return endOfPaymentYear <= policyBeginYear
    ? {
        id: 'insuredAdultPayUntilUnderRange',
      }
    : undefined;
};

export const isPayUntilOverRangeForPeriodicPayment = (id) => (value, allValues) => {
  const fieldValue = get(allValues, `insuredAdults[${id}].personalInformation.birthDate`, false);

  if (!fieldValue) {
    return;
  }

  let minAge = 18;
  const maxAge = 80;
  const policyBeginYear = getPolicyBeginDate().add(1, 'years').year();
  const insuredBirthYear = moment(fieldValue, DATE_FORMAT).year();
  minAge = Math.max(minAge, policyBeginYear - insuredBirthYear);
  return value < minAge || value > maxAge
    ? {
        id: 'insuredAdultPayUntilOverRange',
        values: { min: minAge, max: maxAge },
      }
    : undefined;
};

export const ibanRequiredWhenBicPresent = (value, allValues) => {
  const bic = get(allValues, 'policyHolder.financialInformation.bic');
  return !value && !!bic
    ? {
        id: 'required',
      }
    : undefined;
};

export const bloodPressureFormat = (value) =>
  !value || /^([1-9]|[1-9]\d)\/([1-9]|[1-9]\d)$/.test(value)
    ? undefined
    : { id: 'incorrectFormat' };

export const checkMedicalOptionIsExisted = (value) =>
  existed(value) ? { id: 'medicalOptionIsExisted' } : undefined;

export const nationalRegisterNumberFormat = (value) => {
  if (value && !nrnUtils.isValidNRN(value)) {
    return { id: 'invalidNationalRegisterNumberFormat' };
  }
};

export const nationalRegisterNumberMatchesPersonData = (value, personData) => {
  if (!value) {
    return;
  }
  const isMatched = nrnUtils.matchesPerson(value, personData);
  return isMatched ? undefined : { id: 'nationalRegisterNumberNotMatch' };
};

export const nationalRegisterNumberMatchesInsuredAdult = (index) => (value, allValues) => {
  const insuredAdult = get(allValues, `insuredAdults[${index}]`, { personalInformation: {} });
  const {
    personalInformation: { gender, birthDate },
  } = insuredAdult;

  return nationalRegisterNumberMatchesPersonData(value, { birthDate, gender });
};

export const nationalRegisterNumberMatchesPolicyHolder = (value, allValues) => {
  const birthDate = get(allValues, 'policyHolder.personalInformation.birthDate', false);
  const gender = get(allValues, 'policyHolder.personalInformation.gender', false);
  if (!value) {
    return;
  }
  return nationalRegisterNumberMatchesPersonData(value, { birthDate, gender });
};

export const nationalRegisterNumberUniquePolicyHolder = (
  insuredAdultsNationalRegisterNumbers,
  participatingAsInsuredAdult
) => {
  return (value, allValues) => {
    if (!value) return;

    const policyHolderNationalRegisterNumber = get(
      allValues,
      'policyHolder.personalInformation.nationalRegisterNumber',
      false
    );
    const isPolicyHolderFirstInsured = participatingAsInsuredAdult !== SIMPLE_ANSWER.YES;
    const { firstInsuredAdultNationalRegisterNumber, secondInsuredAdultNationalRegisterNumber } =
      insuredAdultsNationalRegisterNumbers;

    return (isPolicyHolderFirstInsured &&
      policyHolderNationalRegisterNumber === firstInsuredAdultNationalRegisterNumber) ||
      policyHolderNationalRegisterNumber === secondInsuredAdultNationalRegisterNumber
      ? { id: 'nationalRegisterNumberNotUnique' }
      : undefined;
  };
};

export const nationalRegisterNumberUniqueFirstInsuredAdult = (
  policyHolderNationalRegisterNumber,
  participatingAsPolicyHolder
) => {
  return (value, allValues) => {
    if (!value) return;

    const firstInsuredAdultNationalRegisterNumber = get(
      allValues,
      'insuredAdults[0].personalInformation.nationalRegisterNumber',
      false
    );
    const secondInsuredAdultNationalRegisterNumber = get(
      allValues,
      'insuredAdults[1].personalInformation.nationalRegisterNumber',
      false
    );
    const isFirstInsuredPolicyHolder = participatingAsPolicyHolder !== SIMPLE_ANSWER.YES;

    return (isFirstInsuredPolicyHolder &&
      policyHolderNationalRegisterNumber === firstInsuredAdultNationalRegisterNumber) ||
      firstInsuredAdultNationalRegisterNumber === secondInsuredAdultNationalRegisterNumber
      ? { id: 'nationalRegisterNumberNotUnique' }
      : undefined;
  };
};

export const nationalRegisterNumberUniqueSecondInsuredAdult = (
  policyHolderNationalRegisterNumber
) => {
  return (value, allValues) => {
    if (!value) return;
    const firstInsuredAdultNationalRegisterNumber = get(
      allValues,
      'insuredAdults[0].personalInformation.nationalRegisterNumber',
      false
    );
    const secondInsuredAdultNationalRegisterNumber = get(
      allValues,
      'insuredAdults[1].personalInformation.nationalRegisterNumber',
      false
    );

    return firstInsuredAdultNationalRegisterNumber === secondInsuredAdultNationalRegisterNumber ||
      policyHolderNationalRegisterNumber === secondInsuredAdultNationalRegisterNumber
      ? { id: 'nationalRegisterNumberNotUnique' }
      : undefined;
  };
};
