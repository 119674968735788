import {put, all, takeLatest, select} from 'redux-saga/effects';
import {popoverService, PopoverServiceClass} from '../../shared/services/popoverService';
import {
   initializePopover,
   resetPopover,
   showPopover,
   hidePopover,
   nextPopoverStep,
   prevPopoverStep,
   setPopover,
   setPopoverShow,
   setPopoverStep,
   toggleAnimation,
} from './reducer';
import {selectPopover, selectShouldShowPopover} from './selectors';

export const POPOVER_ANIMATION_PROP = 'popoverAnimation';
export const BUTTON_ANIMATION_PROP = 'buttonAnimation';

export const createInitializePopoverSaga = ({popoverService}) =>
   function* ({payload: popoverId}) {
      let popover = popoverService.getPopover(popoverId);

      if (!popover) {
         popover = PopoverServiceClass.getPopoverInstance(true);
      }

      const payload = {popoverId, popover};
      yield put(setPopover.action(payload));
   };

export const initializePopoverSaga = createInitializePopoverSaga({popoverService});

export function* resetPopoverSaga({payload: popoverId}) {
   const popover = PopoverServiceClass.getPopoverInstance();

   const payload = {popoverId, popover};
   yield put(setPopover.action(payload));
   yield put(toggleAnimation.action({popoverId, animationProp: BUTTON_ANIMATION_PROP}));
}

export function* showPopoverSaga({payload: popoverId}) {
   const show = yield select(selectShouldShowPopover, popoverId);
   if (show) {
      yield put(toggleAnimation.action({popoverId, animationProp: POPOVER_ANIMATION_PROP}));
      return;
   }

   const payload = {popoverId, show: true};
   yield put(setPopoverShow.action(payload));
}

export function* hidePopoverSaga({payload: popoverId}) {
   const payload = {popoverId, show: false};
   yield put(setPopoverShow.action(payload));
   yield put(toggleAnimation.action({popoverId, animationProp: BUTTON_ANIMATION_PROP}));
}

export function* nextPopoverStepSaga({payload: popoverId}) {
   const popover = yield select(selectPopover, popoverId);

   const payload = {popoverId, step: popover.step + 1};
   yield put(setPopoverStep.action(payload));
}

export function* prevPopoverStepSaga({payload: popoverId}) {
   const popover = yield select(selectPopover, popoverId);

   const payload = {popoverId, step: popover.step - 1};
   yield put(setPopoverStep.action(payload));
}

export const createSyncPopoverToServiceSaga = ({popoverService}) =>
   function* syncPopoverToServiceSaga({payload: {popoverId}}) {
      const popover = yield select(selectPopover, popoverId);
      yield popoverService.setPopover(popoverId, popover);
   };

export const syncPopoverToServiceSaga = createSyncPopoverToServiceSaga({popoverService});

export default function* popoverContainerSagas() {
   yield all([
      takeLatest(initializePopover.ACTION, initializePopoverSaga),
      takeLatest(resetPopover.ACTION, resetPopoverSaga),
      takeLatest(setPopover.ACTION, syncPopoverToServiceSaga),

      takeLatest(showPopover.ACTION, showPopoverSaga),
      takeLatest(hidePopover.ACTION, hidePopoverSaga),
      takeLatest(setPopoverShow.ACTION, syncPopoverToServiceSaga),

      takeLatest(nextPopoverStep.ACTION, nextPopoverStepSaga),
      takeLatest(prevPopoverStep.ACTION, prevPopoverStepSaga),
      takeLatest(setPopoverStep.ACTION, syncPopoverToServiceSaga),
   ]);
}
