import {SECTIONS} from '../../../shared/constants';
import {createReducer} from '../../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../../shared/services/ActionHelpers';

export const containerId = 'Beneficiaries';
export const fetchBeneficiaries = new AsyncAction(`${containerId}/BENEFICIARIES_FETCH`);
export const saveBeneficiaries = new AsyncAction(`${containerId}/BENEFICIARIES_SAVE`);
export const checkShareInsuredBenefit = new Action(
   `${containerId}/BENEFICIARIES_SHARE_INSURED_BENEFIT_CHECK`
);
export const uncheckShareInsuredBenefit = new Action(
   `${containerId}/BENEFICIARIES_SHARE_INSURED_BENEFIT_UNCHECK`
);
export const setBeneficiariesTabKey = new Action(`${containerId}/BENEFICIARIES_TAB_KEY_SET`, {
   [SECTIONS.FIRST_INSURED_ADULT]: 0,
   [SECTIONS.SECOND_INSURED_ADULT]: 0,
});

export const omitBeneficiaryServerErrors = new Action(
   `${containerId}/INSURED_BENEFICIARY_SERVER_ERRORS_OMIT`
);

export const copyFromPolicyParty = new Action(`${containerId}/COPY_FROM_POLICY_PARTY`);

const actionToHandlerMap = new Map([
   [
      setBeneficiariesTabKey.ACTION,
      (draft, {payload}) => {
         const {section, selectedTabIndex} = payload;
         draft[setBeneficiariesTabKey.type][section] = selectedTabIndex;
      },
   ],
]);

export default createReducer(
   [fetchBeneficiaries, saveBeneficiaries, setBeneficiariesTabKey],
   null,
   actionToHandlerMap
);
