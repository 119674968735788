import { createSelector } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
  formValueSelector,
  getFormMeta,
  getFormSyncErrors,
  isDirty,
  isInvalid,
  isSubmitting,
} from 'redux-form';

import { SUMMARY_SECTION_TITLES } from '../../../shared/components/HealthDeclarationSummary/Diseases/components/index';
import * as fields from '../../../shared/constants/fields';
import {
  GENDER,
  HANDLED_SERVER_ERRORS,
  HEALTH_DECLARATION_FORM_STATES,
  HEALTH_DECLARATION_SHARE_STATUS,
  PARTY_TYPES,
  QUESTIONNAIRE_STEP_TYPES,
  SECTIONS,
  SIMPLE_ANSWER,
  SMOKING_ANSWER,
} from '../../../shared/constants/index';
import {
  selectActionData,
  selectAsyncActionData,
  selectIsFinishedStatus,
  selectIsInProgressStatus,
  selectIsSuccessStatus,
} from '../../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../../shared/reducers/selectors';
import {
  adaptInsuredPersonalInfo,
  adaptPolicyHolderPersonalInfo,
  findTouchedFormErrors,
  formatDate,
  getInsuredAdultIndex,
  hasTruthyValue,
  showEmailsEqualityNotification,
  sortAlphabetically,
} from '../../../shared/services/utils';
import {
  getCurrentState as getFormWrapperState,
  setFormSavingAction,
} from '../../FormWrapper/reducer';
import { selectData } from '../../NavigationPane/selectors';
import { selectInsuredAdults } from '../Insured/selectors';
import { selectPolicyHolder } from '../PolicyHolder/selectors';
import { convertToOffer, submitProposal } from '../reducer';
import {
  getCurrentState as proposalDetailsState,
  selectMetadata,
  selectServerErrors,
} from "../selectors";

import {
  changeActiveStep,
  containerId,
  fetchDiseases,
  fetchHealthDeclaration,
  fetchHealthDeclarationData,
  generateForFirstInsured,
  generateForSecondInsured,
  saveHealthDeclaration,
  saveHealthDeclarationForm,
  setActiveCategoryId,
  setValidationErrorsForFirstInsured,
  setValidationErrorsForSecondInsured,
  shareHealthDeclaration,
} from './reducer';

export const HEALTH_DECLARATION_STEPS = [
  {
    name: 'healthDeclaration.steps.optIn',
    serverErrorFields: ['medicalOption'],
    formFields: ['medicalOption'],
  },
  {
    name: 'healthDeclaration.steps.instructions',
    serverErrorFields: ['isInstructionsRead'],
    formFields: ['isInstructionsRead'],
  },
  {
    name: 'healthDeclaration.steps.general',
    formFields: [
      'weight',
      'height',
      'isSmoking',
      'smokingUntilAge',
      'alcoholGlassPerDay',
    ],
    serverErrorFields: [
      'weight',
      'height',
      'isSmoking',
      'smokingUntilAge',
      'alcoholGlassPerDay',
    ],
  },
  {
    name: 'healthDeclaration.steps.medicalIssues',
    serverErrorFields: ['hasMedicalIssues', 'basicForm.formNotStarted'],
  },

  {
    name: 'healthDeclaration.steps.addIssues',
    serverErrorFields: ['detailedForm.questionnaireNotGenerated'],
  },
  {
    name: 'healthDeclaration.steps.questionnaire',
    serverErrorFields: ['questionnaire.notCompleted'],
  },
];

const HEALTH_DECLARATION_MEDICAL_ISSUES_STEP_INDEX = 3;

export const selectEmailFormValueForLocalForm = (sectionName) => (state) =>
  selectFormValuesForLocalForm(state, `${sectionName}.email`) || null;

export const selectIsLocalHealthDeclarationSaving = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), saveHealthDeclaration.type);

export const selectIsLocalFormSaving = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), saveHealthDeclarationForm.type);

export const selectServerErrorsForLocalForm = (sectionName, path) =>
  createSelector(selectServerErrors, (serverErrors) =>
    HANDLED_SERVER_ERRORS.some((errorType) => {
      const errors = serverErrors[errorType];
      if (!errors) {
        return false;
      }
      const index = getInsuredAdultIndex(sectionName);
      const fullPath = `insuredAdults[${index}].${SECTIONS.HEALTH_DECLARATION}.${path}`;

      return get(errors, fullPath);
    })
  );

export const resolveContainerId = () => containerId;

export const selectFormErrorsValuesForLocalForm = getFormSyncErrors(containerId);

const selectFormValuesForLocalForm = formValueSelector(containerId);

export const areValuesNotExisted = (...values) =>
  values.every((value) => !(isNil(value) || value === ''));

export const getHealthDeclarationState = getStateByContainerId(containerId);

export const getCurrentState = () => getHealthDeclarationState;

// Selectors creators
export const selectStatus = (state, sectionName) => get(selectHealthDeclaration(state), `${sectionName}.status`, null);

export const selectHealthDeclaration = (state) =>
  selectAsyncActionData(getHealthDeclarationState(state), fetchHealthDeclaration.type);

export const selectSharedCompleted = (state, sectionName) => {
  const status = selectStatus(state, sectionName);

  return status === HEALTH_DECLARATION_SHARE_STATUS.COMPLETED;
};

export const selectShareButtonId = (state, sectionName) => {
  const status = selectStatus(state, sectionName);

  return `healthDeclaration.${status ? 'resendButtonLabel' : 'sendButtonLabel'}`;
};

export const selectShareStatusId = (state, sectionName) => {
  const status = selectStatus(state, sectionName);
  if (status === HEALTH_DECLARATION_SHARE_STATUS.SHARED) {
    return `healthDeclaration.sentToEmail`;
  }
  return `healthDeclaration.shareStatus.${status}`;
};

export const selectExpirationDate = (state, sectionName) => {
  const date = get(selectHealthDeclaration(state), `${sectionName}.expirationDate`, null);

  if (!date) {
    return null;
  }

  return formatDate(date);
};

export const selectStepsLength = () => HEALTH_DECLARATION_STEPS.length;

export const makeSelectStep = (sectionName, stepIndex) => (state) => ({
    hidden: makeSelectIsHidden(sectionName, stepIndex)(state),
    active: makeSelectIsStepActive(sectionName, stepIndex)(state),
    disabled: selectIsStepDisabled(sectionName, stepIndex)(state),
    visited: makeSelectIsStepVisited(sectionName, stepIndex)(state),
    hasErrors: makeSelectStepServerErrors(sectionName, stepIndex)(state),
    stepNameLocalizationKey: selectStepNameLocalizationKey(stepIndex),
  });

export const makeSelectIsHidden = (sectionName, stepIndex) => (state) => {
    const isAdditionalStep = stepIndex > HEALTH_DECLARATION_MEDICAL_ISSUES_STEP_INDEX;
    const hasMedicalIssues = selectHasMedicalIssues(sectionName)(state);

    return isAdditionalStep && hasMedicalIssues !== SIMPLE_ANSWER.YES;
  };

export const selectHasMedicalIssuesValue = (sectionName) => (state) =>
  get(selectHealthDeclaration(state), `${sectionName}.healthDeclaration.hasMedicalIssues`, null);

export const selectHasMedicalIssues = (sectionName) => (state) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.hasMedicalIssues`);

export const makeSelectIsStepActive = (sectionName, stepIndex) => (state) => {
    const activeStepIndex = selectActiveHealthDeclarationStepIndex(state, sectionName);

    return stepIndex === activeStepIndex;
  };

export const selectActiveHealthDeclarationStepIndex = (state, sectionName) => get(
    selectActionData(getHealthDeclarationState(state), changeActiveStep.type),
    sectionName,
    null
  );

export const selectIsStepDisabled = (sectionName, stepIndex) => (state) => {
  const lastEnabledStep = makeSelectLastEnabledStep(sectionName)(state);
  const isProposalSubmitted = selectIsProposalSubmitted(state);

  const hasPoloValidationError = selectHasPoloValidationErrors(sectionName)(state);
  if (hasPoloValidationError) {
    return true;
  }

  if (stepIndex === 5) {
    const isFormDirty = makeSelectIsDirty()(state);
    const diseaseIds = selectDiseaseIds(sectionName)(state);

    if (
      !isFormDirty &&
      diseaseIds.length > 0 &&
      lastEnabledStep > HEALTH_DECLARATION_MEDICAL_ISSUES_STEP_INDEX
    ) {
      return false;
    }
  }

  return stepIndex > lastEnabledStep || isProposalSubmitted;
};

export const selectIsProposalSubmitted = (state) => get(selectMetadata(state), 'blocked');
export const selectProposalId = (state) => get(selectMetadata(state), 'proposalId');

export const makeSelectLastEnabledStep = (sectionName) => (state) => {
    const isShared = selectIsShared(state, sectionName);
    const isMedicalOptChecked = selectMedicalOptChecked(state, sectionName);
    const isInstructionsRead = selectIsInstructionsRead(state, sectionName);
    const weight = selectWeight(state, sectionName);
    const height = selectHeight(state, sectionName);
    const isSmoking = selectIsSmokingChecked(state, sectionName);
    const smokingUntil = selectSmokingUntil(state, sectionName);
    const averageAlcohol = selectAlcoholGlassPerDay(state, sectionName);
    const formState = makeSelectFormState(sectionName)(state);

    const isFormShared = isShared === SIMPLE_ANSWER.YES;

    const isSecondStepDisabled = !isMedicalOptChecked || isFormShared;
    const isThirdStepDisabled = isSecondStepDisabled || !isInstructionsRead;

    let isSmokingCompleted = null;

    if (isSmoking === SMOKING_ANSWER.NO || isSmoking === SMOKING_ANSWER.YES) {
      isSmokingCompleted = true;
    }

    if (isSmoking === SMOKING_ANSWER.QUIT) {
      const isFieldCompleted = areValuesNotExisted(smokingUntil);
      if (isFieldCompleted) {
        isSmokingCompleted = true;
      }
    }

    const isForthStepDisabled =
      isThirdStepDisabled ||
      !areValuesNotExisted(weight, height, isSmokingCompleted, averageAlcohol);

    const isFifthStepDisabled =
      formState === HEALTH_DECLARATION_FORM_STATES.BASIC || isForthStepDisabled;

    const isSixthStepDisabled =
      formState === HEALTH_DECLARATION_FORM_STATES.DETAILED || isFifthStepDisabled;

    const disabledSteps = {
      0: false,
      1: isSecondStepDisabled,
      2: isThirdStepDisabled,
      3: isForthStepDisabled,
      4: isFifthStepDisabled,
      5: isSixthStepDisabled,
    };

    return Object.values(disabledSteps).lastIndexOf(false);
  };

export const selectIsShared = (state, sectionName) => selectFormValuesForLocalForm(state, `${sectionName}.isShared`);

export const selectMedicalOptChecked = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.medicalOption`);

export const selectIsInstructionsRead = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.isInstructionsRead`);

export const selectWeight = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.weight`);

export const selectHeight = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.height`);

export const selectSmokingUntil = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.smokingUntilAge`);

export const selectAlcoholGlassPerDay = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.alcoholGlassPerDay`);

export const selectIsSmokingChecked = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.isSmoking`);

export const makeSelectFormState = (sectionName) => (state) =>
  get(
    selectHealthDeclaration(state),
    `${sectionName}.healthDeclaration.formState`,
    HEALTH_DECLARATION_FORM_STATES.BASIC
  );

export const makeSelectIsStepVisited = (sectionName, stepIndex) => (state) => {
    const isHealthDeclarationStepCompleted =
      get(selectData(state), 'completeness.healthDeclaration') === 'DONE';

    if (isHealthDeclarationStepCompleted) {
      return true;
    }

    const lastEnabledStep = makeSelectLastEnabledStep(sectionName)(state);
    const dirty = makeSelectIsDirty()(state);
    const hasMedicalIssues = selectHasMedicalIssues(sectionName)(state);
    const formState = makeSelectFormState(sectionName)(state);

    switch (stepIndex) {
      case 0:
      case 1:
      case 2: {
        return stepIndex < lastEnabledStep;
      }

      case 3: {
        if (hasMedicalIssues === SIMPLE_ANSWER.NO) {
          return !dirty;
        }
        if (hasMedicalIssues === SIMPLE_ANSWER.YES) {
          return formState !== HEALTH_DECLARATION_FORM_STATES.BASIC;
        }
        return false;
      }

      case 4: {
        return (
          formState !== HEALTH_DECLARATION_FORM_STATES.BASIC &&
          formState !== HEALTH_DECLARATION_FORM_STATES.DETAILED
        );
      }

      case 5: {
        return formState === HEALTH_DECLARATION_FORM_STATES.COMPLETED;
      }

      default: {
        return false;
      }
    }
  };

export const makeSelectIsDirty = () => (state) => {
  const currentContainerId = resolveContainerId();

  return isDirty(currentContainerId)(state);
};

export const makeSelectStepServerErrors = (sectionName, stepIndex) => (state) => {
    const serverErrorFields = get(HEALTH_DECLARATION_STEPS[stepIndex], 'serverErrorFields');
    if (!serverErrorFields) {
      return false;
    }

    const selectMappedFormErrors = selectMappedFormErrorsForLocalForm;
    const path = `${sectionName}.${SECTIONS.HEALTH_DECLARATION}`;

    const formErrors = selectMappedFormErrors(path)(state);

    return serverErrorFields.some(
      (field) =>
        get(formErrors, `${path}.${field}`) ||
        makeSelectServerErrors(sectionName, field)(state) ||
        makeSelectValidationErrors(sectionName, field, stepIndex === 5)(state)
    );
  };

export const makeSelectServerErrors = (sectionName, path) =>
  selectServerErrorsForLocalForm(sectionName, path);

export const makeSelectValidationErrors = (sectionName, path, includeInternalErrors = false) =>
  createSelector(selectQuestionnaireValidationErrors(sectionName), (validationErrors) => {
    const fullPath = `${sectionName}.${SECTIONS.HEALTH_DECLARATION}.${path}`;

    if (includeInternalErrors) {
      const errorsKeys = Object.keys(validationErrors);

      return errorsKeys.some((error) => error.search(fullPath) !== -1);
    }

    return get(validationErrors, fullPath);
  });

export const selectStepNameLocalizationKey = (stepIndex) => HEALTH_DECLARATION_STEPS[stepIndex].name;

export const selectShowHealthDeclarationSummary = (state, sectionName) => {
  const activeStepIndex = selectActiveHealthDeclarationStepIndex(state, sectionName);

  return activeStepIndex > HEALTH_DECLARATION_MEDICAL_ISSUES_STEP_INDEX;
};

export const selectInsuredFullName = (state, sectionName) => {
  const insuredAdults = selectInsuredAdults(state);

  const hasPersonalInfo = get(insuredAdults, `${sectionName}.personalInformation.firstName`, false);

  if (!hasPersonalInfo) {
    return;
  }

  return `${insuredAdults[sectionName].personalInformation.firstName} ${insuredAdults[sectionName].personalInformation.surname}`;
};

export const selectIsOneAdult = (state) => get(selectMetadata(state), 'oneAdult');

export const makeSelectIsSharedOption = (sectionName, value) => (state) => {
    const isShared = selectIsShared(state, sectionName);

    return isShared === value;
  };

export const makeSelectIsProgressBarHidden = (sectionName) => (state) => {
    const isShared = selectIsShared(state, sectionName);

    return isShared === SIMPLE_ANSWER.YES;
  };

export const selectFieldPath = (sectionName) => `${sectionName}.healthDeclaration`;

export const makeSelectIsSmokingEquals = (sectionName, smokingAnswer) => (state) => {
    const isSmokingChecked = selectIsSmokingChecked(state, sectionName);

    return isSmokingChecked === smokingAnswer;
  };

export const makeSelectFieldsValidation = (sectionName, stepLocKey) => {
  const stepFields = selectStepFields(stepLocKey);

  const result = {};

  return (state) => {
    stepFields.forEach((value) => {
      result[value] = {
        hasServerError: makeSelectServerErrors(sectionName, value)(state),
        validate: selectValidate(value, sectionName),
      };
    });
    return result;
  };
};

export const selectStepFields = (stepLocKey) => {
  const index = findIndex(HEALTH_DECLARATION_STEPS, ['name', stepLocKey]);

  return HEALTH_DECLARATION_STEPS[index].formFields;
};

export const selectValidate = (field, sectionName) =>
  get(fields, [field, 'validate', sectionName], []);

const makeSelectIsInvalid = () => (state) => {
  const currentContainerId = resolveContainerId();

  return isInvalid(currentContainerId)(state);
};

export const selectFormInvalidOrSaving = (state) => {
  const invalid = makeSelectIsInvalid()(state);
  const saving = selectIsSaving(state);
  const submitting = selectIsSubmitting(state);
  const converting = selectIsConverting(state);

  return invalid || saving || submitting || converting;
};

export const selectIsSubmitting = (state) =>
  selectIsInProgressStatus(proposalDetailsState(state), submitProposal.type);

export const selectGender = (sectionName) => (state) => {
  const insuredAdults = selectInsuredAdults(state);

  return get(insuredAdults, `${sectionName}.personalInformation.gender`);
};

export const selectWeightInfoMessage = (sectionName) => (state) => {
  const gender = selectGender(sectionName)(state);
  if (gender === GENDER.MALE) {
    return null;
  }

  return 'fields.weight.infoMessage';
};

export const selectIsConvertingToOfferStatus = (state) => selectIsInProgressStatus(proposalDetailsState(state), convertToOffer.type);

export const selectIsConverting = createSelector(
  selectIsConvertingToOfferStatus,
  (isConverting) => isConverting
);

export const makeSelectCanStartQuestionnaire = (sectionName) => (state) => {
    const formInvalidOrSaving = selectFormInvalidOrSaving(state);
    const diseaseIds = selectDiseaseIds(sectionName)(state);

    return formInvalidOrSaving || diseaseIds.length === 0;
  };

export const makeSelectIsSubmitIssuesButtonDisabled = (sectionName) => (state) => {
    const disabled = selectIsProposalSubmitted(state);
    const canStartQuestionnaire = makeSelectCanStartQuestionnaire(sectionName)(state);

    return canStartQuestionnaire || disabled;
  };

export const selectHealthDeclarationAddIssuesStepData = (state) => {
  const { searchDiseasesList, categoriesMap, categoriesList } = selectRefs(state);

  return {
    diseasesList: searchDiseasesList,
    categoriesMap,
    categoriesList,
  };
};

export const makeSelectShowCompleted = (sectionName) => (state) => {
  const formState = makeSelectFormState(sectionName)(state);

  return formState === HEALTH_DECLARATION_FORM_STATES.COMPLETED;
};

export const selectQuestionnaireDisabled = (state) => {
  const disabled = selectIsProposalSubmitted(state);
  const saving = selectIsSaving(state);

  return saving || disabled;
};

export const selectQuestionnaireValidationErrors = (sectionName) => (state) => {
  let errors;
  if (sectionName === SECTIONS.FIRST_INSURED_ADULT) {
    errors = selectActionData(
      getHealthDeclarationState(state),
      setValidationErrorsForFirstInsured.type
    );
  }

  if (sectionName === SECTIONS.SECOND_INSURED_ADULT) {
    errors = selectActionData(
      getHealthDeclarationState(state),
      setValidationErrorsForSecondInsured.type
    );
  }
  return errors ?? {};
};

export const selectIsGeneratingForFirstInsured = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), generateForFirstInsured.type);

export const selectIsGeneratingForSecondInsured = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), generateForSecondInsured.type);

export const selectIsGenerating = (state) => {
  const generatingForFirstInsured = selectIsGeneratingForFirstInsured(state);
  const generatingForSecondInsured = selectIsGeneratingForSecondInsured(state);

  return generatingForFirstInsured || generatingForSecondInsured;
};

export const selectFormErrorsForLocalForm = (state) => selectFormErrorsValuesForLocalForm(state);

export const selectHealthDeclarationHealthQuestionnaireData = (state) => {
  const refs = selectRefs(state);

  return {
    categoriesList: refs.categoriesList,
    subCategoriesList: refs.subCategoriesList,
  };
};

export const selectPartyType = (sectionName) => {
  if (sectionName === SECTIONS.FIRST_INSURED_ADULT) {
    return PARTY_TYPES.FIRST_INSURED_ADULT;
  }

  if (sectionName === SECTIONS.SECOND_INSURED_ADULT) {
    return PARTY_TYPES.SECOND_INSURED_ADULT;
  }
};

export const selectIsSharing = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), shareHealthDeclaration.type);

export const makeSelectFormInvalidExcludingEmail = (sectionName) => createSelector(selectFormErrorsForLocalForm, (formErrors) => {
    const hasFormErrors = !!get(formErrors, sectionName);
    const isEmailValid = !get(formErrors, `${sectionName}.email`);

    return hasFormErrors && isEmailValid;
  });

export const selectIsLocalHealthDeclaration = (sectionName) =>
  sectionName === SECTIONS.FIRST_INSURED_ADULT || sectionName === SECTIONS.SECOND_INSURED_ADULT;

export const makeSelectFormWasShared = (sectionName) => (state) => Boolean(selectStatus(state, sectionName));

export const selectIsSharingPossible = (state, sectionName) => {
  const formWasShared = makeSelectFormWasShared(sectionName)(state);
  const formInvalidExcludingEmail = makeSelectFormInvalidExcludingEmail(sectionName)(state);
  const proposalSubmitted = selectIsProposalSubmitted(state);

  return formWasShared || formInvalidExcludingEmail || proposalSubmitted;
};

export const makeSelectBaseHealthDeclarationCompleted = (sectionName) => (state) => {
    const hasMedicalIssues = selectHasMedicalIssues(sectionName)(state);
    const dirty = makeSelectIsDirty()(state);

    return hasMedicalIssues === SIMPLE_ANSWER.NO && !dirty;
  };

export const selectHasFormErrors = (state, sectionName) => !!get(selectFormErrorsForLocalForm(state), sectionName);

export const makeSelectIsSummaryEditable = (sectionName) => (state) => {
  const saving = selectIsSaving(state);
  const generating = selectIsGenerating(state);
  const hasFormErrors = selectHasFormErrors(state, sectionName);

  return saving || generating || hasFormErrors;
};

export const selectInsuredAdultIndexForAnotherInsured = (sectionName) =>
  sectionName === SECTIONS.FIRST_INSURED_ADULT ? 2 : 1;

export const selectShowFillInsured = (state, sectionName) => {
  const insuredAdults = selectInsuredAdults(state);
  const firstName = get(insuredAdults, `${sectionName}.personalInformation.firstName`, null);

  return firstName === null;
};

export const selectHealthDeclarationSharedStatus = (state, sectionName) => get(selectHealthDeclaration(state), `${sectionName}.status`, null);

export const makeSelectIsProposalShared = (sectionName) => (state) => {
  const isProposalSubmitted = selectIsProposalSubmitted(state);
  const sharedStatus = selectHealthDeclarationSharedStatus(state, sectionName);

  return isProposalSubmitted && isNil(sharedStatus);
};

export const selectIsDataRetreived = (state) => selectIsSuccessStatus(getHealthDeclarationState(state), fetchDiseases.type);

export const makeSelectIsShared = (sectionName) => (state) => {
    const status = selectStatus(state, sectionName);
    const isShared = selectIsShared(state, sectionName);

    return status && isShared === SIMPLE_ANSWER.YES;
  };

export const selectIsFetching = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), fetchHealthDeclarationData.type);

export const selectIsDataFetched = (state) => {
  if (selectIsDataFetchingFinished(state)) {
    return selectIsSuccessStatus(getHealthDeclarationState(state), fetchHealthDeclarationData.type);
  }

  return false;
};

export const selectIsSavingFormInProgress = (state) =>
  selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsLocalFormFetching = createSelector(
  [selectIsDataFetched, selectIsSavingFormInProgress],
  (isDataFetched, isSavingFormInProgress) => isDataFetched || isSavingFormInProgress
);

export const selectIsDataFetchingFinished = (state) => (
    selectIsProposalSubmitted(state) ||
    (selectIsFinishedStatus(getHealthDeclarationState(state), fetchDiseases.type) &&
      selectIsFinishedStatus(getHealthDeclarationState(state), fetchHealthDeclaration.type))
  );

export const selectDiseaseIds = (sectionName) => (state) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.diseasesIds`) || [];

export const selectRefs = (state) =>
  selectAsyncActionData(getHealthDeclarationState(state), fetchDiseases.type);

export const selectIsFetchingDiseases = (state) =>
  selectIsInProgressStatus(getHealthDeclarationState(state), fetchDiseases.type);

export const selectIsSaving = createSelector(
  [selectIsLocalHealthDeclarationSaving, selectIsLocalFormSaving],
  (isHealthDeclarationSaving, isFormSaving) => isHealthDeclarationSaving || isFormSaving
);

export const selectQuestionnaireForFirstInsured = (state) =>
  selectAsyncActionData(getHealthDeclarationState(state), generateForFirstInsured.type);

export const selectQuestionnaireForSecondInsured = (state) =>
  selectAsyncActionData(getHealthDeclarationState(state), generateForSecondInsured.type);

export const makeSelectQuestionnaire = (sectionName) =>
  createSelector(
    [selectQuestionnaireForFirstInsured, selectQuestionnaireForSecondInsured],
    (firstInsuredQuestionnaire, secondInsuredQuestionnaire) => {
      if (!(firstInsuredQuestionnaire || secondInsuredQuestionnaire)) {
        return [];
      }

      if (sectionName === SECTIONS.FIRST_INSURED_ADULT) {
        return firstInsuredQuestionnaire;
      }

      if (sectionName === SECTIONS.SECOND_INSURED_ADULT) {
        return secondInsuredQuestionnaire;
      }
    }
  );

export const selectQuestionnaireAnswers = (state, sectionName) =>
  selectFormValuesForLocalForm(state, `${sectionName}.healthDeclaration.questionnaire`);

export const makeSelectQuestionnaireErrors = (sectionName) =>
  createSelector(selectFormErrorsForLocalForm, (formErrors) =>
    get(formErrors, `${sectionName}.healthDeclaration.questionnaire`)
  );

export const selectNotCompletedError = (state, sectionName) =>
  createSelector(
    selectServerErrorsForLocalForm(sectionName, 'questionnaire.notCompleted'),
    (notCompletedError) => notCompletedError
  )(state);

export const selectPolicyHolderEmail = (state) => get(selectPolicyHolder(state), 'contactInformation.email', null);

export const selectIsEmailRequiredForInsuredAdult = (sectionName) =>
  createSelector(
    [selectEmailFormValueForLocalForm(sectionName), selectIsOneAdult],
    (email, isOneAdult) => {
      if (isOneAdult) {
        return false;
      }
      return email === null;
    }
  );

export const selectIsEmailRequiredForPolicyHolder = createSelector(
  selectPolicyHolderEmail,
  (email) => email === null
);

export const selectIsEmailRequiredForPolicyHolderAndInsured = (sectionName) => {
  const insuredWithRequiredEmail =
    sectionName === SECTIONS.FIRST_INSURED_ADULT
      ? SECTIONS.SECOND_INSURED_ADULT
      : SECTIONS.FIRST_INSURED_ADULT;

  return createSelector(
    [
      selectIsOneAdult,
      selectIsEmailRequiredForPolicyHolder,
      selectIsEmailRequiredForInsuredAdult(insuredWithRequiredEmail),
    ],
    (isOneAdult, isPolicyHolderEmailRequired, isInsuredEmailRequired) => {
      if (isOneAdult) {
        return false;
      }

      return isPolicyHolderEmailRequired && isInsuredEmailRequired;
    }
  );
};

export const selectShowContinueButtonForMedicalStep = (sectionName) =>
  createSelector(
    [selectHasMedicalIssues(sectionName), makeSelectFormState(sectionName)],
    (formValue, formState) => {
      if (formValue === SIMPLE_ANSWER.NO) {
        return true;
      }

      return formState !== HEALTH_DECLARATION_FORM_STATES.BASIC;
    }
  );

export const selectHasPoloValidationErrors = (sectionName) => (state) => {
  const path = `${sectionName}.${SECTIONS.HEALTH_DECLARATION}`;
  const formErrors = selectMappedFormErrorsForLocalForm(path)(state);
  const filteredFormErrors = get(formErrors, path);

  if (isNil(filteredFormErrors)) {
    return false;
  }

  return Object.values(filteredFormErrors).some(
    (error) => error.id !== 'medicalOptionIsExisted' && error.id !== 'required'
  );
};

export const selectTouchedFieldsForLocalForm = (state) => getFormMeta(containerId)(state);

export const selectMappedFormErrorsForLocalForm = (errorsPath) =>
  createSelector(
    [selectFormErrorsValuesForLocalForm, selectTouchedFieldsForLocalForm],
    (formErrors, touchedFields) => findTouchedFormErrors(formErrors, touchedFields, errorsPath)
  );

export const selectIsLocalFormDisabled = createSelector(
  [
    isSubmitting(containerId),
    selectIsSaving,
    selectIsGeneratingForFirstInsured,
    selectIsGeneratingForSecondInsured,
  ],
  (isSubmitting, isSaving, isGeneratingForFirstInsured, isGeneratingForSecondInsured) =>
    isSubmitting || isSaving || isGeneratingForFirstInsured || isGeneratingForSecondInsured
);

export const makeSelectIsEmailNotificationShown = (sectionName) => {
  const otherSectionName =
    sectionName === SECTIONS.FIRST_INSURED_ADULT
      ? SECTIONS.SECOND_INSURED_ADULT
      : SECTIONS.FIRST_INSURED_ADULT;

  return createSelector(
    [
      selectEmailFormValueForLocalForm(sectionName),
      selectEmailFormValueForLocalForm(otherSectionName),
      selectPolicyHolder,
      selectInsuredAdults,
    ],
    (emailFormValue, otherEmailFormValue, policyHolder, insuredAdults) => {
      if (!emailFormValue) {
        return false;
      }

      const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);
      const currentInsuredAdult = get(insuredAdults, sectionName);
      const participatingAsInsuredAdult = get(policyHolder, 'participatingAsInsuredAdult');

      const otherInsuredAdult = get(insuredAdults, otherSectionName);

      const currentInsuredObject = adaptInsuredPersonalInfo(currentInsuredAdult);
      const otherInsuredObject = adaptInsuredPersonalInfo(otherInsuredAdult);

      return showEmailsEqualityNotification(
        {
          ...currentInsuredObject,
          email: emailFormValue,
        },
        [{ ...otherInsuredObject, email: otherEmailFormValue }, policyHolderObject],
        participatingAsInsuredAdult
      );
    }
  );
};

export const selectQuestionnaireActiveCategoryId = (state, sectionName) =>
  selectActionData(getHealthDeclarationState(state), setActiveCategoryId.type, sectionName);

const selectMergedDiseasesList = createSelector(selectRefs, ({ searchDiseasesList }) => [
  ...get(searchDiseasesList, 'browsing', []),
  ...get(searchDiseasesList, 'search', []),
]);

const selectCategoriesList = createSelector(
  selectRefs,
  ({ categoriesList = [] }) => categoriesList
);

export const selectIsQuestionnaireFormState = createSelector(
  (state, sectionName) => makeSelectFormState(sectionName)(state),
  (formState) => formState === HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE
);

export const selectIsDetailedFormState = createSelector(
  (state, sectionName) => makeSelectFormState(sectionName)(state),
  (formState) => formState === HEALTH_DECLARATION_FORM_STATES.DETAILED
);
export const selectCategorizedDiseases = createSelector(
  [
    selectMergedDiseasesList,
    (state, sectionName) => selectDiseaseIds(sectionName)(state),
    selectCategoriesList,
    selectIsQuestionnaireFormState,
    (state, sectionName) => makeSelectQuestionnaire(sectionName)(state),
  ],
  (mergedDiseases, diseaseIds, categories, isQuestionnaireFormState, questionnaire) => {
    const hasGenericQuestion = questionnaire?.some(
      (question) => question.type === QUESTIONNAIRE_STEP_TYPES.GENERIC
    );
    const categorizedDiseases = {};
    const uncategorizedDiseases = {
      diseases: [],
      code: SUMMARY_SECTION_TITLES.UNCATEGORIZED,
    };

    diseaseIds.forEach((diseaseId) => {
      const disease = mergedDiseases.find((diseaseItem) => diseaseItem.id === diseaseId);
      const categoryId = disease.mainCategoryId ?? disease.categoryId;
      if (categoryId) {
        if (categorizedDiseases[categoryId]) {
          categorizedDiseases[categoryId].diseases.push(disease);
        } else {
          categorizedDiseases[categoryId] = {
            diseases: [disease],
            categoryId,
            code: get(
              categories.find((category) => category.value === categoryId),
              'label'
            ),
          };
        }
      } else {
        uncategorizedDiseases.diseases.push(disease);
      }
    });

    const categorizedDiseasesArray = Object.values(categorizedDiseases).sort((next, prev) =>
      sortAlphabetically(next.code, prev.code)
    );

    if (hasGenericQuestion && isQuestionnaireFormState) {
      categorizedDiseasesArray.push({
        categoryId: SUMMARY_SECTION_TITLES.GENERIC,
      });
    }

    if (!isEmpty(uncategorizedDiseases.diseases)) {
      categorizedDiseasesArray.push(uncategorizedDiseases);
    }

    return categorizedDiseasesArray;
  }
);

const resolveInsuredTabsServerErrors = (serverErrors, index) =>
  HANDLED_SERVER_ERRORS.some((errorType) => {
    const errors = serverErrors[errorType];
    const insuredErrors = get(errors, `insuredAdults[${index}].healthDeclaration`, {});

    return hasTruthyValue(Object.values(insuredErrors));
  });

export const selectHasInsuredTabsServerErrors = createSelector(
  selectServerErrors,
  (serverErrors) => ({
      [SECTIONS.FIRST_INSURED_ADULT]: resolveInsuredTabsServerErrors(serverErrors, 0),
      [SECTIONS.SECOND_INSURED_ADULT]: resolveInsuredTabsServerErrors(serverErrors, 1),
    })
);
