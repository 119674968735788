import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

import {
  COMPLETENESS_STATUSES,
  DUPLICATION_SERVER_ERRORS,
  HANDLED_SERVER_ERRORS,
  SERVER_ERROR_TYPES,
} from '../../shared/constants';
import { PROPOSAL_PAGES } from '../../shared/constants/requestErrors';
import {
  selectAsyncActionData,
  selectIsInProgressStatus,
  selectIsSuccessStatus,
} from '../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../shared/reducers/selectors';
import { hasTruthyValue } from '../../shared/services/utils';
import { selectConvertingToProposalStatus } from '../OfferDetails/selectors';
import { RESIDENTIAL_ADDRESS_SAVE_ERROR } from '../ProposalDetails/PolicyHolder/sagas';
import { submitProposal } from '../ProposalDetails/reducer';
import {
  getCurrentState as proposalDetailsState,
  selectConvertingToOfferStatus,
  selectServerErrors,
} from '../ProposalDetails/selectors';

import { containerId, fetchCompleteness } from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectData = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchCompleteness.type) || {};

export const selectIsDataFetched = (state) =>
  selectIsSuccessStatus(getCurrentState(state), fetchCompleteness.type);

export const selectOfferId = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchCompleteness.type, 'offerId');

export const selectProposalId = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchCompleteness.type, 'proposalId');

export const selectIsBlocked = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchCompleteness.type, 'blocked');

export const selectCompleteness = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchCompleteness.type, 'completeness') || {};

export const selectNeedsAndDesiresStatus = createSelector(selectCompleteness, (completeness) =>
  get(completeness, 'needsAndDesires')
);

export const selectOfferStatus = createSelector(selectCompleteness, (completeness) =>
  get(completeness, 'offer', null)
);

export const selectProposalStatus = createSelector(selectCompleteness, (completeness) =>
  get(completeness, 'proposal', null)
);

export const selectIsNeedsAndDesiresNotCompleted = createSelector(
  selectNeedsAndDesiresStatus,
  (needsAndDesiresStatus) => needsAndDesiresStatus !== COMPLETENESS_STATUSES.DONE
);

export const selectIsDocumentsItemDisabled = createSelector(
  [selectProposalId, selectIsBlocked],
  (proposalId, isBlocked) => !proposalId || !isBlocked
);

// Duplicate of selector from FormWrapper to solve circular dependency
export const selectRequestingDocumentsStateStatus = (state) =>
  selectIsInProgressStatus(proposalDetailsState(state), submitProposal.type);

export const selectIsConvertToOfferButtonDisabled = createSelector(
  [
    selectIsNeedsAndDesiresNotCompleted,
    selectIsBlocked,
    selectRequestingDocumentsStateStatus,
    selectConvertingToOfferStatus,
  ],
  (isNeedsAndDesiresNotCompleted, isBlocked, isRequesting, convertingToOffer) =>
    isNeedsAndDesiresNotCompleted || isBlocked || isRequesting || convertingToOffer
);

export const selectIsConvertToProposalButtonDisabled = createSelector(
  [selectIsDataFetched, selectRequestingDocumentsStateStatus, selectConvertingToProposalStatus],
  (isDataFetched, isRequesting, convertingToProposal) =>
    !isDataFetched || isRequesting || convertingToProposal
);

export const selectHasAdditionalInformationErrors = createSelector(
  selectServerErrors,
  (serverErrors) => {
    const errors = serverErrors[SERVER_ERROR_TYPES.VALIDATION_ERRORS];
    const provided = get(errors, 'additionalInformation.provided', false);
    const insuranceIntroducer = get(errors, 'insuranceIntroducer', false);

    return provided || insuranceIntroducer;
  }
);

export const selectHasPolicyHolderErrors = (targetErrors) =>
  createSelector(selectServerErrors, (serverErrors) =>
    targetErrors.some((errorType) => {
      const errors = serverErrors[errorType];
      const policyHolder = get(errors, 'policyHolder', {});
      const invalidResidentialAddress = get(errors, RESIDENTIAL_ADDRESS_SAVE_ERROR, false);

      return hasTruthyValue(Object.values(policyHolder)) || invalidResidentialAddress;
    })
  );

export const selectHasInsuredErrors = (targetErrors) =>
  createSelector(selectServerErrors, (serverErrors) =>
    targetErrors.some((errorType) => {
      const errors = serverErrors[errorType];
      const firstInsuredAdult = get(errors, 'insuredAdults[0]', {});
      const secondInsuredAdult = get(errors, 'insuredAdults[1]', {});
      const insuredChildren = get(errors, 'insuredChildren', []);
      const shouldHaveInsuredChildren = get(errors, 'shouldHaveInsuredChildren', false);

      const hasInsuredAdultsError = hasTruthyValue([
        firstInsuredAdult?.personalInformation,
        secondInsuredAdult?.personalInformation,
      ]);
      const hasInsuredChildrenError = hasTruthyValue(insuredChildren);

      return hasInsuredAdultsError || shouldHaveInsuredChildren || hasInsuredChildrenError;
    })
  );

export const checkBeneficiariesErrors = ({
  beneficiariesTotalShare,
  paymentToUndertaker,
  beneficiaries = [],
}) => beneficiariesTotalShare || paymentToUndertaker || hasTruthyValue(beneficiaries);

export const selectHasBeneficiariesErrors = (targetErrors) =>
  createSelector(selectServerErrors, (serverErrors) =>
    targetErrors.some((errorType) => {
      const errors = serverErrors[errorType];
      const insuredAdults = get(errors, 'insuredAdults', []);

      return insuredAdults.some((insuredAdult) => checkBeneficiariesErrors(insuredAdult));
    })
  );

export const selectHasHealthDeclarationErrors = (targetErrors) =>
  createSelector(selectServerErrors, (serverErrors) =>
    targetErrors.some((errorType) => {
      const errors = serverErrors[errorType];
      const insuredAdults = get(errors, 'insuredAdults', []);

      return insuredAdults.some((insuredAdult) => {
        const { healthDeclaration = {} } = insuredAdult;

        return hasTruthyValue(Object.values(healthDeclaration));
      });
    })
  );

export const selectNavigationStepsErrorsByType = (targetErrors) =>
  createSelector(
    [
      selectHasPolicyHolderErrors(targetErrors),
      selectHasInsuredErrors(targetErrors),
      selectHasBeneficiariesErrors(targetErrors),
      selectHasHealthDeclarationErrors(targetErrors),
      selectHasAdditionalInformationErrors,
    ],
    (
      policyHolderErrors,
      insuredErrors,
      beneficiariesErrors,
      healthDeclarationErrors,
      additionalInformationErrors
    ) => ({
      [PROPOSAL_PAGES.policyHolder]: policyHolderErrors,
      [PROPOSAL_PAGES.insured]: insuredErrors,
      [PROPOSAL_PAGES.beneficiaries]: beneficiariesErrors,
      [PROPOSAL_PAGES.healthDeclaration]: healthDeclarationErrors,
      [PROPOSAL_PAGES.additionalInformation]: additionalInformationErrors,
    })
  );

export const selectNavigationStepsErrors = selectNavigationStepsErrorsByType(HANDLED_SERVER_ERRORS);
export const selectNavigationDuplicationStepsErrors =
  selectNavigationStepsErrorsByType(DUPLICATION_SERVER_ERRORS);
