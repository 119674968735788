import {SECTIONS} from '../../../shared/constants';
import {createReducer} from '../../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../../shared/services/ActionHelpers';

export const containerId = 'HealthDeclaration';
export const changeProgressBarStep = new Action(`${containerId}/CHANGE_PROGRESS_BAR_STEP`);

export const changeActiveStep = new Action(`${containerId}/CHANGE_ACTIVE_STEP`, {
   [SECTIONS.FIRST_INSURED_ADULT]: 0,
   [SECTIONS.SECOND_INSURED_ADULT]: 0,
});

export const changeSmokingOption = new Action(`${containerId}_CHANGE_SMOKING_OPTION`);

export const editSummary = new Action(`${containerId}/EDIT_SUMMARY`);
export const goToNextStep = new Action(`${containerId}/GO_TO_NEXT_STEP`);

export const fetchHealthDeclarationData = new AsyncAction(
   `${containerId}/FETCH_HEALTH_DECLARATION_DATA`
);
export const fetchHealthDeclaration = new AsyncAction(`${containerId}/FETCH_HEALTH_DECLARATION`);
export const fetchDiseases = new AsyncAction(`${containerId}/DISEASES_FETCH`);
export const shareHealthDeclaration = new AsyncAction(`${containerId}/HEALTH_DECLARATION_SHARE`);
export const clearHealthDeclaration = new Action(`${containerId}/CLEAR_HEALTH_DECLARATION`);
export const saveHealthDeclaration = new AsyncAction(`${containerId}/HEALTH_DECLARATION_SAVE`);
export const saveHealthDeclarationForm = new AsyncAction(
   `${containerId}/HEALTH_DECLARATION_SAVE_FORM`
);
export const handleSaveHealthDeclaration = new Action(
   `${containerId}/HEALTH_DECLARATION_SAVE_HANDLER`
);
export const generateForFirstInsured = new AsyncAction(
   `${containerId}/HEALTH_QUESTIONNAIRE_GENERATE_FOR_FIRST_INSURED`
);
export const generateForSecondInsured = new AsyncAction(
   `${containerId}/HEALTH_QUESTIONNAIRE_GENERATE_FOR_SECOND_INSURED`
);

export const submitShare = new Action(`${containerId}_SUBMIT_SHARE`);

export const touchField = new Action(`${containerId}_TOUCH_FIELD`);
export const redirectToPolicyHolderEmailField = new Action(
   `${containerId}_REDIRECT_TO_POLICY_HOLDER_EMAIL_FIELD`
);
export const redirectToInsuredEmailField = new Action(
   `${containerId}_REDIRECT_TO_INSURED_EMAIL_FIELD`
);
export const submitBaseHealthDeclaration = new Action(
   `${containerId}_SUBMIT_BASE_HEALTH_DECLARATION`
);

export const submitHealthDeclaration = new Action(`${containerId}_SUBMIT_HEALTH_DECLARATION`);
export const validateHealthDeclaration = new Action(`${containerId}_VALIDATE_HEALTH_DECLARATION`);

export const submitIssues = new Action(`${containerId}_SUBMIT_ISSUES`);

export const removeDisease = new Action(`${containerId}_REMOVE_DISEASE`);

export const completeQuestionnaire = new Action(`${containerId}_COMPLETE_QUESTIONNAIRE`);

export const goBackToQuestionnaire = new Action(`${containerId}_GO_BACK_TO_QUESTIONNAIRE`);
export const setValidationErrorsForFirstInsured = new Action(
   `${containerId}_SET_VALIDATION_ERRORS_FOR_FIRST_INSURED`
);
export const setValidationErrorsForSecondInsured = new Action(
   `${containerId}_SET_VALIDATION_ERRORS_FOR_SECOND_INSURED`
);

export const setActiveCategoryId = new Action(`${containerId}/SET_ACTIVE_CATEGORY_ID`, {});

export const healthDeclarationActions = [
   fetchHealthDeclaration,
   fetchHealthDeclarationData,
   fetchDiseases,
   generateForFirstInsured,
   generateForSecondInsured,
   shareHealthDeclaration,
   saveHealthDeclaration,
   changeActiveStep,
   setValidationErrorsForFirstInsured,
   setValidationErrorsForSecondInsured,
   saveHealthDeclarationForm,
   setActiveCategoryId,
];

const actionToHandlerMap = new Map([
   [
      changeActiveStep.ACTION,
      (draft, {payload}) => {
         const {sectionName, stepIndex} = payload;
         draft[changeActiveStep.type][sectionName] = stepIndex;
      },
   ],
   [
      setActiveCategoryId.ACTION,
      (draft, {payload}) => {
         const {sectionName, categoryId} = payload;
         draft[setActiveCategoryId.type][sectionName] = categoryId;
      },
   ],
]);

export default createReducer(healthDeclarationActions, null, actionToHandlerMap);
