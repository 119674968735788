import get from 'lodash/get';
import {getStateByContainerId} from '../../shared/reducers/selectors';
import {containerId} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectPopover = (state, popoverId) => get(getCurrentState(state), popoverId, null);

export const selectShouldShowPopover = (state, popoverId) =>
   get(selectPopover(state, popoverId), 'show');

export const selectCurrentPopoverStep = (state, popoverId) =>
   get(selectPopover(state, popoverId), 'step');

export const selectPopoverAnimation = (state, popover, animatedElement) =>
   get(selectPopover(state, popover), animatedElement);
