import get from 'lodash/get';
import noop from 'lodash/noop';
import { change, getFormValues, isDirty, registerField, reset, touch } from 'redux-form';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { history } from '../../../history';
import {
  HEALTH_DECLARATION_FORM_STATES,
  PARTY_TYPES,
  PROCESS_STATUS,
  SECTIONS,
  SIMPLE_ANSWER,
} from '../../../shared/constants';
import { api, toastHandler } from '../../../shared/services';
import { takeAction } from '../../../shared/services/sagaHelpers';
import { mapCategories, prepareMedicalInfo, scrollToField } from '../../../shared/services/utils';
import { setGeneralError } from '../../App/reducer';
import { selectActiveFormValues } from '../../FormWrapper/selectors';
import { fetchCompleteness } from '../../NavigationPane/reducer';
import { containerId as insuredContainerId, fetchInsuredAdults } from '../Insured/reducer';
import { selectFirstInsuredAdult, selectSecondInsuredAdult } from '../Insured/selectors';
import { containerId as policyHolderContainerId, fetchPolicyHolder } from '../PolicyHolder/reducer';
import { selectPolicyHolder } from '../PolicyHolder/selectors';
import { fetchFormData } from '../reducer';
import {
  fetchMetadataSaga,
  handleProposalGeneralError,
  resolveErrorMap,
  skipErrorPredicate,
} from '../sagas';
import { selectMetadata, selectMetadataError, selectProposalId } from '../selectors';

import {
  changeActiveStep,
  changeProgressBarStep as changeProgressBarStepAction,
  clearHealthDeclaration,
  completeQuestionnaire,
  containerId,
  editSummary,
  fetchDiseases,
  fetchHealthDeclaration,
  fetchHealthDeclarationData,
  generateForFirstInsured,
  generateForSecondInsured,
  goBackToQuestionnaire,
  goToNextStep as goToNextStepAction,
  handleSaveHealthDeclaration,
  healthDeclarationActions,
  redirectToInsuredEmailField,
  redirectToPolicyHolderEmailField,
  removeDisease as removeDiseaseAction,
  saveHealthDeclaration,
  saveHealthDeclarationForm,
  setValidationErrorsForFirstInsured,
  setValidationErrorsForSecondInsured,
  shareHealthDeclaration,
  submitBaseHealthDeclaration as submitBaseHealthDeclarationAction,
  submitHealthDeclaration,
  submitIssues,
  submitShare as submitShareAction,
  touchField,
  validateHealthDeclaration,
} from './reducer';
import {
  HEALTH_DECLARATION_STEPS,
  makeSelectFormState,
  makeSelectLastEnabledStep,
  resolveContainerId,
  selectActiveHealthDeclarationStepIndex,
  selectDiseaseIds,
  selectFieldPath,
  selectHasMedicalIssues,
  selectHasPoloValidationErrors,
  selectInsuredFullName,
  selectIsStepDisabled,
  selectPartyType,
} from './selectors';
import { transformHealthDeclarationForm } from './utils';

const resolveQuestionnaireAction = (partyType) => {
  if (partyType === PARTY_TYPES.FIRST_INSURED_ADULT) {
    return generateForFirstInsured;
  }

  if (partyType === PARTY_TYPES.SECOND_INSURED_ADULT) {
    return generateForSecondInsured;
  }
};

export function* clearHealthDeclarationSaga() {
  for (let asyncAction of healthDeclarationActions) {
    yield put(asyncAction.clear());
  }
}

export const createFetchHealthDeclarationDataSaga = (takeActionHandler) =>
  function* ({ payload }) {
    try {
      yield put(fetchFormData.request(payload));
      const result = yield takeActionHandler([fetchFormData, fetchHealthDeclarationData]);

      if (result.type !== fetchFormData.SUCCESS) {
        yield put(fetchHealthDeclarationData.failure());
        return;
      }

      const metadata = yield select(selectMetadata);

      if (!metadata) {
        throw yield select(selectMetadataError);
      }

      const { blocked, status } = metadata;
      if (!blocked || status === PROCESS_STATUS.WAITING_FOR_HEALTH_DECLARATION) {
        yield put(fetchHealthDeclaration.request(payload));
        yield put(fetchDiseases.request());
      }

      yield put(fetchHealthDeclarationData.success());
    } catch (error) {
      yield put(fetchHealthDeclarationData.failure(error));
      yield call(handleProposalGeneralError, error);
    }
  };
export const fetchHealthDeclarationDataSaga = createFetchHealthDeclarationDataSaga(takeAction);

export const createFetchHealthDeclarationSaga = ({
  selectLastEnabledStepForFirstInsured,
  selectLastEnabledStepForSecondInsured,
}) =>
  function* ({ payload }) {
    try {
      let id = payload;
      if (!id) {
        id = yield select(selectProposalId);
      }

      const healthDeclaration = yield call(api.proposal.getHealthDeclaration, id);
      const transformedHealthDeclaration = transformHealthDeclarationForm({
        formValues: healthDeclaration,
      });

      if (
        get(transformedHealthDeclaration, 'firstInsuredAdult.healthDeclaration.formState') ===
        HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE
      ) {
        yield put(
          generateForFirstInsured.request({
            id,
            partyType: PARTY_TYPES.FIRST_INSURED_ADULT,
          })
        );
      }
      if (
        get(transformedHealthDeclaration, 'secondInsuredAdult.healthDeclaration.formState') ===
        HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE
      ) {
        yield put(
          generateForSecondInsured.request({
            id,
            partyType: PARTY_TYPES.SECOND_INSURED_ADULT,
          })
        );
      }
      yield put(fetchHealthDeclaration.success(transformedHealthDeclaration));

      const lastEnabledStepIndexForFirstInsured = yield select(
        selectLastEnabledStepForFirstInsured
      );
      const lastEnabledStepIndexForSecondInsured = yield select(
        selectLastEnabledStepForSecondInsured
      );

      yield put(
        changeActiveStep.action({
          sectionName: SECTIONS.FIRST_INSURED_ADULT,
          stepIndex: lastEnabledStepIndexForFirstInsured,
        })
      );

      yield put(
        changeActiveStep.action({
          sectionName: SECTIONS.SECOND_INSURED_ADULT,
          stepIndex: lastEnabledStepIndexForSecondInsured,
        })
      );
    } catch (error) {
      yield put(fetchHealthDeclaration.failure(error));
      yield call(handleProposalGeneralError, error);
    }
  };

export const fetchHealthDeclarationSaga = createFetchHealthDeclarationSaga({
  selectLastEnabledStepForFirstInsured: makeSelectLastEnabledStep(SECTIONS.FIRST_INSURED_ADULT),
  selectLastEnabledStepForSecondInsured: makeSelectLastEnabledStep(SECTIONS.SECOND_INSURED_ADULT),
});

function* handleSaveHealthDeclarationSaga({ payload }) {
  const data = prepareDataSaveHealthDeclaration(payload);
  yield put(saveHealthDeclaration.request(data));
}

export const prepareDataSaveHealthDeclaration = (payload) => {
  const {
    [SECTIONS.FIRST_INSURED_ADULT]: firstInsuredAdult,
    [SECTIONS.SECOND_INSURED_ADULT]: secondInsuredAdult,
    partyType,
    newFormStateName,
    ...restValues
  } = payload;

  const firstInsuredAdultHealthDeclaration = prepareMedicalInfo(
    get(firstInsuredAdult, 'healthDeclaration', null)
  );
  const secondInsuredAdultHealthDeclaration = prepareMedicalInfo(
    get(secondInsuredAdult, 'healthDeclaration', null)
  );

  if (partyType === PARTY_TYPES.FIRST_INSURED_ADULT) {
    firstInsuredAdultHealthDeclaration.formState = newFormStateName;
  } else if (partyType === PARTY_TYPES.SECOND_INSURED_ADULT) {
    secondInsuredAdultHealthDeclaration.formState = newFormStateName;
  }
  return {
    firstInsuredAdult: {
      ...payload.firstInsuredAdult,
      healthDeclaration: firstInsuredAdultHealthDeclaration,
    },
    secondInsuredAdult: payload.secondInsuredAdult
      ? {
          ...payload.secondInsuredAdult,
          healthDeclaration: secondInsuredAdultHealthDeclaration,
        }
      : null,
    ...restValues,
  };
};

export function* updateInsuredEmailsSaga() {
  const formValues = yield select(selectActiveFormValues);
  const firstInsuredAdult = yield select(selectFirstInsuredAdult);
  const firstInsuredAdultEmail = get(formValues, `${SECTIONS.FIRST_INSURED_ADULT}.email`);
  const secondInsuredAdult = yield select(selectSecondInsuredAdult);

  const updatedInsured = [
    {
      ...firstInsuredAdult,
      email: firstInsuredAdultEmail,
    },
  ];

  if (secondInsuredAdult) {
    updatedInsured.push({
      ...secondInsuredAdult,
      email: get(formValues, `${SECTIONS.SECOND_INSURED_ADULT}.email`),
    });
  }
  yield put(fetchInsuredAdults.update(updatedInsured));
  if (firstInsuredAdult.participatingAsPolicyHolder === SIMPLE_ANSWER.YES) {
    const policyHolder = yield select(selectPolicyHolder);
    yield put(
      fetchPolicyHolder.update({
        ...policyHolder,
        contactInformation: {
          ...policyHolder.contactInformation,
          email: firstInsuredAdultEmail,
        },
      })
    );
  }
}

function* saveHealthDeclarationFormSaga() {
  yield call(updateInsuredEmailsSaga);

  const proposalId = yield select(selectProposalId);
  const formValues = yield select(selectActiveFormValues);
  const transformedFormValues = transformHealthDeclarationForm({
    formValues,
    isTransformToNumber: true,
  });

  const data = prepareDataSaveHealthDeclaration(transformedFormValues);

  const { ...values } = data;

  try {
    yield call(api.proposal.patchHealthDeclaration, proposalId, values);
    yield call(fetchMetadataSaga, {
      payload: proposalId,
    });
    yield put(saveHealthDeclarationForm.success());
  } catch (error) {
    yield put(saveHealthDeclarationForm.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* shareDataCallback({ shareData, questionnairePartyType, proposalId }) {
  if (shareData) {
    yield put(shareHealthDeclaration.request(shareData));
  } else if (questionnairePartyType) {
    const action = resolveQuestionnaireAction(questionnairePartyType);

    yield put(
      action.request({
        id: proposalId,
        partyType: questionnairePartyType,
      })
    );
  } else {
    toastHandler.successSave();
  }
}

export const createSaveHealthDeclarationSaga = (defaultShareCallback) =>
  function* ({ payload }) {
    const proposalId = yield select(selectProposalId);

    const {
      navigateCallback,
      shareData,
      questionnairePartyType,
      saveCallback,
      callbackSaga,
      ...values
    } = payload;

    const defaultSaveCallbackSaga = () =>
      defaultShareCallback({ shareData, questionnairePartyType, proposalId });

    const postSaveSaga = callbackSaga || defaultSaveCallbackSaga;
    yield call(updateInsuredEmailsSaga);

    const transformedFormValues = transformHealthDeclarationForm({
      formValues: values,
      isTransformToNumber: true,
    });

    try {
      yield call(api.proposal.patchHealthDeclaration, proposalId, transformedFormValues);
      yield call(fetchMetadataSaga, {
        payload: proposalId,
      });

      if (saveCallback) {
        yield call(saveCallback);
      }

      yield call(fetchHealthDeclarationSaga, { payload: proposalId });

      yield put(
        fetchCompleteness.request({
          id: proposalId,
        })
      );

      yield put(saveHealthDeclaration.success());
      yield call(postSaveSaga);

      if (navigateCallback) {
        yield put(reset(containerId));
        navigateCallback();
      }
    } catch (error) {
      yield put(saveHealthDeclaration.failure(error));
      yield put(setGeneralError.action(error));
    }
  };

export const saveHealthDeclarationSaga = createSaveHealthDeclarationSaga(shareDataCallback);

export function* fetchDiseasesSaga() {
  try {
    const [diseasesList, categoriesList] = yield all([
      call(api.proposal.getDiseasesList),
      call(api.proposal.getCategoriesList),
    ]);
    const categoriesMap = mapCategories(categoriesList, diseasesList.browsing);
    yield put(
      fetchDiseases.success({
        searchDiseasesList: diseasesList,
        categoriesList: categoriesList
          .filter((category) => !category.parentId)
          .map((category) => ({ value: category.id, label: category.code })),
        subCategoriesList: categoriesList.filter((category) => category.parentId),
        categoriesMap,
      })
    );
  } catch (error) {
    yield put(fetchDiseases.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* shareHealthDeclarationForm({ payload: { partyType } }) {
  const proposalId = yield select(selectProposalId);
  try {
    yield call(api.proposal.shareHealthDeclaration, {
      id: proposalId,
      payload: { partyType },
    });
    yield call(fetchHealthDeclarationSaga, { payload: proposalId });
    yield put(
      fetchCompleteness.request({
        id: proposalId,
      })
    );
    yield put(shareHealthDeclaration.success());
    toastHandler.uniqueSuccess({
      id: 'toasts.success.proposal.sentToProvidedEmail',
    });
  } catch (error) {
    yield put(shareHealthDeclaration.failure(error));
    toastHandler.unexpectedIssue();
  }
}

export function* generateHealthQuestionnaireSaga({ payload: { id, partyType } }) {
  const action = resolveQuestionnaireAction(partyType);
  try {
    const questionnaire = yield call(api.proposal.fetchHealthQuestionnaire, {
      id,
      payload: partyType,
    });
    yield put(action.success(questionnaire));
  } catch (error) {
    yield put(action.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* changeProgressBarStepSaga(action) {
  const {
    payload: { sectionName, stepIndex },
  } = action;

  yield call(changeProgressBarStep, {
    selectIsStepDisabled: selectIsStepDisabled(sectionName, stepIndex),
    sectionName,
    stepIndex,
  });
}

export function* changeProgressBarStep({
  selectIsStepDisabled,
  sectionName,
  stepIndex,
  handler = goToAddedStep,
}) {
  const isStepDisabled = yield select(selectIsStepDisabled);

  if (isStepDisabled) {
    return null;
  }

  if (stepIndex === 4) {
    yield call(handler, HEALTH_DECLARATION_FORM_STATES.DETAILED, sectionName, 4);
    return;
  }
  if (stepIndex === 5) {
    yield call(handler, HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE, sectionName, 5);
    return;
  }

  yield put(changeActiveStep.action({ sectionName, stepIndex }));
}

export function* goToAddedStep(newFormStateName, sectionName, stepIndex) {
  const selectFormState = makeSelectFormState(sectionName);

  const currentContainerId = resolveContainerId(sectionName);

  const selectIsDirty = (state) => isDirty(currentContainerId)(state);
  const saveCallback = () => changeActiveStep.action({ sectionName, stepIndex });

  yield call(doGoToAddedStep, {
    selectFormState,
    selectIsDirty,
    newFormStateName,
    sectionName,
    stepIndex,
    saveCallback,
  });
}

export function* doGoToAddedStep({
  selectFormState,
  selectIsDirty,
  newFormStateName,
  sectionName,
  stepIndex,
  saveCallback,
}) {
  const formStateName = yield select(selectFormState);
  const dirty = yield select(selectIsDirty);

  if (stepIndex) {
    if (!dirty && formStateName === newFormStateName) {
      yield put(changeActiveStep.action({ sectionName, stepIndex }));
      return;
    }
    yield put(submitHealthDeclaration.action({ newFormStateName, sectionName, saveCallback }));
    return;
  }
  yield put(submitHealthDeclaration.action({ newFormStateName, sectionName }));
}

export function* submitForm({
  payload: {
    newFormStateName,
    sectionName,
    saveCallback,
    callbackSaga,
    isSubmitting,
    isValidatingSharedHealthQuestionnaire,
  },
}) {
  const currentContainerId = resolveContainerId(sectionName);

  yield call(doSubmitForm, {
    selectFormState: getFormValues(currentContainerId),
    newFormStateName,
    sectionName,
    saveCallback,
    callbackSaga,
    isSubmitting,
    isValidatingSharedHealthQuestionnaire,
  });
}

export function* doSubmitForm({
  selectFormState,
  newFormStateName,
  sectionName,
  saveCallback,
  callbackSaga,
}) {
  const values = yield select(selectFormState);
  const partyType = selectPartyType(sectionName);

  yield put(
    handleSaveHealthDeclaration.action({
      ...values,
      partyType,
      newFormStateName,
      saveCallback,
      callbackSaga,
    })
  );
}

export const createEditSummary = (handler) =>
  function* (action) {
    const { payload: sectionName } = action;

    yield call(handler, HEALTH_DECLARATION_FORM_STATES.DETAILED, sectionName, 4);
  };

const editSummarySaga = createEditSummary(goToAddedStep);

export function* goToNextStepSaga(action) {
  const { payload: sectionName } = action;
  const selectLastEnabledStep = makeSelectLastEnabledStep(sectionName);
  const selectActiveStepIndex = (state) =>
    selectActiveHealthDeclarationStepIndex(state, sectionName);

  const selectHasValidationError = selectHasPoloValidationErrors(sectionName);

  yield call(goToNextStep, {
    sectionName,
    selectLastEnabledStep,
    selectActiveStepIndex,
    selectHasValidationError,
  });
}

export function* goToNextStep({
  sectionName,
  selectLastEnabledStep,
  selectActiveStepIndex,
  selectHasValidationError,
}) {
  const currentContainerId = resolveContainerId(sectionName);
  const activeStepIndex = yield select(selectActiveStepIndex);
  const nextStepIndex = activeStepIndex + 1;

  const hasValidationError = yield select(selectHasValidationError);
  const lastEnabledStep = yield select(selectLastEnabledStep);
  if (nextStepIndex <= lastEnabledStep && !hasValidationError) {
    yield put(changeActiveStep.action({ sectionName, stepIndex: nextStepIndex }));
    return;
  }
  const formFields = HEALTH_DECLARATION_STEPS[activeStepIndex].formFields;

  const fieldPath = selectFieldPath(sectionName);
  const fields = formFields.map((field) => `${fieldPath}.${field}`);
  yield put(touch(currentContainerId, ...fields));
}

export function* touchFieldSaga(action) {
  const {
    payload: { sectionName, fieldName },
  } = action;
  const currentContainerId = resolveContainerId(sectionName);

  yield put(touch(currentContainerId, `${sectionName}.${fieldName}`));
}

function* submitShareSaga(action) {
  const { payload: sectionName } = action;
  const currentContainerId = resolveContainerId(sectionName);

  const selectIsDirty = (state) => isDirty(currentContainerId)(state);
  const selectFormValues = getFormValues(currentContainerId);
  const selectInsuredName = (state) => selectInsuredFullName(state, sectionName);

  yield call(submitShare, {
    selectIsDirty,
    selectFormValues,
    selectInsuredName,
    sectionName,
  });
}

export function* submitShare({ selectIsDirty, selectFormValues, selectInsuredName, sectionName }) {
  const dirty = yield select(selectIsDirty);
  const values = yield select(selectFormValues);
  const partyType = selectPartyType(sectionName);
  const insuredName = yield select(selectInsuredName);

  if (dirty) {
    yield put(
      handleSaveHealthDeclaration.action({
        ...values,
        shareData: {
          partyType,
          insuredName,
        },
      })
    );
  } else {
    yield put(
      shareHealthDeclaration.request({
        partyType,
        insuredName,
      })
    );
  }
}

function* submitBaseHealthDeclarationSaga(action) {
  const { payload: sectionName } = action;
  const currentContainerId = resolveContainerId(sectionName);

  const selectHasMedicalIssuesSelector = selectHasMedicalIssues(sectionName);
  const selectIsDirty = (state) => isDirty(currentContainerId)(state);

  yield call(submitBaseHealthDeclaration, {
    selectHasMedicalIssues: selectHasMedicalIssuesSelector,
    selectIsDirty,
    sectionName,
  });
}

export function* submitBaseHealthDeclaration(
  { selectHasMedicalIssues, selectIsDirty, sectionName },
  handler = goToAddedStep
) {
  const hasMedicalIssues = yield select(selectHasMedicalIssues);
  if (hasMedicalIssues === SIMPLE_ANSWER.YES) {
    yield call(handler, HEALTH_DECLARATION_FORM_STATES.DETAILED, sectionName, 4);
    return;
  }

  const dirty = yield select(selectIsDirty);
  if (!dirty) {
    return;
  }

  yield put(
    submitHealthDeclaration.action({
      newFormStateName: HEALTH_DECLARATION_FORM_STATES.BASIC,
      sectionName,
    })
  );
}

export const createSubmitIssues = (handler) =>
  function* (action) {
    const { payload: sectionName } = action;
    yield call(handler, HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE, sectionName, 5);
  };

const submitIssuesSaga = createSubmitIssues(goToAddedStep);

export function* removeDiseaseSaga(action) {
  const {
    payload: { sectionName, id },
  } = action;

  const selectDiseaseIdsSelector = selectDiseaseIds(sectionName);

  yield call(removeDisease, { sectionName, id, selectDiseaseIdsSelector });
}

export function* removeDisease({ sectionName, id, selectDiseaseIdsSelector }) {
  const currentContainerId = resolveContainerId(sectionName);

  const fieldPath = selectFieldPath(sectionName);
  const diseases = yield select(selectDiseaseIdsSelector);

  const updatedValue = diseases.filter((disease) => disease.toString() !== id.toString());

  yield put(change(currentContainerId, `${fieldPath}.diseasesIds`, updatedValue));
}

export const createCompleteQuestionnaire = (handler) =>
  function* (action) {
    const { payload: sectionName } = action;

    if (sectionName === SECTIONS.FIRST_INSURED_ADULT) {
      yield put(setValidationErrorsForFirstInsured.clear());
    }

    if (sectionName === SECTIONS.SECOND_INSURED_ADULT) {
      yield put(setValidationErrorsForSecondInsured.clear());
    }

    yield call(handler, HEALTH_DECLARATION_FORM_STATES.COMPLETED, sectionName);
  };

const completeQuestionnaireSaga = createCompleteQuestionnaire(goToAddedStep);

export const createGoBackToQuestionnaire = (handler) =>
  function* (action) {
    const { payload: sectionName } = action;
    yield call(handler, HEALTH_DECLARATION_FORM_STATES.QUESTIONNAIRE, sectionName);
  };

const goBackToQuestionnaireSaga = createGoBackToQuestionnaire(goToAddedStep);

export function* triggerRedirectToEmailField(path) {
  let unlisten = noop;

  const promise = () =>
    new Promise((resolve) => {
      unlisten = history.listen(({ pathname }) => {
        if (pathname === path) {
          resolve();
        }
      });

      history.push(path);
    });

  yield call(promise);

  unlisten();
}

export function* redirectToPolicyHolderEmailFieldSaga({ payload: proposalId }) {
  const path = `/sales-process/proposals/${proposalId}/policy-holder`;
  yield call(triggerRedirectToEmailField, path);

  const fieldName = 'policyHolder.contactInformation.email';

  yield take(registerField, policyHolderContainerId, fieldName, 'Field');
  yield take(fetchFormData.SUCCESS);

  scrollToField(fieldName);
}

export function* redirectToInsuredEmailFieldSaga({ payload }) {
  const { proposalId, sectionName } = payload;

  const path = `/sales-process/proposals/${proposalId}/insured`;

  yield call(triggerRedirectToEmailField, path);

  const insuredEmailFieldIndex = sectionName === SECTIONS.FIRST_INSURED_ADULT ? 1 : 0;

  const fieldName = `insuredAdults[${insuredEmailFieldIndex}].email`;
  yield take(registerField, insuredContainerId, fieldName, 'Field');
  yield take(fetchFormData.SUCCESS);

  scrollToField(fieldName);
}

export function* validateHealthQuestionnaireSaga({
  payload: { sectionName, navigateToUnansweredQuestionCallback },
}) {
  const proposalId = yield select(selectProposalId);
  const currentContainerId = resolveContainerId(sectionName);
  const partyType = selectPartyType(sectionName);
  let action;

  if (sectionName === SECTIONS.FIRST_INSURED_ADULT) {
    action = setValidationErrorsForFirstInsured;
  }

  if (sectionName === SECTIONS.SECOND_INSURED_ADULT) {
    action = setValidationErrorsForSecondInsured;
  }
  if (action) {
    yield put(action.clear());
  }
  try {
    yield call(api.proposal.validateHealthQuestionnaire, proposalId, partyType);
  } catch (error) {
    if (error.validationErrors) {
      const errorMap = error.validationErrors.reduce(
        resolveErrorMap(skipErrorPredicate(currentContainerId)),
        {}
      );
      yield put(action.action(errorMap));
    }
    if (navigateToUnansweredQuestionCallback) {
      navigateToUnansweredQuestionCallback();
    }
  }
}

export default function* healthDeclarationSagas() {
  yield all([
    takeLatest(fetchHealthDeclarationData.REQUEST, fetchHealthDeclarationDataSaga),
    takeLatest(fetchDiseases.REQUEST, fetchDiseasesSaga),
    takeLatest(fetchHealthDeclaration.REQUEST, fetchHealthDeclarationSaga),
    takeLatest(handleSaveHealthDeclaration.ACTION, handleSaveHealthDeclarationSaga),
    takeLatest(saveHealthDeclaration.REQUEST, saveHealthDeclarationSaga),
    takeLatest(saveHealthDeclarationForm.REQUEST, saveHealthDeclarationFormSaga),
    takeLatest(shareHealthDeclaration.REQUEST, shareHealthDeclarationForm),
    takeEvery(generateForFirstInsured.REQUEST, generateHealthQuestionnaireSaga),
    takeEvery(generateForSecondInsured.REQUEST, generateHealthQuestionnaireSaga),
    takeEvery(clearHealthDeclaration.ACTION, clearHealthDeclarationSaga),
    takeLatest(changeProgressBarStepAction.ACTION, changeProgressBarStepSaga),
    takeLatest(goToNextStepAction.ACTION, goToNextStepSaga),
    takeLatest(editSummary.ACTION, editSummarySaga),
    takeLatest(touchField.ACTION, touchFieldSaga),
    takeLatest(submitShareAction.ACTION, submitShareSaga),
    takeLatest(submitBaseHealthDeclarationAction.ACTION, submitBaseHealthDeclarationSaga),
    takeLatest(submitIssues.ACTION, submitIssuesSaga),
    takeLatest(removeDiseaseAction.ACTION, removeDiseaseSaga),
    takeLatest(goBackToQuestionnaire.ACTION, goBackToQuestionnaireSaga),
    takeLatest(completeQuestionnaire.ACTION, completeQuestionnaireSaga),
    takeLatest(redirectToPolicyHolderEmailField.ACTION, redirectToPolicyHolderEmailFieldSaga),
    takeLatest(redirectToInsuredEmailField.ACTION, redirectToInsuredEmailFieldSaga),
    takeLatest(submitHealthDeclaration.ACTION, submitForm),
    takeLatest(validateHealthDeclaration.ACTION, validateHealthQuestionnaireSaga),
  ]);
}
