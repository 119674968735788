import React from 'react';
import { FormattedMessage } from 'react-intl';

import MenuPanelContent from 'v2/features/app-drawer/components/MenuPanelContent';
import ScrollableStack from 'v2/features/app-drawer/components/ScrollableStack/ScrollableStack';

export function DELActiveContent() {
  return (
    <ScrollableStack spacing={4}>
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.DELActive.DELActiveSection.title" />}
        text={<FormattedMessage id="appDrawer.DELActive.DELActiveSection.description" />}
        linkText={<FormattedMessage id="appDrawer.DELActive.DELActiveSection.link.text" />}
        hrefId="appDrawer.DELActive.DELActiveSection.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.DELActive.delaAcademySection.title" />}
        text={<FormattedMessage id="appDrawer.DELActive.delaAcademySection.description" />}
        linkText={<FormattedMessage id="appDrawer.DELActive.delaAcademySection.link.text" />}
        hrefId="appDrawer.DELActive.delaAcademySection.link.href"
      />
    </ScrollableStack>
  );
}
