import { scroller } from 'react-scroll';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import set from 'lodash/set';
import some from 'lodash/some';
import moment from 'moment';

import {
  DATE_FORMAT,
  DOCUMENT_STATUS,
  PAYMENT_FREQUENCY,
  PERSONAL_INFO_KEYS,
  PHONE_CODES,
  ROOT_URI,
  SECTIONS,
  SIMPLE_ANSWER,
  SMOKING_ANSWER,
} from '../constants';

import {
  dateIsValid,
  getPolicyBeginDate,
  isOldForOneTimePayment,
  isOldForPeriodicPayment,
} from './validators';

const NOT_NUMBERS = /\D+/g;
const ALLOWED_HOUSE_NUMBER_CHARACTERS = /^[\d/A-Za-z-]*$/;

export const isHouseNumberCharacterAllowed = (value = '') => ALLOWED_HOUSE_NUMBER_CHARACTERS.test(value);
export const isPhoneNumberValid = (value = '') => /^\d{0,16}$/g.test(value);

export const filterNumbers = (value) =>
  value && String(value).replace(NOT_NUMBERS, '') ? +String(value).replace(NOT_NUMBERS, '') : null;

export const roundNumber = (value) => (value ? +(Math.round(value + 'e+2') + 'e-2') : null);

export const devideEqually = (number, decimalValues = 2) => {
  if (!number) {
    return null;
  }

  const decimalMultiplier = Math.pow(10, decimalValues);

  const quotient = Math.trunc((100 * decimalMultiplier) / number);

  const remainder = (100 * decimalMultiplier) % number;

  const all = quotient / decimalMultiplier;
  const last = (quotient + remainder) / decimalMultiplier;

  return { all, last };
};

export const parseNumber = (value) => value && +value;

export const removeSpaces = (value) => value.replace(/\s/g, '');

export const sortByIndex = (a, b) => +a.index - b.index;

export const addIndex = (cur, index) => ({ ...cur, index });

export const sortAlphabetically = (a, b) => a.localeCompare(b);

export const sortParties = (parties) => parties.sort(sortByIndex);

export const prepareMedicalInfo = (values) => {
  if (!get(values, 'medicalOption')) {
    return null;
  }
  const isSmoking = get(values, 'isSmoking');

  if (isSmoking === SMOKING_ANSWER.YES || isSmoking === SMOKING_ANSWER.NO) {
    return omit(values, ['smokingUntilAge']);
  }

  return { ...values };
};

export const mapCategories = (categories, browsingDiseases) => (
    categories &&
    categories.reduce((map, category) => {
      map[category.id] = {
        ...category,
        subcategories: [],
        diseases: browsingDiseases.filter((el) => el.categoryId === category.id),
      };
      if (category.parentId) {
        map[category.parentId].subcategories.push(map[category.id]);
      }
      return map;
    }, {})
  );

export const formatCurrency = (value) =>
  (+value).toLocaleString('nl-BE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR',
  });

export const formatDate = (date) => moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm');

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

export const getRedirectUri = (path = '') => ROOT_URI + path;

export const getObjectFirstDefinedValue = (object) =>
  Object.keys(object).find((key) => !!object[key]);

export const getFirstInvalidInputName = (errors) => {
  const firstKey = getObjectFirstDefinedValue(errors);
  let result = firstKey;
  const errorObj = errors[firstKey];
  if (Array.isArray(errorObj)) {
    const errorObjFirstKey = getObjectFirstDefinedValue(errorObj);
    result += '[' + errorObjFirstKey + '].' + getFirstInvalidInputName(errorObj[errorObjFirstKey]);
  } else if (!errorObj.hasOwnProperty('id')) {
    result += '.' + getFirstInvalidInputName(errorObj);
  }
  return result;
};

export const scrollToField = (fieldName) =>
  scroller.scrollTo(fieldName, {
    duration: 1000,
    smooth: true,
    offset: -100,
  });

export const focusInvalidInput = (inputName) => {
  const el = document.querySelector(`[name="${inputName}"]`);
  if (el && el.getAttribute('type') !== 'radio') {
    el.focus();
  }
};

export const scrollToInvalidInput = (errors) => {
  if (isEmpty(errors)) {
    return;
  }
  let invalidInputName;
  try {
    invalidInputName = getFirstInvalidInputName(errors);
  } catch (error) {
    console.log(error);
    return;
  }

  scrollToField(invalidInputName);
  focusInvalidInput(invalidInputName);

  return invalidInputName;
};

export const isTrue = (value) => value === true || value === 'true';

export const composeAsyncValidators = (validatorsMap) => (values, dispatch, props, field) => {
  if (field) {
    const validator = validatorsMap[field];

    return validator(values, dispatch, props);
  }
  return new Promise((resolve) => resolve());
};

export const asyncValidator = (action) => (values, dispatch, props) =>
  new Promise((resolve, reject) => {
    dispatch(action({ values, resolve, reject, props }));
  });

export const withProps = (props) => (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  ...props,
});

export const createFindFormFields = (param) => {
  const findFormFields = (object, path = '', result = []) => {
    if (isArray(object)) {
      object.forEach((value, index) => {
        findFormFields(value, `${path}[${index}]`, result);
      });
    }

    if (isPlainObject(object)) {
      Object.keys(object).forEach((key) => {
        const keyPath = getFullErrorPath(path, key);

        if (object[key][param]) {
          result.push(keyPath);
          return;
        }

        findFormFields(object[key], keyPath, result);
      });
    }

    return result;
  };

  return findFormFields;
};

const findTouchedFields = createFindFormFields('touched');

const getFullErrorPath = (prefix, errorPath) => {
  if (!prefix) {
    return errorPath;
  }

  return `${prefix}.${errorPath}`;
};

export const findTouchedFormErrors = (formErrors, touchedFields, errorPath = '') => {
  const getErrors = (errors, path) => {
    if (!path) {
      return errors;
    }

    return get(errors, path);
  };

  const result = {};

  const selectedTouchedFields = getErrors(touchedFields, errorPath);
  const selectedFormErrors = getErrors(formErrors, errorPath);

  if (!selectedTouchedFields || !selectedFormErrors) {
    return result;
  }

  const resultTouchedFields = findTouchedFields(selectedTouchedFields);

  resultTouchedFields.forEach((localErrorPath) => {
    const fullPath = getFullErrorPath(errorPath, localErrorPath);
    const error = get(formErrors, fullPath);

    if (!error) {
      return;
    }

    return set(result, fullPath, error);
  });

  return result;
};

export const disableOneTimeOnlyOption = (paymentFrequency) =>
  paymentFrequency.value === PAYMENT_FREQUENCY.ONE_TIME_ONLY
    ? { ...paymentFrequency, disabled: true }
    : paymentFrequency;

export const filterPaymentFrequencies = ({ value }) =>
  value !== PAYMENT_FREQUENCY.QUARTERLY && value !== PAYMENT_FREQUENCY.HALF_YEARLY;

export const getCurrentMoment = () => moment();

export const isDateInPast = (date) => moment(date, DATE_FORMAT).isSameOrBefore(moment());

export const ageIsOlder = (birthDate, max) => {
  const policyBeginYear = getCurrentMoment().startOf('month').add(1, 'month').year();
  const birthYear = moment(birthDate, DATE_FORMAT).year();
  const age = policyBeginYear - birthYear;

  return birthDate && isDateInPast(birthDate) && age > max;
};

export const getCompleteYearsOfInsured = (value) => {
  const birthDate = moment(value, DATE_FORMAT);
  const lastDayOfPreviousYear = getPolicyBeginDate().startOf('year').subtract(1, 'days');

  return lastDayOfPreviousYear.diff(birthDate, 'year');
};

export const isInRangeOfOneTimeOnly = (birthDate) => {
  const isValid = !dateIsValid(birthDate);
  const isOldForPeriodic = Boolean(isOldForPeriodicPayment(birthDate));
  const isOldForOneTime = Boolean(isOldForOneTimePayment(birthDate));

  return isValid && isOldForPeriodic && !isOldForOneTime;
};

export const getPartyFullName = (personalInfo) => {
  if (!personalInfo) {
    return;
  }
  const { firstName, surname } = personalInfo;

  return firstName && surname ? `${firstName} ${surname}` : undefined;
};

export const parsePhoneNumbers = (phoneNumber) => {
  let phoneNumberCode = PHONE_CODES.BELGIUM;
  let modifiedPhoneNumber = phoneNumber;
  if (phoneNumber) {
    const phoneCodes = Object.values(PHONE_CODES);
    const currentCode = phoneCodes.find((code) => phoneNumber.startsWith(code));

    if (currentCode) {
      phoneNumberCode = currentCode;
      modifiedPhoneNumber = phoneNumber.split(currentCode)[1];
    }
  }
  return {
    phoneNumberCode,
    phoneNumber: modifiedPhoneNumber,
  };
};

export const slicePhoneNumber = (number) => {
  if (number && number.startsWith('0')) {
    return number.slice(1);
  }
  return number;
};

export const resolveMobileNumber = (payload, path) => {
  const phoneNumber = get(payload, path, false);
  if (!phoneNumber) {
    return false;
  }
  const phoneNumberCodePath = `${path}Code`;

  let updatedPhoneNumber = phoneNumber.replace(/\s/g, '');
  updatedPhoneNumber = slicePhoneNumber(updatedPhoneNumber);
  const phoneNumberCode = get(payload, phoneNumberCodePath);

  return phoneNumberCode + updatedPhoneNumber;
};

export const convertPhoneNumber = (payload, path) => {
  if (isEmpty(payload)) {
    return null;
  }
  const phoneNumber = resolveMobileNumber(payload, path);
  const phoneNumberCodePath = `${path}Code`;

  if (phoneNumber) {
    set(payload, path, phoneNumber);
  }
  return omit(payload, phoneNumberCodePath);
};

export const checkStringsAreEqual = (firstString, secondString) => {
  if (!firstString || !secondString) {
    return false;
  }

  return firstString.toLowerCase() === secondString.toLowerCase();
};

export const EMAIL_KEY = {
  EMAIL: 'email',
};

export const PHONE_KEYS = {
  MOBILE_NUMBER: 'mobileNumber',
  MOBILE_NUMBER_CODE: 'mobileNumberCode',
};

export const checkPersonalEquality = (targetObject, checkedObject) => {
  const keys = [
    PERSONAL_INFO_KEYS.FIRST_NAME,
    PERSONAL_INFO_KEYS.SURNAME,
    PERSONAL_INFO_KEYS.BIRTH_DATE,
  ];

  return keys.every(
    (key) =>
      targetObject[key] &&
      checkedObject[key] &&
      targetObject[key].toLowerCase() === checkedObject[key].toLowerCase()
  );
};

export const checkMobilesEquality = (firstObject, secondObject) => {
  const firstPhoneNumber = slicePhoneNumber(firstObject[PHONE_KEYS.MOBILE_NUMBER]);
  const secondPhoneNumber = slicePhoneNumber(secondObject[PHONE_KEYS.MOBILE_NUMBER]);

  return (
    checkStringsAreEqual(
      firstObject[PHONE_KEYS.MOBILE_NUMBER_CODE],
      secondObject[PHONE_KEYS.MOBILE_NUMBER_CODE]
    ) && checkStringsAreEqual(firstPhoneNumber, secondPhoneNumber)
  );
};

export const showEmailsEqualityNotification = (
  targetObject,
  objectArray,
  participatingAsPolicyHolder
) =>
  objectArray.some((object) => {
    if (!checkStringsAreEqual(targetObject.email, object.email)) {
      return false;
    }
    return participatingAsPolicyHolder === SIMPLE_ANSWER.NO
      ? true
      : !checkPersonalEquality(targetObject, object);
  });

export const showMobileEqualityNotification = (targetObject, objectArray) =>
  objectArray.some((object) => {
    if (!checkMobilesEquality(targetObject, object)) {
      return false;
    }

    return !checkPersonalEquality(targetObject, object);
  });

export const adaptPolicyHolderPersonalInfo = (policyHolder) => {
  if (!policyHolder || isEmpty(policyHolder)) {
    return {};
  }

  const adaptedPolicyHolderInfo = {
    [PERSONAL_INFO_KEYS.FIRST_NAME]: get(policyHolder, 'personalInformation.firstName'),
    [PERSONAL_INFO_KEYS.SURNAME]: get(policyHolder, 'personalInformation.surname'),
    [PERSONAL_INFO_KEYS.BIRTH_DATE]: get(policyHolder, 'personalInformation.birthDate'),
    [PERSONAL_INFO_KEYS.GENDER]: get(policyHolder, 'personalInformation.gender'),
    [PHONE_KEYS.MOBILE_NUMBER]: get(policyHolder, 'contactInformation.mobileNumber'),
    [PHONE_KEYS.MOBILE_NUMBER_CODE]: get(policyHolder, 'contactInformation.mobileNumberCode'),
    [EMAIL_KEY.EMAIL]: get(policyHolder, 'contactInformation.email'),
    [PERSONAL_INFO_KEYS.NATIONAL_REGISTER_NUMBER]: get(
      policyHolder,
      'personalInformation.nationalRegisterNumber'
    ),
  };

  return adaptedPolicyHolderInfo;
};

export const adaptInsuredPersonalInfo = (insured) => {
  if (!insured || isEmpty(insured)) {
    return {};
  }

  const adaptedInsuredPersonalInfo = {
    [PERSONAL_INFO_KEYS.FIRST_NAME]: get(insured, 'personalInformation.firstName'),
    [PERSONAL_INFO_KEYS.SURNAME]: get(insured, 'personalInformation.surname'),
    [PERSONAL_INFO_KEYS.BIRTH_DATE]: get(insured, 'personalInformation.birthDate'),
    [PERSONAL_INFO_KEYS.GENDER]: get(insured, 'personalInformation.gender'),
    [PHONE_KEYS.MOBILE_NUMBER]: get(insured, 'mobileNumber'),
    [PHONE_KEYS.MOBILE_NUMBER_CODE]: get(insured, 'mobileNumberCode'),
    [EMAIL_KEY.EMAIL]: get(insured, 'email'),
    [PERSONAL_INFO_KEYS.NATIONAL_REGISTER_NUMBER]: get(
      insured,
      'personalInformation.nationalRegisterNumber'
    ),
  };

  return adaptedInsuredPersonalInfo;
};

export const adaptInsuredChildrenPersonalInfo = (child) => {
  if (!child || isEmpty(child)) {
    return {};
  }

  return {
    [PERSONAL_INFO_KEYS.FIRST_NAME]: get(child, 'personalInformation.firstName'),
    [PERSONAL_INFO_KEYS.SURNAME]: get(child, 'personalInformation.surname'),
    [PERSONAL_INFO_KEYS.GENDER]: get(child, 'personalInformation.gender'),
    [PERSONAL_INFO_KEYS.BIRTH_DATE]: get(child, 'personalInformation.birthDate'),
  };
};

export const hasSignerStatus = ({ signers, status }) =>
  some(signers, (signer) => signer.status === status);

export const hasFailedDocumentStatus = (signers) =>
  hasSignerStatus({ signers, status: DOCUMENT_STATUS.FAILED });

export const hasWaitingDocumentStatus = (signers) =>
  hasSignerStatus({ signers, status: DOCUMENT_STATUS.WAITING });

export const hasUnavailableDocumentStatus = (signers) =>
  hasSignerStatus({ signers, status: DOCUMENT_STATUS.UNAVAILABLE });


export const hasTruthyValue = (array) => array.some((value) => {
    if (typeof value === 'object') {
      return hasTruthyValue(Object.values(value));
    }
    return value;
  });

export const getInsuredAdultIndex = (section) => {
  if (section === SECTIONS.FIRST_INSURED_ADULT) {
    return 0;
  }
  if (section === SECTIONS.SECOND_INSURED_ADULT) {
    return 1;
  }
};

export const getInsuredAdultSection = (index) => {
  if (index === 0) {
    return SECTIONS.FIRST_INSURED_ADULT;
  }
  if (index === 1) {
    return SECTIONS.SECOND_INSURED_ADULT;
  }
};

export const areRequiredFieldsTouched = (paths, meta) =>
  paths.every((path) => get(meta, `${path}.touched`, false));
