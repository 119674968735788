import { IPortfolio, ISalesProcessItem } from 'v2/common/api/queries/portfolio';

const proposalStatuses = [
  'DRAFT',
  'IN_REVIEW_BY_DELA',
  'WAITING_FOR_HEALTH_DECLARATION',
  'WAITING_FOR_IDENTITY_CONFIRMATION',
  'WAITING_FOR_SIGNATURE',
  'DOCUMENTS_REQUESTED',
  'DOCUMENTS_GENERATING',
  'REJECTED_BY_POLICY_PARTY',
];

const policyStatuses = ['ACTIVE', 'ENDED', 'CANCELLED', 'PASSED_AWAY', 'SURRENDER_POLICY'];
const policyProducts = ['UZPN', 'UZP', 'UZP_PLUS', 'PROD_EX_LILAS', 'HR_400'];

export const emptyList: IPortfolio = {
  page: { number: 1, size: 20, totalPages: 1, totalElements: 0 },
  _embedded: { salesProcessItems: [] },
};

export const createSalesProcessItem = (params?: Partial<ISalesProcessItem>): ISalesProcessItem => ({
  akCode: null,
  daysBeforeDiscard: null,
  id: 1,
  number: '1',
  offerId: 1,
  policyHolderFirstName: 'John',
  policyHolderSurname: 'Doe',
  policyStatus: null,
  premiumBalance: null,
  productName: null,
  proposalId: null,
  state: 'OFFER',
  status: 'DRAFT',
  step: null,
  ...params,
});

export const offersList: IPortfolio = {
  page: { number: 1, size: 20, totalPages: 1, totalElements: 2 },
  _embedded: {
    salesProcessItems: [
      createSalesProcessItem(),
      createSalesProcessItem({ id: 2, number: '2', offerId: 2, status: 'SHARED' }),
    ],
  },
};

export const proposalList: IPortfolio = {
  page: { number: 1, size: 20, totalPages: 2, totalElements: proposalStatuses.length },
  _embedded: {
    salesProcessItems: proposalStatuses.map((status, index) => {
      const id = index + 1;

      return createSalesProcessItem({
        proposalId: id,
        number: id.toString(),
        state: 'PROPOSAL',
        status,
        daysBeforeDiscard: index || null,
      });
    }),
  },
};

export const policyList: IPortfolio = {
  page: { number: 1, size: 20, totalPages: 1, totalElements: policyStatuses.length },
  _embedded: {
    salesProcessItems: policyStatuses.map((status, index) => {
      const id = index + 1;

      return createSalesProcessItem({
        proposalId: id,
        number: id.toString(),
        state: 'POLICY',
        productName: policyProducts[index],
        policyStatus: status,
        daysBeforeDiscard: index || null,
      });
    }),
  },
};
