import {createReducer} from '../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../shared/services/ActionHelpers';
export const containerId = 'OfferDetails';
export const fetchOffer = new AsyncAction(`${containerId}/OFFER_FETCH`);
export const saveOffer = new AsyncAction(`${containerId}/OFFER_SAVE`);
export const convertToProposal = new AsyncAction(`${containerId}/TO_PROPOSAL_CONVERT`);
export const shareByPrinting = new AsyncAction(`${containerId}/BY_PRINTING_SHARE`);
export const shareByEmail = new AsyncAction(`${containerId}/BY_EMAIL_SHARE`);
export const setPreviousPaymentFrequency = new Action(
   `${containerId}/PREVIOUS_PAYMENT_FREQUENCY_SET`
);
export const setOfferMetadata = new Action(`${containerId}/OFFER_METADATA_SET`);
export const validateOfferForm = new AsyncAction(`${containerId}/VALIDATE_OFFER_FORM`);

const offerDetailsActions = [
   fetchOffer,
   saveOffer,
   convertToProposal,
   shareByPrinting,
   shareByEmail,
   setPreviousPaymentFrequency,
   setOfferMetadata,
   validateOfferForm,
];

export default createReducer(offerDetailsActions);
