import React from 'react';
import {FormattedMessage} from '../../../../FormattedMessage';
import {shape, string, bool, func} from 'prop-types';

const Disease = ({disease, showRemoveButton, onRemoveClick, disabled}) => {
   const handleButtonClick = () => onRemoveClick(disease.id);
   return (
      <div className="summary-section-item" key={disease.id}>
         <div className="summary-section-item-content">
            <div className="summary-section-item-disease">
               {disease.name ?? <FormattedMessage id={disease.id} />}
            </div>
            {disease.mainCategoryId && !disease.isOtherOnly && (
               <div className="summary-section-item-subcategory">
                  <FormattedMessage id={`categories.${disease.categoryId}`} />
               </div>
            )}
         </div>
         {showRemoveButton && (
            <button
               type="button"
               className="btn-remove"
               onClick={handleButtonClick}
               disabled={disabled}
            >
               <i className="iconfont icon-cross" />
            </button>
         )}
      </div>
   );
};

Disease.propTypes = {
   disease: shape({
      id: string,
      name: string,
      mainCategoryId: string,
      categoryId: string,
      isOtherOnly: bool,
   }),
   showRemoveButton: bool,
   onRemoveClick: func,
   disabled: bool,
};

export default Disease;
