import React from 'react';
import {FormattedMessage} from '../../../../components/FormattedMessage';
import './styles.scss';

const LoadingMessage = () => (
   <div className="loading">
      <FormattedMessage id="table.loadingText" />
   </div>
);

export default LoadingMessage;
