import { func, shape, string } from 'prop-types';
import { Field } from 'redux-form';

import { SERVER_ERROR_TYPES } from '../../../constants';
import { useServerError } from '../../ServerError';

export const resolveErrorObject = (error) => {
  switch (error) {
    case SERVER_ERROR_TYPES.VALIDATION_ERRORS: {
      return {
        id: 'validate.requiredForDELA',
        type: SERVER_ERROR_TYPES.VALIDATION_ERRORS,
      };
    }
    case SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS: {
      return {
        id: 'validate.insuredAdultAreEqual',
        isHidden: true,
        type: error,
      };
    }
    case SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN: {
      return {
        id: 'validate.insuredChildrenAreEqual',
        isHidden: true,
        type: error,
      };
    }
    case SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES: {
      return {
        id: 'validate.insuredAdultsAssignedAsBeneficiaries',
        isHidden: true,
        type: error,
      };
    }
    case SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES: {
      return {
        id: 'validate.beneficiaryAreEqual',
        isHidden: true,
        type: error,
      };
    }
    default: {
      return {
        id: 'validate.requiredForDELA',
      };
    }
  }
};

const FieldWithServerError = ({ serverError, onChange, ...restProps }) => {
  const { path, messageProps } = serverError;
  const [error, clearServerError] = useServerError(path);

  const handleChange = (e, newValue, ...args) => {
    if (newValue || newValue === 0) {
      clearServerError();
    }
    if (onChange) {
      onChange(e, newValue, ...args);
    }
  };

  return (
    <Field
      {...restProps}
      onChange={handleChange}
      serverError={error && (messageProps || resolveErrorObject(error))}
    />
  );
};

FieldWithServerError.propTypes = {
  serverError: shape({
    path: string,
    messageProps: shape({
      id: string,
    }),
  }).isRequired,
  onChange: func,
};

export default FieldWithServerError;
