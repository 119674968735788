import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {appConfig} from 'v2/appConfig';
import {msalInstance} from 'v2/common/providers/AuthProvider/AuthProvider';

export const ACCESS_TOKEN = 'accessToken';

export const getToken = async () => {
   if (msalInstance) {
      const accounts = msalInstance.getAllAccounts();
      try {
         const {accessToken} = await msalInstance.acquireTokenSilent({
            account: accounts[0],
            scopes: [appConfig.auth.clientId],
         });
         // For AutoQA
         sessionStorage.setItem(ACCESS_TOKEN, accessToken);
         return accessToken;
      } catch (error) {
         if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect({
               scopes: [appConfig.auth.clientId],
               prompt: 'select_account',
            });
         } else {
            console.error(error);
            throw error;
         }
      }
   }
};
