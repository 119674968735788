import { FormattedMessage } from 'react-intl';

import { ENDPOINTS } from 'shared/constants';
import MenuPanelContent from 'v2/features/app-drawer/components/MenuPanelContent';
import ScrollableStack from 'v2/features/app-drawer/components/ScrollableStack/ScrollableStack';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

import DocumentLoadingButton from './DocumentLoadingButton';

const DOCUMENT_NAMES = {
  doNotCallMeList: 'doNotCallMeList.xlxs',
  FaqCooperativeNL: 'Veelgestelde_vragen_en_antwoorden_coöperatie_DELA_NL.pdf',
  FaqCooperativeFR: 'Questions_et_réponses_fréquentes_coopérative_DELA_FR.pdf',
};

const ToolsContent = () => {
  const hasDENTAgentPermission = usePermissions(Permission.DENTAgent);

  return (
    <ScrollableStack spacing={3}>
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.tools.orderMaterial.title" />}
        text={<FormattedMessage id="appDrawer.tools.orderMaterial.content" />}
        linkText={<FormattedMessage id="appDrawer.tools.orderMaterial.link.text" />}
        hrefId="appDrawer.tools.orderMaterial.link.href"
      />
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.tools.onlineToolkit.title" />}
        text={<FormattedMessage id="appDrawer.tools.onlineToolkit.content" />}
        linkText={<FormattedMessage id="appDrawer.tools.onlineToolkit.link.text" />}
        hrefId="appDrawer.tools.onlineToolkit.link.href"
      />
      {hasDENTAgentPermission && (
        <MenuPanelContent
          title={<FormattedMessage id="appDrawer.tools.doNotCallMeList.title" />}
          text={<FormattedMessage id="appDrawer.tools.doNotCallMeList.content" />}
        >
          <DocumentLoadingButton
            reference={`${ENDPOINTS.doNotCallMeList}/download`}
            label={<FormattedMessage id="appDrawer.tools.doNotCallMeList.link.text" />}
            name={DOCUMENT_NAMES.FaqCooperativeFR}
          />
        </MenuPanelContent>
      )}
      <MenuPanelContent
        title={<FormattedMessage id="appDrawer.tools.cooperate.title" />}
        text={<FormattedMessage id="appDrawer.tools.cooperate.content" />}
      >
        <DocumentLoadingButton
          reference={`${ENDPOINTS.cooperativeMembership}/faq?language=nl`}
          label={<FormattedMessage id="appDrawer.tools.cooperate.link.dutch" />}
          name={DOCUMENT_NAMES.FaqCooperativeNL}
        />
        <DocumentLoadingButton
          reference={`${ENDPOINTS.cooperativeMembership}/faq?language=fr`}
          label={<FormattedMessage id="appDrawer.tools.cooperate.link.french" />}
          name={DOCUMENT_NAMES.FaqCooperativeFR}
        />
      </MenuPanelContent>
    </ScrollableStack>
  );
};

export default ToolsContent;
