import React from 'react';
import classNames from 'classnames';
import { bool, node, oneOfType, string } from 'prop-types';

import Tooltip from '../../../../v2/common/components/Tooltip';

import classes from './styles.module.scss';

const Label = ({ controlId, value, required, hint, className }) => (
  <label htmlFor={controlId} className={classNames(classes.root, className)}>
    <span className={classNames({ [classes.required]: required })}>{value}</span>
    {hint && <Tooltip>{hint}</Tooltip>}
  </label>
);

Label.propTypes = {
  controlId: string,
  value: node,
  required: bool,
  hint: oneOfType([node, string]),
  className: string,
};

export default Label;
