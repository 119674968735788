import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import AccessDenied from 'shared/components/ErrorBoundary/AccessDenied';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

type PermissionsRouteProps = Omit<RouteProps, 'render' | 'component'> & {
  permissions: Permission[];
};

const PermissionRoute = ({ permissions, children, ...routeProps }: PermissionsRouteProps) => {
  const hasPermission = usePermissions(permissions, 'some');

  return <Route {...routeProps}>{hasPermission ? children : <AccessDenied />}</Route>;
};

export default PermissionRoute;
