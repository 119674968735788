import React from 'react';
import {FormattedMessage} from '../../components/FormattedMessage';
import {FeatureToggling} from '../../constants/FeatureToggling';
import styles from './styles.module.scss';

const TestEnvNotification = () => {
   if (!FeatureToggling.testEnvNotification) {
      return null;
   }
   return (
      <div className={styles.TestEnvNotification}>
         <FormattedMessage id="testEnvNotification.message" />
      </div>
   );
};

export default TestEnvNotification;
