import React from 'react';
import {connect} from 'react-redux';
import {number, string, func, bool} from 'prop-types';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import {STEPS} from '../../../../shared/constants';
import {ConvertButton, NavigationPaneItem} from '../';
import {
   selectProposalId,
   selectIsConvertToProposalButtonDisabled,
   selectProposalStatus,
} from '../../selectors';
import {handleConvertToProposalButtonClickAction} from '../../../FormWrapper/reducer';
import {selectIsDisabled} from '../../../FormWrapper/selectors';

export const ProposalNavigationPaneItem = ({
   proposalId,
   status,
   isFormDisabled,
   isConvertToProposalButtonDisabled,
   handleConvertToProposalAction,
}) => {
   const disabled = isFormDisabled || isConvertToProposalButtonDisabled;

   return !proposalId ? (
      <ConvertButton
         label={<FormattedMessage id="navigationPane.convertToProposal" />}
         disabled={disabled}
         handleClick={handleConvertToProposalAction}
      />
   ) : (
      <NavigationPaneItem
         title={<FormattedMessage id="misc.proposal" />}
         step={STEPS.PROPOSAL}
         status={status}
         root
      />
   );
};

ProposalNavigationPaneItem.propTypes = {
   proposalId: number,
   status: string,
   isFormDisabled: bool,
   isConvertToProposalButtonDisabled: bool,
   handleConvertToProposalAction: func,
};

const mapStateToProps = (state) => {
   return {
      proposalId: selectProposalId(state),
      status: selectProposalStatus(state),
      isFormDisabled: selectIsDisabled(state),
      isConvertToProposalButtonDisabled: selectIsConvertToProposalButtonDisabled(state),
   };
};

export default connect(mapStateToProps, {
   handleConvertToProposalAction: handleConvertToProposalButtonClickAction.request,
})(ProposalNavigationPaneItem);
