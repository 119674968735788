import {ACTION_STATUS} from '../constants';

export class BaseAction {
   _actionToHandlerMap = new Map();

   get actionToHandlerMap() {
      return this._actionToHandlerMap;
   }

   get CLEAR() {
      return `${this.type}_CLEAR`;
   }

   static getInitialState(data) {
      return data;
   }

   constructor(type, data = null) {
      if (!type) {
         throw new Error('Type parameter is mandatory');
      }
      this.type = type;

      this.initialState = this.constructor.getInitialState(data);

      this._actionToHandlerMap.set(this.CLEAR, (draft) => {
         draft[this.type] = this.initialState;
      });
   }

   getReduxAction(type, payload) {
      return {
         type,
         payload,
      };
   }

   clear = (payload = undefined) => this.getReduxAction(this.CLEAR, payload);
}

export class Action extends BaseAction {
   get ACTION() {
      return `${this.type}_ACTION`;
   }

   constructor(type, data = null) {
      super(type, data);

      this._actionToHandlerMap.set(this.ACTION, (draft, {payload}) => {
         draft[this.type] = payload;
      });
   }

   action = (payload) => this.getReduxAction(this.ACTION, payload);
}

export class AsyncAction extends BaseAction {
   get REQUEST() {
      return `${this.type}_REQUEST`;
   }

   get SUCCESS() {
      return `${this.type}_SUCCESS`;
   }

   get FAILURE() {
      return `${this.type}_FAILURE`;
   }

   get UPDATE() {
      return `${this.type}_UPDATE`;
   }

   static getInitialState(data) {
      return {
         status: ACTION_STATUS.INITIAL,
         data,
         error: null,
      };
   }

   constructor(type, data = null) {
      super(type, data);

      this._actionToHandlerMap.set(this.REQUEST, this.requestReduxActionHandler);

      this._actionToHandlerMap.set(this.SUCCESS, this.successReduxActionHandler);

      this._actionToHandlerMap.set(this.FAILURE, this.failureReduxActionHandler);

      this._actionToHandlerMap.set(this.UPDATE, this.updateReduxActionHandler);
   }

   _requestReduxActionHandler(draft) {
      const actionState = draft[this.type];
      actionState.status = ACTION_STATUS.IN_PROGRESS;
      actionState.error = null;
   }

   _successReduxActionHandler(draft, {payload}) {
      const actionState = draft[this.type];
      actionState.status = ACTION_STATUS.SUCCESS;
      actionState.data = payload;
   }

   _failureReduxActionHandler(draft, {payload}) {
      const actionState = draft[this.type];
      actionState.status = ACTION_STATUS.FAILURE;
      actionState.error = payload;
   }

   _updateReduxActionHandler(draft, {payload}) {
      const actionState = draft[this.type];
      actionState.data = payload;
   }

   requestReduxActionHandler = (...args) => this._requestReduxActionHandler(...args);

   successReduxActionHandler = (...args) => this._successReduxActionHandler(...args);

   failureReduxActionHandler = (...args) => this._failureReduxActionHandler(...args);

   updateReduxActionHandler = (...args) => this._updateReduxActionHandler(...args);

   request = (payload) => this.getReduxAction(this.REQUEST, payload);

   success = (payload) => this.getReduxAction(this.SUCCESS, payload);

   failure = (payload) => this.getReduxAction(this.FAILURE, payload);

   update = (payload) => this.getReduxAction(this.UPDATE, payload);
}
