import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {default as MuiDialog, DialogProps as MuiDialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import {FormattedMessage} from 'react-intl';

type DialogProps = {
   open: MuiDialogProps['open'];
   title?: React.ReactNode;
   confirmText?: React.ReactNode;
   cancelText?: React.ReactNode;
   children: React.ReactNode;
   onConfirm: () => void;
   onCancel?: () => void;
   onClose?: () => void;
};

const Dialog = ({
   open,
   title,
   confirmText,
   cancelText,
   children,
   onConfirm,
   onCancel,
   onClose,
}: DialogProps) => {
   return (
      <MuiDialog open={open} onClose={onClose}>
         <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
            <DialogTitle sx={{paddingX: 1, paddingY: 0}}>{title}</DialogTitle>
            {onClose && (
               <IconButton sx={{padding: 0}} onClick={onClose}>
                  <ClearRoundedIcon />
               </IconButton>
            )}
         </Box>
         <DialogContent sx={{paddingY: 0}}>
            <DialogContentText>{children}</DialogContentText>
         </DialogContent>
         <DialogActions sx={{padding: 2}}>
            {onCancel && (
               <Button variant="outlined" onClick={onCancel}>
                  {cancelText ?? <FormattedMessage id="dialogs.no" />}
               </Button>
            )}
            <Button variant="contained" color="primary" onClick={onConfirm}>
               {confirmText ?? <FormattedMessage id="dialogs.yes" />}
            </Button>
         </DialogActions>
      </MuiDialog>
   );
};

export default Dialog;
