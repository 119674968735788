import {Commission, CommissionType} from 'v2/common/api/queries/commissions';

export const RECEIPT_ID = 'e6ddb0f2-6dca-4463-933f-9ff219f7e71b-1623744392220';
export const STATEMENT_ID = '704943e5-4eec-4a10-9490-3bb1d6950fa0-1623746400802';

export const createCommission = (type: CommissionType, year: number, month: number) => {
   const changedDate = `${year}-${month}-01T00:00:00`;
   return {
      type,
      name: `${type}.pdf`,
      title: `${type} : ${year}/${month}`,
      documentId: type === CommissionType.Receipt ? RECEIPT_ID : STATEMENT_ID,
      uploadDate: changedDate,
      createDate: changedDate,
      year,
      month,
   };
};

export const commissionsData: Commission[] = [
   {
      type: CommissionType.Statement,
      name: 'CommissionBordereau_FR.PDF',
      title: 'Bordereau : 2021/01',
      documentId: STATEMENT_ID,
      uploadDate: '2021-03-15T10:06:31',
      createDate: '2021-03-15T10:06:31',
      year: 2021,
      month: 1,
   },
   {
      type: CommissionType.Receipt,
      name: 'CommissionCurrentAccount_FR.PDF',
      title: 'Compte courant : 2021/04',
      documentId: RECEIPT_ID,
      uploadDate: '2021-06-15T10:40:00',
      createDate: '2021-06-15T10:40:00',
      year: 2021,
      month: 4,
   },
   createCommission(CommissionType.Statement, 2020, 11),
   createCommission(CommissionType.Receipt, 2019, 10),
   createCommission(CommissionType.Statement, 2020, 12),
];
