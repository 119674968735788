import { GENDER } from 'shared/constants';
import { PolicyPolicyHolder } from 'v2/common/api/queries/policyDetails/policyHolder';

export const createPolicyPolicyHolder = (data?: Partial<PolicyPolicyHolder>) => ({
  personalInformation: {
    firstName: 'John',
    surname: 'Doe',
    birthDate: '15/02/1985',
    gender: GENDER.MALE,
  },
  residentialAddress: {
    stateProvince: null,
    municipality: 'Liège',
    postalCode: '4000',
    streetName: "Rue de l'Abattoir",
    boxNumber: null,
    houseNumber: '4',
  },
  contactInformation: {
    contactLanguage: 'FRENCH',
    landlineNumber: null,
    mobileNumber: null,
    email: 'john.doe@gmail.com',
    enabledEmailAddressForDocumentReceiving: null,
    enabledEmailAddressForMarketing: null,
  },
  financialInformation: {
    paymentFrequency: 'MONTHLY',
    paymentDay: 5,
    paymentMethod: 'SEPA_MANDATE',
    iban: 'BE19000000001212',
    bic: 'BPOTBEB1',
    careClause: 'NO',
  },
  ...data,
});
