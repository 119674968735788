import React from 'react';
import classNames from 'classnames';
import {string, object, bool} from 'prop-types';
import classes from './styles.module.scss';

const RadioButton = ({id, label, name, value, input, disabled, checked, hasError}) => {
   return (
      <div className={classes.root}>
         <input
            id={id}
            name={name}
            className={classNames(classes.option, {[classes.hasError]: hasError})}
            {...input}
            disabled={disabled}
            value={value}
            checked={checked}
         />
         <label htmlFor={id} className={classes.label}>
            <span className={classes.labelText}>{label}</span>
         </label>
      </div>
   );
};

RadioButton.propTypes = {
   id: string,
   label: string,
   name: string,
   value: string,
   input: object,
   disabled: bool,
   checked: bool,
   hasError: bool,
};

export default RadioButton;
