import {Theme, useMediaQuery} from '@mui/material';
import Button, {ButtonProps} from '@mui/material/Button';
import {delaBlue} from 'common/styles/colors';
import React from 'react';
import {NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps} from 'react-router-dom';

type NavLinkProps = ButtonProps &
   RouterNavLinkProps & {
      icon?: React.ReactElement;
   };

const activeLinkClassName = 'active';

const NavLink = ({icon, children, ...restProps}: NavLinkProps) => {
   const isHeaderMinWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down(1024));
   return (
      <Button
         sx={{
            paddingX: 1,
            minWidth: '40px',
            [`&.${activeLinkClassName}`]: {
               backgroundColor: delaBlue[80],
            },
         }}
         component={RouterNavLink}
         {...restProps}
         variant="contained"
         activeClassName={activeLinkClassName}
      >
         {isHeaderMinWidth && icon ? icon : children}
      </Button>
   );
};

export default NavLink;
