import React from 'react';
import {bool, func, node} from 'prop-types';
import classNames from 'classnames';
import ActionButton from '../../../../shared/components/ActionButton';

const ConvertButton = ({label, disabled, handleClick}) => {
   return (
      <div className={classNames('nav-item root', {disabled})}>
         <i className="iconfont icon-circle-plus" />
         <ActionButton
            className="nav-item-button convert-button"
            disabled={disabled}
            onClick={handleClick}
         >
            {label}
         </ActionButton>
      </div>
   );
};

ConvertButton.propTypes = {
   label: node,
   disabled: bool,
   handleClick: func,
};

export default ConvertButton;
