import React from 'react';
import Browser from 'bowser';
import {LOCALES, LOCALES_TO_BROWSER_LOCALE_MAP} from '../../shared/constants';
import errorIcon from '../../assets/images/unsupport.svg';
import messages from '../../translations/messages.json';
import './styles.scss';
import {DelaLogo} from '../../v2/features/app-header/components';

const SUPPORTED_BROWSER_LIST = ['chrome', 'microsoft edge', 'safari'];
export const resolveBrowserLanguage = () => {
   const language = navigator.language || navigator.userLanguage;
   let locale = LOCALES.NL;
   for (const key in LOCALES_TO_BROWSER_LOCALE_MAP) {
      const regexp = new RegExp(LOCALES_TO_BROWSER_LOCALE_MAP[key], 'i');
      if (regexp.test(language)) {
         locale = key;
      }
   }

   return locale;
};

export const resolveSupportedBrowsersLinks = (language) => {
   const formattedSafariLocale = language.replace('-', '_');
   const formattedChromeLocale = language.split('-')[0];
   return {
      edge: `https://www.microsoft.com/${language}/edge`,
      chrome: `https://www.google.com/intl/${formattedChromeLocale}/chrome`,
      safari: `https://support.apple.com/${formattedSafariLocale}/downloads/safari`,
   };
};

const isBrowserSupported = (userAgent) => {
   const browser = Browser.getParser(userAgent);
   const browserName = browser.getBrowserName(true);
   // Do not allow EDGE (legacy)
   if (browserName === 'microsoft edge' && browser.getEngine().name !== 'Blink') {
      return false;
   }

   return SUPPORTED_BROWSER_LIST.indexOf(browserName) > -1;
};

const UnsupportedBrowser = ({children}) => {
   const isSupported = React.useMemo(() => isBrowserSupported(navigator.userAgent), []);
   const language = React.useMemo(() => resolveBrowserLanguage(), []);

   if (isSupported) {
      return children;
   }

   const {chrome, edge, safari} = resolveSupportedBrowsersLinks(language);

   return (
      <>
         <header className="unsupported-header">
            <DelaLogo />
         </header>
         <div className="unsupported-browser">
            <img className="unsupported-browser-img" src={errorIcon} alt="unsupported" />
            <h2 className="unsupported-browser-title">
               {messages[language].unsupportedBrowser.warn}
            </h2>
            <p className="unsupported-browser-info">
               {messages[language].unsupportedBrowser.apologies}
            </p>
            <p>{messages[language].unsupportedBrowser.recommend}</p>
            <p>
               <a rel="noopener noreferrer" target="_blank" href={edge}>
                  Edge
               </a>
               <span>, </span>
               <a rel="noopener noreferrer" target="_blank" href={chrome}>
                  Google Chrome
               </a>
               <span> </span>
               <span>{messages[language].unsupportedBrowser.or}</span>
               <span> </span>
               <a rel="noopener noreferrer" target="_blank" href={safari}>
                  Safari
               </a>
               <span>, </span>
            </p>
            <p>{messages[language].unsupportedBrowser.open}.</p>
         </div>
      </>
   );
};

export default UnsupportedBrowser;
