import React from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {PAYMENT_FREQUENCY} from '../../shared/constants';
import {formatCurrency} from '../../shared/services/utils';
import {InsuredAdultInfo, PremiumBoxItem} from 'containers/PremiumBox/components';
import {
   selectCalculationData,
   selectCalculatingStatus,
   selectFetchingStatus,
   selectShowCareClause,
} from './selectors';
import LegalInfoLink from 'shared/components/LegalInfoLink';

export const LOADING_MESSAGE = '...';

const getAmount = (firstInsureAdultAmount: number, secondInsuredAdultAmount: number) => {
   return formatCurrency(firstInsureAdultAmount + secondInsuredAdultAmount);
};

export const PremiumBox = () => {
   const calculationData = useSelector(selectCalculationData);
   const showCareClause = useSelector(selectShowCareClause);
   const isFetching = useSelector(selectFetchingStatus);
   const isCalculating = useSelector(selectCalculatingStatus);

   if (!calculationData) {
      return null;
   }

   const {firstInsuredAdult, secondInsuredAdult} = calculationData;

   const inProgress = isFetching || isCalculating;

   const isIndexation = calculationData.paymentFrequency !== PAYMENT_FREQUENCY.ONE_TIME_ONLY;

   const indexation = <FormattedMessage id={`references.yesNo.${isIndexation ? 'YES' : 'NO'}`} />;

   const careClause = (
      <FormattedMessage id={`references.yesNo.${calculationData.careClause ? 'YES' : 'NO'}`} />
   );

   const acquisitionCosts = getAmount(
      firstInsuredAdult.acquisitionCosts.amount,
      secondInsuredAdult?.acquisitionCosts?.amount ?? 0
   );

   const administrativeCosts = getAmount(
      firstInsuredAdult.administrativeCosts.amount,
      secondInsuredAdult?.administrativeCosts?.amount ?? 0
   );

   const tax = getAmount(firstInsuredAdult.tax.amount, secondInsuredAdult?.tax?.amount ?? 0);

   const premium = getAmount(
      firstInsuredAdult.premium.amount,
      secondInsuredAdult?.premium?.amount ?? 0
   );

   return (
      <Box
         className="premium-calculation-result"
         sx={{width: 220, letterSpacing: 0.6, fontSize: 12}}
      >
         <Accordion
            defaultExpanded={true}
            sx={{
               borderRadius: 0,
               '&:first-of-type': {
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
               },
            }}
            disableGutters
         >
            <AccordionSummary>
               <Typography variant="body2" fontWeight="bold" sx={{textTransform: 'uppercase'}}>
                  <FormattedMessage id="premiumBox.label" />
               </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
               <Stack
                  spacing={1}
                  sx={{
                     padding: 2,
                     paddingTop: 0,
                  }}
               >
                  <InsuredAdultInfo
                     index={1}
                     insuredAdult={firstInsuredAdult}
                     inProgress={inProgress}
                  />
                  <InsuredAdultInfo
                     index={2}
                     insuredAdult={secondInsuredAdult}
                     inProgress={inProgress}
                  />
                  <Box data-ut-id="paymentFrequency" display="flex" flexDirection="column">
                     <Typography variant="inherit" paddingBottom="5px">
                        <FormattedMessage id="fields.paymentFrequency.label" />
                     </Typography>
                     <Typography variant="inherit" fontWeight="bold" flexShrink={0}>
                        {inProgress ? (
                           LOADING_MESSAGE
                        ) : (
                           <FormattedMessage
                              id={`references.paymentFrequencies.${calculationData.paymentFrequency}`}
                           />
                        )}
                     </Typography>
                  </Box>
                  <PremiumBoxItem
                     data-ut-id="indexation"
                     label={<FormattedMessage id="premiumBox.indexation" />}
                     value={indexation}
                     inProgress={inProgress}
                  />
                  {showCareClause && (
                     <PremiumBoxItem
                        data-ut-id="careClause"
                        label={<FormattedMessage id="premiumBox.careClause" />}
                        value={careClause}
                        inProgress={inProgress}
                     />
                  )}
               </Stack>
               <Stack
                  spacing={1}
                  sx={{
                     backgroundColor: 'primary.main',
                     color: 'common.white',
                     padding: 2,
                     paddingBottom: 0,
                  }}
               >
                  <PremiumBoxItem
                     label={<FormattedMessage id="premiumBox.acquisitionCosts" />}
                     value={
                        <Typography component="span" variant="inherit" fontWeight="normal">
                           {acquisitionCosts}
                        </Typography>
                     }
                     inProgress={inProgress}
                  />
                  <PremiumBoxItem
                     label={<FormattedMessage id="premiumBox.administrativeCosts" />}
                     value={
                        <Typography component="span" variant="inherit" fontWeight="normal">
                           {administrativeCosts}
                        </Typography>
                     }
                     inProgress={inProgress}
                  />
                  <PremiumBoxItem
                     label={<FormattedMessage id="premiumBox.tax" />}
                     value={
                        <Typography component="span" variant="inherit" fontWeight="normal">
                           {tax}
                        </Typography>
                     }
                     inProgress={inProgress}
                  />
                  {!inProgress && <LegalInfoLink />}
               </Stack>
            </AccordionDetails>
         </Accordion>
         <Box
            sx={{
               backgroundColor: 'primary.main',
               color: 'common.white',
               fontWeight: 'bold',
               fontSize: 14,
               padding: 2,
               borderRadius: '0 0 8px 8px',
            }}
         >
            <PremiumBoxItem
               label={<FormattedMessage id="premiumBox.grandTotal" values={{br: <br />}} />}
               value={premium}
               loadingMessage={<CircularProgress color="inherit" size={22} />}
               inProgress={inProgress}
            />
         </Box>
      </Box>
   );
};

export default PremiumBox;
