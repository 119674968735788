import React from 'react';
import {useDispatch} from 'react-redux';
import {blurBackdrop, closeDrawer, openDrawerPanel} from 'v2/features/app-drawer/appDrawerSlice';
import {AppDrawerPanel} from 'v2/features/app-drawer/types';

export function useOpenDrawerPanel() {
   const dispatch = useDispatch();
   return React.useCallback((panel: AppDrawerPanel) => dispatch(openDrawerPanel(panel)), [
      dispatch,
   ]);
}

export function useCloseAppDrawer() {
   const dispatch = useDispatch();
   return React.useCallback(() => dispatch(closeDrawer()), [dispatch]);
}

export function useDrawerBlurredBackdrop() {
   const dispatch = useDispatch();
   React.useEffect(() => {
      dispatch(blurBackdrop(true));
      return () => {
         dispatch(blurBackdrop(false));
      };
   }, [dispatch]);
}
