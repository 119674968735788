import React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from 'v2/common/components/Dialog';

export type RemoveInsuredProps = {
  show: boolean;
  children: React.ReactElement;
  onApply: () => void;
  onReject: () => void;
};

const RemoveParty = ({ show, children, onApply, onReject }: RemoveInsuredProps) => (
  <Dialog
    open={show}
    confirmText={<FormattedMessage id="dialogs.delete" />}
    cancelText={<FormattedMessage id="dialogs.cancel" />}
    onConfirm={onApply}
    onCancel={onReject}
    onClose={onReject}
  >
    {children}
  </Dialog>
);

export default RemoveParty;
