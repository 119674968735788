const SAVED_CALCULATE_PREMIUM_KEY = 'savedCalculatePremium';

export class SavedCalculatePremiumServiceClass {
   _get = () => sessionStorage.getItem(SAVED_CALCULATE_PREMIUM_KEY);

   _set = (data) => sessionStorage.setItem(SAVED_CALCULATE_PREMIUM_KEY, data);

   clear = () => sessionStorage.removeItem(SAVED_CALCULATE_PREMIUM_KEY);

   getData = () => {
      try {
         const data = this._get();
         return JSON.parse(data);
      } catch (e) {
         console.log(e);
         this.clear();
      }
   };

   setData = (data) => {
      try {
         this._set(JSON.stringify(data));
      } catch (e) {
         console.log(e);
      }
   };

   getSavedDataType = () => {
      try {
         const data = this.getData();
         const {type} = data;

         return type;
      } catch (e) {
         console.log(e);
         return null;
      }
   };
}

export const SavedCalculatePremiumService = new SavedCalculatePremiumServiceClass();
