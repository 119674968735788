import React from 'react';
import {bool, element, number, string, object, shape, oneOfType} from 'prop-types';
import classNames from 'classnames';
import classes from './styles.module.scss';
import Label from '../Label';
import FieldMeta from '../FieldMeta';
import FieldError from '../FieldError';
import {resolveError} from '../formUtils';
import FieldInfo from '../FieldInfo';

const TextArea = ({
   input,
   meta,
   name,
   value,
   label,
   hint,
   placeholder,
   required = false,
   disabled,
   maxLength,
   serverError,
   hasServerError,
   info,
   hideMeta = false,
}) => {
   const controlId = input?.name || name;
   const _value = input?.value ?? value;
   const error = resolveError(_value, meta, hasServerError, serverError);
   const isFieldInfoShown = !error && info;

   const _onBlur = () => {
      const value = _value || null;
      input?.onBlur && input.onBlur(value);
   };

   return (
      <div className={classes.root}>
         {label && <Label controlId={controlId} value={label} hint={hint} required={required} />}
         <div className={classes.textAreaWrapper}>
            <textarea
               id={controlId}
               name={name}
               value={value}
               className={classNames(classes.textArea, {[classes.hasError]: error})}
               placeholder={placeholder}
               disabled={disabled}
               {...input}
               onBlur={_onBlur}
               maxLength={maxLength}
            />
            {!hideMeta && (
               <FieldMeta>
                  {error && <FieldError message={error} />}
                  {isFieldInfoShown && <FieldInfo message={info} />}
               </FieldMeta>
            )}
         </div>
      </div>
   );
};

TextArea.propTypes = {
   input: object,
   meta: shape({
      touched: bool,
      error: shape({
         id: string.isRequired,
         values: object,
      }),
   }),
   name: string,
   value: string,
   label: string,
   hint: oneOfType([element, string]),
   placeholder: string,
   required: bool,
   disabled: bool,
   maxLength: number,
   hasServerError: bool,
   info: oneOfType([element, string]),
   hideMeta: bool,
};

export default TextArea;
