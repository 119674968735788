import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';
import {useIntl} from 'react-intl';

export const PortfolioIcon = (props: SvgIconProps) => {
   const intl = useIntl();
   const title = intl.formatMessage({id: 'icons.portfolio'});
   return (
      <SvgIcon {...props} viewBox="0 0 22 21" titleAccess={title}>
         <g fill="none" fillRule="evenodd">
            <g transform="translate(4.1e-5 -7.5e-5)" fill="#fff">
               <path d="m8.2983 2.7853c-0.19943-1.1515-0.011325-1.3857 1.0807-1.3852 1.0382 3.9874e-4 2.0765 0.0013291 3.1146-2.7613e-4 1.115-0.0015846 1.2727 0.18064 1.137 1.3855h-5.3323zm-5.4784 0.032563c-1.4399 0.014487-2.6284 0.92134-2.7582 2.2955-0.12159 1.2878-0.026036 2.5971-0.026036 3.9352h21.86c0-1.1404 0.001432-2.2421-3.905e-4 -3.3437-0.0027337-1.7037-1.1273-2.8656-2.8101-2.8876-1.084-0.014222-2.1682-0.0041202-3.2524-0.0094367-0.24903-0.0011962-0.49806-0.021133-0.78992-0.034291 0-0.49137 0.012497-0.90911-0.0026035-1.3258-0.030722-0.84518-0.50457-1.4058-1.3233-1.4214-1.8423-0.035089-3.6861-0.035753-5.5284 5.3165e-4 -0.82038 0.016215-1.2911 0.57644-1.3217 1.4228-0.01497 0.41654-0.0026036 0.83415-0.0026036 1.3651-1.4058 0-2.725-0.010367-4.0441 0.0029241zm-2.7755 7.7114h8.1356c0.22156 2.3616 1.1223 3.4831 2.7728 3.4837 1.6503 5.316e-4 2.5512-1.1204 2.7741-3.4833h8.1733c0 1.6173 6.509e-4 3.2049-1.302e-4 4.7925-5.207e-4 0.93011 0 1.8602-0.0054674 2.7903-0.0097633 1.6686-1.1379 2.8531-2.775 2.8583-4.2729 0.01369-8.546 0.0055822-12.819 0.0058481-1.1712 0-2.3427 0.010899-3.5138-0.0042532-1.6384-0.021266-2.7697-1.1901-2.774-2.8597-0.0061183-2.3253-0.0023432-4.6506-2.6036e-4 -6.9759 2.6036e-4 -0.19352 0.019657-0.38704 0.031763-0.60754z" />
            </g>
         </g>
      </SvgIcon>
   );
};
