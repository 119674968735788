import get from 'lodash/get';
import {SERVER_ERROR_TYPES} from '../../constants';

export function isEmptyValue(value) {
   return (
      value === undefined ||
      value === null ||
      value === false ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
   );
}

export function resolveError(value, meta, hasServerError, serverError) {
   if (meta?.touched && meta?.error) {
      return {...meta.error, id: `validate.${meta.error.id}`};
   }
   if (serverError && isEmptyValue(value)) {
      return serverError;
   }
   if (hasServerError && isEmptyValue(value)) {
      if (typeof hasServerError === 'object') {
         return {id: hasServerError.messageId};
      }
      return {id: 'validate.requiredForDELA'};
   }

   const errorType = get(serverError, 'type');

   if (
      errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS ||
      errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN ||
      errorType === SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES ||
      errorType === SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES ||
      errorType === SERVER_ERROR_TYPES.INVALID_RESIDENTIAL_ADDRESS
   ) {
      return serverError;
   }
}
