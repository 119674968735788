import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAgent } from 'v2/common/api/queries/agent';
import EmailLink from 'v2/common/components/EmailLink';
import ScrollableStack from 'v2/features/app-drawer/components/ScrollableStack/ScrollableStack';

type ContactProps = {
  title: React.ReactNode;
  additionalInfo?: React.ReactNode;
  children: React.ReactNode;
  direction?: StackProps['direction'];
  spacing?: StackProps['spacing'];
};

const Contact = ({ title, additionalInfo, children, direction, spacing = 2 }: ContactProps) => (
  <Box>
    <Typography fontWeight="bold" marginBottom={2} letterSpacing={0.7}>
      {title}
    </Typography>
    {additionalInfo}
    <Stack direction={direction} spacing={spacing}>
      {children}
    </Stack>
  </Box>
);

type ContactInfoProps = {
  title: React.ReactNode;
  additionalInfo?: React.ReactNode;
  phoneNumber?: React.ReactNode;
  email?: string;
};

const ContactInfo = ({ title, additionalInfo, phoneNumber, email }: ContactInfoProps) => (
  <Box>
    <Typography variant="body2" fontWeight="bold" gutterBottom>
      {title}
    </Typography>
    {additionalInfo}
    {phoneNumber && <Typography variant="body2">{phoneNumber}</Typography>}
    {email && <EmailLink variant="body2" email={email} />}
  </Box>
);

export const ContactsContent = () => {
  const intl = useIntl();
  const { data } = useAgent();
  const { accountManager: { mobileNumber, email, firstName = '', surname = '' } = {} } =
    data!.intermed;
  const managerFullName = [firstName, surname].join(' ').trim();
  const website = intl.formatMessage({ id: 'appDrawer.contacts.generalInfo.website' });

  return (
    <ScrollableStack spacing={4}>
      <Contact
        title={<FormattedMessage id="appDrawer.contacts.generalInfo.title" />}
        additionalInfo={
          <>
            <Link
              gutterBottom
              color="inherit"
              href={`https://${website}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="always"
            >
              {website}
            </Link>
            <Typography variant="body2" gutterBottom>
              <FormattedMessage id="appDrawer.contacts.generalInfo.address" />
            </Typography>
            <Typography variant="body2" gutterBottom marginBottom={2}>
              <FormattedMessage id="appDrawer.contacts.generalInfo.city" />
            </Typography>
          </>
        }
        direction="row"
        spacing={5}
      >
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.generalInfo.forAgents.title" />}
          phoneNumber={
            <FormattedMessage id="appDrawer.contacts.generalInfo.forAgents.phoneNumber" />
          }
          email={intl.formatMessage({ id: 'appDrawer.contacts.generalInfo.forAgents.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.generalInfo.forClients.title" />}
          phoneNumber={
            <FormattedMessage id="appDrawer.contacts.generalInfo.forClients.phoneNumber" />
          }
          email={intl.formatMessage({
            id: 'appDrawer.contacts.generalInfo.forClients.email',
          })}
        />
      </Contact>
      <Contact title={<FormattedMessage id="appDrawer.contacts.delaConnect.title" />}>
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.delaConnect.0.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.delaConnect.0.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.delaConnect.0.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.delaConnect.1.title" />}
          additionalInfo={
            <Typography variant="body2" gutterBottom>
              {managerFullName}
            </Typography>
          }
          phoneNumber={mobileNumber}
          email={email}
        />
      </Contact>
      <Contact title={<FormattedMessage id="appDrawer.contacts.haveQuestions.title" />}>
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.0.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.0.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.0.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.1.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.1.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.1.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.2.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.2.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.2.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.3.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.3.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.3.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.4.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.4.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.4.email' })}
        />
        <ContactInfo
          title={<FormattedMessage id="appDrawer.contacts.haveQuestions.5.title" />}
          phoneNumber={<FormattedMessage id="appDrawer.contacts.haveQuestions.5.phoneNumber" />}
          email={intl.formatMessage({ id: 'appDrawer.contacts.haveQuestions.5.email' })}
        />
      </Contact>
    </ScrollableStack>
  );
};
