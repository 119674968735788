import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { routes } from 'routes';

import { FeatureToggling } from 'shared/constants/FeatureToggling';
import IncreaseInPremium from 'v2/features/dialogs/IncreaseInPremium';

import ErrorBoundary from '../../../../shared/components/ErrorBoundary';
import { MaintenanceNotification } from '../../../../shared/components/MaintenanceNotification';
import TestEnvNotification from '../../../../shared/components/TestEnvNotification';
import AppDrawer from '../../../../v2/features/app-drawer';
import AppHeader from '../../../../v2/features/app-header';
import ToggledPopover from '../../../PopoverContainer/components/ToggledPopover';
import { INCREASE_IN_PREMIUM_ID } from '../../reducer';
import { selectShowIncreaseInPremiumPrompt } from '../../selectors';

export const MainContent = () => {
  const showIncreaseInPremiumPrompt: unknown = useSelector(selectShowIncreaseInPremiumPrompt);

  return (
    <>
      <AppHeader />
      <AppDrawer />
      <ErrorBoundary>
        <main
          className={classNames({
            shifted: FeatureToggling.testEnvNotification,
          })}
        >
          {/* ENABLE THE MAINTENANCE NOTIFICATION WHEN THE MAINTENANCE DATES ARE CLEAR */}
           <MaintenanceNotification />
          {routes}
          <ToggledPopover popoverId={INCREASE_IN_PREMIUM_ID}>
            <IncreaseInPremium show={!!showIncreaseInPremiumPrompt} />
          </ToggledPopover>
        </main>
      </ErrorBoundary>
      <TestEnvNotification />
    </>
  );
};

export default MainContent;
