export const createPopoverServiceClass = ({localStorage}) =>
   class PopoverServiceClass {
      static _localStorageKey = 'guidePopovers';

      static getPopoverInstance(show = false, step = 1) {
         return {
            show,
            step,
         };
      }

      _popovers = {};

      constructor() {
         this._initializeFromLocalStorage();
      }

      getPopover(popoverId) {
         return this._popovers[popoverId];
      }

      setPopover(popoverId, popover) {
         this._popovers[popoverId] = popover;
         this._flushToLocalStorage();
      }

      _initializeFromLocalStorage() {
         const popoverData = localStorage.getItem(PopoverServiceClass._localStorageKey);
         if (popoverData !== null) {
            this._popovers = JSON.parse(popoverData);
         }
      }

      _flushToLocalStorage() {
         localStorage.setItem(PopoverServiceClass._localStorageKey, JSON.stringify(this._popovers));
      }
   };

export const PopoverServiceClass = createPopoverServiceClass({localStorage});

export const popoverService = new PopoverServiceClass();
