import { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import uploadDefault from '../../../../assets/images/ID-card.svg';
import uploadRejected from '../../../../assets/images/ID-card-error.svg';
import uploadFinished from '../../../../assets/images/ID-card-uploaded.svg';
import { idCardDocumentShape } from '../../../constants/propTypes';
import { FormattedMessage } from '../../FormattedMessage';
import OverflowTooltip from '../../OverflowTooltip';

import './styles.scss';

export const FIRST_ZONE = 'firstZone';
export const SECOND_ZONE = 'secondZone';

export class DragnDrop extends PureComponent {
  state = {
    [FIRST_ZONE]: { file: get(this.props.input, '[0]', null), errorId: null },
    [SECOND_ZONE]: { file: get(this.props.input, '[1]', null), errorId: null },
  };

  isFirstFileExists = () => {
    const { input } = this.props;
    const { firstZone } = this.state;

    return !!(firstZone.file || input[0]);
  };

  isMaxLengthExceeded = (files) => {
    if (files.length > 2) {
      return true;
    }

    return !!(files.length > 1 && this.isFirstFileExists());
  };

  isTypeInvalid = (files) => {
    const { accept } = this.props;

    return files.some((file) => {
      const fileExtension = file.name.split('.').pop();

      return !accept.includes(fileExtension.toLowerCase());
    });
  };

  handleDropRejected = (files, index) => {
    let postfix;

    if (this.isMaxLengthExceeded(files)) {
      postfix = 'maxFilesUploadError';
    } else if (files.some((el) => el.size > this.props.maxSize * 1024 * 1024)) {
      postfix = 'maxSizeUploadError';
    } else {
      postfix = 'requiredFormatUploadError';
    }

    this.setState({ [index]: { file: null, errorId: `documents.${postfix}` } });
  };

  handleDropAccepted = (files, index) => {
    files.forEach((file, idx) => {
      this.setState({
        [idx === 0 ? index : SECOND_ZONE]: { file, errorId: null },
      });
    });
    this.props.onDrop(files, files.length === 1 && index, this.reset);
  };

  handleDrop = (acceptedFiles, rejectedFiles, index) => {
    const files = [...acceptedFiles, ...rejectedFiles];
    const isRejected =
      rejectedFiles.length > 0 ||
      this.isMaxLengthExceeded(files) ||
      this.isTypeInvalid(acceptedFiles);
    if (isRejected) {
      this.handleDropRejected(files, index);
    } else {
      this.handleDropAccepted(acceptedFiles, index);
    }
  };

  reset = (index) => {
    if (!index) {
      this.setState({
        [FIRST_ZONE]: { file: get(this.props.input, '[0]', null), errorId: null },
        [SECOND_ZONE]: { file: get(this.props.input, '[1]', null), errorId: null },
      });
    } else {
      const path = index === FIRST_ZONE ? '[0]' : '[1]';
      this.setState({
        [index]: { file: get(this.props.input, path, null), errorId: null },
      });
    }
  };

  renderIconSection(data, uploading, open) {
    const { intl } = this.props;
    const { file, errorId } = data;
    const icon = errorId ? uploadRejected : file ? uploadFinished : uploadDefault;

    return (
      <>
        {uploading ? (
          <i className="iconfont icon-circle-arrows" />
        ) : (
          <img src={icon} alt={intl.formatMessage({ id: 'documents.documentPreview' })} />
        )}
        {!file && !errorId && <p>Pdf, jpg/jpeg, png</p>}
        {file && !uploading && (
          <div className="replace">
            <OverflowTooltip>{file.name}</OverflowTooltip>
            <span onClick={open}>
              <FormattedMessage id="documents.replaceIdCard" />
            </span>
          </div>
        )}
        {errorId && <FormattedMessage id={errorId} values={{ br: <br /> }} />}
      </>
    );
  }

  renderDropZone(index) {
    const { disabled, maxSize } = this.props;
    const { file, errorId } = this.state[index];
    const uploading = index === FIRST_ZONE ? this.props.uploading[0] : this.props.uploading[1];

    return (
      <Dropzone
        className={classNames('dragndrop-input', {
          accepted: !!file,
          rejected: !!errorId,
          uploading,
        })}
        disabled={disabled}
        disableClick={!!file}
        activeClassName="active"
        disabledClassName="disabled"
        maxSize={maxSize * 1024 * 1024}
        onDrop={(acceptedFiles, rejectedFiles) =>
          this.handleDrop(acceptedFiles, rejectedFiles, index)
        }
      >
        {({ open }) => (
          <div className="placeholder-container">
            <h3>
              <FormattedMessage
                id="documents.idCard"
                values={{
                  index: index === FIRST_ZONE ? 1 : 2,
                }}
              />
            </h3>
            <div>
              <FormattedMessage
                id="documents.dragnDropMessage.label"
                values={{
                  browseMessage: (
                    <span>
                      <FormattedMessage id="documents.dragnDropMessage.browse" />
                    </span>
                  ),
                }}
              />
            </div>
            <div className="icon-section">
              {this.renderIconSection(this.state[index], uploading, open)}
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  render() {
    const { info } = this.props;

    return (
      <div className="input-dragndrop-wrapper">
        <div className="dropzone-container">
          {this.renderDropZone(FIRST_ZONE)}
          {this.isFirstFileExists() && this.renderDropZone(SECOND_ZONE)}
        </div>
        <div className="info">
          <i className="iconfont icon-circle-exclamation-filled" />
          {info}
        </div>
      </div>
    );
  }
}

DragnDrop.propTypes = {
  input: idCardDocumentShape,
  info: PropTypes.node,
  disabled: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  uploading: PropTypes.arrayOf(PropTypes.bool),
  onDrop: PropTypes.func,
};

export default injectIntl(DragnDrop);
