import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {hidePopover, initializePopover} from '../../reducer';

export class ToggledPopover extends React.PureComponent {
   static propTypes = {
      children: PropTypes.node.isRequired,
      popoverId: PropTypes.string.isRequired,
      initializePopover: PropTypes.func,
      hidePopover: PropTypes.func,
   };

   componentDidMount() {
      const {initializePopover, popoverId} = this.props;
      initializePopover(popoverId);
   }

   onHide = () => {
      const {hidePopover, popoverId} = this.props;
      hidePopover(popoverId);
   };

   render() {
      const {children, ...rest} = this.props;
      return React.cloneElement(children, {
         ...rest,
         onHide: this.onHide,
      });
   }
}

export default connect(null, {
   initializePopover: initializePopover.action,
   hidePopover: hidePopover.action,
})(ToggledPopover);
