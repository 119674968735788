import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage as IntlFormattedMessage} from 'react-intl';

export const FormattedMessage = (props) => <IntlFormattedMessage {...props} />;

FormattedMessage.propTypes = {
   id: PropTypes.string,
   values: PropTypes.object,
};

export default FormattedMessage;
