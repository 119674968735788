import {formValueSelector, isSubmitting} from 'redux-form';
import {createSelector} from '@reduxjs/toolkit';
import {
   selectAsyncActionData,
   selectIsInProgressStatus,
   selectActionData,
   selectIsFinishedStatus,
} from '../../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../../shared/reducers/selectors';
import {getCurrentState as getFormWrapperState} from '../../FormWrapper/selectors';
import {
   containerId,
   fetchNeedsAndDesires,
   NEEDS_AND_DESIRES_SECOND_VERSION,
   saveNeedsAndDesires,
} from './reducer';
import {setFormSavingAction} from '../../FormWrapper/reducer';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = formValueSelector(containerId);

export const selectData = (state) =>
   selectAsyncActionData(getCurrentState(state), fetchNeedsAndDesires.type);

export const selectFetchedNeedsAndDesires = (state) =>
   selectIsFinishedStatus(getCurrentState(state), fetchNeedsAndDesires.type);

export const selectIsSaving = (state) =>
   selectIsInProgressStatus(getCurrentState(state), saveNeedsAndDesires.type);

export const selectHasFuneralInsuranceFormValue = (state) =>
   selectFormValues(state, 'funeralInsurance.hasFuneralInsurance');

export const selectFuneralInsuranceStatusFormValue = (state) =>
   selectFormValues(state, 'funeralInsurance.status');

export const selectIsTakeoverFormValue = (state) => selectFormValues(state, 'takeover.isTakeover');

export const selectIsReplacementFormValue = (state) =>
   selectFormValues(state, 'replacement.isReplacement');

export const selectTypeFormValue = (state) => selectFormValues(state, 'type');

export const selectIsSavingFormInProgress = (state) =>
   selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsFormFetching = createSelector(
   [selectFetchedNeedsAndDesires, selectIsSavingFormInProgress],
   (isFetched, isSavingFormInProgress) => !isFetched && !isSavingFormInProgress
);

export const selectIsSubmitting = (state) => isSubmitting(containerId)(state);

export const selectIsSavingButtonDisabled = createSelector(
   [selectIsSaving, selectIsSubmitting],
   (isSaving, isSubmitting) => isSaving || isSubmitting
);

const selectNeedsAndDesiresVersion = (state) =>
   selectAsyncActionData(getCurrentState(state), fetchNeedsAndDesires.type, 'version');

export const selectIsNeedsAndDesiresSecondVersion = createSelector(
   [selectNeedsAndDesiresVersion],
   (version) => version === NEEDS_AND_DESIRES_SECOND_VERSION
);
