import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from '../FormattedMessage';
import './styles.scss';

export const Popover = ({
   title,
   step = 1,
   totalSteps = 1,
   children,
   onBack,
   onNext,
   onClose,
   onConfirm,
}) => (
   <div className="app-popover">
      <div className="app-popover-container">
         <div className="app-popover-header">
            <div className="app-popover-title">{title}</div>
            <button type="button" className="btn-remove" onClick={onClose}>
               <i className="iconfont icon-cross" />
            </button>
         </div>
         {totalSteps > 1 && (
            <div className="app-popover-steps">
               <FormattedMessage
                  id="popovers.howItWorksPanel.steps"
                  values={{current: step, amount: totalSteps}}
               />
            </div>
         )}
         <div className="app-popover-content">{children}</div>
      </div>
      <div className="app-popover-actions">
         {step !== 1 && (
            <button onClick={onBack} type="button" className="app-popover-action back">
               <i className="iconfont icon-arrow-left" />
               <span>
                  <FormattedMessage id="formWrapper.back" />
               </span>
            </button>
         )}
         {step !== totalSteps ? (
            <button onClick={onNext} type="button" className="app-popover-action next">
               <span>
                  <FormattedMessage id="formWrapper.next" />
               </span>
               <i className="iconfont icon-arrow-right" />
            </button>
         ) : (
            <button onClick={onConfirm} type="button" className="app-popover-action confirm">
               <FormattedMessage id="popovers.howItWorksPanel.ok" />
            </button>
         )}
      </div>
   </div>
);

Popover.propTypes = {
   title: PropTypes.node,
   step: PropTypes.number,
   totalSteps: PropTypes.number,
   children: PropTypes.node,
   onBack: PropTypes.func,
   onNext: PropTypes.func,
   onClose: PropTypes.func,
   onConfirm: PropTypes.func,
};
