import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Accordion, {AccordionProps} from '@mui/material/Accordion';
import AccordionSummary, {accordionSummaryClasses} from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useCloseAppDrawer} from 'v2/features/app-drawer/hooks';

type DrawerPanelProps = {
   expanded: AccordionProps['expanded'];
   icon: React.ReactNode;
   title: React.ReactNode;
   children: React.ReactNode;
   onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const DrawerPanel = ({expanded, icon, title, children, onChange}: DrawerPanelProps) => {
   const closeAppDrawer = useCloseAppDrawer();
   const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      closeAppDrawer();
   };

   return (
      <Accordion
         sx={{
            borderRadius: 2,
            '&:first-of-type, &:last-of-type': {
               borderRadius: 2,
            },
         }}
         expanded={expanded}
         onChange={onChange}
      >
         <AccordionSummary
            sx={{
               [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
                  color: 'primary.main',
               },
            }}
            expandIcon={
               expanded ? (
                  <IconButton sx={{padding: '2px'}} color="inherit" onClick={handleClose}>
                     <CloseRoundedIcon fontSize="small" />
                  </IconButton>
               ) : undefined
            }
         >
            <Stack direction="row" spacing={1} alignItems="center">
               {icon}
               <Typography variant="body2">{title}</Typography>
            </Stack>
         </AccordionSummary>
         {children}
      </Accordion>
   );
};

export default DrawerPanel;
