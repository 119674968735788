import {useQuery} from 'react-query';
import {toastHandler} from 'shared/services';
import {downloadFile} from 'shared/services/sagaHelpers';
import {useAxios} from 'v2/common/providers/AxiosProvider';

type DownloadDocumentsProps = {
   reference: string;
   name?: string;
};

const splitContentDisposition = (contentDisposition: string) => {
   return /filename\*?=([^']*'')?([^;]*)/.exec(contentDisposition);
};

export function useDownloadDocument({reference, name}: DownloadDocumentsProps) {
   const axios = useAxios();
   return useQuery(
      reference,
      async () => {
         const {data, headers} = await axios.get<Blob>(reference, {
            responseType: 'blob',
         });

         const matches = splitContentDisposition(headers['content-disposition']);

         const fileName = matches != null && matches[2] ? decodeURIComponent(matches[2]) : name;

         return {data, fileName};
      },
      {
         enabled: false,
         refetchOnWindowFocus: false,
         onSuccess: ({data, fileName}) => {
            downloadFile(data, fileName);
            toastHandler.success({
               id: 'toasts.success.documentDownloaded',
               values: {title: fileName},
            });
         },
         onError: () => toastHandler.unexpectedIssue(),
      }
   );
}
