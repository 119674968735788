import { createSelector } from '@reduxjs/toolkit';

import { SIMPLE_ANSWER } from '../constants';

export const getStateByContainerId = (containerId) => (rootState) => rootState[containerId];

export const makeSelectIsYes = (selector) =>
  createSelector(selector, (field) => field === SIMPLE_ANSWER.YES);

export const makeSelectIsNo = (selector) =>
  createSelector(selector, (field) => field === SIMPLE_ANSWER.NO);
