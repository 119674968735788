import React from 'react';
import {FormattedMessage} from 'react-intl';
import Dialog from 'v2/common/components/Dialog';

type InsuredIsPolicyHolderProps = {
   show: boolean;
   onApply: () => void;
   onReject: () => void;
};

const InsuredIsPolicyHolder = ({show, onApply, onReject}: InsuredIsPolicyHolderProps) => {
   return (
      <Dialog open={show} onConfirm={onApply} onCancel={onReject} onClose={onReject}>
         <FormattedMessage id="dialogs.insuredIsPolicyHolder.content" />
      </Dialog>
   );
};

export default InsuredIsPolicyHolder;
