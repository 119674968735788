import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import './styles.scss';

const NavButton = ({to, className, disabled, onClick = () => {}, children}) =>
   to && !disabled ? (
      <Link to={to} className={classNames('nav-button', className, {disabled})} draggable="false">
         {children}
      </Link>
   ) : (
      <button
         type="button"
         className={classNames('nav-button', className, {disabled})}
         draggable="false"
         onClick={onClick}
      >
         {children}
      </button>
   );

NavButton.propTypes = {
   to: PropTypes.string,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   onClick: PropTypes.func,
   children: PropTypes.node,
};

export default NavButton;
