import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import ThemeProvider from 'common/providers/ThemeProvider';
import policyDetailsTheme from 'common/providers/ThemeProvider/themes/policyDetails';
import OfferDetails from 'containers/OfferDetails';
import SalesProcessPage from 'containers/SalesProcessPage';
import NotFound from 'shared/components/ErrorBoundary/NotFound';
import Spinner from 'shared/components/Spinner';
import PermissionRoute from 'v2/features/permissions/PermissionRoute';
import { Permission } from 'v2/features/permissions/types';
import { Homepage } from 'v2/pages/Homepage';

const Portfolio = lazy(() => import('./v2/pages/Portfolio'));
const PolicyUpdates = lazy(() => import('./v2/pages/PolicyUpdates'));
const Commissions = lazy(() => import('./v2/pages/Commissions'));

const ProposalDetails = lazy(() => import('./containers/ProposalDetails'));
const PolicyDetails = lazy(() => import('containers/PolicyDetails'));

export const routes = (
  <Suspense fallback={<Spinner fixed />}>
    <Switch>
      <Route exact path="/">
        <Homepage />
      </Route>
      <PermissionRoute
        permissions={[Permission.OfferList, Permission.ProposalList, Permission.PolicyList]}
        exact
        path="/portfolio"
      >
        <Portfolio />
      </PermissionRoute>
      <PermissionRoute permissions={[Permission.PolicyTemplatesList]} exact path="/policy-updates">
        <PolicyUpdates />
      </PermissionRoute>
      <PermissionRoute permissions={[Permission.CommissionsList]} exact path="/commissions">
        <Commissions />
      </PermissionRoute>
      <PermissionRoute permissions={[Permission.ReadOffer]} exact path="/sales-process/offer/:id">
        <SalesProcessPage>
          <OfferDetails />
        </SalesProcessPage>
      </PermissionRoute>
      <PermissionRoute permissions={[Permission.ReadProposal]} path="/sales-process/proposals/:id">
        <SalesProcessPage>
          <ProposalDetails />
        </SalesProcessPage>
      </PermissionRoute>
      <PermissionRoute permissions={[Permission.ReadPolicy]} path="/sales-process/policies/:id">
        <ThemeProvider theme={policyDetailsTheme}>
          <PolicyDetails />
        </ThemeProvider>
      </PermissionRoute>
      <Route component={NotFound} />
    </Switch>
  </Suspense>
);
