import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import {useTable} from 'react-table';
import {FormattedMessage} from '../FormattedMessage';
import Spinner from '../Spinner';
import styles from './styles.module.scss';

export function Table({columns, data, loading = false, isFiltered = false, NoDataComponent}) {
   const {getTableProps, getTableBodyProps, headerGroups, prepareRow, rows} = useTable({
      columns,
      data,
   });

   return (
      <div className={styles.Wrapper}>
         {loading && (
            <div className={styles.LoaderWrapper}>
               <div className={styles.ActiveLoader}>
                  <Spinner fixed />
               </div>
            </div>
         )}
         <table {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                     {headerGroup.headers.map((column) => (
                        <th
                           {...column.getHeaderProps([
                              {
                                 className: styles[column.className],
                              },
                           ])}
                        >
                           {column.render('Header')}
                        </th>
                     ))}
                  </tr>
               ))}
            </thead>
            <tbody {...getTableBodyProps()}>
               {rows.map((row) => {
                  prepareRow(row);
                  return (
                     <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                           <td
                              {...cell.getCellProps([
                                 {
                                    className: styles[cell.column.className],
                                 },
                              ])}
                           >
                              {cell.render('Cell')}
                           </td>
                        ))}
                     </tr>
                  );
               })}
            </tbody>
         </table>
         {!loading && isEmpty(rows) ? (
            isFiltered ? (
               <div className={styles.NoResults}>
                  <span>
                     <FormattedMessage id="table.search.noResults" />
                  </span>
               </div>
            ) : (
               NoDataComponent
            )
         ) : null}
      </div>
   );
}

Table.propTypes = {
   columns: PropTypes.array,
   data: PropTypes.array,
   loading: PropTypes.bool,
   isFiltered: PropTypes.bool,
   NoDataComponent: PropTypes.node,
};

export default Table;
