import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from '../../components/FormattedMessage';
import {PROCESS_STATUS_CLASSNAME} from '../../constants';
import './styles.scss';

const StatusIndicator = ({status}) => {
   if (!status) {
      return null;
   }

   const iconClassName = get(PROCESS_STATUS_CLASSNAME, status);

   return (
      <div className="status-indicator">
         <i className={classNames('circle', iconClassName && `status-${iconClassName}`)} />
         <FormattedMessage id={`salesProcess.statuses.${status}`} />
      </div>
   );
};

StatusIndicator.propTypes = {
   status: PropTypes.string,
};

export default StatusIndicator;
