import {PolicyBeneficiaries} from '../../common/api/queries/policyDetails/beneficiaries';

export const createBeneficiaries = (): PolicyBeneficiaries => ({
   insuredAdults: [
      {
         insuredAdultType: 'FIRST',
         paymentToFuneralDirector: 'NO',
         beneficiaries: [
            [
               {
                  personalInformation: {
                     firstName: 'John',
                     surname: 'Smith',
                     birthDate: '15/12/2020',
                  },
                  share: 25,
                  clauseType: null,
                  clauseValue: null,
               },
               {
                  personalInformation: {
                     firstName: 'Jane',
                     surname: 'Doe',
                     birthDate: '15/12/2020',
                  },
                  share: 25,
                  clauseType: null,
                  clauseValue: null,
               },
               {
                  personalInformation: {
                     firstName: 'Lola',
                     surname: 'Smith',
                     birthDate: '15/12/2020',
                  },
                  share: 25,
                  clauseType: null,
                  clauseValue: null,
               },
               {
                  personalInformation: {
                     firstName: 'Jake',
                     surname: 'Doe',
                     birthDate: '15/12/2020',
                  },
                  share: 25,
                  clauseType: null,
                  clauseValue: null,
               },
            ],
         ],
      },
      {
         insuredAdultType: 'SECOND',
         paymentToFuneralDirector: 'YES',
         beneficiaries: [
            [
               {
                  personalInformation: {
                     firstName: 'Barry',
                     surname: 'Smith',
                     birthDate: '15/12/2020',
                  },
                  share: 100,
                  clauseType: null,
                  clauseValue: null,
               },
            ],
         ],
      },
   ],
});
