import * as Yup from 'yup';
import validationMessages from 'v2/common/utils/validationMessages';
import {AnyObject, Maybe, Message} from 'yup/lib/types';
import BaseSchema from 'yup/lib/schema';
import moment from 'moment';
import {
  DATE_FORMAT,
  specialSymbolsRegexpFirstname,
  specialSymbolsRegexpSurname,
  suspiciousSymbolsRegexp,
} from '../../../shared/constants';
import {getPolicyBeginDate} from 'shared/services/validators';
import {getCompleteYearsOfInsured, getCurrentMoment} from 'shared/services/utils';

Yup.setLocale({
   mixed: {
      required: validationMessages.required,
   },
});

function parseDateFromFormats(this: Yup.DateSchema) {
   return this.transform(function (value: Date, originalValue: string) {
      const dateObject = moment(originalValue, DATE_FORMAT, true);
      if (dateObject.isValid()) {
         return dateObject.toDate();
      }
      return originalValue === '' ? undefined : originalValue;
   });
}

Yup.addMethod(Yup.date, 'format', parseDateFromFormats);

export function isYoungForCoverageValidator(value?: Date) {
   if (!value) {
      return true;
   }

   const birthDate = moment(value, DATE_FORMAT);
   const age = getCurrentMoment().diff(birthDate, 'years');

   return age >= 18;
}

Yup.addMethod<Yup.DateSchema>(Yup.date, 'isYoungForCoverage', function (message?: Message) {
   const validationMessage = message ? message : validationMessages.isYoungForCoverage();
   return this.test('isYoungForCoverage', validationMessage, isYoungForCoverageValidator);
});

export function isOldForOneTimePaymentValidator(value?: Date) {
   if (!value) {
      return true;
   }

   const years = getCompleteYearsOfInsured(value);
   return years < 108;
}

Yup.addMethod<Yup.DateSchema>(Yup.date, 'isOldForOneTimePayment', function (message?: Message) {
   const validationMessage = message ? message : validationMessages.isOldForOneTimePayment(108);
   return this.test('isOldForOneTimePayment', validationMessage, isOldForOneTimePaymentValidator);
});

export function isOldForPeriodicPaymentValidator(value?: Date) {
   if (!value) {
      return true;
   }

   const years = getCompleteYearsOfInsured(value);
   return years < 69;
}

Yup.addMethod<Yup.DateSchema>(Yup.date, 'isOldForPeriodicPayment', function (message?: any) {
   const validationMessage = message ? message : validationMessages.isOldForPeriodicPayment(69);
   return this.test('isOldForPeriodicPayment', validationMessage, isOldForPeriodicPaymentValidator);
});

export function rangeValidator(
   this: Yup.NumberSchema,
   min: number,
   max: number,
   message?: Message
) {
   const resultMessage = message || validationMessages.range(min, max);
   return this.min(min, resultMessage).max(max, resultMessage);
}

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'range', rangeValidator);

export const isPayUntilUnderRangeForPeriodicPaymentValidator = (
   value: number | undefined,
   testContext: Yup.TestContext
) => {
   if ((!value && value !== 0) || !testContext.parent?.personalInformation.birthDate) {
      return true;
   }
   const policyBeginYear = getPolicyBeginDate().year();
   const endOfPaymentYear = moment(testContext.parent.personalInformation.birthDate, DATE_FORMAT)
      .add(value, 'years')
      .year();

   return endOfPaymentYear > policyBeginYear;
};

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'isPayUntilUnderRangeForPeriodicPayment', function (
   message?: Message
) {
   const validationMessage = message
      ? message
      : validationMessages.isPayUntilUnderRangeForPeriodicPayment();
   return this.test(
      'isPayUntilUnderRangeForPeriodicPayment',
      validationMessage,
      isPayUntilUnderRangeForPeriodicPaymentValidator
   );
});

export const isPayUntilOverRangeForPeriodicPaymentValidator = (
   value: number | undefined,
   testContext: Yup.TestContext
) => {
   if ((!value && value !== 0) || !testContext.parent?.personalInformation.birthDate) {
      return true;
   }

   let minAge = 18;
   const maxAge = 80;
   const policyBeginYear = getPolicyBeginDate().add(1, 'years').year();
   const insuredBirthYear = moment(
      testContext.parent.personalInformation.birthDate,
      DATE_FORMAT
   ).year();
   minAge = Math.max(minAge, policyBeginYear - insuredBirthYear);
   return value < minAge || value > maxAge
      ? testContext.createError({params: {minAge, maxAge}})
      : true;
};

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'isPayUntilOverRangeForPeriodicPayment', function (
   message?: any
) {
   const validationMessage = message
      ? message
      : validationMessages.isPayUntilOverRangeForPeriodicPayment;
   return this.test(
      'isPayUntilOverRangeForPeriodicPayment',
      validationMessage,
      isPayUntilOverRangeForPeriodicPaymentValidator
   );
});

export const checkSuspiciousSymbols = (regex: RegExp, value?: string) => {
   if (!value) {
      return true;
   }
   const words = value.split(/\s+/);

   return !(suspiciousSymbolsRegexp.test(value) || words.some((word) => regex.test(word)));
};

export const checkSuspiciousSymbolsFirstname = (value?: string) => checkSuspiciousSymbols(specialSymbolsRegexpFirstname, value);

export const checkSuspiciousSymbolsSurname = (value?: string) => checkSuspiciousSymbols(specialSymbolsRegexpSurname, value);

Yup.addMethod<Yup.StringSchema>(Yup.string, 'checkSuspiciousSymbolsFirstname', function (message?: Message) {
   const validationMessage = message ? message : validationMessages.checkSuspiciousSymbols();
   return this.test('checkSuspiciousSymbolsFirstname', validationMessage, checkSuspiciousSymbolsFirstname);
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'checkSuspiciousSymbolsSurname', function (message?: Message) {
   const validationMessage = message ? message : validationMessages.checkSuspiciousSymbols();
   return this.test('checkSuspiciousSymbolsSurname', validationMessage, checkSuspiciousSymbolsSurname);
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'trimWhitespaces', function () {
   return this.transform((value) => value.trim().replace(/\s+/g, ' '));
});

declare module 'yup' {
   interface NumberSchema<
      TType extends Maybe<number> = number | undefined,
      TContext extends AnyObject = AnyObject,
      TOut extends TType = TType
   > extends BaseSchema<TType, TContext, TOut> {
      range(this: NumberSchema<TType, TContext, TOut>, min: number, max: number): this;
      isPayUntilUnderRangeForPeriodicPayment(value?: number): this;
      isPayUntilOverRangeForPeriodicPayment(value?: number): this;
   }

   interface StringSchema<
      TType extends Maybe<string> = string | undefined,
      TContext extends AnyObject = AnyObject,
      TOut extends TType = TType
   > extends BaseSchema<TType, TContext, TOut> {
    checkSuspiciousSymbolsFirstname(value?: string): this;
    checkSuspiciousSymbolsSurname(value?: string): this;
      trimWhitespaces(): this;
   }

   interface DateSchema<
      TType extends Maybe<Date>,
      TContext extends AnyObject = AnyObject,
      TOut extends TType = TType
   > extends BaseSchema<TType, TContext, TOut> {
      format(): this;
      isYoungForCoverage(value?: Date): this;
      isOldForOneTimePayment(value?: Date): this;
      isOldForPeriodicPayment(value?: Date): this;
   }
}

export default Yup;
