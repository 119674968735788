import { useQuery } from 'react-query';

import { ENDPOINTS, PAGE_SIZE, PORTFOLIO_TYPES } from 'shared/constants';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { transformPortfolioResponse } from 'v2/common/utils/apiTransformations';

export interface ISalesProcessItem {
  akCode: Nullable<number>;
  daysBeforeDiscard: Nullable<number>;
  id: number;
  number: string;
  offerId: Nullable<number>;
  policyHolderFirstName: string;
  policyHolderSurname: string;
  policyStatus: Nullable<string>;
  premiumBalance: Nullable<number>;
  productName: Nullable<string>;
  proposalId: Nullable<number>;
  state: string;
  status: string;
  step: Nullable<string>;
}

export interface IPortfolio {
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
  _embedded?: {
    salesProcessItems: ISalesProcessItem[];
  };
}

export type PortfolioParams = {
  type: string;
  search?: string;
  page?: number;
  sort?: string;
};

const PORTFOLIO_URL = {
  [PORTFOLIO_TYPES.OFFER]: ENDPOINTS.offers,
  [PORTFOLIO_TYPES.PROPOSAL]: ENDPOINTS.proposals,
  [PORTFOLIO_TYPES.POLICY]: ENDPOINTS.policies,
};

export function usePortfolio(params: PortfolioParams) {
  const { type, search = '', sort = '', page = 1 } = params;
  const axios = useAxios();

  return useQuery(
    ['portfolio', { type, search, sort }, page],
    () => {
      const serverPage = page - 1;

      return axios
        .get<IPortfolio>(PORTFOLIO_URL[type], {
          params: { page: serverPage, size: PAGE_SIZE, search, sort },
        })
        .then((result) => transformPortfolioResponse(result.data));
    },
    {
      initialData: {
        page: { number: 1, size: PAGE_SIZE, totalPages: 1, totalElements: 0 },
        items: [],
      },
      keepPreviousData: true,
    }
  );
}
