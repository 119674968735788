import React from 'react';
import {connect} from 'react-redux';
import {number, string, func, bool} from 'prop-types';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import {STEPS} from '../../../../shared/constants';
import {ConvertButton, NavigationPaneItem} from '../';
import {
   selectOfferId,
   selectIsConvertToOfferButtonDisabled,
   selectOfferStatus,
   selectIsBlocked,
} from '../../selectors';
import {handleConvertToOfferButtonClickAction} from '../../../FormWrapper/reducer';
import {selectIsDisabled} from '../../../FormWrapper/selectors';

export const OfferNavigationPaneItem = ({
   offerId,
   status,
   isFormDisabled,
   isConvertToOfferButtonDisabled,
   isBlocked,
   handleConvertToOfferAction,
}) => {
   const disabled = isFormDisabled || isConvertToOfferButtonDisabled;
   const showConvertButton = !offerId && !isBlocked;

   return showConvertButton ? (
      <ConvertButton
         label={<FormattedMessage id="navigationPane.createOffer" />}
         disabled={disabled}
         handleClick={handleConvertToOfferAction}
      />
   ) : (
      <NavigationPaneItem
         title={<FormattedMessage id="misc.offer" />}
         step={STEPS.OFFER}
         status={status}
         disabled={!offerId}
         root
      />
   );
};

OfferNavigationPaneItem.propTypes = {
   offerId: number,
   status: string,
   isFormDisabled: bool,
   isConvertToOfferButtonDisabled: bool,
   isBlocked: bool,
   handleConvertToOfferAction: func,
};

const mapStateToProps = (state) => ({
   offerId: selectOfferId(state),
   status: selectOfferStatus(state),
   isFormDisabled: selectIsDisabled(state),
   isConvertToOfferButtonDisabled: selectIsConvertToOfferButtonDisabled(state),
   isBlocked: selectIsBlocked(state),
});

export default connect(mapStateToProps, {
   handleConvertToOfferAction: handleConvertToOfferButtonClickAction.request,
})(OfferNavigationPaneItem);
