import { IAgent, IProducerAccount, ProducerAccountType } from 'v2/common/api/queries/agent';
import { Permission } from 'v2/features/permissions/types';

export const createProducerAccount = (
  values: Partial<IProducerAccount> = {}
): IProducerAccount => ({
  permissions: [
    Permission.SalesProcessList,
    Permission.SalesProcessStatistics,
    Permission.ReadOffer,
    Permission.CreateOffer,
    Permission.UpdateOffer,
    Permission.OfferList,
    Permission.ReadProposal,
    Permission.CreateProposal,
    Permission.UpdateProposal,
    Permission.ProposalList,
    Permission.ReadPolicy,
    Permission.PolicyList,
    Permission.ReadPolicyTemplates,
    Permission.PolicyTemplatesList,
    Permission.ReadCommissions,
    Permission.CommissionsList,
    Permission.DeleteNotifications,
    Permission.NotificationsList,
    Permission.ExternalAgent,
    Permission.DENTAgent,
    Permission.PremiumCalculation,
    Permission.ProductInfo,
    Permission.Tools,
    Permission.Contacts,
    Permission.ExternalReference,
    Permission.BadgeNumber,
    Permission.DELActive,
  ],
  id: 189,
  type: ProducerAccountType.Own,
  number: 50_000,
  roles: ['POLICY_MANAGEMENT', 'POLICY_CONSULTATION', 'PRODUCTION', 'CONSULT_COMMISSIONS'],
  intermed: {
    name: 'DELA Enterprises',
    id: 3347,
    address: {
      streetName: 'Baker street',
      houseNumber: '55',
      postalCode: '1000',
      municipality: 'BRUSSEL',
      boxNumber: null,
    },
    email: 'coolIntermed1@gmail.com',
    vaultId: 778_126,
    accountManager: {
      email: 'info@dela.net',
      mobileNumber: '329888995',
      firstName: 'Account1',
      surname: 'Manager1',
    },
    enterpriseNumber: 'Number A',
    fsmaNumber: '104194',
    dent: true,
    website: 'www.dela.be',
    commercialName: 'DELA',
    legalForm: 'NV',
    externalReferenceEnabled: false,
    telephoneNumber: '+32488888888',
    faxNumber: null,
  },
  linkedIntermed: null,
  linkedLeadGenerator: null,
  partyId: '1697418',
  isActive: true,
  ...values,
});

export const producerAccounts: IProducerAccount[] = [
  createProducerAccount({ number: 1 }),
  createProducerAccount({
    number: 2,
    type: ProducerAccountType.LeadGenerator,
    linkedLeadGenerator: { name: 'DFA1 - Uitvaartzorg Olmen' },
  }),
  createProducerAccount({ number: 4, type: ProducerAccountType.Online }),
];

export const createAgentData = (values?: Partial<IAgent>): IAgent => ({
  id: 132,
  type: 'EMPLOYED',
  email: 'testuserbp3@delabedev.onmicrosoft.com',
  vaultId: 676_566,
  activeDirectoryUserId: 'f45b4df6-018b-4e58-894e-5af8c524a1cc',
  contactNumber: '+32488885555',
  mobileNumber: '+33644637852',
  firstName: 'TEST33',
  surname: 'AGENT3',
  contactLanguage: 'FRENCH',
  tenantId: '75c4bb41-77fa-4947-b4ba-d23309dd783d',
  intermed: {
    name: 'DELA Enterprises',
    id: 3347,
    address: {
      streetName: 'Baker street',
      houseNumber: '55',
      postalCode: '1000',
      municipality: 'BRUSSEL',
      boxNumber: null,
    },
    email: 'coolIntermed1@gmail.com',
    vaultId: 778_126,
    accountManager: {
      email: 'info@dela.net',
      mobileNumber: '329888995',
      firstName: 'Account1',
      surname: 'Manager1',
    },
    enterpriseNumber: 'Number A',
    fsmaNumber: '104194',
    dent: true,
    website: 'www.dela.be',
    commercialName: 'DELA',
    legalForm: 'NV',
    externalReferenceEnabled: false,
    telephoneNumber: '+32488888888',
    faxNumber: null,
  },
  residentialAddress: {
    type: 'RESIDENTIAL',
    country: null,
    municipality: null,
    postalCode: null,
    suffix: null,
    streetName: null,
    boxNumber: null,
    houseNumber: null,
  },
  agentCompany: {
    name: 'Tom Reymen Consulting',
    enterpriseNumber: 'Num1',
    fsmaNumber: '103158 cA',
    commercialName: null,
    legalForm: 'NV',
  },
  dateOfBirth: '11/11/1999',
  producerAccountNumbers: producerAccounts,
  isActive: true,
  ...values,
});
