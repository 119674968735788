import {
  selectActionData,
  selectAsyncActionData,
  selectIsInProgressStatus,
} from '../reducers/createReducer';
import { getStateByContainerId } from '../reducers/selectors';

import { containerId, fetchMunicipalities, localizeReferences } from '.';

export const getCurrentState = getStateByContainerId(containerId);

export const selectConfirmationTypes = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'confirmationTypes');

export const selectPaymentFrequencies = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'paymentFrequencies');

export const selectContactLanguages = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'contactLanguages');

export const selectGenders = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'genders');

export const selectInsuranceIntroducer = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'insuranceIntroducer');

export const selectFuneralInsuranceStatuses = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'funeralInsuranceStatuses');

export const selectYesNo = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'yesNo');

export const selectPhoneCodes = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'phoneCodes');

export const selectFillHealthDeclarationAnswers = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'fillHealthDeclarationAnswers');

export const selectSmokingAnswers = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'smokingAnswers');

export const selectPaymentMethods = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'paymentMethods');

export const selectIsFetchingMunicipalities = (state) =>
  selectIsInProgressStatus(getCurrentState(state), fetchMunicipalities.type);

export const selectMunicipalities = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchMunicipalities.type) || [];

export const selectInsuredProductBasis = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'insuredProductBasis');

export const selectAdviceType = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'adviceType');

export const selectSourceOfInformation = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'sourceOfInformation');

export const selectFollowAdvice = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'followAdvice');

export const selectSigningMethods = (state) =>
  selectActionData(getCurrentState(state), localizeReferences.type, 'signingMethods');
