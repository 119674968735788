import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {change} from 'redux-form';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {api} from '../../../shared/services';
import {
   adaptInsuredPersonalInfo,
   addIndex,
   convertPhoneNumber,
   EMAIL_KEY,
   parsePhoneNumbers,
   PHONE_KEYS,
   slicePhoneNumber,
   sortParties,
} from '../../../shared/services/utils';
import {setGeneralError} from '../../App/reducer';
import {handleProposalGeneralError} from '../sagas';
import {selectMetadata, selectProposalId, selectServerErrors} from '../selectors';
import {
   containerId as insuredContainerId,
   saveInsured,
   fetchInsuredAdults,
   fetchInsuredChildren,
   omitInsuredAdultServerErrors,
   omitInsuredChildServerErrors,
} from './reducer';
import {
   selectFirstInsuredAdultsFormValue,
   selectFirstInsuredParticipatingAsPolicyHolderValue,
   selectInsuredAdultsFormValue,
   selectShouldHaveInsuredChildrenFormValue,
} from './selectors';
import {selectActiveFormValues} from '../../FormWrapper/selectors';
import {
   HANDLED_SERVER_ERRORS,
   PAYMENT_FREQUENCY,
   PERSONAL_INFO_KEYS,
   SECTIONS,
   SERVER_ERROR_TYPES,
   SIMPLE_ANSWER,
} from '../../../shared/constants';
import {fetchMetadata, setServerErrors} from '../reducer';
import {fetchPolicyHolder} from '../PolicyHolder/reducer';
import {selectPolicyHolder} from '../PolicyHolder/selectors';

export function* updateEmptyInsuredAdultId(currentValues, index, insuredAdults) {
   const path = index === 0 ? 'firstInsuredAdult' : 'secondInsuredAdult';
   if (currentValues && currentValues.id === null) {
      yield put(
         change(
            insuredContainerId,
            `insuredAdults[${index}].id`,
            get(insuredAdults, `${path}.id`, null)
         )
      );
   }
}

const parseInsuredAdultsMobileNumbers = (insuredAdults, section) => {
   const insured = get(insuredAdults, section);

   if (isEmpty(insured)) {
      return {};
   }

   const mobileNumber = get(insured, 'mobileNumber');

   const {phoneNumberCode: mobileNumberCode, phoneNumber: modifiedMobileNumber} = parsePhoneNumbers(
      mobileNumber
   );

   return {
      ...insured,
      mobileNumber: modifiedMobileNumber,
      mobileNumberCode,
   };
};

const prepareChildrenResponse = (response) => {
   sortParties(response);
   return response.map(({index, ...rest}) => rest);
};

export function* fetchInsuredAdultsSaga({payload}) {
   try {
      let id = payload;
      if (!id) {
         id = yield select(selectProposalId);
      }
      const insuredAdults = yield call(api.proposal.getInsuredAdults, id);

      const [firstInsuredAdult, secondInsuredAdult] = yield select(selectInsuredAdultsFormValue);

      yield call(updateEmptyInsuredAdultId, firstInsuredAdult, 0, insuredAdults);
      yield call(updateEmptyInsuredAdultId, secondInsuredAdult, 1, insuredAdults);

      const firstInsuredFetchData = parseInsuredAdultsMobileNumbers(
         insuredAdults,
         SECTIONS.FIRST_INSURED_ADULT
      );
      const secondInsuredFetchData = parseInsuredAdultsMobileNumbers(
         insuredAdults,
         SECTIONS.SECOND_INSURED_ADULT
      );
      const fetchData = {
         [SECTIONS.FIRST_INSURED_ADULT]: {
            ...firstInsuredFetchData,
            participatingAsPolicyHolder: firstInsuredFetchData.participatingAsPolicyHolder
               ? SIMPLE_ANSWER.YES
               : SIMPLE_ANSWER.NO,
         },
      };

      if (!isEmpty(secondInsuredFetchData)) {
         fetchData[SECTIONS.SECOND_INSURED_ADULT] = {
            ...secondInsuredFetchData,
            participatingAsPolicyHolder: SIMPLE_ANSWER.NO,
         };
      }

      yield put(fetchInsuredAdults.success(fetchData));
   } catch (error) {
      yield put(fetchInsuredAdults.failure(error));
      yield call(handleProposalGeneralError, error);
   }
}

export function* fetchInsuredChildrenSaga({payload}) {
   try {
      let id = payload;
      if (!id) {
         id = yield select(selectProposalId);
      }
      const {children} = yield call(api.proposal.getInsuredChildren, id);
      yield put(fetchInsuredChildren.success(prepareChildrenResponse(children)));
   } catch (error) {
      yield put(fetchInsuredChildren.failure(error));
      yield call(handleProposalGeneralError, error);
   }
}

export const prepareDataSaveInsuredSaga = (data) => {
   const firstInsured = get(data, 'insuredAdults[0]');
   const secondInsured = get(data, 'insuredAdults[1]');
   const paymentFrequency = get(data, 'paymentFrequency');
   const isOneTimeOnlyPaymentFrequency = paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;
   const preparedData = {
      firstInsuredAdult: {
         ...convertPhoneNumber({...firstInsured}, 'mobileNumber'),
         participatingAsPolicyHolder:
            firstInsured.participatingAsPolicyHolder === SIMPLE_ANSWER.YES,
         payUntilAge: isOneTimeOnlyPaymentFrequency ? null : firstInsured.payUntilAge,
      },
      ...omit(data, 'insuredAdults'),
      insuredChildren: get(data, 'insuredChildren', []).map(addIndex),
   };

   if (secondInsured) {
      preparedData.secondInsuredAdult = {
         ...convertPhoneNumber({...secondInsured}, 'mobileNumber'),
         participatingAsPolicyHolder: false,
         payUntilAge: isOneTimeOnlyPaymentFrequency ? null : secondInsured.payUntilAge,
      };
   }
   return preparedData;
};

export function* saveInsuredSaga() {
   const formValues = yield select(selectActiveFormValues);
   const data = prepareDataSaveInsuredSaga(formValues);
   const proposalId = yield select(selectProposalId);
   try {
      const response = yield call(api.proposal.patchInsured, proposalId, data);
      const {children} = yield call(api.proposal.getInsuredChildren, proposalId);
      yield call(updateInsuredSaga, response);
      yield call(updateInsuredChildrenSaga, prepareChildrenResponse(children));
      yield call(updatePolicyHolderSaga, data);
      yield call(updateMetadataSaga);
      yield put(saveInsured.success());
   } catch (error) {
      yield put(saveInsured.failure(error));
      yield put(setGeneralError.action(error));
   }
}

export function* updateInsuredSaga(payload) {
   const firstInsuredParticipatingAsPolicyHolder = yield select(
      selectFirstInsuredParticipatingAsPolicyHolderValue
   );

   const firstInsuredId = get(payload, `${SECTIONS.FIRST_INSURED_ADULT}.id`, null);
   const secondInsuredId = get(payload, `${SECTIONS.SECOND_INSURED_ADULT}.id`, null);

   const insuredFormValues = yield select(selectInsuredAdultsFormValue);

   const updatedInsuredFormValues = insuredFormValues.map((insured, index) => {
      const isFirstInsured = index === 0;
      return {
         ...insured,
         mobileNumber: slicePhoneNumber(insured.mobileNumber),
         participatingAsPolicyHolder: isFirstInsured
            ? firstInsuredParticipatingAsPolicyHolder
            : SIMPLE_ANSWER.NO,
         id: isFirstInsured ? firstInsuredId : secondInsuredId,
      };
   });

   const firstInsuredMobileNumber = get(updatedInsuredFormValues, `0.mobileNumber`, null);
   const secondInsuredMobileNumber = get(updatedInsuredFormValues, `1.mobileNumber`, null);

   yield put(change(insuredContainerId, `insuredAdults[0].mobileNumber`, firstInsuredMobileNumber));

   if (secondInsuredMobileNumber) {
      yield put(
         change(insuredContainerId, `insuredAdults[1].mobileNumber`, secondInsuredMobileNumber)
      );
   }
   yield put(fetchInsuredAdults.update(updatedInsuredFormValues));
}

export function* updateInsuredChildrenSaga(payload) {
   // as children index is not part of redux form
   // we need to update insuredChildren with data that is sent to back on save
   for (let index = 0; index < payload.length; index++) {
      yield put(
         change(
            insuredContainerId,
            `insuredChildren[${index}].insuredChildId`,
            payload[index].insuredChildId
         )
      );
   }

   yield put(fetchInsuredChildren.update(payload));
}

export function* updatePolicyHolderSaga() {
   const policyHolder = yield select(selectPolicyHolder);
   const participatingAsPolicyHolder = yield select(
      selectFirstInsuredParticipatingAsPolicyHolderValue
   );

   if (participatingAsPolicyHolder === SIMPLE_ANSWER.NO) {
      return yield put(
         fetchPolicyHolder.update({
            ...policyHolder,
            participatingAsInsuredAdult: SIMPLE_ANSWER.NO,
         })
      );
   }

   const insured = yield select(selectFirstInsuredAdultsFormValue);
   const insuredObject = adaptInsuredPersonalInfo(insured);

   const personalInformation = {
      ...policyHolder.personalInformation,
      firstName: insuredObject[PERSONAL_INFO_KEYS.FIRST_NAME],
      surname: insuredObject[PERSONAL_INFO_KEYS.SURNAME],
      gender: insuredObject[PERSONAL_INFO_KEYS.GENDER],
      birthDate: insuredObject[PERSONAL_INFO_KEYS.BIRTH_DATE],
      nationalRegisterNumber: insuredObject[PERSONAL_INFO_KEYS.NATIONAL_REGISTER_NUMBER],
   };

   return yield put(
      fetchPolicyHolder.update({
         ...policyHolder,
         personalInformation,
         contactInformation: {
            ...policyHolder.contactInformation,
            email: insuredObject[EMAIL_KEY.EMAIL],
            mobileNumber: insuredObject[PHONE_KEYS.MOBILE_NUMBER],
            mobileNumberCode: insuredObject[PHONE_KEYS.MOBILE_NUMBER_CODE],
         },
         participatingAsInsuredAdult: SIMPLE_ANSWER.YES,
      })
   );
}

export function* updateMetadataSaga() {
   const shouldHaveInsuredChildren = yield select(selectShouldHaveInsuredChildrenFormValue);
   const metadata = yield select(selectMetadata);
   const updatedMetadata = {
      ...metadata,
      shouldHaveInsuredChildren,
   };

   const participatingAsPolicyHolder = yield select(
      selectFirstInsuredParticipatingAsPolicyHolderValue
   );

   if (participatingAsPolicyHolder === SIMPLE_ANSWER.YES) {
      const firstInsured = yield select(selectFirstInsuredAdultsFormValue);
      const firstName = get(firstInsured, 'personalInformation.firstName');
      const surname = get(firstInsured, 'personalInformation.surname');
      updatedMetadata.policyHolderFirstName = firstName;
      updatedMetadata.policyHolderSurname = surname;
   }

   yield put(fetchMetadata.update(updatedMetadata));
}

export function* omitInsuredAdultServerErrorsSaga({payload}) {
   const serverErrors = yield select(selectServerErrors);
   const index = payload === 0 ? 1 : 0;
   const result = {};
   HANDLED_SERVER_ERRORS.forEach((errorType) => {
      if (
         errorType === SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES ||
         errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN
      ) {
         result[errorType] = serverErrors[errorType] || {};
         return;
      }
      if (
         errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS ||
         errorType === SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES
      ) {
         return (result[errorType] = {});
      }

      const insuredAdults = get(serverErrors, `${errorType}.insuredAdults`);
      if (!insuredAdults) {
         return (result[errorType] = {});
      }
      result[errorType] = {insuredAdults: [...insuredAdults].splice(index, 1)};
   });

   yield put(setServerErrors.action(result));
}

export function* omitInsuredChildServerErrorsSaga({payload}) {
   const serverErrors = yield select(selectServerErrors);
   const result = {};

   HANDLED_SERVER_ERRORS.forEach((errorType) => {
      if (
         errorType === SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES ||
         errorType === SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES ||
         errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS
      ) {
         result[errorType] = serverErrors[errorType] || {};
         return;
      }

      if (errorType === SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN) {
         return (result[errorType] = {});
      }

      const insuredChildren = [...get(serverErrors, `${errorType}.insuredChildren`, [])];
      if (!insuredChildren.length) {
         return (result[errorType] = serverErrors[errorType]);
      }

      if (isUndefined(payload)) {
         return (result[errorType] = {
            ...serverErrors[errorType],
            insuredChildren: [],
         });
      }

      insuredChildren.splice(payload, 1);

      result[errorType] = {
         ...get(serverErrors, `${errorType}`),
         insuredChildren,
      };
   });
   yield put(setServerErrors.action(result));
}

export default function* insuredSagas() {
   yield takeLatest(saveInsured.REQUEST, saveInsuredSaga);

   yield takeLatest(omitInsuredAdultServerErrors.ACTION, omitInsuredAdultServerErrorsSaga);
   yield takeLatest(omitInsuredChildServerErrors.ACTION, omitInsuredChildServerErrorsSaga);
   yield takeLatest(fetchInsuredAdults.REQUEST, fetchInsuredAdultsSaga);
   yield takeLatest(fetchInsuredChildren.REQUEST, fetchInsuredChildrenSaga);
}
