import React from 'react';
import {injectIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {FormattedMessage} from '../../../components/FormattedMessage';

const ICON = {
   [toast.TYPE.SUCCESS]: 'icon-circle-check',
   [toast.TYPE.WARNING]: 'icon-triangle-exclamation',
   [toast.TYPE.ERROR]: 'icon-circle-exclamation',
};

const ToastBody = ({messageProps, type, closeToast}) => {
   return (
      <>
         <i className={`iconfont ${ICON[type]}`} />
         <div className="toast-content">
            <FormattedMessage {...messageProps} />
         </div>
         <i className="iconfont icon-cross" onClick={closeToast} />
      </>
   );
};

export default injectIntl(ToastBody);
