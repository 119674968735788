import React from 'react';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type MenuPanelProps = {
  expanded: AccordionProps['expanded'];
  icon: React.ReactNode;
  title?: React.ReactNode;
  children: React.ReactNode;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const MenuPanel = ({ expanded, icon, title, children, onChange }: MenuPanelProps) => (
  <Accordion
    sx={{
      marginBottom: 1,
      borderRadius: 1,
      color: 'primary.contrastText',
      backgroundColor: 'primary.main',
    }}
    expanded={expanded}
    onChange={onChange}
  >
    <AccordionSummary
      sx={{
        [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
          color: 'primary.contrastText',
        },
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        {title && <Typography variant="body2">{title}</Typography>}
      </Stack>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export default MenuPanel;
