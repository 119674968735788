import React from 'react';
import { useIntl } from 'react-intl';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function DELActiveIcon({ sx }: SvgIconProps = {}) {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'appDrawer.DELActive.headerAccessibleTitle' });

  return (
    <SvgIcon sx={sx} viewBox="0 0 153 27" titleAccess={title} data-testid="DELActiveIcon">
      <path d="M59.3817 0.236109C59.3419 0.343974 59.3286 0.478805 59.3684 0.532738C60.1913 2.06982 65.5136 10.6855 65.6331 10.6855C65.8322 10.6855 71.8181 0.829367 71.911 0.343974L71.9641 0.0338614H65.7127C60.3638 0.0338614 59.448 0.0608277 59.3817 0.236109Z" />
      <path d="M116.467 1.96156C115.976 2.19078 115.233 3.02673 115.127 3.48516C115.074 3.67392 115.074 4.14583 115.1 4.55033C115.206 5.87168 116.109 6.68066 117.489 6.68066C118.856 6.68066 119.852 5.66943 119.852 4.2537C119.852 2.91887 118.923 1.90763 117.622 1.82673C117.171 1.79976 116.706 1.8537 116.467 1.96156Z" />
      <path d="M3.38452 2.66277C2.36252 2.75715 1.36708 2.85153 1.16799 2.85153C0.809629 2.86501 0.796357 2.89198 0.796357 3.52569V4.17288L1.67235 4.32119C2.66779 4.48299 3.07925 4.7931 3.3447 5.54816C3.47742 5.92569 3.51724 8.00209 3.51724 14.6628V23.292L3.18543 23.9527C2.77398 24.8021 2.17671 25.1257 0.91581 25.2201L0 25.2875V25.9212V26.5684L4.75159 26.6493C7.35303 26.6897 10.3261 26.7706 11.3481 26.8111C12.755 26.8785 13.5115 26.8381 14.4273 26.6763C19.5373 25.7729 22.9085 22.2673 23.6916 17.0223C24.5676 11.1841 22.3378 6.41108 17.5729 3.9167C15.237 2.68973 13.7902 2.46052 8.82629 2.474C6.86194 2.48749 4.40651 2.56839 3.38452 2.66277ZM11.0826 4.36164C16.166 5.04928 19.4444 9.8358 18.8471 15.7145C18.4489 19.665 16.6571 22.847 14.0955 24.1279C13.1399 24.6133 13.0204 24.6403 11.7993 24.6403C10.5783 24.6403 10.4854 24.6133 9.94118 24.2358C9.55628 23.9527 9.22446 23.5616 8.95901 23.0223L8.56083 22.2268L8.52102 13.3279C8.50774 8.43355 8.50774 4.36164 8.54756 4.26726C8.61392 4.10546 9.29083 4.13243 11.0826 4.36164Z" />
      <path d="M26.4125 3.3773V4.02449L27.1956 4.18629C28.1645 4.38854 28.6821 4.92786 28.9475 5.97955C29.213 7.04472 29.213 22.456 28.9475 23.3998C28.629 24.5189 27.6734 25.1121 26.1868 25.1121H25.4834V25.7863V26.4604H34.084H42.6714L43.2554 23.7234C43.574 22.2267 43.866 20.9324 43.8925 20.838C43.9323 20.7436 43.7332 20.5683 43.4678 20.4335L42.9634 20.1908L42.7511 20.5279C41.4769 22.5234 40.1629 23.8312 39.0347 24.2357C37.5084 24.7751 35.6104 24.5998 34.8539 23.8312C34.1902 23.1571 34.1106 22.6043 34.1106 18.6267V15.1076L35.7431 15.202C36.6324 15.2425 37.6677 15.3773 38.0393 15.4852C38.8755 15.7144 39.5391 16.402 39.8842 17.3998C40.1231 18.0739 40.1496 18.1009 40.6407 18.1009H41.1451V14.2582V10.4155H40.6407C40.1364 10.4155 40.1231 10.429 39.8975 11.184C39.7382 11.6829 39.4727 12.1413 39.1675 12.4515C38.5967 13.0447 37.9995 13.193 35.6768 13.3144L34.1106 13.3953V8.93236V4.45595L34.7477 4.53685C35.0928 4.5773 35.7564 4.64472 36.2342 4.68517C38.9418 4.95483 40.3753 5.73685 41.4769 7.58404C41.676 7.92112 41.8618 8.20427 41.8751 8.23124C41.8883 8.24472 42.0874 8.19079 42.3131 8.10989C42.8174 7.93461 42.8174 7.97506 42.5387 5.15708C42.4325 4.07843 42.3396 3.09416 42.3396 2.95933C42.3396 2.7436 41.8751 2.73011 34.376 2.73011H26.4125V3.3773Z" />
      <path d="M47.3832 3.39079V4.05146L47.9539 4.13236C48.8034 4.26719 49.3476 4.67168 49.6794 5.39977C49.9714 6.02 49.9714 6.12786 50.0112 14.2582C50.0377 20.0964 50.0112 22.6852 49.905 23.1975C49.6263 24.4784 48.6707 25.1121 46.9983 25.1121H46.3214V25.7863V26.4604H54.9353C63.0316 26.4604 63.5625 26.447 63.6289 26.2178C63.7351 25.8402 64.7703 20.9324 64.7703 20.7975C64.7703 20.6897 64.1067 20.2582 63.9342 20.2582C63.8943 20.2582 63.5094 20.7571 63.0714 21.3638C62.1556 22.6582 61.1867 23.6155 60.4036 24.0335C59.01 24.7481 56.7006 24.6807 55.8246 23.8852C55.0282 23.184 55.015 23.0762 55.015 14.2582C55.015 6.95034 55.0415 6.24921 55.2539 5.6964C55.6122 4.77955 56.0635 4.37506 56.966 4.18629L57.7359 4.02449V3.3773V2.73011H52.5595H47.3832V3.39079Z" />
      <path d="M72.1367 10.3751C67.0931 23.629 67.0798 23.6829 66.456 24.384C66.0047 24.8829 65.7924 25.0178 65.3411 25.0717L64.7704 25.1526V25.7998V26.4604H69.0176H73.2649V25.7863V25.1121H72.6676C71.9243 25.1121 71.2474 24.8425 70.9554 24.4245C70.5838 23.8852 70.69 22.9683 71.3669 20.8649L71.9907 18.9099L75.7734 18.9369L79.5693 18.9773L80.2595 20.9324C80.6312 22.011 80.9497 23.1166 80.9497 23.3998C80.9762 24.4649 80.2728 25.1121 79.1048 25.1121H78.5739V25.7863V26.4604H83.1529H87.732V25.8133C87.732 25.2065 87.7055 25.1661 87.2144 24.9638C86.9224 24.8425 86.5906 24.5728 86.4578 24.3706C86.3251 24.1548 84.4404 19.22 82.2902 13.3953L78.3483 2.79753L76.7025 2.75708L75.0567 2.73011L72.1367 10.3751ZM77.2068 12.3975C78.0165 14.6897 78.6403 16.5908 78.6137 16.6447C78.5474 16.7796 72.7605 16.7796 72.6941 16.6447C72.6278 16.5234 75.5477 8.2582 75.6539 8.2582C75.6937 8.2582 76.3972 10.1189 77.2068 12.3975Z" />
      <path d="M104.854 8.54173V10.9687L104.429 11.144C104.19 11.2518 103.646 11.4136 103.234 11.4945L102.465 11.6698V12.1822V12.6945L103.633 12.735L104.787 12.7754L104.854 18.7889L104.92 24.8024L105.318 25.4496C105.902 26.3934 106.831 26.8923 108.159 26.9732C108.835 27.0136 109.353 26.9597 109.738 26.8249C110.335 26.6091 111.517 25.9215 112.114 25.4361C112.432 25.1799 112.459 25.126 112.3 24.762C111.941 23.8856 111.862 23.8586 111.079 24.2631C110.149 24.762 109.313 24.7754 108.955 24.317C108.716 24.0204 108.703 23.7507 108.729 18.3844L108.769 12.7754L110.534 12.735L112.286 12.6945V11.899V11.1035L110.534 11.0631L108.769 11.0226L108.729 8.55521L108.703 6.10128H106.778H104.854V8.54173Z" />
      <path d="M94.7664 10.3751C90.4396 11.1032 87.4665 15.4852 88.1168 20.1504C88.6079 23.7234 90.9439 26.3122 94.1559 26.8785C95.2442 27.0672 97.182 26.9728 98.2439 26.6762C98.9871 26.474 101.363 25.4492 101.589 25.247C101.642 25.1796 101.509 24.8021 101.283 24.3841L100.859 23.6425L100.168 24.047C99.0004 24.7616 98.2173 25.0178 97.2882 25.0178C96.1866 25.0178 95.377 24.6403 94.4479 23.6964C92.3243 21.5526 91.7137 17.7234 93.0144 14.865C93.4259 13.9616 94.7001 12.6537 95.6557 12.1279L96.3591 11.7504L97.1422 12.1683C97.5669 12.411 98.2969 12.9234 98.7615 13.3279L99.6109 14.056L100.673 12.9639C101.708 11.9122 101.721 11.8852 101.469 11.6695C100.898 11.2245 99.3057 10.5504 98.4828 10.4155C97.6466 10.2807 95.4433 10.2537 94.7664 10.3751Z" />
      <path d="M115.777 10.9957L113.348 11.6699V12.1822V12.6946L114.45 12.735L115.538 12.7755L115.578 18.9373L115.604 25.1126H114.609H113.614V25.8541V26.5957H117.662H121.71V25.8541V25.1126H120.648H119.586V17.6968V10.2811L118.896 10.2946C118.511 10.3081 117.104 10.6182 115.777 10.9957Z" />
      <path d="M144.671 10.3755C142.827 10.6856 140.889 11.8991 139.92 13.3418C138.858 14.9328 138.433 16.4564 138.433 18.7216C138.433 21.2969 139.124 23.2384 140.57 24.789C141.951 26.2452 143.411 26.8519 145.747 26.9598C147.804 27.0542 148.892 26.8115 150.976 25.7867C153.219 24.6946 153.139 24.7755 152.715 23.9261C152.516 23.5351 152.33 23.2249 152.29 23.2249C152.25 23.2249 151.759 23.4946 151.202 23.8182C149.967 24.5463 149.051 24.9238 148.229 25.0452C147.512 25.153 146.291 24.9238 145.561 24.5463C143.955 23.7238 142.773 21.4182 142.654 18.9103L142.614 18.0339L147.777 17.9935L152.927 17.9665L152.874 16.3755C152.821 14.8789 152.794 14.7171 152.343 13.7463C151.533 12.0339 150.166 10.9553 148.229 10.4834C147.459 10.2946 145.468 10.2407 144.671 10.3755ZM147.193 11.9665C148.096 12.4384 148.653 13.7193 148.653 15.2969V16.2137H145.667H142.681V15.9036C142.681 15.1216 143.344 13.5575 144.008 12.789C144.817 11.8586 146.277 11.4811 147.193 11.9665Z" />
      <path d="M122.373 11.3461C122.373 12.0067 122.387 12.0202 122.811 12.0607L123.236 12.1011L125.068 16.6854C126.063 19.2067 127.404 22.564 128.028 24.1281L129.169 27H129.859H130.536L133.111 20.0831C134.531 16.2944 135.792 12.9236 135.925 12.6L136.164 12.0337H136.907H137.637V11.3595V10.6854H134.982H132.328V11.3595V12.0337H133.337C134.292 12.0337 134.345 12.0472 134.279 12.3169C134.053 13.1663 131.067 20.7843 130.987 20.7034C130.815 20.5011 127.576 12.236 127.629 12.1416C127.669 12.0742 127.988 12.0337 128.359 12.0337H129.01V11.3595V10.6854H125.692H122.373V11.3461Z" />
    </SvgIcon>
  );
}
