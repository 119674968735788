import {useMsal} from '@azure/msal-react';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {SavedCalculatePremiumService} from 'shared/services/SavedCalculatePremium';
import {transformInitiateSalesProcessFormValues} from 'v2/common/utils/apiTransformations';
import {
   InitiateSalesProcessFormValues,
   SalesProcessType,
} from 'v2/features/PremiumCalculation/components/InitiateSalesProcess/InitiateSalesProcess';

import PremiumCalculation from 'v2/features/PremiumCalculation/components/PremiumCalculation';

const ScrollableBox = styled(Box)(({theme}) => ({
   maxHeight: '580px',
   overflowY: 'auto',
   '&::-webkit-scrollbar': {
      width: 5,
   },
   '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
   },
}));

const AnonymousPremiumCalculation = () => {
   const {instance} = useMsal();

   const salesProcessInitiateHandler = async (
      type: SalesProcessType,
      values: InitiateSalesProcessFormValues
   ) => {
      SavedCalculatePremiumService.setData({
         // TODO: Change when sagas are removed
         type: type === SalesProcessType.Offer ? 'offer' : 'proposal',
         ...transformInitiateSalesProcessFormValues(values),
      });
      await instance.loginRedirect();
   };

   return (
      <Box margin="auto" width={520}>
         <Typography variant="h5" textAlign="center" marginBottom={3}>
            <FormattedMessage id="login.premiumCalculation" />
         </Typography>
         <ScrollableBox paddingX={2}>
            <PremiumCalculation onSalesProcessInitiate={salesProcessInitiateHandler} />
         </ScrollableBox>
      </Box>
   );
};

export default AnonymousPremiumCalculation;
