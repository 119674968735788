export const policyStatus = Object.freeze({
   ACTIVE: 'ACTIVE',
   CANCELLED: 'CANCELLED',
   ENDED: 'ENDED',
});

export const policyProductName = Object.freeze({
   HR_400: 'HR_400',
   HR_420: 'HR_420',
   HR_435: 'HR_435',
   PROD_EX_LILAS: 'PROD_EX_LILAS',
   UZP: 'UZP',
   UZP_PLUS: 'UZP+',
   UZPN: 'UZPN',
});

export const warrantyType = Object.freeze({
   DEATH: 'DEATH',
   ASSISTANCE: 'ASSISTANCE',
   LIFE: 'LIFE',
   AFTER_CARE_CAPITAL: 'AFTER_CARE_CAPITAL',
   EAS: 'EAS',
   AFTER_CARE_NBZ: 'AFTER_CARE_NBZ',
   REPATRIATION: 'REPATRIATION',
});

export const beneficiaryClauseType = Object.freeze({
   DEATH: 'DEATH',
   LIFE: 'LIFE',
   AFTER_CARE: 'AFTER_CARE',
});
