import {createIntl, createIntlCache} from 'react-intl';
import {OnErrorFn} from '@formatjs/intl/src/types';

import {FeatureToggling} from 'shared/constants/FeatureToggling';
import {flattenMessages} from 'shared/services/utils';
import diseases from 'translations/diseases.json';
import commonMessages from 'translations/messages.json';
import questionnaire from 'translations/questionnaire.json';
import {Locale} from 'v2/common/providers/LocalizationProvider/types';
import {webStorage} from 'v2/common/services/WebStorage';

export const DEFAULT_LOCALE = FeatureToggling.reactAppProductionLocales
   ? Locale.Netherlands
   : Locale.English;
const productionLocales = [Locale.Netherlands, Locale.French];
const developmentLocales = [Locale.English, Locale.Id];

export function getAvailableLocales() {
   if (FeatureToggling.reactAppProductionLocales && !webStorage.getShowAllLocales()) {
      return productionLocales;
   }

   return [...productionLocales, ...developmentLocales];
}

export function getInitialLocale() {
   const locales = getAvailableLocales();
   const storedLocale = locales.find((locale) => webStorage.getLocale() === locale);
   if (storedLocale) {
      return storedLocale;
   }
   const userPreferredLocale = locales.find((locale) =>
      navigator.languages?.find((preferredLocale) => locale === preferredLocale)
   );
   if (userPreferredLocale) {
      return userPreferredLocale;
   }
   return DEFAULT_LOCALE;
}

export function getMessages(locale: Locale) {
   return flattenMessages({
      ...(commonMessages as Record<Locale, any>)[locale],
      ...(diseases as Record<Locale, any>)[locale],
      ...(questionnaire as Record<Locale, any>)[locale],
   });
}

export function handleLocalizationError({
   locale,
   error,
}: {
   locale: Locale;
   error: Parameters<OnErrorFn>[0];
}) {
   if (locale === Locale.Id) {
      return;
   }
   console.error(error);
}

export function getIntlContext(locale: Locale) {
   const cache = createIntlCache();

   return createIntl(
      {
         locale,
         messages: getMessages(locale),
         onError(error) {
            handleLocalizationError({locale, error});
         },
      },
      cache
   );
}
