import {bool, func} from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ActionButton from '../../../../shared/components/ActionButton';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import {handleSaveButtonClickAction} from '../../reducer';
import {selectIsFormSaving, selectIsSavingButtonDisabled} from '../../selectors';

class SavingButton extends PureComponent {
   static propTypes = {
      saving: bool,
      disabled: bool,
      handleSaveAction: func,
   };

   render() {
      const {disabled, saving} = this.props;
      return (
         <ActionButton
            className="save"
            action={saving}
            disabled={disabled}
            onClick={this.handleClickButton}
         >
            <FormattedMessage id="formWrapper.save" />
         </ActionButton>
      );
   }

   handleClickButton = () => this.props.handleSaveAction();
}

const mapStateToProps = () => {
   return (state) => ({
      disabled: selectIsSavingButtonDisabled(state),
      saving: selectIsFormSaving(state),
   });
};

export default connect(mapStateToProps, {
   handleSaveAction: handleSaveButtonClickAction.request,
})(SavingButton);
