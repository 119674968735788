import {createReducer} from '../../../shared/reducers/createReducer';
import {AsyncAction} from '../../../shared/services/ActionHelpers';

export const containerId = 'AdditionalInformation';
export const fetchAdditionalInformation = new AsyncAction(
   `${containerId}/ADDITIONAL_INFORMATION_FETCH`
);

export const saveAdditionalInformation = new AsyncAction(
   `${containerId}/ADDITIONAL_INFORMATION_SAVE`
);

export default createReducer([fetchAdditionalInformation, saveAdditionalInformation]);
