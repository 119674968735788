// The order is important, polyfills should be imported before any other code
// polyfills for Safari 12-
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/polyfill';
//@ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

// polyfills for ie
import 'react-app-polyfill/stable';

import App from 'containers/App';
import UnsupportedBrowser from 'pages/UnsupportedBrowser';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from 'store';
import AuthProvider from 'v2/common/providers/AuthProvider/AuthProvider';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import './index.scss';

smoothscroll.polyfill();

if (process.env.REACT_APP_MOCK) {
   const {worker} = require('v2/mocks/browser');
   worker.start({onUnhandledRequest: 'bypass'});
}

ReactDOM.render(
   <UnsupportedBrowser>
      <Provider store={store}>
         <AuthProvider>
            <App />
         </AuthProvider>
      </Provider>
   </UnsupportedBrowser>,
   document.getElementById('root')
);
