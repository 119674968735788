import classNames from 'classnames';
import PropTypes from 'prop-types';

import './styles.scss';

export default function InfoPanel({ children, className, highlight }) {
  return <div className={classNames('info-panel', className, { highlight })}>{children}</div>;
}

InfoPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  highlight: PropTypes.bool,
};
