import {containerId as additionalInformationContainerId} from '../../containers/ProposalDetails/AdditionalInformation/reducer';
import {containerId as beneficiariesContainerId} from '../../containers/ProposalDetails/Beneficiaries/reducer';
import {containerId as documentsContainerId} from '../../containers/ProposalDetails/Documents/reducer';
import {containerId as healthDeclarationContainerId} from '../../containers/ProposalDetails/HealthDeclaration/reducer';
import {containerId as insuredContainerId} from '../../containers/ProposalDetails/Insured/reducer';
import {containerId as policyHolderContainerId} from '../../containers/ProposalDetails/PolicyHolder/reducer';

export const PROPOSAL_PAGES = {
   policyHolder: 'policyHolder',
   insured: 'insured',
   beneficiaries: 'beneficiaries',
   healthDeclaration: 'healthDeclaration',
   additionalInformation: 'additionalInformation',
   documents: 'documents',
};

export const PROPOSAL_PAGES_TO_CONTAINER_IDS = {
   [PROPOSAL_PAGES.policyHolder]: policyHolderContainerId,
   [PROPOSAL_PAGES.insured]: insuredContainerId,
   [PROPOSAL_PAGES.beneficiaries]: beneficiariesContainerId,
   [PROPOSAL_PAGES.healthDeclaration]: healthDeclarationContainerId,
   [PROPOSAL_PAGES.additionalInformation]: additionalInformationContainerId,
   [PROPOSAL_PAGES.documents]: documentsContainerId,
};

export const REQUEST_ERRORS = {
   [PROPOSAL_PAGES.healthDeclaration]: [
      'firstInsuredAdult.healthDeclaration',
      'secondInsuredAdult.healthDeclaration',
   ],
};

export const getPageByError = (error) => {
   for (let page in REQUEST_ERRORS) {
      if (REQUEST_ERRORS[page].some((prefix) => error.startsWith(prefix))) {
         return page;
      }
   }
};
