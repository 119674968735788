import {InputBaseComponentProps} from '@mui/material';
import {CleaveOptions} from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React from 'react';

type MaskProps = InputBaseComponentProps & {
   mask?: CleaveOptions;
};

type Ref = {
   focus: HTMLInputElement['focus'];
} | null;

const MaskedInput = React.forwardRef<Ref, MaskProps>(({mask = {}, ...other}, ref) => {
   const inputRef = React.useRef<HTMLInputElement | null>(null);
   React.useImperativeHandle(ref, () => ({
      focus(options) {
         inputRef.current?.focus(options);
      },
   }));
   return <Cleave {...other} htmlRef={(i) => (inputRef.current = i)} options={mask} />;
});

export default MaskedInput;
