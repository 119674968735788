import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import * as nrnUtils from '../services/nrnUtils';
import {
  isHouseNumberCharacterAllowed,
  isPhoneNumberValid,
  parseNumber,
  removeSpaces,
} from '../services/utils';
import {
  ageBetween,
  ageDiffLess,
  benefitInRange,
  benefitsAreEqual,
  bloodPressureFormat,
  checkBeneficiariesAreEqual,
  checkDependentField,
  checkField,
  checkFirstName,
  checkInsuredAreEqual,
  checkInsuredChildrenAreEqual,
  checkInsuredFirstName,
  checkInsuredIsAssignedAsBeneficiary,
  checkInsuredIsBeneficiary,
  checkInsuredSurname,
  checkInsuredValueNotRemoved,
  checkSmokingUntilAge,
  checkSurname,
  checkSuspiciousSymbolsFirstname,
  checkSuspiciousSymbolsSurname,
  checkTotalShare,
  checkValueNotRemovedById,
  dateInPast,
  dateIsValid,
  emailIsValid,
  ibanFormatValidation,
  insuredMobilePhoneNumberFormat,
  IsBICLengthValid,
  isBICRequired,
  isFreeCoverageAge,
  isOldForOneTimePayment,
  isOldForPeriodicPayment,
  isPayUntilOverRangeForPeriodicPayment,
  isPayUntilUnderRangeForPeriodicPayment,
  isPolicyHolderAgeUnderLimit,
  isYoungForCoverage,
  lessThan,
  minLengthOrEmpty,
  moreThan,
  numberBetweenExcludingMinIncludingMax,
  numberBetweenIncludingMax,
  offerResidentialAddressHouseNumber,
  percentageIsValid,
  policyHolderLandlinePhoneNumberFormat,
  policyHolderMobilePhoneNumberFormat,
  policyHoldersEmail,
  policyHoldersResidentialAddress,
  proposalResidentialAddressHouseNumber,
  required,
  withPrecondition,
} from '../services/validators';

import { PHONE_CODES } from './main';
import { phoneValueToCountryCodes, SECTIONS, SIMPLE_ANSWER } from ".";

export const phoneUtil = PhoneNumberUtil.getInstance();

const toUpperCaseWithoutMetacharacters = (value) => value && value.toUpperCase().replace(/\s/g, '');

export const date = {
  mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  validate: [required, dateIsValid, dateInPast],
};

export const nationalRegisterNumber = {
  mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/],
  normalize: (value) => (value ? nrnUtils.normalize(value) : value),
};

export const paymentFrequencyHint = 'fields.paymentFrequency.hint';

export const paymentFrequency = {
  validate: [required],
};

export const [payUntilAgeHintPeriodic, payUntilAgeHintOneTimeOnly] = [
  'fields.payUntilAge.hint.periodic',
  'fields.payUntilAge.hint.oneTimeOnly',
];

export const payUntilAge = {
  validate: [
    [required, isPayUntilUnderRangeForPeriodicPayment(0), isPayUntilOverRangeForPeriodicPayment(0)],
    [required, isPayUntilUnderRangeForPeriodicPayment(1), isPayUntilOverRangeForPeriodicPayment(1)],
  ],
  mask: createNumberMask({
    prefix: '',
    integerLimit: 3,
    includeThousandsSeparator: false,
  }),
};

export const amount = {
  placeholder: '\u20AC',
  mask: createNumberMask({
    prefix: '\u20AC ',
    includeThousandsSeparator: false,
    integerLimit: 10,
  }),
  validate: [required, moreThan(0)],
};

export const benefitLowerRangeValue = 2500;

export const benefit = {
  validate: [required, benefitInRange(benefitLowerRangeValue, 10_000), benefitsAreEqual],
};

export const careClause = {
  validate: [benefitsAreEqual],
};

export const insuredSurname = (beneficiariesData) => ({
  validate: [
    [
      checkInsuredSurname(0),
      checkInsuredValueNotRemoved(0, 'personalInformation.surname'),
      checkSuspiciousSymbolsSurname,
      checkInsuredAreEqual(0),
      checkInsuredIsBeneficiary(beneficiariesData)(0),
    ],
    [
      checkInsuredSurname(1),
      checkInsuredValueNotRemoved(1, 'personalInformation.surname'),
      checkSuspiciousSymbolsSurname,
      checkInsuredAreEqual(1),
      checkInsuredIsBeneficiary(beneficiariesData)(1),
    ],
  ],
});

export const insuredFirstName = (beneficiariesData) => ({
  validate: [
    [
      checkInsuredFirstName(0),
      checkInsuredValueNotRemoved(0, 'personalInformation.firstName'),
      checkSuspiciousSymbolsFirstname,
      checkInsuredAreEqual(0),
      checkInsuredIsBeneficiary(beneficiariesData)(0),
    ],
    [
      checkInsuredFirstName(1),
      checkInsuredValueNotRemoved(1, 'personalInformation.firstName'),
      checkSuspiciousSymbolsFirstname,
      checkInsuredAreEqual(1),
      checkInsuredIsBeneficiary(beneficiariesData)(1),
    ],
  ],
});

const normalizePhoneNumber = (value, prev) => {
  if (!value) {
    return value;
  }

  const updatedValue = removeSpaces(value);

  if (!isPhoneNumberValid(updatedValue)) {
    return prev;
  }

  return updatedValue;
};

export const normalizeBenefitShare = (value, prev) => {
  if (!value || value.slice(-1) === '.') {
    return value;
  }

  return parseFloat(value);
};

const createPhoneMask = (phoneNumber) => {
  const result = [];
  for (let i = 0; i < phoneNumber.length; i++) {
    phoneNumber[i] === ' ' ? result.push(' ') : result.push(/\d/);
  }

  return result;
};

const maskPhoneNumber = (isMobile) => (codeValue, value) => {
  if (!value || !codeValue) {
    return [];
  }
  if (value.length < 2 || value.length > 17 || !isPhoneNumberValid(value)) {
    return createPhoneMask(value);
  }

  const phoneNumber = codeValue + value;

  const countryCode = phoneValueToCountryCodes[codeValue];
  const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);

  if (isMobile && phoneUtil.getNumberType(number) !== PhoneNumberType.MOBILE) {
    return createPhoneMask(value);
  }

  if (!phoneUtil.isValidNumberForRegion(number, countryCode)) {
    return createPhoneMask(value);
  }

  if (codeValue === PHONE_CODES.BELGIUM && value.startsWith('0')) {
    const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.NATIONAL);

    return createPhoneMask(formattedNumber);
  }

  const internationalPhoneNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
  const sliceIndex = codeValue.length + 1;

  return createPhoneMask(internationalPhoneNumber.slice(sliceIndex));
};

const maskMobilePhoneNumber = maskPhoneNumber(true);
const maskLandlinePhoneNumber = maskPhoneNumber(false);

export const insuredMobilePhoneNumber = {
  validate: [[insuredMobilePhoneNumberFormat(0)], [insuredMobilePhoneNumberFormat(1)]],
  normalize: normalizePhoneNumber,
  mask: maskMobilePhoneNumber,
};

export const policyHolderBirthDate = (participatingAsInsuredAdult, isOneTimeOnly) => {
  const validators = [
    dateIsValid,
    dateInPast,
    isYoungForCoverage('policyHolderUnderAge'),
    isPolicyHolderAgeUnderLimit,
  ];
  if (participatingAsInsuredAdult === SIMPLE_ANSWER.YES) {
    validators.push(required);
    isOneTimeOnly
      ? validators.push(isOldForOneTimePayment)
      : validators.push(isOldForPeriodicPayment);
  }
  return { validate: validators };
};

const defaultInsuredBirthDateValidators = [
  required,
  dateIsValid,
  dateInPast,
  ageDiffLess(10),
  isYoungForCoverage('insuredAdultUnderAge'),
];

export const insuredBirthDate = (beneficiariesData) => ({
  validate: [
    [
      ...defaultInsuredBirthDateValidators,
      checkInsuredAreEqual(0),
      checkInsuredIsBeneficiary(beneficiariesData)(0),
    ],
    [
      ...defaultInsuredBirthDateValidators,
      checkInsuredAreEqual(1),
      checkInsuredIsBeneficiary(beneficiariesData)(1),
    ],
  ],
});

export const calculationInsuredBirthDate = {
  validate: defaultInsuredBirthDateValidators,
};

export const residentialAddress = {
  validate: [policyHoldersResidentialAddress],
  validateProposalHouseNumber: [proposalResidentialAddressHouseNumber],
  validateOfferHouseNumber: [offerResidentialAddressHouseNumber],
};

export const streetName = {
  maxLength: 100,
};

export const municipality = {
  maxLength: 500,
};

export const postalCode = {
  placeholder: 'XXXX',
  mask: createNumberMask({
    prefix: '',
    integerLimit: 4,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  }),
  validate: [minLengthOrEmpty(4)],
};

export const boxNumber = {
  mask: createNumberMask({
    prefix: '',
    integerLimit: 8,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  }),
};

export const houseNumber = {
  maxLength: 12,
  normalize: (value, prev) => {
    if (!value) {
      return '';
    }
    if (!isHouseNumberCharacterAllowed(value)) {
      return prev ? prev.toUpperCase() : '';
    }
    return value.toUpperCase();
  },
};

export const policyHolderMobilePhoneNumber = {
  validate: [policyHolderMobilePhoneNumberFormat],
  normalize: normalizePhoneNumber,
  mask: maskMobilePhoneNumber,
};

export const policyHolderLandlinePhoneNumber = {
  validate: [policyHolderLandlinePhoneNumberFormat],
  normalize: normalizePhoneNumber,
  mask: maskLandlinePhoneNumber,
};

export const policyHolderEmail = {
  validate: (agentEmail) => [policyHoldersEmail, emailIsValid(agentEmail)],
};

export const insuredEmail = {
  validate: (agentEmail) => [emailIsValid(agentEmail)],
};

export const email = {
  validate: (agentEmail) => [required, emailIsValid(agentEmail)],
};

export const bic = {
  mask: (value) => Array(value.trim().length).fill(/[a-zA-Z0-9]/),
  validate: [isBICRequired, IsBICLengthValid],
  maxLength: 11,
  normalize: toUpperCaseWithoutMetacharacters,
};

export const iban = {
  placeholder: 'BEXX XXXX XXXX XXXX',
  validate: [ibanFormatValidation],
  maxLength: 42, // 34 IBAN chars + 8 indents for mask
  mask: (rawValue) => {
    const tempValue = rawValue.replace(/\s/g, '').slice(0, 34);

    let value = '';
    for (let i = 0; i < tempValue.length; i++) {
      if (i !== 0 && i % 4 === 0) {
        value += ' ';
      }
      value += tempValue[i];
    }

    const result = [];

    for (let i = 0; i < value.length; i++) {
      if (i === 0 || i === 1) {
        result.push(/[a-zA-Z]/);
        continue;
      }

      if (value[i] === ' ') {
        result.push(' ');
        continue;
      }

      result.push(/[a-zA-Z0-9]/);
    }
    return result;
  },
  normalize: toUpperCaseWithoutMetacharacters,
};

export const weight = {
  maxLength: 5,
  validate: {
    [SECTIONS.FIRST_INSURED_ADULT]: [numberBetweenExcludingMinIncludingMax(0, 400)],
    [SECTIONS.SECOND_INSURED_ADULT]: [numberBetweenExcludingMinIncludingMax(0, 400)],
  },
  mask: createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    integerLimit: 3,
    decimalLimit: 1,
    allowDecimal: true,
    decimalSymbol: ',',
  }),
};

export const height = {
  maxLength: 3,
  validate: {
    [SECTIONS.FIRST_INSURED_ADULT]: [numberBetweenIncludingMax(0, 250)],
    [SECTIONS.SECOND_INSURED_ADULT]: [numberBetweenIncludingMax(0, 250)],
  },
  mask: createNumberMask({
    prefix: '',
    integerLimit: 3,
    includeThousandsSeparator: false,
  }),
};

export const smokingUntilAge = {
  maxLength: 2,
  validate: {
    [SECTIONS.FIRST_INSURED_ADULT]: [
      withPrecondition(
        checkField(`${SECTIONS.FIRST_INSURED_ADULT}.healthDeclaration.medicalOption`),
        checkSmokingUntilAge(`${SECTIONS.FIRST_INSURED_ADULT}.healthDeclaration`)
      ),
      withPrecondition(
        checkField(`${SECTIONS.FIRST_INSURED_ADULT}.healthDeclaration.medicalOption`),
        numberBetweenIncludingMax(0, 99)
      ),
    ],
    [SECTIONS.SECOND_INSURED_ADULT]: [
      withPrecondition(
        checkField(`${SECTIONS.SECOND_INSURED_ADULT}.healthDeclaration.medicalOption`),
        checkSmokingUntilAge(`${SECTIONS.SECOND_INSURED_ADULT}.healthDeclaration`)
      ),
      withPrecondition(
        checkField(`${SECTIONS.SECOND_INSURED_ADULT}.healthDeclaration.medicalOption`),
        numberBetweenIncludingMax(0, 99)
      ),
    ],
  },
  mask: createNumberMask({
    prefix: '',
    integerLimit: 2,
    includeThousandsSeparator: false,
  }),
};

const lessThan50 = lessThan(50);

export const alcoholGlassPerDay = {
  validate: {
    [SECTIONS.FIRST_INSURED_ADULT]: [lessThan50],
    [SECTIONS.SECOND_INSURED_ADULT]: [lessThan50],
  },
  mask: createNumberMask({
    prefix: '',
    integerLimit: 2,
    includeThousandsSeparator: false,
  }),
};

export const share = {
  mask: createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    integerLimit: 3,
  }),

  validate: {
    [SECTIONS.FIRST_INSURED_ADULT]: [
      checkTotalShare(`${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries`),
    ],
    [SECTIONS.SECOND_INSURED_ADULT]: [
      checkTotalShare(`${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries`),
    ],
  },

  normalize: normalizeBenefitShare,
};

export const insuredChildren = {
  validateFirstName: Array.apply(null, Array(15)).map((el, idx) => [
    checkFirstName('insuredChildren', idx),
    checkValueNotRemovedById(`insuredChildren[${idx}][insuredChildId]`),
    checkSuspiciousSymbolsFirstname,
    checkInsuredChildrenAreEqual(idx),
  ]),
  validateSurname: Array.apply(null, Array(15)).map((el, idx) => [
    checkSurname('insuredChildren', idx),
    checkValueNotRemovedById(`insuredChildren[${idx}][insuredChildId]`),
    checkSuspiciousSymbolsSurname,
    checkInsuredChildrenAreEqual(idx),
  ]),
  validateBirthDate: Array.apply(null, Array(15)).map((el, idx) => [
    dateIsValid,
    dateInPast,
    isFreeCoverageAge,
    checkInsuredChildrenAreEqual(idx),
  ]),
};

export const beneficiaries = (insured) => {
  const firstInsuredIsAssignedAsBeneficiaryValidator = checkInsuredIsAssignedAsBeneficiary(
    insured,
    SECTIONS.FIRST_INSURED_ADULT
  );
  const secondInsuredIsAssignedAsBeneficiaryValidator = checkInsuredIsAssignedAsBeneficiary(
    insured,
    SECTIONS.SECOND_INSURED_ADULT
  );

  const firstInsuredDuplicatedBeneficiariesValidator = checkBeneficiariesAreEqual(
    SECTIONS.FIRST_INSURED_ADULT
  );
  const secondInsuredDuplicatedBeneficiariesValidator = checkBeneficiariesAreEqual(
    SECTIONS.SECOND_INSURED_ADULT
  );

  return {
    validateFirstName: {
      [SECTIONS.FIRST_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        checkFirstName(`${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries`, idx),
        checkValueNotRemovedById(
          `${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries[${idx}][beneficiaryId]`
        ),
        checkSuspiciousSymbolsFirstname,
        firstInsuredIsAssignedAsBeneficiaryValidator(idx),
        firstInsuredDuplicatedBeneficiariesValidator(idx),
      ]),

      [SECTIONS.SECOND_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        checkFirstName(`${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries`, idx),
        checkValueNotRemovedById(
          `${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries[${idx}][beneficiaryId]`
        ),
        checkSuspiciousSymbolsFirstname,
        secondInsuredIsAssignedAsBeneficiaryValidator(idx),
        secondInsuredDuplicatedBeneficiariesValidator(idx),
      ]),
    },
    validateSurname: {
      [SECTIONS.FIRST_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        checkSurname(`${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries`, idx),
        checkValueNotRemovedById(
          `${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries[${idx}][beneficiaryId]`
        ),
        checkSuspiciousSymbolsSurname,
        firstInsuredIsAssignedAsBeneficiaryValidator(idx),
        firstInsuredDuplicatedBeneficiariesValidator(idx),
      ]),

      [SECTIONS.SECOND_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        checkSurname(`${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries`, idx),
        checkValueNotRemovedById(
          `${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries[${idx}][beneficiaryId]`
        ),
        checkSuspiciousSymbolsSurname,
        secondInsuredIsAssignedAsBeneficiaryValidator(idx),
        secondInsuredDuplicatedBeneficiariesValidator(idx),
      ]),
    },
    validateBirthDate: {
      [SECTIONS.FIRST_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        dateIsValid,
        dateInPast,
        ageBetween(0, 150),
        checkInsuredIsAssignedAsBeneficiary(insured, SECTIONS.FIRST_INSURED_ADULT)(idx),
        checkBeneficiariesAreEqual(SECTIONS.FIRST_INSURED_ADULT)(idx),
      ]),
      [SECTIONS.SECOND_INSURED_ADULT]: Array.from({ length: 4 }, (el, idx) => [
        dateIsValid,
        dateInPast,
        ageBetween(0, 150),
        checkInsuredIsAssignedAsBeneficiary(insured, SECTIONS.SECOND_INSURED_ADULT)(idx),
        checkBeneficiariesAreEqual(SECTIONS.SECOND_INSURED_ADULT)(idx),
      ]),
    },
  };
};

export const insuredIsAssignedAsBeneficiaryError = {
  id: 'insuredAdultsAssignedAsBeneficiaries',
  isHidden: true,
};

export const beneficiariesEqualityError = {
  id: 'beneficiaryAreEqual',
  isHidden: true,
};

export const beneficiaryBirthDate = {
  validate: Array.apply(null, Array(15)).map((el, idx) => (insured) => [
    dateIsValid,
    dateInPast,
    ageBetween(0, 150),
    checkInsuredIsAssignedAsBeneficiary(idx, insured),
  ]),
};

export const policyHolderFirstName = {
  validate: [checkSuspiciousSymbolsFirstname, checkDependentField('surname')],
};

export const policyHolderSurname = {
  validate: [checkSuspiciousSymbolsSurname, checkDependentField('firstName')],
};

export const percentage = {
  maxLength: 3,
  parse: parseNumber,
  validate: [percentageIsValid],
  normalize: (value) => (value || value === 0 ? value.toString() : ''),
};

export const bloodPressureMask = (value) => {
  const result = [/[1-9]/];
  const slashPosition = value.indexOf('/');
  for (let i = 1; i < value.length && i <= 4; i++) {
    // if 2 symbols after slash are entered - break the loop
    if (slashPosition !== -1 && i - slashPosition > 2) {
      break;
    }
    // allow to enter slash as a second symbol
    if (i === 1 && value[i] === '/') {
      result.push('/');
      continue;
    }
    // automatically add slash as a 3rd symbol and 1-9 as a 4th if slash was not entered earlier
    if (i === 2 && slashPosition !== 1) {
      result.push('/', /[1-9]/);
      i += 1;
      continue;
    }
    // allow to enter 1-9 after slash symbol
    if (slashPosition === i - 1) {
      result.push(/[1-9]/);
      continue;
    }
    result.push(/\d/);
  }
  return result;
};

export const bloodPressure = {
  mask: bloodPressureMask,
  validate: [bloodPressureFormat],
};

export const additionalInformation = {
  normalize: (value) => value?.trimStart(),
};
