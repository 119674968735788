import { ENDPOINTS } from 'shared/constants';

export const MOCK_POLICY_API_MAP = {
  b2cVerify: `/gateway${ENDPOINTS.b2cVerify}`,
  agentNumbers: `/gateway${ENDPOINTS.agentNumbers}`,
  statistics: `/gateway${ENDPOINTS.salesProcesses}/statistics`,
  offers: `/gateway${ENDPOINTS.offers}`,
  proposals: `/gateway${ENDPOINTS.proposals}`,
  policies: `/gateway${ENDPOINTS.policies}`,
  commissions: `/gateway${ENDPOINTS.commissions}`,
  commissionsDownload: `/gateway${ENDPOINTS.commissions}/:id`,
  policyUpdates: `/gateway${ENDPOINTS.policyUpdates}/policies/mapping`,
  policyUpdatesDownload: `/gateway${ENDPOINTS.policyUpdates}/policy/content`,
  notifications: `/gateway${ENDPOINTS.notifications}`,
  notificationsRemove: `/gateway${ENDPOINTS.notifications}/:notificationId`,
  notificationsStatus: `/gateway${ENDPOINTS.notifications}/status`,
  premiumCalculation: `/gateway${ENDPOINTS.premiumCalculation}`,
  metadata: `/gateway${ENDPOINTS.policies}/:id/metadata`,
  policyHolder: `/gateway${ENDPOINTS.policies}/:id/policy-holder`,
  insured: `/gateway${ENDPOINTS.policies}/:id/insured-parties`,
  beneficiaries: `/gateway${ENDPOINTS.policies}/:id/beneficiaries`,
  documents: `/gateway${ENDPOINTS.policies}/:id/documents`,
  documentDownload: `/gateway${ENDPOINTS.policies}/documents`,
  specificTermsAndConditions: '/specific terms and conditions.pdf',
  statement: '/statement.pdf',
};
