import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Popover} from '../../shared/components/Popover';
import {
   selectShouldShowPopover,
   selectCurrentPopoverStep,
   selectPopoverAnimation,
} from './selectors';
import {
   hidePopover,
   initializePopover,
   nextPopoverStep,
   prevPopoverStep,
   resetPopover,
   toggleAnimation,
} from './reducer';
import {POPOVER_ANIMATION_PROP} from './sagas';

import './styles.scss';

export const POSITION = {
   LEFT: 'left',
   RIGHT: 'right',
};

export class PopoverContainer extends React.PureComponent {
   static propTypes = {
      children: PropTypes.node.isRequired,
      popoverId: PropTypes.string.isRequired,
      totalSteps: PropTypes.number,
      step: PropTypes.number,
      shouldShow: PropTypes.bool,
      hasPopoverAnimation: PropTypes.bool,
      className: PropTypes.string,
      content: PropTypes.node,
      currentStep: PropTypes.number,
      title: PropTypes.node,
      initializePopover: PropTypes.func,
      onClose: PropTypes.func,
      nextPopoverStep: PropTypes.func,
      prevPopoverStep: PropTypes.func,
      onConfirm: PropTypes.func,
      position: PropTypes.string,
   };

   componentDidMount() {
      const {initializePopover, popoverId} = this.props;
      initializePopover(popoverId);
   }

   onNextClick = () => {
      const {nextPopoverStep, popoverId} = this.props;
      nextPopoverStep(popoverId);
   };

   onBackClick = () => {
      const {prevPopoverStep, popoverId} = this.props;
      prevPopoverStep(popoverId);
   };

   handleConfirm = () => {
      const {onConfirm, popoverId} = this.props;
      onConfirm(popoverId);
   };

   handleClose = () => {
      const {onClose, popoverId} = this.props;
      onClose(popoverId);
   };

   handleAnimationEnd = () => {
      const {onToggleAnimation, popoverId} = this.props;
      onToggleAnimation({popoverId, animationProp: POPOVER_ANIMATION_PROP});
   };

   render() {
      const {
         children,
         shouldShow,
         className,
         content,
         step = 1,
         currentStep = 1,
         title,
         totalSteps = 1,
         hasPopoverAnimation,
         position = POSITION.LEFT,
      } = this.props;

      const shouldShowPopover = shouldShow && currentStep === step;

      const popover = shouldShowPopover && (
         <div
            className={classNames('popover-wrapper', className, position, {
               animated: hasPopoverAnimation,
            })}
            onAnimationEnd={this.handleAnimationEnd}
         >
            <Popover
               step={currentStep}
               totalSteps={totalSteps}
               onClose={this.handleClose}
               onConfirm={this.handleConfirm}
               onBack={this.onBackClick}
               onNext={this.onNextClick}
               title={title}
            >
               {content}
            </Popover>
            <div className="popover-divider" />
         </div>
      );

      return (
         <div className="popover-container">
            {position === POSITION.LEFT && popover}
            {children}
            {position === POSITION.RIGHT && popover}
         </div>
      );
   }
}

const mapStateToProps = (state, ownProps) => ({
   shouldShow: selectShouldShowPopover(state, ownProps.popoverId),
   currentStep: selectCurrentPopoverStep(state, ownProps.popoverId),
   hasPopoverAnimation: selectPopoverAnimation(state, ownProps.popoverId, POPOVER_ANIMATION_PROP),
});

export default connect(mapStateToProps, {
   initializePopover: initializePopover.action,
   onClose: hidePopover.action,
   nextPopoverStep: nextPopoverStep.action,
   prevPopoverStep: prevPopoverStep.action,
   onConfirm: resetPopover.action,
   onToggleAnimation: toggleAnimation.action,
})(PopoverContainer);
