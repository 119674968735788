import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import {ThemeProvider} from '@mui/material/styles';
import {default as MuiTooltip, TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';
import main from 'common/providers/ThemeProvider/themes/main';
import React from 'react';

type TooltipProps = {
   placement?: MuiTooltipProps['placement'];
   maxWidth?: number;
   children: MuiTooltipProps['title'];
};

const Tooltip = ({children, maxWidth, placement = 'right'}: TooltipProps) => {
   return (
      <ThemeProvider theme={main}>
         <MuiTooltip
            PopperProps={{style: {maxWidth: maxWidth ?? 300}}}
            title={children}
            placement={placement}
         >
            <HelpRoundedIcon sx={{cursor: 'pointer', marginX: 1 / 2}} fontSize="small" />
         </MuiTooltip>
      </ThemeProvider>
   );
};

export default Tooltip;
