import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import internalError from '../../../../assets/images/500.svg';
import {selectTransformServerError} from '../../../../containers/App/selectors';
import {selectErrorSource} from '../../../../containers/ProposalDetails/selectors';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import {selectProducerAccount} from '../../../../v2/pages/Login/producerAccountSlice';
import {MAIL_INFO} from '../../../constants';
import {producerAccountLabelShape, serverErrorsShape} from '../../../constants/propTypes';
import './styles.scss';

const carry = {
   single: '%0D%0A%0D%0A',
   big: '%0D%0A%0D%0A%0D%0A',
};

export const GeneralError = ({error = {}, selectedProducerAccount, errorSource = ''}) => {
   const intl = useIntl();
   const {label = '', value = '', type = ''} = selectedProducerAccount ?? {};
   const errorUUID = get(error, 'errors[0].errorUUID', false);
   const time = get(error, 'userTime', false);
   const userAgent = get(error, 'userAgent', false);
   const linkToMail = `mailto:${MAIL_INFO.ADDRESS}?subject=${intl.formatMessage({
      id: `errorPage.email.subject`,
   })}&body=${intl.formatMessage(
      {
         id: `errorPage.email.body`,
      },
      {
         br: carry.single,
         bigBr: carry.big,
         producerAccount: `${label} (${value}) ${type}`,
         errorSource,
         errorId: errorUUID,
         time: time,
         browser: userAgent,
      }
   )}`;

   return (
      <div className="error-page">
         <div className="error-page-container">
            <img
               src={internalError}
               alt={intl.formatMessage({
                  id: `errorPage.error.title`,
               })}
               draggable={false}
            />
         </div>
         <h2 className="error-page-title">
            <FormattedMessage id="errorPage.error.title" />
         </h2>
         <p>
            <FormattedMessage id="errorPage.error.description" values={{br: <br />}} />
         </p>
         <section className="sales-support">
            <p>
               <FormattedMessage id="errorPage.error.supportInfo" />{' '}
               <a href={linkToMail}>{MAIL_INFO.ADDRESS}</a>
            </p>
            <p>
               <FormattedMessage id="errorPage.error.or" />{' '}
               <a href={`tel: ${MAIL_INFO.PHONE}`}>{MAIL_INFO.PHONE}</a>{' '}
               <FormattedMessage id="errorPage.error.workingHours" />
            </p>
         </section>
         <section className="sales-error">
            <p className="error-description">
               <FormattedMessage id="errorPage.error.errorInfo" />
            </p>
            <p className="error-uuid">
               <FormattedMessage id="errorPage.error.errorId" />: <span>{errorUUID}</span>
            </p>
            <p>
               <FormattedMessage id="errorPage.error.time" />: <span>{time}</span>
            </p>
         </section>
         <a className="report-button button btn-flat" href={linkToMail}>
            <FormattedMessage id="errorPage.error.reportIssue" />
         </a>
      </div>
   );
};

GeneralError.propTypes = {
   error: PropTypes.shape({
      status: PropTypes.number,
      errors: serverErrorsShape,
   }),
   selectedProducerAccount: producerAccountLabelShape,
   errorSource: PropTypes.string,
};

const mapStateToProps = (state) => ({
   error: selectTransformServerError(state),
   selectedProducerAccount: selectProducerAccount(state),
   errorSource: selectErrorSource(state),
});

export default connect(mapStateToProps)(GeneralError);
