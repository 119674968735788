export const delaBlue = {
   main: '#007fa4',
   light: '#e5f2f6',
   dark: '#3399b6',
   20: '#cce6ed',
   40: '#99ccdb',
   60: '#66b3c9',
   80: '#3399b6',
   100: '#1c82ef',
};
export const green = {
   main: '#75bb21',
   light: '#c8e4a7',
   dark: '#007834',
};
export const red = {
   main: '#e54c42',
   light: '#fef1e9',
};
export const yellow = {
   main: '#ffd044',
   light: '#ffe38f',
   dark: '#c89211',
};
export const white = {
   main: '#fff',
};
export const grey = {
   main: '#4d738a',
   light: '#c4cdd3',
   dark: '#022938',
};
export const orange = {
   100: '#ed7522',
};
