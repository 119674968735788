import get from 'lodash/get';
import { touch } from 'redux-form';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { toastHandler } from '../../shared/services';
import { takeAction } from '../../shared/services/sagaHelpers';
import { createFindFormFields, scrollToInvalidInput } from '../../shared/services/utils';
import { containerId as offerDetailsContainerId, saveOffer } from '../OfferDetails/reducer';
import {
  containerId as additionalInformationContainerId,
  saveAdditionalInformation,
} from '../ProposalDetails/AdditionalInformation/reducer';
import {
  containerId as beneficiariesContainerId,
  saveBeneficiaries,
} from '../ProposalDetails/Beneficiaries/reducer';
import {
  containerId as healthDeclarationContainerId,
  saveHealthDeclarationForm,
} from '../ProposalDetails/HealthDeclaration/reducer';
import { containerId as insuredContainerId, saveInsured } from '../ProposalDetails/Insured/reducer';
import {
  containerId as needsAndDesiresContainerId,
  saveNeedsAndDesires,
} from '../ProposalDetails/NeedsAndDesires/reducer';
import {
  containerId as policyHolderContainerId,
  savePolicyHolder,
} from '../ProposalDetails/PolicyHolder/reducer';

import { setFormSavingAction, showSuccessToast, validateFormAction } from './reducer';
import {
  selectActiveFormName,
  selectFormErrors,
  selectIsFormDirty,
  selectShouldSkipValidation,
} from './selectors';

const formContainerIdToSaveFormAsyncAction = new Map([
  [offerDetailsContainerId, saveOffer],
  [needsAndDesiresContainerId, saveNeedsAndDesires],
  [policyHolderContainerId, savePolicyHolder],
  [insuredContainerId, saveInsured],
  [beneficiariesContainerId, saveBeneficiaries],
  [additionalInformationContainerId, saveAdditionalInformation],
  [healthDeclarationContainerId, saveHealthDeclarationForm],
]);

export const createFormSaveSaga =
  (saveSuccessAction) =>
  (
    saveAction,
    handlerAction,
    formContainerIdToPreSaveAsyncAction = new Map(),
    formContainerIdToPostSaveAsyncAction = new Map(),
    takeActionHandler = takeAction
  ) =>
    function* ({ payload }) {
      const formName = yield select(selectActiveFormName);
      // pre save AsyncAction
      if (formContainerIdToPreSaveAsyncAction.has(formName)) {
        const preSaveAsyncAction = formContainerIdToPreSaveAsyncAction.get(formName);
        yield put(preSaveAsyncAction.request(payload));
        const preSaveActionResult = yield takeActionHandler([saveAction, preSaveAsyncAction]);
        if (preSaveActionResult.type !== preSaveAsyncAction.SUCCESS) {
          yield put(saveAction.failure(preSaveActionResult.payload));
          return;
        }
      }

      if (!formContainerIdToSaveFormAsyncAction.has(formName)) {
        yield put(saveAction.failure());
        return;
      }

      const isDirty = yield select(selectIsFormDirty);
      if (isDirty) {
        // saving current form
        yield put(setFormSavingAction.action(true));
        const saveFormAsyncAction = formContainerIdToSaveFormAsyncAction.get(formName);
        yield put(saveFormAsyncAction.request());

        const saveFormActionResult = yield takeActionHandler([saveAction, saveFormAsyncAction]);

        if (saveFormActionResult.type !== saveFormAsyncAction.SUCCESS) {
          yield put(saveAction.failure(saveFormActionResult.payload));
          yield put(setFormSavingAction.clear());
          return;
        }

        // post save AsyncAction
        if (formContainerIdToPostSaveAsyncAction.has(formName)) {
          const postSaveAsyncAction = formContainerIdToPostSaveAsyncAction.get(formName);
          yield put(postSaveAsyncAction.request());

          const postSaveActionResult = yield takeActionHandler([saveAction, postSaveAsyncAction]);
          if (postSaveActionResult.type !== postSaveAsyncAction.SUCCESS) {
            yield put(saveAction.failure(postSaveActionResult.payload));
            return;
          }
        }

        if (saveSuccessAction) {
          yield put(saveSuccessAction.action());
        }
      }

      if (handlerAction) {
        yield put(handlerAction.action());
      }

      // call handler callback
      const onFormSubmittedCallback = get(payload, 'onFormSubmittedCallback');
      if (onFormSubmittedCallback) {
        yield call(onFormSubmittedCallback);
      }

      // done
      yield put(saveAction.success());
    };

export const createDefaultFormSaveSaga = createFormSaveSaga();
export const createShowSuccessFormSaveSaga = createFormSaveSaga(showSuccessToast);

export const createValidateFormSaga = (scrollToInvalidInputHandler) =>
  function* () {
    const shouldSkipValidation = yield select(selectShouldSkipValidation);
    if (shouldSkipValidation) {
      yield put(validateFormAction.success());
      return;
    }
    const formErrors = yield select(selectFormErrors);
    const formName = yield select(selectActiveFormName);
    const findFieldsWithErrors = createFindFormFields('id');
    const fieldsWithErrors = findFieldsWithErrors(formErrors);

    yield put(touch(formName, ...fieldsWithErrors));
    const isScrolled = scrollToInvalidInputHandler(formErrors);

    if (!isScrolled) {
      yield put(validateFormAction.success());
      return;
    }

    yield put(validateFormAction.failure());
  };

const validateFormSaga = createValidateFormSaga(scrollToInvalidInput);

export function* showSuccessToastSaga() {
  yield toastHandler.successSave();
}

export default function* formWrapperBaseSagas() {
  yield takeLatest(validateFormAction.REQUEST, validateFormSaga);
  yield takeLatest(showSuccessToast.ACTION, showSuccessToastSaga);
}
