import React from 'react';
import {FormattedMessage} from '../../../FormattedMessage';
import './styles.scss';

const NoOptionsMessage = () => (
   <div className="no-results">
      <FormattedMessage id="validate.noResultsText" />
   </div>
);

export default NoOptionsMessage;
