import React from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {number, string, bool, object, oneOfType} from 'prop-types';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {selectOfferId, selectProposalId, selectIsBlocked} from '../../selectors';
import {STEPS} from '../../../../shared/constants';
import {ERROR_STATUSES} from '../../';
import NavigationButton from '../../../FormWrapper/FormWrapperButtonsPanel/NavigationButton';
import {selectIsFormSaving, selectIsFormAsyncValidating} from '../../../FormWrapper/selectors';
import {selectIsFetching as selectIsOfferDetailsFetching} from '../../../OfferDetails/selectors';
import {selectProposalDetailsIsFetching} from '../../../FormWrapper/FormWrapperButtonsPanel/SubmitButton/selectors';
import {NavigationLink} from '../';

export const ROUTE = {
   [STEPS.OFFER]: '/sales-process/offer/',
   [STEPS.PROPOSAL]: '/sales-process/proposals/',
};

export const getPath = ({id, step}) => {
   if (!id) {
      return '';
   }
   switch (step) {
      case STEPS.OFFER:
         return ROUTE[STEPS.OFFER] + id;
      case STEPS.PROPOSAL:
         return ROUTE[STEPS.PROPOSAL] + id;
      default:
         return `${ROUTE[STEPS.PROPOSAL]}${id}/${step}`;
   }
};

export const NavigationPaneItem = ({
   step,
   status,
   title,
   disabled,
   isSaving,
   isAsyncValidating,
   isProposalDetailsFetching,
   isOfferDetailsFetching,
   isBlocked,
   proposalId,
   offerId,
   root,
}) => {
   const {pathname} = useLocation();
   const isOfferStep = step === STEPS.OFFER;
   const path = isOfferStep ? getPath({id: offerId, step}) : getPath({id: proposalId, step});
   const active = !isEmpty(path) && pathname.includes(path);
   const isHealthDeclarationStepActive = pathname.includes(STEPS.HEALTH_DECLARATION);
   const isStepActive = !root && pathname.includes(step);
   const showNavigationButton =
      !isStepActive && (!isBlocked || isHealthDeclarationStepActive || (isOfferStep && disabled));
   const error = includes(ERROR_STATUSES, status);
   const isNavigationButtonDisabled =
      disabled ||
      isSaving ||
      isAsyncValidating ||
      isProposalDetailsFetching ||
      isOfferDetailsFetching;

   return (
      <div className={classNames('nav-item', {root})}>
         <i className={classNames('iconfont', status.toLowerCase())} />
         {showNavigationButton ? (
            <NavigationButton
               disabled={isNavigationButtonDisabled}
               route={path}
               label={title}
               className={classNames('nav-item-button', {
                  active,
                  error,
                  'not-enabled': disabled,
               })}
            />
         ) : (
            <NavigationLink title={title} path={path} active={active} error={error} />
         )}
      </div>
   );
};

NavigationPaneItem.propTypes = {
   step: string,
   status: string,
   title: oneOfType([object, string]),
   disabled: bool,
   isSaving: bool,
   isAsyncValidating: bool,
   isProposalDetailsFetching: bool,
   isOfferDetailsFetching: bool,
   isBlocked: bool,
   proposalId: number,
   offerId: number,
   root: bool,
};

const mapStateToProps = (state) => ({
   proposalId: selectProposalId(state),
   offerId: selectOfferId(state),
   isSaving: selectIsFormSaving(state),
   isAsyncValidating: selectIsFormAsyncValidating(state),
   isProposalDetailsFetching: selectProposalDetailsIsFetching(state),
   isOfferDetailsFetching: selectIsOfferDetailsFetching(state),
   isBlocked: selectIsBlocked(state),
});

export default connect(mapStateToProps)(NavigationPaneItem);
