import { FormattedMessage } from 'react-intl';

import { useAgent } from '../../../../common/api/queries/agent';
import { Widget } from '../Widget';

export const Greeting = () => {
  const { data } = useAgent();

  return (
    <Widget classPrefix="greeting">
      <FormattedMessage id="homepage.greeting" />
      {data && (
        <>
          ,
          <div className="greeting-account-label">
            <span>
              {data.personalInformation.firstName} {data.personalInformation.surname}
            </span>
          </div>
        </>
      )}
    </Widget>
  );
};
