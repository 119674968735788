import {combineReducers, configureStore, createNextState} from '@reduxjs/toolkit';
import appSlice from 'containers/App/appSlice';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {reducer as formReducer} from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import appDrawerSlice from 'v2/features/app-drawer/appDrawerSlice';
import agentsReducer, {containerId as agentsId} from './containers/App/reducer';
import initiateSalesProcessReducer, {
   containerId as initiateSalesProcessId,
} from './containers/CalculatePremium/InitiateSalesProcess/reducer';
import formWrapperReducer, {
   containerId as formWrapperContainerId,
} from './containers/FormWrapper/reducer';
import navigationPaneReducer, {
   containerId as navigationPaneId,
} from './containers/NavigationPane/reducer';
import offerDetailsReducer, {
   containerId as offerDetailsId,
} from './containers/OfferDetails/reducer';
import popoverReducer, {containerId as popoverId} from './containers/PopoverContainer/reducer';
import premiumBoxReducer, {containerId as premiumBoxId} from './containers/PremiumBox/reducer';
import additionalInformationReducer, {
   containerId as additionalInformationId,
} from './containers/ProposalDetails/AdditionalInformation/reducer';
import beneficiariesReducer, {
   containerId as beneficiariesId,
} from './containers/ProposalDetails/Beneficiaries/reducer';
import documentsReducer, {
   containerId as documentsId,
} from './containers/ProposalDetails/Documents/reducer';
import healthDeclarationReducer, {
   containerId as healthDeclarationId,
} from './containers/ProposalDetails/HealthDeclaration/reducer';
import insuredReducer, {
   containerId as insuredId,
} from './containers/ProposalDetails/Insured/reducer';
import insuredTabContainerReducer, {
   containerId as insuredTabContainerId,
} from './containers/ProposalDetails/InsuredTabContainer/reducer';
import needsAndDesiresReducer, {
   containerId as needsAndDesiresId,
} from './containers/ProposalDetails/NeedsAndDesires/reducer';
import policyHolderReducer, {
   containerId as policyHolderId,
   removeFirstInsuredAdultFormValues,
} from './containers/ProposalDetails/PolicyHolder/reducer';
import proposalDetailsReducer, {
   containerId as proposalDetailsId,
} from './containers/ProposalDetails/reducer';
import rootSaga from './rootSaga';
import references, {containerId as referencesReducerId} from './shared/references';
import producerAccountSlice from 'v2/pages/Login/producerAccountSlice';

const policyHolderFormPlugin = createNextState((draft, action) => {
   if (action.type === removeFirstInsuredAdultFormValues.ACTION) {
      draft.values = omit(draft.values, 'firstInsuredAdult');
      if (get(draft, 'fields.firstInsuredAdult.payUntilAge', false)) {
         draft.fields.firstInsuredAdult.payUntilAge.touched = false;
      }
   }
});

export const rootReducer = combineReducers<{
   app: typeof appSlice.reducer;
   appDrawer: typeof appDrawerSlice.reducer;
   producerAccount: typeof producerAccountSlice.reducer;
   form: any;
   [key: string]: any;
}>({
   [appSlice.name]: appSlice.reducer,
   [appDrawerSlice.name]: appDrawerSlice.reducer,
   [producerAccountSlice.name]: producerAccountSlice.reducer,
   form: formReducer.plugin({
      [policyHolderId]: policyHolderFormPlugin,
   }),
   [referencesReducerId]: references,
   [agentsId]: agentsReducer,
   [premiumBoxId]: premiumBoxReducer,
   [navigationPaneId]: navigationPaneReducer,
   [initiateSalesProcessId]: initiateSalesProcessReducer,
   [offerDetailsId]: offerDetailsReducer,
   [needsAndDesiresId]: needsAndDesiresReducer,
   [proposalDetailsId]: proposalDetailsReducer,
   [policyHolderId]: policyHolderReducer,
   [insuredId]: insuredReducer,
   [beneficiariesId]: beneficiariesReducer,
   [healthDeclarationId]: healthDeclarationReducer,
   [additionalInformationId]: additionalInformationReducer,
   [documentsId]: documentsReducer,
   [insuredTabContainerId]: insuredTabContainerReducer,
   [popoverId]: popoverReducer,
   [formWrapperContainerId]: formWrapperReducer,
});
const sagaMiddleware = createSagaMiddleware();

export const initializeStore = () => {
   const configuredStore = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
         getDefaultMiddleware({thunk: false}).concat(sagaMiddleware),
   });
   sagaMiddleware.run(rootSaga);
   return configuredStore;
};
const store = initializeStore();

export type RootState = ReturnType<typeof rootReducer>;

export default store;
