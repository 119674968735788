import {FormattedMessage} from 'react-intl';

type ErrorMessageProps = {
   value?: {id: string; values?: Record<string, any>} | string;
};

const ErrorMessage = ({value}: ErrorMessageProps) => {
   if (!value) {
      return <>&nbsp;</>;
   }
   const isString = typeof value === 'string';
   return isString ? <>{value}</> : <FormattedMessage {...value} />;
};

export default ErrorMessage;
