import React from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import {array, bool, element, oneOfType} from 'prop-types';

const FieldMeta = ({metaHeightAuto = false, children}) => {
   return (
      <div className={classNames(classes.root, {[classes.autoHeight]: metaHeightAuto})}>
         {children}
      </div>
   );
};

FieldMeta.propTypes = {
   metaHeightAuto: bool,
   children: oneOfType([element, array]),
};

export default FieldMeta;
