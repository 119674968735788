import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {AppDrawerPanel} from 'v2/features/app-drawer/types';

type SliceState = {activePanel: Nullable<AppDrawerPanel>; blurredBackdrop: boolean};
const initialState: SliceState = {
   activePanel: null,
   blurredBackdrop: false,
};

const appDrawerSlice = createSlice({
   name: 'appDrawer',
   initialState,
   reducers: {
      openDrawerPanel: (state, action: PayloadAction<SliceState['activePanel']>) => {
         state.activePanel = action.payload;
      },
      closeDrawer: (state) => {
         state.activePanel = null;
      },
      blurBackdrop: (state, action: PayloadAction<SliceState['blurredBackdrop']>) => {
         state.blurredBackdrop = action.payload;
      },
   },
});

export const selectAppDrawer = (state: RootState) => state.appDrawer;

export const {openDrawerPanel, closeDrawer, blurBackdrop} = appDrawerSlice.actions;
export default appDrawerSlice;
