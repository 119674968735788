import React from 'react';
import {useSelector} from 'react-redux';
import {Permission} from 'v2/features/permissions/types';
import {selectProducerAccount} from 'v2/pages/Login/producerAccountSlice';

export type PermissionsCheckType = 'some' | 'exact';
const checkTypeHandlers: Record<
   PermissionsCheckType,
   (producerAccountPermissions: Permission[], requestedPermissions: Permission[]) => boolean
> = {
   some: hasSomePermissions,
   exact: hasExactPermissions,
};

export function usePermissions(requiredPermission: Permission): boolean;
export function usePermissions(
   requiredPermissions: Permission[],
   checkType: PermissionsCheckType
): boolean;
export function usePermissions(
   requiredPermissions: Permission | Permission[],
   checkType?: PermissionsCheckType
): boolean {
   const producerAccount = useSelector(selectProducerAccount);
   return React.useMemo(() => {
      if (!producerAccount) {
         return false;
      }
      if (checkType) {
         const handler = checkTypeHandlers[checkType];
         return handler(producerAccount.permissions, requiredPermissions as Permission[]);
      }

      return hasPermission(producerAccount.permissions, requiredPermissions as Permission);
   }, [checkType, producerAccount, requiredPermissions]);
}

export function hasPermission(
   producerAccountPermissions: Permission[],
   requiredPermission: Permission
) {
   return producerAccountPermissions.includes(requiredPermission);
}

export function hasSomePermissions(
   producerAccountPermissions: Permission[],
   requiredPermissions: Permission[]
) {
   return producerAccountPermissions.some((currentPermission) =>
      requiredPermissions.includes(currentPermission)
   );
}

export function hasExactPermissions(
   producerAccountPermissions: Permission[],
   requiredPermissions: Permission[]
) {
   const hasSameLength = producerAccountPermissions.length === requiredPermissions.length;
   const hasSameValues = producerAccountPermissions.every((producerAccountPermission) =>
      requiredPermissions.includes(producerAccountPermission)
   );
   return hasSameLength && hasSameValues;
}
