import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@mui/material/Box';
import OverflowTooltip from '../../../../shared/components/OverflowTooltip';
import {formatCurrency} from '../../../../shared/services/utils';
import PremiumBoxItem from '../PremiumBoxItem';

export type InsuredAdultProps = {
   payUntilAge: number;
   benefit: {
      amount: number;
   };
   premium: {
      amount: number;
   };
   personalInformation: {
      firstName: string;
      surname: string;
   };
};

export type InsuredAdultInfoProps = {
   insuredAdult?: InsuredAdultProps | null;
   index?: number | null;
   inProgress: boolean;
};

const getFullName = (personalInformation: InsuredAdultProps['personalInformation']) => {
   const {firstName, surname} = personalInformation;

   if (!firstName || !surname) {
      return null;
   }

   return `${firstName} ${surname}`;
};

const InsuredAdultInfo = ({
   insuredAdult = null,
   index = null,
   inProgress,
}: InsuredAdultInfoProps) => {
   const intl = useIntl();

   if (!insuredAdult) {
      return null;
   }

   const fullName = getFullName(insuredAdult.personalInformation);
   const premium = formatCurrency(insuredAdult.premium?.amount ?? 0);
   const benefit = <>&euro; {insuredAdult.benefit?.amount ?? 0}</>;
   const payUntilAge = insuredAdult.payUntilAge
      ? `${insuredAdult.payUntilAge} ${intl.formatMessage({id: 'premiumBox.payUntilPostfix'})}`
      : null;

   return (
      <>
         <Box fontWeight="bold">
            <OverflowTooltip>
               {fullName || (
                  <FormattedMessage
                     id="premiumBox.insuredAdult"
                     values={{
                        index,
                     }}
                  />
               )}
            </OverflowTooltip>
         </Box>
         <PremiumBoxItem
            value={premium}
            label={<FormattedMessage id="premiumBox.premium" />}
            inProgress={inProgress}
         />
         <PremiumBoxItem
            value={payUntilAge}
            label={<FormattedMessage id="premiumBox.payUntil" />}
            inProgress={inProgress}
         />
         <PremiumBoxItem
            value={benefit}
            label={<FormattedMessage id="premiumBox.benefit" />}
            inProgress={inProgress}
         />
         <hr />
      </>
   );
};

export default InsuredAdultInfo;
