import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {PAYMENT_FREQUENCY} from '../../shared/constants/index';
import {api, toastHandler} from '../../shared/services';
import {
   checkIfCalculationOutdated,
   fetchCalculationData,
   recalculatePremium,
   setCalculationData,
   updateCalculationOnPolicyHolderPage,
   updatePersonalInformation,
} from './reducer';
import {selectCalculationData} from './selectors';

export const getFormValues = (state, id) => {
   return state.form[id].values;
};

export const prepareData = ({insuredAdults, paymentFrequency, careClause}, calculationInfo) => {
   const data = {
      firstInsuredAdult: {
         payUntilAge: Number(insuredAdults[0].payUntilAge),
         benefit: insuredAdults[0].benefit,
         premium: calculationInfo.firstInsuredAdult.premium,
         acquisitionCosts: calculationInfo.firstInsuredAdult.acquisitionCosts,
         administrativeCosts: calculationInfo.firstInsuredAdult.administrativeCosts,
         tax: calculationInfo.firstInsuredAdult.tax,
         personalInformation: insuredAdults[0].personalInformation,
      },
      secondInsuredAdult: insuredAdults[1]
         ? {
              payUntilAge: Number(insuredAdults[1].payUntilAge),
              benefit: insuredAdults[1].benefit,
              premium: get(calculationInfo, 'secondInsuredAdult.premium', null),
              acquisitionCosts: get(calculationInfo, 'secondInsuredAdult.acquisitionCosts', null),
              administrativeCosts: get(
                 calculationInfo,
                 'secondInsuredAdult.administrativeCosts',
                 null
              ),
              tax: get(calculationInfo, 'secondInsuredAdult.tax', null),
              personalInformation: insuredAdults[1].personalInformation,
           }
         : null,
      paymentFrequency,
      careClause,
   };

   if (data.paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY) {
      data.firstInsuredAdult.payUntilAge = null;
   }

   return data;
};
export function* recalculateOnDataChange({payload}) {
   const values = yield select(getFormValues, payload);
   const isOneTimeOnlyViolation =
      values.paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY &&
      values.insuredAdults.length !== 1;
   if (!isOneTimeOnlyViolation) {
      const calculationInfo = yield select(selectCalculationData);
      const data = yield call(prepareData, values, calculationInfo);
      if (!isEqual(data, calculationInfo)) {
         yield put(setCalculationData.action(data));
         yield put(recalculatePremium.request());
      }
   }
}

function* updatePersonalInformationSaga({payload: {insuredId, formId}}) {
   const calculationInfo = cloneDeep(yield select(selectCalculationData));
   const values = yield select(getFormValues, formId);
   const personalInfo = values.insuredAdults[insuredId].personalInformation;
   if (insuredId === 0) {
      set(calculationInfo, 'firstInsuredAdult.personalInformation', personalInfo);
   } else {
      set(calculationInfo, 'secondInsuredAdult.personalInformation', personalInfo);
   }
   yield put(setCalculationData.action(calculationInfo));
}

export function* updateCalculationOnPolicyHolderPageSaga() {
   const values = yield select(getFormValues, 'PolicyHolder');
   const {paymentFrequency} = values;

   const payUntil = get(values, 'firstInsuredAdult.payUntilAge', null);

   const calculationInfo = cloneDeep(yield select(selectCalculationData));

   const isPaymentFrequencyFieldUpdated =
      get(calculationInfo, 'paymentFrequency') !== paymentFrequency;

   if (isPaymentFrequencyFieldUpdated) {
      set(calculationInfo, 'paymentFrequency', paymentFrequency);
   }

   const isPayUntilFieldUpdated =
      get(calculationInfo, 'firstInsuredAdult.payUntilAge') !== payUntil;

   if (isPayUntilFieldUpdated) {
      set(calculationInfo, 'firstInsuredAdult.payUntilAge', payUntil);
   }

   if (isPaymentFrequencyFieldUpdated || isPayUntilFieldUpdated) {
      yield put(setCalculationData.action(calculationInfo));
      yield put(recalculatePremium.request());
   }
}

export function* recalculateSaga() {
   const request = cloneDeep(yield select(selectCalculationData));
   const {firstInsuredAdult, secondInsuredAdult} = request;
   toastHandler.closeAll();
   try {
      const {
         calculationResult: [
            firstInsuredAdultCalculationResult,
            secondInsuredAdultCalculationResult = null,
         ],
      } = yield call(api.calculatePremium.post, request);

      const updatedInfo = {
         ...request,
         firstInsuredAdult: {
            ...firstInsuredAdult,
            ...firstInsuredAdultCalculationResult,
         },
         secondInsuredAdult: secondInsuredAdult
            ? {
                 ...secondInsuredAdult,
                 ...secondInsuredAdultCalculationResult,
              }
            : null,
      };

      yield put(setCalculationData.action(updatedInfo));
      yield put(recalculatePremium.success());
   } catch (error) {
      yield put(recalculatePremium.failure(error));
      toastHandler.unexpectedIssue();
   }
}

export function* fetchCalculationDataSaga({payload: {endpoint = 'proposals', id}}) {
   try {
      const calculationData = yield call(api.calculatePremium.getCalculationInfo, endpoint, id);
      yield put(setCalculationData.action(calculationData));
      yield put(fetchCalculationData.success());
   } catch (error) {
      yield put(fetchCalculationData.failure(error));
   }
}

export default function* premiumBoxSagas() {
   yield all([
      takeLatest(fetchCalculationData.REQUEST, fetchCalculationDataSaga),
      takeLatest(recalculatePremium.REQUEST, recalculateSaga),
      takeLatest(checkIfCalculationOutdated.ACTION, recalculateOnDataChange),
      takeLatest(updatePersonalInformation.ACTION, updatePersonalInformationSaga),
      takeLatest(
         updateCalculationOnPolicyHolderPage.ACTION,
         updateCalculationOnPolicyHolderPageSaga
      ),
   ]);
}
