import React from 'react';
import {FormattedMessage} from 'react-intl';
import Dialog from 'v2/common/components/Dialog';
import {useDialog} from 'v2/common/hooks/utils';

type IncreaseInPremiumProps = {
   show: boolean;
   onHide?: () => void;
};

const IncreaseInPremium = ({show, onHide}: IncreaseInPremiumProps) => {
   const {open, close} = useDialog(true);
   if (!show) {
      return null;
   }
   const handleConfirm = () => {
      close();
      onHide?.();
   };

   return (
      <Dialog
         open={open}
         confirmText={<FormattedMessage id="dialogs.gotIt" />}
         onConfirm={handleConfirm}
      >
         <FormattedMessage id="dialogs.increaseInPremium.content" />
      </Dialog>
   );
};

export default IncreaseInPremium;
