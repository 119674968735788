import Box from '@mui/material/Box';
import React from 'react';
import Tooltip from 'v2/common/components/Tooltip';

type LabelProps = {
   label: React.ReactNode;
   hint?: React.ReactNode;
};

const Label = ({label, hint}: LabelProps) => (
   <Box display="flex" alignItems="center">
      {label}
      {hint ? <Tooltip maxWidth={250}>{hint}</Tooltip> : null}
   </Box>
);

export default Label;
