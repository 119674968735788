import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import AxiosProvider from 'v2/common/providers/AxiosProvider';

type QueryProviderProps = {
  client?: QueryClient;
  children: React.ReactNode;
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const QueryProvider = ({ client = queryClient, children }: QueryProviderProps) => (
  <AxiosProvider>
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  </AxiosProvider>
);

export default QueryProvider;
