import {CleaveOptions} from 'cleave.js/options';

export const fiveDigitsMask: CleaveOptions = {
   numeral: true,
   numeralThousandsGroupStyle: 'none',
   numeralPositiveOnly: true,
   numeralDecimalScale: 0,
   numeralIntegerScale: 5,
};

export const threeDigitsMask: CleaveOptions = {
   numeral: true,
   numeralThousandsGroupStyle: 'none',
   numeralPositiveOnly: true,
   numeralDecimalScale: 0,
   numeralIntegerScale: 3,
};

export const createDateMask = (dateMax: string): CleaveOptions => ({
   date: true,
   datePattern: ['d', 'm', 'Y'],
   dateMax,
});
