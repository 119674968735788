import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccordionDetails from '@mui/material/AccordionDetails';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { selectAppDrawer } from 'v2/features/app-drawer/appDrawerSlice';
import DrawerPanel from 'v2/features/app-drawer/components/DrawerPanel/DrawerPanel';
import { Menu } from 'v2/features/app-drawer/components/Menu/Menu';
import { useOpenDrawerPanel } from 'v2/features/app-drawer/hooks';
import { AppDrawerPanel } from 'v2/features/app-drawer/types';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';
import SidePanelPremiumCalculation from 'v2/features/PremiumCalculation/components/SidePanelPremiumCalculation';

const drawerWidth = '510px';

const AppDrawer = () => {
  const hasPremiumCalculationPermission = usePermissions(Permission.PremiumCalculation);
  const { activePanel, blurredBackdrop } = useSelector(selectAppDrawer);
  const openPanel = useOpenDrawerPanel();

  const handleChange =
    (panel: AppDrawerPanel) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        openPanel(panel);
      }
    };

  return (
    <Drawer
      disablePortal
      sx={{
        width: drawerWidth,
        [`& .${drawerClasses.paper}`]: {
          width: drawerWidth,
          backgroundColor: 'transparent',
          padding: 2,
        },
      }}
      ModalProps={{
        keepMounted: true,
        BackdropProps: {
          sx: { backdropFilter: blurredBackdrop ? 'blur(10px)' : 'none' },
        },
      }}
      anchor="right"
      open={activePanel !== null}
    >
      {hasPremiumCalculationPermission && (
        <DrawerPanel
          expanded={activePanel === AppDrawerPanel.CalculatePremium}
          icon={<CalculateRoundedIcon />}
          title={<FormattedMessage id="appDrawer.calculatePremium" />}
          onChange={handleChange(AppDrawerPanel.CalculatePremium)}
        >
          <AccordionDetails sx={{ paddingX: 0, height: 600 }}>
            <SidePanelPremiumCalculation />
          </AccordionDetails>
        </DrawerPanel>
      )}
      <DrawerPanel
        expanded={activePanel === AppDrawerPanel.Menu}
        icon={<MenuRoundedIcon />}
        title={<FormattedMessage id="appDrawer.menu" />}
        onChange={handleChange(AppDrawerPanel.Menu)}
      >
        <AccordionDetails>
          <Menu />
        </AccordionDetails>
      </DrawerPanel>
    </Drawer>
  );
};

export default AppDrawer;
