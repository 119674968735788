import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {oneOf, number, bool, func, shape} from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from '../../shared/components/FormattedMessage';
import {STEPS, COMPLETENESS_STATUSES, HANDLED_SERVER_ERRORS} from '../../shared/constants';
import {selectServerErrors} from '../ProposalDetails/selectors';
import {
   Divider,
   NavigationPaneItem,
   OfferNavigationPaneItem,
   ProposalNavigationPaneItem,
} from './components';
import {fetchCompleteness} from './reducer';
import {
   selectOfferId,
   selectProposalId,
   selectCompleteness,
   selectIsDocumentsItemDisabled,
   selectIsNeedsAndDesiresNotCompleted,
   selectNavigationStepsErrors,
   selectNavigationDuplicationStepsErrors,
} from './selectors';

import './styles.scss';

const STEP_KEYS = {
   [STEPS.OFFER]: 'offer',
   [STEPS.PROPOSAL]: 'proposal',
   [STEPS.NEEDS_AND_DESIRES]: 'needsAndDesires',
   [STEPS.POLICY_HOLDER]: 'policyHolder',
   [STEPS.INSURED]: 'insured',
   [STEPS.BENEFICIARIES]: 'beneficiaries',
   [STEPS.HEALTH_DECLARATION]: 'healthDeclaration',
   [STEPS.ADDITIONAL_INFORMATION]: 'additionalInformation',
   [STEPS.DOCUMENTS]: 'documents',
};

export const ERROR_STATUS = 'ERROR';
export const ERROR_STATUSES = [COMPLETENESS_STATUSES.REJECTED, ERROR_STATUS];

const completenessStepShape = oneOf(Object.values(COMPLETENESS_STATUSES));

export class NavigationPane extends PureComponent {
   static propTypes = {
      completeness: shape({
         offer: completenessStepShape,
         proposal: completenessStepShape,
         needsAndDesires: completenessStepShape,
         policyHolder: completenessStepShape,
         insured: completenessStepShape,
         beneficiaries: completenessStepShape,
         healthDeclaration: completenessStepShape,
         documents: completenessStepShape,
      }),
      offerId: number,
      proposalId: number,
      disabled: bool,
      disabledDocumentsItem: bool,
      clearData: func.isRequired,
   };

   componentWillUnmount() {
      this.props.clearData();
   }

   resolveStatus = (step) => {
      const {
         completeness,
         serverErrors,
         navigationStepsErrors,
         duplicationStepsErrors,
      } = this.props;
      const stepKey = STEP_KEYS[step];
      let error;
      const status = get(completeness, stepKey, null);

      if (!status) {
         return COMPLETENESS_STATUSES.DISABLED;
      }

      const isDone = COMPLETENESS_STATUSES[status] === COMPLETENESS_STATUSES.DONE;

      if (navigationStepsErrors.hasOwnProperty(stepKey)) {
         error = navigationStepsErrors[stepKey];
      } else {
         error = HANDLED_SERVER_ERRORS.some((errorType) => {
            const errors = serverErrors[errorType];
            if (!errors) {
               return false;
            }
            return errors.hasOwnProperty(stepKey) && !isEmpty(errors[stepKey]);
         });
      }
      const hasDuplicationErrors = duplicationStepsErrors.hasOwnProperty(stepKey);

      if (error && (!isDone || hasDuplicationErrors)) {
         return ERROR_STATUS;
      }

      return COMPLETENESS_STATUSES[status];
   };

   render() {
      const {offerId, proposalId, disabled, disabledDocumentsItem} = this.props;

      return (
         <div className="navigation-pane">
            <OfferNavigationPaneItem />
            <Divider disabled={!proposalId || !offerId} />
            <ProposalNavigationPaneItem />
            <div
               className={classNames('proposal-nav', {
                  collapsed: !proposalId,
               })}
            >
               <Divider disabled={!proposalId} />
               <NavigationPaneItem
                  title={
                     <FormattedMessage id="navigationPane.needsAndDesires" values={{br: <br />}} />
                  }
                  status={this.resolveStatus(STEPS.NEEDS_AND_DESIRES)}
                  step={STEPS.NEEDS_AND_DESIRES}
                  disabled={!proposalId}
                  data-ut-id={STEPS.NEEDS_AND_DESIRES}
               />
               <Divider disabled={disabled} />
               <NavigationPaneItem
                  title={<FormattedMessage id="misc.policyHolder" />}
                  status={this.resolveStatus(STEPS.POLICY_HOLDER)}
                  step={STEPS.POLICY_HOLDER}
                  disabled={disabled}
                  data-ut-id={STEPS.POLICY_HOLDER}
               />
               <Divider disabled={disabled} />
               <NavigationPaneItem
                  title={<FormattedMessage id="insured.title" />}
                  status={this.resolveStatus(STEPS.INSURED)}
                  step={STEPS.INSURED}
                  disabled={disabled}
                  data-ut-id={STEPS.INSURED}
               />
               <Divider disabled={disabled} />
               <NavigationPaneItem
                  title={<FormattedMessage id="beneficiaries.title" />}
                  status={this.resolveStatus(STEPS.BENEFICIARIES)}
                  step={STEPS.BENEFICIARIES}
                  disabled={disabled}
                  data-ut-id={STEPS.BENEFICIARIES}
               />
               <Divider disabled={disabled} />
               <NavigationPaneItem
                  title={<FormattedMessage id="healthDeclaration.healthDeclaration" />}
                  status={this.resolveStatus(STEPS.HEALTH_DECLARATION)}
                  step={STEPS.HEALTH_DECLARATION}
                  disabled={disabled}
                  data-ut-id={STEPS.HEALTH_DECLARATION}
               />
               <Divider disabled={disabled} />
               <NavigationPaneItem
                  title={
                     <FormattedMessage
                        id="additionalInformation.additionalInformation"
                        values={{br: <br />}}
                     />
                  }
                  status={this.resolveStatus(STEPS.ADDITIONAL_INFORMATION)}
                  step={STEPS.ADDITIONAL_INFORMATION}
                  disabled={disabled}
                  data-ut-id={STEPS.ADDITIONAL_INFORMATION}
               />
               <Divider disabled={disabledDocumentsItem} />
               <NavigationPaneItem
                  title={<FormattedMessage id="documents.title" />}
                  status={this.resolveStatus(STEPS.DOCUMENTS)}
                  step={STEPS.DOCUMENTS}
                  disabled={disabledDocumentsItem}
                  data-ut-id={STEPS.DOCUMENTS}
               />
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => ({
   completeness: selectCompleteness(state),
   offerId: selectOfferId(state),
   proposalId: selectProposalId(state),
   disabled: selectIsNeedsAndDesiresNotCompleted(state),
   disabledDocumentsItem: selectIsDocumentsItemDisabled(state),
   serverErrors: selectServerErrors(state),
   navigationStepsErrors: selectNavigationStepsErrors(state),
   duplicationStepsErrors: selectNavigationDuplicationStepsErrors(state),
});

export default connect(mapStateToProps, {
   clearData: fetchCompleteness.clear,
})(NavigationPane);
