import omit from 'lodash/omit';
import set from 'lodash/set';

import { SERVER_ERROR_TYPES } from '../../shared/constants';
import { createReducer } from '../../shared/reducers/createReducer';
import { Action, AsyncAction } from '../../shared/services/ActionHelpers';

export const containerId = 'ProposalDetails';
export const fetchMetadata = new AsyncAction(`${containerId}/METADATA_FETCH`);
export const convertToOffer = new AsyncAction(`${containerId}/TO_OFFER_CONVERT`);
export const changeProcessStatus = new Action(`${containerId}/PROCESS_STATUS_CHANGE`);
export const submitProposal = new AsyncAction(`${containerId}/PROPOSAL_SUBMIT`);
export const setServerErrors = new Action(`${containerId}/SERVER_ERRORS_SET`);
export const clearServerError = new Action(`${containerId}/SERVER_ERROR_CLEAR`);
export const clearHealthDeclarationServerError = new Action(
  `${containerId}/HEALTH_DECLARATION_SERVER_ERROR_CLEAR`
);

export const fetchPolicyPartyData = new AsyncAction(`${containerId}/POLICY_PARTY_DATA_FETCH`);
export const fetchFormData = new AsyncAction(`${containerId}/FORM_DATA_FETCH`);

const actionToHandlerMap = new Map([
  [
    changeProcessStatus.ACTION,
    (draft, { payload }) => {
      draft[fetchMetadata.type].data.status = payload;
    },
  ],
  [
    setServerErrors.ACTION,
    (draft, { payload }) => {
      draft[setServerErrors.type] = { ...draft[setServerErrors.type], ...payload };
    },
  ],
  [
    clearServerError.ACTION,
    (draft, { payload }) => {
      set(draft[setServerErrors.type], `${SERVER_ERROR_TYPES.VALIDATION_ERRORS}.${payload}`, false);
    },
  ],
  [
    clearHealthDeclarationServerError.ACTION,
    (draft, { payload }) => {
      if (
        !draft[setServerErrors.type]?.validationErrors?.insuredAdults[payload]?.healthDeclaration
      ) {
        return;
      }
      omit(
        draft[setServerErrors.type],
        `${SERVER_ERROR_TYPES.VALIDATION_ERRORS}.insuredAdults[${payload}].healthDeclaration`
      );
    },
  ],
]);

export default createReducer(
  [
    fetchMetadata,
    convertToOffer,
    submitProposal,
    setServerErrors,
    fetchPolicyPartyData,
    fetchFormData,
  ],
  null,
  actionToHandlerMap
);
