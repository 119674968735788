import React from 'react';
import InfoPanel from '../../InfoPanel';
import FormattedMessage from '../../FormattedMessage/FormattedMessage';

const PersonalEmailInfo = () => {
   return (
      <InfoPanel highlight>
         <FormattedMessage id="fields.email.emailSigningDeprecated" />
      </InfoPanel>
   );
};

export default PersonalEmailInfo;
