import {SelectProps} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {selectLocale, setLocale} from 'containers/App/appSlice';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {localizeReferences} from 'shared/references';
import {Locale} from 'v2/common/providers/LocalizationProvider';
import {getAvailableLocales} from 'v2/common/providers/LocalizationProvider/utils';
import {webStorage} from 'v2/common/services/WebStorage';

type LocaleSwitcherProps = {
   sx?: SelectProps['sx'];
   size?: SelectProps['size'];
};

const LocaleSwitcher = ({sx, size}: LocaleSwitcherProps) => {
   const dispatch = useDispatch();
   const currentLocale = useSelector(selectLocale);
   const handleLocaleChange = (event: SelectChangeEvent<Locale>) => {
      const locale = event.target.value as Locale;
      dispatch(setLocale(locale));
      dispatch(localizeReferences.action(locale));
      webStorage.setLocale(locale);
   };
   const locales = getAvailableLocales();
   return (
      <Select
         sx={{minWidth: 130, ...sx}}
         size={size}
         value={currentLocale}
         onChange={handleLocaleChange}
      >
         {locales.map((locale) => (
            <MenuItem key={locale} value={locale}>
               <FormattedMessage id={`references.locales.${locale}`} />
            </MenuItem>
         ))}
      </Select>
   );
};

export default LocaleSwitcher;
