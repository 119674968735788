import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {createTheme} from '@mui/material/styles';
import {colors} from 'common/styles';

const {delaBlue, red, yellow, green, grey} = colors;

const policyDetails = createTheme({
   palette: {
      mode: 'light',
      primary: delaBlue,
      error: red,
      warning: yellow,
      success: green,
      text: {primary: delaBlue.main, secondary: grey.main, disabled: grey.light},
      divider: delaBlue.main,
      action: {
         hover: delaBlue.light,
         hoverOpacity: 0.1,
         selected: delaBlue.light,
      },
   },
   typography: {
      htmlFontSize: 14,
      fontFamily: 'Open Sans, sans-serif',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      h1: {
         fontWeight: 'bold',
         fontSize: '1.714rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
         textTransform: 'none',
      },
      h2: {
         fontWeight: 'bold',
         fontSize: '1.429rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
         textTransform: 'none',
      },
      h3: {
         fontWeight: 'bold',
         fontSize: '1.143rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
         textTransform: 'none',
      },
      subtitle1: {
         fontWeight: 600,
         fontSize: '1.143rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
      },
      subtitle2: {
         fontWeight: 600,
         fontSize: '1rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
      },
      body1: {
         fontWeight: 'inherit',
         fontSize: '1.143rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
      },
      body2: {
         fontWeight: 'normal',
         fontSize: '1rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
      },
      button: {
         fontWeight: 'normal',
         fontSize: '1.071rem',
         lineHeight: 'normal',
         letterSpacing: 'normal',
      },
   },
   shape: {borderRadius: 8},
   components: {
      MuiScopedCssBaseline: {
         styleOverrides: {
            root: {
               '& a': {
                  color: 'inherit',
                  '&:hover': {
                     color: 'inherit',
                  },
               },
            },
         },
      },
      MuiAppBar: {
         styleOverrides: {
            colorPrimary: {
               backgroundColor: delaBlue[60],
            },
         },
      },
      MuiToolbar: {
         styleOverrides: {
            dense: {
               minHeight: '60px',
            },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               textTransform: 'none',
               borderRadius: '4px',
            },
         },
      },
      MuiTabs: {
         styleOverrides: {
            root: {
               backgroundColor: colors.delaBlue.light,
               borderRadius: '8px 8px 0 0',
            },
            indicator: {
               height: '5px',
            },
         },
      },
      MuiTab: {
         styleOverrides: {
            root: {
               textTransform: 'none',
            },
         },
      },
      MuiTooltip: {
         styleOverrides: {
            tooltip: {
               backgroundColor: colors.delaBlue['80'],
               maxWidth: '200px',
               fontWeight: 'normal',
               fontSize: '1rem',
            },
         },
         defaultProps: {
            enterTouchDelay: 0,
            leaveTouchDelay: 3000,
         },
      },
      MuiLink: {
         styleOverrides: {
            underlineHover: {
               textDecoration: 'underline',
               '&:hover': {
                  textDecoration: 'none',
               },
            },
         },
      },
      MuiRadio: {
         styleOverrides: {
            root: {
               padding: '5px',
               margin: '0 4px',
            },
         },
      },
      MuiSelect: {
         defaultProps: {
            IconComponent: KeyboardArrowDownRoundedIcon,
         },
      },
   },
});

export default policyDetails;
