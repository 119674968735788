import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FormattedMessage} from '../../../shared/components/FormattedMessage';
import ActionButton from '../ActionButton';
import './styles.scss';

export function AdultToRemoveDialog({remove, cancel, optionId}) {
   const [insuredAdultToRemove, setInsuredAdultToRemove] = useState(0);
   const handleChange = (ev) => setInsuredAdultToRemove(+ev.target.value);

   const firstOptionId = `${optionId}-1`;
   const secondOptionId = `${optionId}-2`;
   return (
      <div className="remove-dialog">
         <FormattedMessage id="calculatePremium.removeAdultInfo" />
         <div className="options">
            <input
               id={firstOptionId}
               type="radio"
               name="insuredAdultToRemove"
               value="0"
               checked={insuredAdultToRemove === 0}
               onChange={handleChange}
            />
            <label htmlFor={firstOptionId}>
               <FormattedMessage id="misc.insuredAdult" /> 1
            </label>
            <input
               id={secondOptionId}
               type="radio"
               name="insuredAdultToRemove"
               value="1"
               checked={insuredAdultToRemove === 1}
               onChange={handleChange}
            />
            <label htmlFor={secondOptionId}>
               <FormattedMessage id="misc.insuredAdult" /> 2
            </label>
         </div>
         <ActionButton className="action" onClick={() => remove(insuredAdultToRemove)}>
            <FormattedMessage id="calculatePremium.remove" />
         </ActionButton>
         <br />
         <ActionButton className="btn-flat" onClick={cancel}>
            <FormattedMessage id="calculatePremium.changeFrequency" />
         </ActionButton>
      </div>
   );
}

AdultToRemoveDialog.propTypes = {
   cancel: PropTypes.func,
   optionId: PropTypes.string,
   remove: PropTypes.func,
};
