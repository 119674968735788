import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {alpha, Theme} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {Shadows} from '@mui/material/styles/shadows';
import {delaBlue, orange, red, white} from 'common/styles/colors';
import React from 'react';
import {FormattedMessage} from 'react-intl';

let mainTheme = createTheme({
   palette: {
      primary: {main: delaBlue.main},
      secondary: {main: orange['100'], contrastText: white.main},
      background: {
         paper: delaBlue.light,
      },
      error: {main: red.main},
   },
   typography: {
      htmlFontSize: 14,
      fontFamily: ['Open Sans'].join(','),
      button: {
         textTransform: 'none',
      },
   },
   mixins: {
      toolbar: {
         minHeight: '60px',
      },
   },
   shadows: Array(25).fill('none') as Shadows,
   components: {
      MuiCssBaseline: {
         styleOverrides: {
            '& span': {
               lineHeight: 'inherit',
            },
         },
      },
      MuiAccordionSummary: {
         defaultProps: {
            expandIcon: <KeyboardArrowDownRoundedIcon />,
         },
      },
      MuiSelect: {
         defaultProps: {
            IconComponent: KeyboardArrowDownRoundedIcon,
         },
      },
      MuiAutocomplete: {
         defaultProps: {
            popupIcon: <KeyboardArrowDownRoundedIcon />,
            loadingText: <FormattedMessage id="misc.loading" />,
         },
      },
      MuiTooltip: {
         styleOverrides: {
            tooltip: {
               backgroundColor: delaBlue['80'],
               fontSize: '12px',
            },
         },
         defaultProps: {
            enterTouchDelay: 0,
            leaveTouchDelay: 3000,
         },
      },
      MuiOutlinedInput: {
         styleOverrides: {
            notchedOutline: {
               borderColor: delaBlue['60'],
            },
         },
      },
      MuiTabs: {styleOverrides: {indicator: {height: '4px'}}},
   },
});

mainTheme = createTheme(mainTheme, {
   palette: {
      text: {
         primary: mainTheme.palette.primary.main,
         secondary: alpha(mainTheme.palette.primary.main, 0.8),
         disabled: alpha(mainTheme.palette.primary.main, 0.26),
      },
      action: {
         active: alpha(mainTheme.palette.primary.main, 0.54),
         disabled: alpha(mainTheme.palette.primary.main, 0.26),
         disabledBackground: alpha(mainTheme.palette.primary.main, 0.12),
         focus: alpha(mainTheme.palette.primary.main, 0.12),
         hover: alpha(mainTheme.palette.primary.main, 0.04),
         selected: alpha(mainTheme.palette.primary.main, 0.08),
      },
   },
   components: {
      MuiDivider: {
         styleOverrides: {light: {backgroundColor: mainTheme.palette.primary.contrastText}},
      },
      MuiInputBase: {
         styleOverrides: {root: {backgroundColor: mainTheme.palette.background.default}},
      },
   },
} as Partial<Theme>);

export default mainTheme;
