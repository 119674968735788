import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { logIn, setGeneralError } from 'containers/App/reducer';
import { ENDPOINTS } from 'shared/constants';
import { SavedCalculatePremiumService } from 'shared/services/SavedCalculatePremium';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { queryClient } from 'v2/common/providers/QueryProvider';
import { webStorage } from 'v2/common/services/WebStorage';
import { transformAgentResponse } from 'v2/common/utils/apiTransformations';
import { Permission } from 'v2/features/permissions/types';

export interface IIntermed {
  name: string;
  id: number;
  address: {
    streetName: Nullable<string>;
    houseNumber: Nullable<string>;
    postalCode: Nullable<string>;
    municipality: Nullable<string>;
    boxNumber: Nullable<string>;
  };
  enterpriseNumber: string;
  email: string;
  vaultId: number;
  dent: boolean;
  accountManager: {
    email: string;
    mobileNumber: string;
    firstName: string;
    surname: string;
  };
  fsmaNumber: string;
  website: string;
  commercialName: string;
  legalForm: string;
  externalReferenceEnabled: boolean;
  telephoneNumber: string;
  faxNumber: Nullable<string>;
}

export enum ProducerAccountType {
  Own = 'OWN',
  SIAM = 'SIAM',
  LeadGenerator = 'LEADGENERATOR',
  Online = 'ONLINE',
}

export interface IProducerAccount {
  id: number;
  type: ProducerAccountType;
  number: number;
  linkedIntermed: null;
  permissions: Permission[];
  roles: string[];
  intermed: IIntermed;
  linkedLeadGenerator: Nullable<{ name: string }>;
  partyId: string;
  isActive: boolean;
}

export interface IAgent {
  id: number;
  type: string;
  tenantId: string;
  email: string;
  vaultId: number;
  mobileNumber: string;
  intermed: IIntermed;
  contactNumber: string;
  activeDirectoryUserId: string;
  firstName: string;
  surname: string;
  contactLanguage: string;
  residentialAddress: {
    type: string;
    country: Nullable<string>;
    municipality: Nullable<string>;
    postalCode: Nullable<string>;
    suffix: Nullable<string>;
    streetName: Nullable<string>;
    boxNumber: Nullable<string>;
    houseNumber: Nullable<string>;
  };
  agentCompany: {
    name: string;
    enterpriseNumber: string;
    fsmaNumber: string;
    commercialName: Nullable<string>;
    legalForm: string;
  };
  dateOfBirth: string;
  producerAccountNumbers: IProducerAccount[];
  isActive: boolean;
}

export type ProducerAccount = {
  value: string;
  label: string;
  type: ProducerAccountType;
  permissions: Permission[];
};

export function useAgent() {
  const dispatch = useDispatch();
  const axios = useAxios();

  return useQuery<ReturnType<typeof transformAgentResponse>, AxiosError>(
    'agent',
    async () => {
      await axios.post(ENDPOINTS.b2cVerify as string);
      const { data } = await axios.get<IAgent>(ENDPOINTS.agentNumbers);
      const agent = transformAgentResponse(data);
      if (!agent.isActive || agent.producerAccounts.length === 0) {
        const error = {
          response: { status: 403 },
        };

        return Promise.reject(error);
      }
      return agent;
    },
    {
      staleTime: Number.POSITIVE_INFINITY,
      onSuccess: (data) => {
        const { producerAccounts } = data;
        const calculationData: unknown = SavedCalculatePremiumService.getData();
        if (!calculationData) {
          const storedNumber = webStorage.getProducerAccountNumber();
          const producerAccount = producerAccounts.find(
            (account) => account.value === storedNumber
          );
          if (producerAccount) {
            dispatch(logIn.request(producerAccount));
          }
        }

        if (producerAccounts.length === 1) {
          const [account] = producerAccounts;
          dispatch(logIn.request(account));
        }
      },
      onError: (err) => {
        dispatch(logIn.clear());
        const status = err.response?.status;
        if (status === 401 || status === 403) {
          return;
        }
        dispatch(
          setGeneralError.action({
            status: err.response?.status,
            errors: [err.response?.statusText],
          })
        );
      },
    }
  );
}

export function getAgentDataCache() {
  return queryClient.getQueryData<ReturnType<typeof transformAgentResponse>>('agent');
}
