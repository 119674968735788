import {policyProductName, policyStatus} from 'common/enums';
import {PolicyMetadata} from 'v2/common/api/queries/policyDetails/metadata';

export function createPolicyMetadata(data?: Partial<PolicyMetadata>) {
   return {
      number: '1',
      policyStatus: policyStatus.ACTIVE,
      policyHolderFirstName: 'Test',
      policyHolderSurname: 'Test',
      productName: policyProductName.UZPN,
      beginDate: '18/09/2020',
      ...data,
   };
}
