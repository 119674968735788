import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAgent } from 'v2/common/api/queries/agent';
import { Routes, useNavigate } from 'v2/common/hooks/router';
import { queryClient } from 'v2/common/providers/QueryProvider';
import { webStorage } from 'v2/common/services/WebStorage';
import { closeDrawer } from 'v2/features/app-drawer/appDrawerSlice';
import {
  selectProducerAccountLabel,
  setProducerAccount,
} from 'v2/pages/Login/producerAccountSlice';

export function ProducerAccountInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useAgent();
  const producerAccount = useSelector(selectProducerAccountLabel);
  if (!producerAccount) {
    return null;
  }
  const { name, type } = producerAccount;
  const hasOtherProducerAccounts = data ? data.producerAccounts.length > 1 : false;
  const changeProducerAccount = () => {
    webStorage.removeProducerAccountNumber();
    dispatch(setProducerAccount(null));
    dispatch(closeDrawer());
    navigate(Routes.root);
    queryClient.clear();
  };

  return (
    <Box display="flex" alignItems="center" marginBottom={3}>
      <AccountCircleRoundedIcon sx={{ marginRight: 1 }} fontSize="large" />
      <Typography sx={{ flexGrow: 1 }} variant="caption">
        {name}
        {type && (
          <>
            <br />
            <FormattedMessage id={`accountTypes.${type}`} />
          </>
        )}
      </Typography>
      {hasOtherProducerAccounts && (
        <Button variant="outlined" onClick={changeProducerAccount}>
          <FormattedMessage id="misc.change" />
        </Button>
      )}
    </Box>
  );
}
