import {
   DelayMode,
   DefaultRequestBody,
   ResponseResolver,
   RequestParams,
   RestContext,
   RestRequest,
} from 'msw';

export type Resolver<
   RequestBodyType extends DefaultRequestBody = DefaultRequestBody,
   ResponseBody extends DefaultRequestBody = any,
   Params extends RequestParams = RequestParams
> = ResponseResolver<RestRequest<RequestBodyType, Params>, RestContext, ResponseBody>;

export type ResolverOptions = Partial<{
   once: boolean;
   delay: number | DelayMode;
   statusCode: number;
}>;

export const jsonResolver = <DataType = any>(
   data: DataType,
   options?: ResolverOptions
): Resolver => (req, res, context) => {
   const {once = false, delay = undefined, statusCode = 200} = options || {};
   const response = once ? res.once : res;
   return response(context.delay(delay), context.status(statusCode), context.json<DataType>(data));
};

export const fileResolver = (file: RequestInfo, options?: ResolverOptions): Resolver => async (
   req,
   res,
   context
) => {
   const {once = false, delay = undefined, statusCode = 200} = options || {};
   const response = once ? res.once : res;
   const blob = await fetch(file);
   const blobBuffer = await blob.arrayBuffer();
   return response(
      context.delay(delay),
      context.status(statusCode),
      context.set('Content-Length', blobBuffer.byteLength.toString()),
      context.set('Content-Type', 'application/pdf'),
      context.body(blobBuffer)
   );
};

export const voidResolver = (options?: ResolverOptions): Resolver => (req, res, context) => {
   const {once = false, delay = undefined, statusCode = 200} = options || {};
   const response = once ? res.once : res;
   return response(context.delay(delay), context.status(statusCode));
};

export const networkErrorResolver = (): Resolver => (req, res) => {
   return res.networkError('Network error');
};
