import {Locale} from 'v2/common/providers/LocalizationProvider';

class WebStorage {
   static localeKey = 'locale';
   static producerAccountNumberKey = 'agentNumber';
   // used by auto QA
   static showAllLocalesKey = '_showAllLocales';

   private localStorage: Storage;
   private sessionStorage: Storage;

   constructor(localStorage: Storage, sessionStorage: Storage) {
      this.localStorage = localStorage;
      this.sessionStorage = sessionStorage;
   }

   getLocale(): Nullable<Locale> {
      return this.localStorage.getItem(WebStorage.localeKey) as Locale;
   }

   setLocale(value: Locale) {
      this.localStorage.setItem(WebStorage.localeKey, value);
   }

   getShowAllLocales() {
      return this.localStorage.getItem(WebStorage.showAllLocalesKey);
   }

   getProducerAccountNumber() {
      return this.sessionStorage.getItem(WebStorage.producerAccountNumberKey);
   }

   setProducerAccountNumber(value: string) {
      this.sessionStorage.setItem(WebStorage.producerAccountNumberKey, value);
   }

   removeProducerAccountNumber() {
      return this.sessionStorage.removeItem(WebStorage.producerAccountNumberKey);
   }
}

export const webStorage = new WebStorage(localStorage, sessionStorage);
