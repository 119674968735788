import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { ProducerAccount, ProducerAccountType } from 'v2/common/api/queries/agent';

type SliceState = Nullable<ProducerAccount>;

const producerAccountSlice = createSlice({
  name: 'producerAccount',
  initialState: null as SliceState,
  reducers: {
    setProducerAccount: (state, action: PayloadAction<SliceState>) => action.payload,
  },
});

export const selectProducerAccount = (state: RootState) => state.producerAccount;
export const selectProducerAccountLabel = ({ producerAccount }: RootState) => {
  if (!producerAccount) {
    return null;
  }
  return {
    name: `${producerAccount.label} (${producerAccount.value})`,
    type: producerAccount.type !== ProducerAccountType.Own ? producerAccount.type : null,
  };
};

export const { setProducerAccount } = producerAccountSlice.actions;
export default producerAccountSlice;
