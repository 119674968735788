import isEmpty from 'lodash/isEmpty';

import { PORTFOLIO_COLUMNS_MAP, Table } from '../../../../../shared/components/Table';
import { PORTFOLIO_TYPES } from '../../../../../shared/constants';
import { usePortfolio } from '../../../../common/api/queries/portfolio';
import { useInitialPortfolioType } from '../../../Portfolio/hooks';
import { Widget } from '../Widget';

import { PortfolioHeader } from './PortfolioHeader';
import { PortfolioNoData } from './PortfolioNoData';

type PortfolioProps = {
  goToCalculator: () => void;
  goToPortfolio: () => void;
};

export const Portfolio = ({ goToCalculator, goToPortfolio }: PortfolioProps) => {
  const initialPortfolioType = useInitialPortfolioType();
  const { data, isFetching } = usePortfolio({
    type: initialPortfolioType,
  });

  return (
    <Widget
      classPrefix="portfolio"
      fetching={isFetching}
      header={<PortfolioHeader goToPortfolio={goToPortfolio} />}
    >
      {!isEmpty(data?.items) ? (
        <Table
          data={data?.items.slice(0, 10)}
          columns={PORTFOLIO_COLUMNS_MAP[PORTFOLIO_TYPES.ALL]}
        />
      ) : (
        <PortfolioNoData goToCalculator={goToCalculator} />
      )}
    </Widget>
  );
};
