import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import moment from 'moment';
import {
  formValueSelector,
  getFormAsyncErrors,
  getFormMeta,
  getFormSyncErrors,
  isAsyncValidating,
  isSubmitting,
} from 'redux-form';

import {
  DATE_FORMAT,
  PAYMENT_FREQUENCY,
  PAYMENT_METHODS,
  PRODUCT_VERSIONS,
  SECTIONS,
  SIMPLE_ANSWER,
} from '../../../shared/constants';
import {
  selectActionData,
  selectAsyncActionData,
  selectIsInProgressStatus,
} from '../../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../../shared/reducers/selectors';
import { selectPaymentFrequencies as selectPaymentFrequenciesReferences } from '../../../shared/references/selectors';
import {
  adaptInsuredPersonalInfo,
  adaptPolicyHolderPersonalInfo,
  disableOneTimeOnlyOption,
  filterPaymentFrequencies,
  showEmailsEqualityNotification,
  showMobileEqualityNotification,
} from '../../../shared/services/utils';
import {
  getPolicyBeginDate,
  isIbanBelgium,
  isOldForPeriodicPayment,
} from '../../../shared/services/validators';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { getAgentDataCache } from '../../../v2/common/api/queries/agent';
import {
  getCurrentState as getFormWrapperState,
  setFormSavingAction,
} from '../../FormWrapper/reducer';
import { fetchInsuredAdults } from '../Insured/reducer';
import { getCurrentState as getInsuredState } from '../Insured/selectors';
import {
  selectIsInitialFormDataFetched,
  selectIsMetadataBlocked,
  selectMetadata,
  selectPaymentFrequencyDisabled,
} from '../selectors';

import { containerId, fetchPolicyHolder, savePolicyHolder } from './reducer';

export const SEPA_MANDATE = 'SEPA_MANDATE';
export const BANK_TRANSFER = 'BANK_TRANSFER';
export const POSTALCODE_FIELD_NAME = 'policyHolder.residentialAddress.postalCode';
export const IBAN_FIELD_NAME = 'policyHolder.financialInformation.iban';
export const BIC_FIELD_NAME = 'policyHolder.financialInformation.bic';

const mobileNumberPath = 'policyHolder.contactInformation.mobileNumber';
const landlineNumberPath = 'policyHolder.contactInformation.landlineNumber';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = formValueSelector(containerId);
const selectFormErrorsValues = getFormSyncErrors(containerId);
const selectAsyncFormErrorsValues = getFormAsyncErrors(containerId);
const selectFormMeta = getFormMeta(containerId);

export const selectFormErrors = (state) => selectFormErrorsValues(state);

export const selectPaymentFrequencyFormValue = (state) =>
  selectFormValues(state, 'paymentFrequency');

export const selectLandlineNumberFormValue = (state) => selectFormValues(state, landlineNumberPath);

export const selectMobileNumberFormValue = (state) => selectFormValues(state, mobileNumberPath);

export const selectLandlineNumberCodeFormValue = (state) =>
  selectFormValues(state, `${landlineNumberPath}Code`);

export const selectMobileNumberCodeFormValue = (state) =>
  selectFormValues(state, `${mobileNumberPath}Code`);

export const selectPaymentMethodFormValue = (state) =>
  selectFormValues(state, 'policyHolder.financialInformation.paymentMethod');

export const selectIbanFormValue = (state) => selectFormValues(state, IBAN_FIELD_NAME);

export const selectIndexationFormValue = (state) => selectFormValues(state, 'indexation');

export const selectPostalCodeFormValue = (state) => selectFormValues(state, POSTALCODE_FIELD_NAME);

export const selectMunicipalityFormValue = (state) =>
  selectFormValues(state, 'policyHolder.residentialAddress.municipality');

export const selectStreetNameFormValue = (state) =>
  selectFormValues(state, 'policyHolder.residentialAddress.streetName');

export const selectEnabledEmailForDocumentReceivingFormValue = (state) =>
  selectFormValues(
    state,
    'policyHolder.contactInformation.enabledEmailAddressForDocumentReceiving'
  );

export const selectPolicyHolderFormValues = (state) => selectFormValues(state, 'policyHolder');

export const selectParticipatingAsInsuredAdultFormValues = (state) =>
  selectFormValues(state, 'policyHolder.participatingAsInsuredAdult');

export const selectBirthDateFormValues = (state) =>
  selectFormValues(state, 'policyHolder.personalInformation.birthDate');

export const selectIsSavingFormInProgress = (state) =>
  selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsLoading = createSelector(
  [selectIsInitialFormDataFetched, selectIsSavingFormInProgress],
  (isFetched, isSavingFormInProgress) => !isFetched && !isSavingFormInProgress
);

export const selectIsSaving = (state) =>
  selectIsInProgressStatus(getCurrentState(state), savePolicyHolder.type);

export const selectPolicyHolder = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchPolicyHolder.type);

export const selectIsPolicyHolderAsInsuredAdult = createSelector(
  [selectPolicyHolder],
  (policyHolder) => get(policyHolder, 'participatingAsInsuredAdult') === SIMPLE_ANSWER.YES
);

export const selectIsOneAdult = (state) => get(selectMetadata(state), 'oneAdult');
export const selectIsInsuredChildrenPresent = (state) =>
  get(selectMetadata(state), 'insuredChildrenPresent');

export const selectIndexation = (state) => get(selectMetadata(state), 'indexation', null);
export const selectPaymentFrequency = (state) =>
  get(selectMetadata(state), 'paymentFrequency', null);

export const selectIsPaymentFrequencyDisabled = createSelector(
  [
    selectPaymentFrequencyDisabled,
    selectParticipatingAsInsuredAdultFormValues,
    selectBirthDateFormValues,
  ],
  (paymentFrequencyDisabled, participatingAsInsuredAdult, birthDate) => {
    if (participatingAsInsuredAdult === SIMPLE_ANSWER.NO) {
      return paymentFrequencyDisabled;
    }

    return !!isOldForPeriodicPayment(birthDate);
  }
);

export const selectIndexationValue = createSelector(
  [selectIndexationFormValue, selectIndexation],
  (indexationFormValue, indexation) => {
    if (!isNil(indexationFormValue)) {
      return indexationFormValue;
    }
    return indexation;
  }
);

export const selectIsBicFieldDisabled = createSelector(
  selectIbanFormValue,
  (iban) => !iban || isIbanBelgium(iban)
);

export const selectIsIbanNonBelgium = createSelector(
  selectIbanFormValue,
  (iban) => iban && !isIbanBelgium(iban)
);

export const makeSelectIsOneTimeOnly = () =>
  createSelector(
    selectPaymentFrequencyFormValue,
    (paymentFrequency) => paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY
  );

export const makeSelectIsMonthly = () =>
  createSelector(
    selectPaymentFrequencyFormValue,
    (paymentFrequency) => paymentFrequency === PAYMENT_FREQUENCY.MONTHLY
  );

export const selectPaymentFrequencies = createSelector(
  [selectMetadata, selectPaymentFrequenciesReferences],
  (metadata, paymentFrequencies) => {
    if (!metadata) {
      return;
    }
    const { productVersion, oneAdult, insuredChildrenPresent } = metadata;

    if (productVersion === PRODUCT_VERSIONS.V_2020_01) {
      paymentFrequencies = paymentFrequencies.filter((element) => filterPaymentFrequencies(element));
    }

    if (!oneAdult || insuredChildrenPresent) {
      paymentFrequencies = paymentFrequencies.map((element) => disableOneTimeOnlyOption(element));
    }

    return paymentFrequencies;
  }
);

export const selectInsuredAdults = (state) =>
  selectAsyncActionData(getInsuredState(state), fetchInsuredAdults.type);

export const makeSelectInitialValues = () =>
  createSelector(
    [
      selectMetadata,
      selectPolicyHolder,
      selectIndexation,
      selectPaymentFrequency,
      selectInsuredAdults,
      selectPaymentFrequencyFormValue,
    ],
    (
      metadata,
      policyHolder,
      indexation,
      paymentFrequency,
      insuredAdults,
      currentPaymentFrequency
    ) => {
      if (!metadata) {
        return;
      }
      const { productVersion } = metadata;
      const payUntilAge = get(insuredAdults, 'firstInsuredAdult.payUntilAge');
      const initialValues = {
        policyHolder,
        paymentFrequency,
      };

      if (currentPaymentFrequency && currentPaymentFrequency !== PAYMENT_FREQUENCY.ONE_TIME_ONLY) {
        initialValues.firstInsuredAdult = { payUntilAge };
      }

      return productVersion === PRODUCT_VERSIONS.V_2020_01
        ? initialValues
        : { ...initialValues, indexation };
    }
  );

export const makeSelectIsPayUntilRequired = () =>
  createSelector(
    [
      selectPaymentFrequency,
      selectPaymentFrequencyFormValue,
      selectBirthDateFormValues,
      selectInsuredAdults,
      selectParticipatingAsInsuredAdultFormValues,
    ],
    (
      initialPaymentFrequency,
      currentPaymentFrequency,
      birthDate,
      insuredAdults,
      participatingAsInsuredAdult
    ) => {
      const payUntilAge = get(insuredAdults, 'firstInsuredAdult.payUntilAge');
      const policyBeginYear = getPolicyBeginDate().year();
      const endOfPaymentYear = moment(birthDate, DATE_FORMAT).add(payUntilAge, 'years').year();
      const isCurrentPaymentFrequencyOneTime =
        currentPaymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;
      const isInitialPaymentFrequencyOneTime =
        initialPaymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;

      return (
        (isInitialPaymentFrequencyOneTime && !isCurrentPaymentFrequencyOneTime) ||
        (!isCurrentPaymentFrequencyOneTime &&
          endOfPaymentYear <= policyBeginYear &&
          participatingAsInsuredAdult === SIMPLE_ANSWER.YES)
      );
    }
  );

export const selectIsPostalCodeValid = createSelector(
  [selectPostalCodeFormValue, selectAsyncFormErrorsValues],
  (postalCode, formAsyncErrors) =>
    postalCode
      ? postalCode.length === 4 && !get(formAsyncErrors, POSTALCODE_FIELD_NAME, false)
      : false
);

const selectIsFieldTouched = (fieldPath) =>
  createSelector(selectFormMeta, (meta) => get(meta, `${fieldPath}.touched`, false));

export const selectMobilePhoneNumberError = createSelector(
  [selectFormErrors, selectIsFieldTouched(mobileNumberPath)],
  (formErrors, isTouched) => isTouched && get(formErrors, mobileNumberPath, false)
);

export const selectLandlinePhoneNumberError = createSelector(
  [selectFormErrors, selectIsFieldTouched(landlineNumberPath)],
  (formErrors, isTouched) => isTouched && get(formErrors, landlineNumberPath, false)
);

export const selectIsMunicipalityDisabled = createSelector(
  [selectIsMetadataBlocked, selectIsPostalCodeValid],
  (blocked, isPostalCodeValid) => blocked || !isPostalCodeValid
);

export const selectIsStreetNameDisabled = createSelector(
  [selectIsMunicipalityDisabled, selectMunicipalityFormValue],
  (isMunicipalityDisabled, selectedMunicipality) => isMunicipalityDisabled || !selectedMunicipality
);

export const selectIsHouseNumberDisabled = createSelector(
  [selectIsMunicipalityDisabled, selectStreetNameFormValue],
  (isMunicipalityDisabled, selectedStreetName) => isMunicipalityDisabled || !selectedStreetName
);

export const makeSelectIsFirstInsuredAdultPayUntilValid = () =>
  createSelector(
    [selectFormErrors],
    (formErrors) => !get(formErrors, 'firstInsuredAdult.payUntilAge', false)
  );

export const selectIsSepa = createSelector(
  [selectPaymentMethodFormValue],
  (paymentMethod) => paymentMethod && paymentMethod === PAYMENT_METHODS.SEPA_MANDATE
);

export const selectIsAsyncValidating = (state) => isAsyncValidating(containerId)(state);
export const selectIsSubmitting = (state) => isSubmitting(containerId)(state);

export const selectIsSavingButtonDisabled = createSelector(
  [selectIsSaving, selectIsAsyncValidating, selectIsSubmitting],
  (isSaving, isAsyncValidatingStatus, isSubmittingStatus) =>
    isSubmittingStatus || isSaving || !!isAsyncValidatingStatus
);

export const selectInsuredAdultsNationalRegisterNumbers = createSelector(
  selectInsuredAdults,
  (insuredAdults) => {
    const firstInsuredAdultNationalRegisterNumber = get(
      insuredAdults,
      'firstInsuredAdult.personalInformation.nationalRegisterNumber',
      false
    );
    const secondInsuredAdultNationalRegisterNumber = get(
      insuredAdults,
      'secondInsuredAdult.personalInformation.nationalRegisterNumber',
      false
    );

    return { firstInsuredAdultNationalRegisterNumber, secondInsuredAdultNationalRegisterNumber };
  }
);

export const selectIsEmailNotificationShown = createSelector(
  [selectPolicyHolderFormValues, selectInsuredAdults],
  (policyHolder, insuredAdults) => {
    const currentEmail = get(policyHolder, 'contactInformation.email');
    if (!currentEmail) {
      return false;
    }

    const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);

    const firstInsuredObject = adaptInsuredPersonalInfo(
      get(insuredAdults, SECTIONS.FIRST_INSURED_ADULT)
    );
    const secondInsuredObject = adaptInsuredPersonalInfo(
      get(insuredAdults, SECTIONS.SECOND_INSURED_ADULT)
    );

    const participatingAsInsuredAdult = get(policyHolder, 'participatingAsInsuredAdult');

    return showEmailsEqualityNotification(
      policyHolderObject,
      [firstInsuredObject, secondInsuredObject],
      participatingAsInsuredAdult
    );
  }
);

export const selectIsMobileNumberNotificationShown = createSelector(
  [selectPolicyHolderFormValues, selectInsuredAdults],
  (policyHolder, insuredAdults) => {
    const mobileNumber = get(policyHolder, 'contactInformation.mobileNumber');

    if (!mobileNumber) {
      return false;
    }

    const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);

    const firstInsuredObject = adaptInsuredPersonalInfo(
      get(insuredAdults, SECTIONS.FIRST_INSURED_ADULT)
    );
    const secondInsuredObject = adaptInsuredPersonalInfo(
      get(insuredAdults, SECTIONS.SECOND_INSURED_ADULT)
    );
    const { personalInformation, contactInformation } = getAgentDataCache();
    const agentPrivateInfo = { ...personalInformation, ...contactInformation };

    return showMobileEqualityNotification(policyHolderObject, [
      firstInsuredObject,
      secondInsuredObject,
      agentPrivateInfo,
    ]);
  }
);

export const selectHasResidentialAddressSaveError = createSelector(
  selectFormErrors,
  (formErrors) => {
    const residentialAddressError = get(formErrors, 'policyHolder.residentialAddress.streetName');

    return residentialAddressError?.id === 'invalidResidentialAddress';
  }
);
