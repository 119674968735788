import {createSelector} from '@reduxjs/toolkit';
import moment from 'moment';
import {DATE_FORMAT_WITH_TIME} from '../../shared/constants';
import {
   selectActionData,
   selectIsFailedStatus,
   selectIsInProgressStatus,
} from '../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../shared/reducers/selectors';
import {selectShouldShowPopover} from '../PopoverContainer/selectors';
import {
   containerId,
   INCREASE_IN_PREMIUM_ID,
   logIn,
   setGeneralError,
   startPostponedProcess,
} from './reducer';

export const getCurrentState = getStateByContainerId(containerId);

export const selectServerError = (state) =>
   selectActionData(getCurrentState(state), setGeneralError.type);

export const selectTransformServerError = createSelector(selectServerError, (serverErrors) => {
   if (!serverErrors) {
      return null;
   }

   const {status, errors, userTime, userAgent} = serverErrors;

   return {
      status,
      errors,
      userAgent,
      userTime: moment(userTime).format(DATE_FORMAT_WITH_TIME),
   };
});

export const selectIsLoginInProgress = (state) =>
   selectIsInProgressStatus(getCurrentState(state), logIn.type);
export const selectIsPostponedProcessFailed = (state) =>
   selectIsFailedStatus(getCurrentState(state), startPostponedProcess.type);

export const selectShowIncreaseInPremiumPrompt = (state) => {
   const isDecember = new Date(Date.now()).getMonth() === 11;
   if (!isDecember) {
      return false;
   }
   return selectShouldShowPopover(state, INCREASE_IN_PREMIUM_ID);
};
