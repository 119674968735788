import get from 'lodash/get';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {api} from '../../../shared/services';
import {setGeneralError} from '../../App/reducer';
import {selectActiveFormValues} from '../../FormWrapper/selectors';
import {selectIsPolicyPartyDataFetched, selectProposalId} from '../selectors';
import {
   fetchNeedsAndDesires,
   NEEDS_AND_DESIRES_SECOND_VERSION,
   saveNeedsAndDesires,
} from './reducer';
import {fetchFormData, fetchPolicyPartyData} from '../reducer';
import {takeAction} from '../../../shared/services/sagaHelpers';
import {INSURED_PRODUCT_BASIS_TYPES} from '../../../shared/constants';

export const createFetchNeedsAndDesiresSaga = (takeActionHandler) =>
   function* ({payload}) {
      try {
         let id = payload;
         if (!id) {
            id = yield select(selectProposalId);
         }

         yield put(fetchFormData.request(id));
         const result = yield takeActionHandler([fetchFormData, fetchNeedsAndDesires]);

         if (result.type !== fetchFormData.SUCCESS) {
            yield put(fetchNeedsAndDesires.failure());
            return;
         }

         const data = yield call(api.proposal.fetchNeedsAndDesires, id);

         const version = get(data, 'version');
         const insuredProductBasis = get(data, 'advice.insuredProductBasis');

         if (
            version === NEEDS_AND_DESIRES_SECOND_VERSION &&
            insuredProductBasis ===
               INSURED_PRODUCT_BASIS_TYPES.PREVIOUS_ANALYSIS_REGARDING_LIFE_INSURANCE
         ) {
            data.advice.insuredProductBasis = null;
         }

         yield put(fetchNeedsAndDesires.success(data));
      } catch (error) {
         yield put(fetchNeedsAndDesires.failure(error));
         yield put(setGeneralError.action(error));
      }
   };

export const fetchNeedsAndDesiresSaga = createFetchNeedsAndDesiresSaga(takeAction);

export function* saveNeedsAndDesiresSaga() {
   const proposalId = yield select(selectProposalId);
   const data = yield select(selectActiveFormValues);

   try {
      yield call(api.proposal.patchNeedsAndDesires, {
         id: proposalId,
         data,
      });
      yield put(saveNeedsAndDesires.success());

      const isPolicyPartyDataFetched = yield select(selectIsPolicyPartyDataFetched);

      if (isPolicyPartyDataFetched) {
         return;
      }

      yield put(fetchPolicyPartyData.request(proposalId));
   } catch (error) {
      yield put(saveNeedsAndDesires.failure(error));
      yield put(setGeneralError.action(error));
   }
}

export default function* needsAndDesiresSagas() {
   yield all([
      takeLatest(fetchNeedsAndDesires.REQUEST, fetchNeedsAndDesiresSaga),
      takeLatest(saveNeedsAndDesires.REQUEST, saveNeedsAndDesiresSaga),
   ]);
}
