import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';

import { useStatistics } from 'v2/common/api/queries/homepage';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

import OverflowTooltip from '../../../../../shared/components/OverflowTooltip';
import { Widget } from '../Widget';

type StatisticCardProps = {
  data: number;
  type: 'offer' | 'proposal';
  fetching: boolean;
};

export const StatisticCard = ({ data, type, fetching }: StatisticCardProps) => (
  <Widget classPrefix="statistics" fetching={fetching}>
    <div className="statistics-figures">
      <OverflowTooltip>{data}</OverflowTooltip>
    </div>
    <div className="statistics-text">
      <FormattedMessage id={`portfolio.${type}.label`} />
      <br />
      <FormattedMessage id="homepage.created" />
    </div>
  </Widget>
);

export const Statistics = () => {
  const hasOfferPermissions = usePermissions(Permission.OfferList);
  const hasProposalPermissions = usePermissions(Permission.ProposalList);
  const { data, isLoading } = useStatistics({
    enabled: hasOfferPermissions || hasProposalPermissions,
  });
  const offersCount = get(data, 'offersCount', 0) as number;
  const proposalsCount = get(data, 'proposalsCount', 0) as number;

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        {hasOfferPermissions && (
          <StatisticCard data={offersCount} type="offer" fetching={isLoading} />
        )}
      </Grid>
      <Grid item xs>
        {hasProposalPermissions && (
          <StatisticCard data={proposalsCount} type="proposal" fetching={isLoading} />
        )}
      </Grid>
    </Grid>
  );
};
