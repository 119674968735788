import {PolicyUpdatesResponse} from '../../common/api/queries/policyUpdates';

export const policyUpdatesData: PolicyUpdatesResponse[] = [
   {
      product: 'Modification police existante Hooghenraed',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance Hooghenraed/Formulaire de modification Hooghenraed.pdf',
      name: 'Formulaire de modification Hooghenraed.pdf',
      index: 1,
   },
   {
      product: 'Modification police existante Hooghenraed',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existance Hooghenraed/Modification preneur d'assurance.pdf",
      name: "Modification preneur d'assurance.pdf",
      index: 2,
   },
   {
      product: 'Modification police existante Hooghenraed',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance Hooghenraed/Modification bénéficiaires.pdf',
      name: 'Modification bénéficiaires.pdf',
      index: 3,
   },
   {
      product: 'Modification police existante Hooghenraed',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance Hooghenraed/Ordre de domiciliation.pdf',
      name: 'Ordre de domiciliation.pdf',
      index: 4,
   },
   {
      product: 'Modification police existante Hooghenraed',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance Hooghenraed/Formulaires de modification - set complet.pdf',
      name: 'Formulaires de modification - set complet.pdf',
      index: 5,
   },
   {
      product: 'Wijziging bestaande polis Hooghenraed',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Hooghenraed/Wijzigingsformulier Hooghenraed.pdf',
      name: 'Wijzigingsformulier Hooghenraed.pdf',
      index: 1,
   },
   {
      product: 'Wijziging bestaande polis Hooghenraed',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Hooghenraed/Wijziging verzekeringsnemer.pdf',
      name: 'Wijziging verzekeringsnemer.pdf',
      index: 2,
   },
   {
      product: 'Wijziging bestaande polis Hooghenraed',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Hooghenraed/Aanduiding nieuwe begunstigden.pdf',
      name: 'Aanduiding nieuwe begunstigden.pdf',
      index: 3,
   },
   {
      product: 'Wijziging bestaande polis Hooghenraed',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Hooghenraed/Domiciliëringsopdracht Hooghenraed.pdf',
      name: 'Domiciliëringsopdracht Hooghenraed.pdf',
      index: 4,
   },
   {
      product: 'Wijziging bestaande polis Hooghenraed',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Hooghenraed/Wijzigingsformulieren Hooghenraed - volledige set.pdf',
      name: 'Wijzigingsformulieren Hooghenraed - volledige set.pdf',
      index: 5,
   },
   {
      product: 'Modification police existante Lilas',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance Lilas/Formulaire de modification Lilas.pdf',
      name: 'Formulaire de modification Lilas.pdf',
      index: 6,
   },
   {
      product: 'Modification police existante Lilas',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance Lilas/Modification bénéficiaires.pdf',
      name: 'Modification bénéficiaires.pdf',
      index: 7,
   },
   {
      product: 'Modification police existante Lilas',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existance Lilas/Modification preneur d'assurance.pdf",
      name: "Modification preneur d'assurance.pdf",
      index: 8,
   },
   {
      product: 'Modification police existante Lilas',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance Lilas/Ordre de domiciliation.pdf',
      name: 'Ordre de domiciliation.pdf',
      index: 9,
   },
   {
      product: 'Modification police existante Lilas',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance Lilas/Conversion en cas de Vie.pdf',
      name: 'Conversion en cas de Vie.pdf',
      index: 10,
   },
   {
      product: 'Wijziging bestaande polis Lilas',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Lilas/Wijzigingsformulier Lilas.pdf',
      name: 'Wijzigingsformulier Lilas.pdf',
      index: 6,
   },
   {
      product: 'Wijziging bestaande polis Lilas',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Lilas/Aanduiding nieuwe begunstigden.pdf',
      name: 'Aanduiding nieuwe begunstigden.pdf',
      index: 7,
   },
   {
      product: 'Wijziging bestaande polis Lilas',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Lilas/Wijziging verzekeringsnemer.pdf',
      name: 'Wijziging verzekeringsnemer.pdf',
      index: 8,
   },
   {
      product: 'Wijziging bestaande polis Lilas',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Lilas/Domiciliëringsopdracht Lilas.pdf',
      name: 'Domiciliëringsopdracht Lilas.pdf',
      index: 9,
   },
   {
      product: 'Wijziging bestaande polis Lilas',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis Lilas/Omzetting bij Leven.pdf',
      name: 'Omzetting bij Leven.pdf',
      index: 10,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance - nature/Formulaire de modification - nature.pdf',
      name: 'Formulaire de modification - nature.pdf',
      index: 11,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance - nature/Conditions générales - nature.pdf',
      name: 'Conditions générales - nature.pdf',
      index: 12,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existance - nature/Avis de changement d'intermédiaire.pdf",
      name: "Avis de changement d'intermédiaire.pdf",
      index: 13,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existance - nature/Modification preneur d'assurance.pdf",
      name: "Modification preneur d'assurance.pdf",
      index: 14,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance - nature/Scission de la police.pdf',
      name: 'Scission de la police.pdf',
      index: 15,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance - nature/Déclaration de santé - assuré 1.pdf',
      name: 'Déclaration de santé - assuré 1.pdf',
      index: 16,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existance - nature/Déclaration de santé - assuré 2.pdf',
      name: 'Déclaration de santé - assuré 2.pdf',
      index: 17,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance - nature/Questionnaire médical.pdf',
      name: 'Questionnaire médical.pdf',
      index: 18,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance - nature/Ordre de domiciliation.pdf',
      name: 'Ordre de domiciliation.pdf',
      index: 19,
   },
   {
      product: 'Modification police existante - nature',
      language: 'FRENCH',
      blobReference: 'FRENCH/Modification police existance - nature/Modification bénéficiaires.pdf',
      name: 'Modification bénéficiaires.pdf',
      index: 20,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Wijzigingsformulier - natura.pdf',
      name: 'Wijzigingsformulier - natura.pdf',
      index: 11,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Algemene voorwaarden - natura.pdf',
      name: 'Algemene voorwaarden - natura.pdf',
      index: 12,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis natura/Wijziging verzekeringstussenpersoon.pdf',
      name: 'Wijziging verzekeringstussenpersoon.pdf',
      index: 13,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Wijziging verzekeringsnemer.pdf',
      name: 'Wijziging verzekeringsnemer.pdf',
      index: 14,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Splitsing polis natura.pdf',
      name: 'Splitsing polis natura.pdf',
      index: 15,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis natura/Gezondheidsverklaring natura - verzekerde 1.pdf',
      name: 'Gezondheidsverklaring natura - verzekerde 1.pdf',
      index: 16,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis natura/Gezondheidsverklaring natura - verzekerde 2.pdf',
      name: 'Gezondheidsverklaring natura - verzekerde 2.pdf',
      index: 17,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Gezondheidsvragenlijst.pdf',
      name: 'Gezondheidsvragenlijst.pdf',
      index: 18,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Domiciliëringsopdracht natura.pdf',
      name: 'Domiciliëringsopdracht natura.pdf',
      index: 19,
   },
   {
      product: 'Wijziging bestaande polis natura',
      language: 'DUTCH',
      blobReference: 'DUTCH/Wijziging bestaande polis natura/Aanduiding nieuwe begunstigden.pdf',
      name: 'Aanduiding nieuwe begunstigden.pdf',
      index: 20,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/updated-2020-01/Formulaire de modification Plan de Prévoyance obsèques.pdf',
      name: 'Formulaire de modification Plan de Prévoyance obsèques.pdf',
      index: 21,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Scission de la police.pdf',
      name: 'Scission de la police.pdf',
      index: 22,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existante Plan de Prévoyance obsèques/Modification preneur d'assurance.pdf",
      name: "Modification preneur d'assurance.pdf",
      index: 23,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Ordre de domiciliation.pdf',
      name: 'Ordre de domiciliation.pdf',
      index: 24,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Questionnaire médical.pdf',
      name: 'Questionnaire médical.pdf',
      index: 25,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         "FRENCH/Modification police existante Plan de Prévoyance obsèques/Avis de changement d'intermédiaire.pdf",
      name: "Avis de changement d'intermédiaire.pdf",
      index: 26,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Déclaration de santé - adulte 1.pdf',
      name: 'Déclaration de santé - adulte 1.pdf',
      index: 27,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Déclaration de santé - adulte 2.pdf',
      name: 'Déclaration de santé - adulte 2.pdf',
      index: 28,
   },
   {
      product: 'Modification police existante Plan de Prévoyance obsèques',
      language: 'FRENCH',
      blobReference:
         'FRENCH/Modification police existante Plan de Prévoyance obsèques/Modification bénéficiaires.pdf',
      name: 'Modification bénéficiaires.pdf',
      index: 29,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/updated-2020-01/Wijzigingsformulier Uitvaartzorgplan.pdf',
      name: 'Wijzigingsformulier Uitvaartzorgplan.pdf',
      index: 21,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Splitsing polis Uitvaartzorgplan.pdf',
      name: 'Splitsing polis Uitvaartzorgplan.pdf',
      index: 22,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Wijziging verzekeringsnemer.pdf',
      name: 'Wijziging verzekeringsnemer.pdf',
      index: 23,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Domiciliëringsopdracht Uitvaartzorgplan.pdf',
      name: 'Domiciliëringsopdracht Uitvaartzorgplan.pdf',
      index: 24,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Gezondheidsvragenlijst Uitvaartzorgplan.pdf',
      name: 'Gezondheidsvragenlijst Uitvaartzorgplan.pdf',
      index: 25,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Wijziging verzekeringstussenpersoon.pdf',
      name: 'Wijziging verzekeringstussenpersoon.pdf',
      index: 26,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Gezondheidsverklaring UZP verzekerde 1.pdf',
      name: 'Gezondheidsverklaring UZP verzekerde 1.pdf',
      index: 27,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Gezondheidsverklaring UZP verzekerde 2.pdf',
      name: 'Gezondheidsverklaring UZP verzekerde 2.pdf',
      index: 28,
   },
   {
      product: 'Wijziging bestaande polis Uitvaartzorgplan',
      language: 'DUTCH',
      blobReference:
         'DUTCH/Wijziging bestaande polis Uitvaartzorgplan/Aanduiding nieuwe begunstigden.pdf',
      name: 'Aanduiding nieuwe begunstigden.pdf',
      index: 29,
   },
];
