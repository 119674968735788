import { useQuery } from 'react-query';

import { ENDPOINTS } from 'shared/constants';
import { toastHandler } from 'shared/services';
import { Routes, useNavigate } from 'v2/common/hooks/router';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { webStorage } from 'v2/common/services/WebStorage';

export function useProposalProducerAccount(proposalId: string) {
  const navigate = useNavigate();
  const axios = useAxios();

  return useQuery(
    ['proposal', 'producerAccount', proposalId],
    async () => {
      const { data } = await axios.get<number>(
        `${ENDPOINTS.proposals}/${proposalId}/producer-account`
      );

      return data.toString();
    },
    {
      onSuccess: (data) => {
        webStorage.setProducerAccountNumber(data);
        navigate(Routes.proposal(proposalId).documents);
      },
      onError: () => {
        toastHandler.unexpectedIssue();
        navigate(Routes.root);
      },
    }
  );
}
