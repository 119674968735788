import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {history} from '../../../history';

import Dialog from '../../../v2/common/components/Dialog';

class NavigationPrompt extends Component {
   constructor(props) {
      super(props);
      this.state = {nextLocation: null, openModal: false};
   }

   componentDidMount() {
      this.unblock = history.block((nextLocation) => {
         const currentLocation = history.location;
         if (this.props.show && nextLocation.pathname !== currentLocation.pathname) {
            this.setState({
               openModal: true,
               nextLocation: nextLocation,
            });
         }
         return !this.props.show;
      });
   }

   componentWillUnmount() {
      this.unblock();
   }

   hide = () => {
      this.setState({nextLocation: null, openModal: false});
   };

   onReject = () => {
      const {nextLocation} = this.state;
      this.unblock();
      this.setState({openModal: false});
      history.push(nextLocation.pathname + nextLocation.search);
   };

   onConfirm = () => {
      const {submitForm} = this.props;
      this.setState({openModal: false});

      if (submitForm) {
         submitForm(this.onFormSubmitted);
      }
   };

   onFormSubmitted = () => {
      this.unblock();

      const {nextLocation} = this.state;
      history.push(nextLocation.pathname + nextLocation.search);
   };

   render() {
      return (
         <Dialog
            open={this.state.openModal}
            onConfirm={this.onConfirm}
            onCancel={this.onReject}
            onClose={this.hide}
         >
            <FormattedMessage id="dialogs.saveChanges.content" />
         </Dialog>
      );
   }
}

NavigationPrompt.propTypes = {
   show: PropTypes.bool.isRequired,
   submitForm: PropTypes.func,
   submitOldForm: PropTypes.func,
};

export default NavigationPrompt;
