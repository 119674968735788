import { useMutation, useQuery } from 'react-query';

import { ENDPOINTS } from 'shared/constants';
import { downloadFile } from 'shared/services/sagaHelpers';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { transformCommissionsResponse } from 'v2/common/utils/apiTransformations';

export enum CommissionType {
   Statement = 'STATEMENT',
   Receipt = 'BALANCE_SHEET',
}

export interface Commission {
   createDate: string;
   documentId: string;
   month: number;
   name: string;
   title: string;
   type: CommissionType;
   uploadDate: string;
   year: number;
}

export function useCommissions() {
   const axios = useAxios();

   return useQuery('commissions', async () => {
      let {data} = await axios.get<Commission[]>(ENDPOINTS.commissions);

      return transformCommissionsResponse(data);
   });
}

export function useDownloadCommissionDocument() {
   const axios = useAxios();

   return useMutation(
      async ({documentId}: Pick<Commission, 'documentId' | 'name'>) => {
         const {data} = await axios.get<Blob>(`${ENDPOINTS.commissions}/${documentId}`, {
            responseType: 'blob',
         });

         return data;
      },
      {onSuccess: (data, variables) => downloadFile(data, variables.name)}
   );
}
