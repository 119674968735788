import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { formValueSelector, getFormMeta, getFormSyncErrors } from 'redux-form';
import { createSelector } from '@reduxjs/toolkit';
import { PAYMENT_FREQUENCY, SERVER_ERROR_TYPES, SIMPLE_ANSWER } from '../../../shared/constants';
import {
  selectActionData,
  selectAsyncActionData,
  selectIsInProgressStatus,
} from '../../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../../shared/reducers/selectors';
import { getAgentDataCache } from '../../../v2/common/api/queries/agent';
import {
  ageIsOlder,
  resolveMobileNumber,
  adaptInsuredPersonalInfo,
  adaptPolicyHolderPersonalInfo,
  showMobileEqualityNotification,
  showEmailsEqualityNotification,
  checkPersonalEquality,
  adaptInsuredChildrenPersonalInfo,
  areRequiredFieldsTouched,
  getInsuredAdultSection,
} from '../../../shared/services/utils';
import {
  selectIsMetadataBlocked,
  selectMetadata,
  selectIsInitialFormDataFetched,
  selectServerErrors,
} from '../selectors';
import { containerId, saveInsured, fetchInsuredChildren, fetchInsuredAdults } from './reducer';
import {
  setFormSavingAction,
  getCurrentState as getFormWrapperState,
} from '../../FormWrapper/reducer';

import { getCurrentState as getPolicyHolderState } from '../PolicyHolder/selectors';
import { fetchPolicyHolder } from '../PolicyHolder/reducer';
import { selectBeneficiaries } from '../Beneficiaries/selectors';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormErrorsValues = getFormSyncErrors(containerId);
const selectFormValues = formValueSelector(containerId);
const selectFormMeta = getFormMeta(containerId);

export const selectPolicyHolder = (state) =>
  selectAsyncActionData(getPolicyHolderState(state), fetchPolicyHolder.type);

export const selectParticipatingAsPolicyHolderFormValues = (state) =>
  selectFormValues(state, 'insuredAdults[0].participatingAsPolicyHolder');

export const selectPolicyHolderNationalRegisterNumber = createSelector(
  selectPolicyHolder,
  (policyHolder) => get(policyHolder, 'personalInformation.nationalRegisterNumber', false)
);

export const selectEnabledEmailAddressForDocumentReceiving = (state) =>
  selectAsyncActionData(
    getPolicyHolderState(state),
    fetchPolicyHolder.type,
    'contactInformation.enabledEmailAddressForDocumentReceiving'
  );

export const selectEnabledEmailAddressForMarketing = (state) =>
  selectAsyncActionData(
    getPolicyHolderState(state),
    fetchPolicyHolder.type,
    'contactInformation.enabledEmailAddressForMarketing'
  );

export const selectIsSavingFormInProgress = (state) =>
  selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsFormFetching = createSelector(
  [selectIsInitialFormDataFetched, selectIsSavingFormInProgress],
  (isFetched, isSavingFormInProgress) => !isFetched && !isSavingFormInProgress
);

export const selectIsSaving = (state) =>
  selectIsInProgressStatus(getCurrentState(state), saveInsured.type);

export const selectInsuredChildren = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchInsuredChildren.type);

export const selectInsuredAdults = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchInsuredAdults.type);

export const selectFirstInsuredAdult = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchInsuredAdults.type, 'firstInsuredAdult');

export const selectSecondInsuredAdult = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchInsuredAdults.type, 'secondInsuredAdult');

export const selectCareClause = (state) => get(selectMetadata(state), 'careClause');

export const selectPaymentFrequency = (state) => get(selectMetadata(state), 'paymentFrequency');

export const selectShouldHaveInsuredChildren = (state) =>
  get(selectMetadata(state), 'shouldHaveInsuredChildren', null);

export const selectInsuredAdultsFormValue = (state) =>
  selectFormValues(state, 'insuredAdults') || [];

export const selectFirstInsuredAdultsFormValue = (state) =>
  selectFormValues(state, 'insuredAdults[0]') || [];

export const selectSecondInsuredAdultsFormValue = (state) =>
  selectFormValues(state, 'insuredAdults[1]') || [];

export const selectShouldHaveInsuredChildrenFormValue = (state) =>
  selectFormValues(state, 'shouldHaveInsuredChildren');

export const selectInsuredChildrenFormValue = (state) =>
  selectFormValues(state, 'insuredChildren') || [];

export const selectShouldHaveInsuredChildrenIsYes = (state) =>
  selectShouldHaveInsuredChildrenFormValue(state) === SIMPLE_ANSWER.YES;

export const selectFormErrors = (state) => selectFormErrorsValues(state);

export const makeSelectIsOneAdult = () => {
  return createSelector(selectInsuredAdultsFormValue, (insuredAdults) => {
    // TODO: check if isOneInsuredAdult should be false if insuredAdults does not exist
    if (!isArray(insuredAdults)) {
      return false;
    }

    return insuredAdults.length === 1;
  });
};

export const makeSelectIsOneTimeOnly = () => {
  return createSelector(selectPaymentFrequency, (paymentFrequency) => {
    return paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;
  });
};

export const makeSelectIsOneTimeOnlyViolation = () => {
  return createSelector(
    [makeSelectIsOneTimeOnly(), makeSelectIsOneAdult()],
    (isOneTimeOnly, isOneAdult) => {
      return isOneTimeOnly && !isOneAdult;
    }
  );
};

export const makeSelectIsInitialValuesExist = () => {
  return createSelector(
    [selectInsuredChildren, selectInsuredAdults],
    (insuredChildren, insuredAdults) => insuredChildren && insuredAdults
  );
};

export const makeSelectPolicyHolderCopyMap = () => {
  return createSelector(selectPolicyHolder, (policyHolder) => {
    if (!policyHolder) {
      return;
    }
    const {
      personalInformation: { firstName, surname, gender, birthDate, nationalRegisterNumber },
      contactInformation: { email, mobileNumber, mobileNumberCode },
    } = policyHolder;

    const policyHolderCopyMap = {
      'insuredAdults[0].personalInformation.firstName': firstName,
      'insuredAdults[0].personalInformation.surname': surname,
      'insuredAdults[0].personalInformation.gender': gender,
      'insuredAdults[0].personalInformation.birthDate': birthDate,
      'insuredAdults[0].personalInformation.nationalRegisterNumber': nationalRegisterNumber,
      'insuredAdults[0].email': email,
      'insuredAdults[0].mobileNumberCode': mobileNumberCode,
      'insuredAdults[0].mobileNumber': mobileNumber,
    };

    return policyHolderCopyMap;
  });
};

export const makeSelectInitialValues = () => {
  return createSelector(
    [
      selectInsuredChildren,
      selectFirstInsuredAdult,
      selectSecondInsuredAdult,
      selectCareClause,
      selectPaymentFrequency,
      selectShouldHaveInsuredChildren,
      makeSelectIsInitialValuesExist(),
    ],
    (
      insuredChildren,
      firstInsuredAdult,
      secondInsuredAdult,
      careClause,
      paymentFrequency,
      shouldHaveInsuredChildren,
      isInitialValuesExist
    ) => {
      if (!isInitialValuesExist) {
        return;
      }

      const insuredAdults = [firstInsuredAdult];

      if (secondInsuredAdult) {
        insuredAdults.push(secondInsuredAdult);
      }

      return {
        insuredAdults,
        insuredChildren,
        paymentFrequency,
        careClause,
        shouldHaveInsuredChildren,
      };
    }
  );
};

export const selectIsAgeOlder = createSelector(
  [selectInsuredAdultsFormValue, selectFormErrors],
  (insuredAdults, formErrors) => {
    if (!isArray(insuredAdults) || !insuredAdults[0]) {
      return false;
    }
    const MAX = 75;
    const birthDate = get(insuredAdults, '[0].personalInformation.birthDate');
    const isBirthDateInvalid = get(
      formErrors,
      'insuredAdults[0].personalInformation.birthDate',
      false
    );
    return !isBirthDateInvalid && ageIsOlder(birthDate, MAX);
  }
);

export const selectIsSavingButtonDisabled = createSelector(
  [makeSelectIsOneTimeOnlyViolation(), selectIsMetadataBlocked],
  (isOneTimeOnlyViolation, isBlocked) => isOneTimeOnlyViolation || isBlocked
);

const selectIsFieldTouched = (fieldPath) =>
  createSelector(selectFormMeta, (meta) => get(meta, `${fieldPath}.touched`, false));

const firstInsuredMobileNumberPath = 'insuredAdults[0].mobileNumber';
const secondInsuredMobileNumberPath = 'insuredAdults[1].mobileNumber';

export const selectFirstInsuredMobilePhoneNumberError = createSelector(
  [selectFormErrors, selectIsFieldTouched(firstInsuredMobileNumberPath)],
  (formErrors, isTouched) => isTouched && get(formErrors, firstInsuredMobileNumberPath, false)
);

export const selectSecondInsuredMobilePhoneNumberError = createSelector(
  [selectFormErrors, selectIsFieldTouched(secondInsuredMobileNumberPath)],
  (formErrors, isTouched) => isTouched && get(formErrors, secondInsuredMobileNumberPath, false)
);

export const selectFirstInsuredMobileNumberCodeValue = (state) =>
  selectFormValues(state, `${firstInsuredMobileNumberPath}Code`);

export const selectSecondInsuredMobileNumberCodeValue = (state) =>
  selectFormValues(state, `${secondInsuredMobileNumberPath}Code`);

export const selectFirstInsuredParticipatingAsPolicyHolderValue = (state) =>
  selectFormValues(state, 'insuredAdults[0]participatingAsPolicyHolder');

const makeSelectIsEmailNotificationShown = (selectCurrentInsuredAdult, selectOtherInsuredAdult) =>
  createSelector(
    [
      selectCurrentInsuredAdult,
      selectOtherInsuredAdult,
      selectPolicyHolder,
      selectParticipatingAsPolicyHolderFormValues,
    ],
    (currentInsuredAdult, otherInsuredAdult, policyHolder, participatingAsPolicyHolder) => {
      const currentInsuredEmail = get(currentInsuredAdult, 'email');

      if (!currentInsuredEmail) {
        return false;
      }

      const currentInsuredObject = adaptInsuredPersonalInfo(currentInsuredAdult);
      const otherInsuredObject = adaptInsuredPersonalInfo(otherInsuredAdult);
      const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);

      return showEmailsEqualityNotification(
        currentInsuredObject,
        [otherInsuredObject, policyHolderObject],
        participatingAsPolicyHolder
      );
    }
  );

export const selectIsFirstInsuredEmailNotificationShown = makeSelectIsEmailNotificationShown(
  selectFirstInsuredAdultsFormValue,
  selectSecondInsuredAdultsFormValue
);

export const selectIsSecondInsuredEmailNotificationShown = makeSelectIsEmailNotificationShown(
  selectSecondInsuredAdultsFormValue,
  selectFirstInsuredAdultsFormValue
);

export const makeSelectIsMobileNumberNotificationShown = (
  selectCurrentInsuredAdult,
  selectOtherInsuredAdult
) =>
  createSelector(
    [selectCurrentInsuredAdult, selectOtherInsuredAdult, selectPolicyHolder],
    (currentInsuredAdult, otherInsuredAdult, policyHolder) => {
      const currentInsuredNumber = resolveMobileNumber(currentInsuredAdult, 'mobileNumber');

      if (!currentInsuredNumber) {
        return false;
      }

      const currentInsuredObject = adaptInsuredPersonalInfo(currentInsuredAdult);
      const otherInsuredObject = adaptInsuredPersonalInfo(otherInsuredAdult);
      const policyHolderObject = adaptPolicyHolderPersonalInfo(policyHolder);
      const { personalInformation, contactInformation } = getAgentDataCache();
      const agentPrivateInfo = { ...personalInformation, ...contactInformation };
      return showMobileEqualityNotification(currentInsuredObject, [
        otherInsuredObject,
        policyHolderObject,
        agentPrivateInfo,
      ]);
    }
  );

export const selectIsFirstInsuredMobileNumberNotificationShown =
  makeSelectIsMobileNumberNotificationShown(
    selectFirstInsuredAdultsFormValue,
    selectSecondInsuredAdultsFormValue
  );

export const selectIsSecondInsuredMobileNumberNotificationShown =
  makeSelectIsMobileNumberNotificationShown(
    selectSecondInsuredAdultsFormValue,
    selectFirstInsuredAdultsFormValue
  );

const createInsuredPersonalFieldPaths = (indexes) => {
  const paths = [];
  indexes.forEach((index) => {
    paths.push(`insuredAdults[${index}].personalInformation.firstName`);
    paths.push(`insuredAdults[${index}].personalInformation.surname`);
    paths.push(`insuredAdults[${index}].personalInformation.birthDate`);
  });
  return paths;
};

const createChildrenPersonalFieldPaths = (indexes) => {
  const paths = [];
  indexes.forEach((index) => {
    paths.push(`insuredChildren[${index}].personalInformation.firstName`);
    paths.push(`insuredChildren[${index}].personalInformation.surname`);
    paths.push(`insuredChildren[${index}].personalInformation.birthDate`);
  });
  return paths;
};

const selectIsInsuredDuplicationErrorShown = createSelector(
  [
    selectFirstInsuredAdultsFormValue,
    selectSecondInsuredAdultsFormValue,
    selectFormMeta,
    selectServerErrors,
  ],
  (firstInsuredAdult, secondInsuredAdult, meta, errors) => {
    const serverError = get(errors, SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS, false);
    if (!isEmpty(serverError)) {
      return true;
    }
    const firstInsuredObject = adaptInsuredPersonalInfo(firstInsuredAdult);
    const secondInsuredObject = adaptInsuredPersonalInfo(secondInsuredAdult);

    if (!checkPersonalEquality(secondInsuredObject, firstInsuredObject)) {
      return false;
    }
    return areRequiredFieldsTouched(createInsuredPersonalFieldPaths([0, 1]), meta);
  }
);

const selectAreInsuredAndBeneficiaryEqual = (insuredIndex) =>
  createSelector(
    [selectInsuredAdultsFormValue, selectBeneficiaries],
    (insuredAdults, beneficiaries) => {
      const insured = insuredAdults[insuredIndex];
      const insuredObject = adaptInsuredPersonalInfo(insured);
      const section = getInsuredAdultSection(insuredIndex);

      const currentBeneficiaries = get(beneficiaries, `${section}.beneficiaries`, []);

      return currentBeneficiaries.some((beneficiary) =>
        checkPersonalEquality(adaptInsuredChildrenPersonalInfo(beneficiary), insuredObject)
      );
    }
  );

const selectIsInsuredAndBeneficiaryDuplicationErrorShown = (insuredIndex) =>
  createSelector(
    [selectAreInsuredAndBeneficiaryEqual(insuredIndex), selectServerErrors, selectFormMeta],
    (areInsuredAndBeneficiaryEqual, errors, meta) => {
      const serverError = get(
        errors,
        `${SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES}.insuredAdults[${insuredIndex}]`,
        false
      );
      if (!isEmpty(serverError)) {
        return true;
      }

      if (!areInsuredAndBeneficiaryEqual) {
        return false;
      }

      return areRequiredFieldsTouched(createInsuredPersonalFieldPaths([insuredIndex]), meta);
    }
  );

export const selectInsuredDuplicationError = (insuredIndex) =>
  createSelector(
    [
      selectIsInsuredDuplicationErrorShown,
      selectIsInsuredAndBeneficiaryDuplicationErrorShown(insuredIndex),
    ],
    (isInsuredDuplicationErrorShown, isInsuredAndBeneficiaryDuplicationErrorShown) => {
      if (isInsuredDuplicationErrorShown) {
        return 'validate.insuredAdultAreEqual';
      }
      if (isInsuredAndBeneficiaryDuplicationErrorShown) {
        return 'validate.insuredAdultsAssignedAsBeneficiaries';
      }
      return null;
    }
  );

export const selectDuplicatedInsuredChildrenIndexes = (index) =>
  createSelector(selectInsuredChildrenFormValue, (children) => {
    const currentChild = children[index];
    const currentChildObject = adaptInsuredChildrenPersonalInfo(currentChild);
    const result = [];
    children
      .map((child) => adaptInsuredChildrenPersonalInfo(child))
      .forEach((child, childIndex) => {
        if (childIndex === index) {
          return;
        }
        if (checkPersonalEquality(child, currentChildObject)) {
          result.push(childIndex);
        }
      });
    return result;
  });

export const selectChildrenDuplicationError = (index) =>
  createSelector(
    [selectDuplicatedInsuredChildrenIndexes(index), selectServerErrors, selectFormMeta],
    (duplicatedIndexes, serverErrors, meta) => {
      const isSeverErrorExist = get(
        serverErrors,
        `${SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN}.insuredChildren[${index}]`,
        false
      );

      if (!isEmpty(isSeverErrorExist)) {
        return 'validate.insuredChildrenAreEqual';
      }

      if (!duplicatedIndexes.length) {
        return null;
      }

      return areRequiredFieldsTouched(
        createChildrenPersonalFieldPaths([index, ...duplicatedIndexes]),
        meta
      )
        ? 'validate.insuredChildrenAreEqual'
        : null;
    }
  );

export const selectIsInsuredEmailRequired = createSelector(
  [
    selectFirstInsuredParticipatingAsPolicyHolderValue,
    selectEnabledEmailAddressForDocumentReceiving,
    selectEnabledEmailAddressForMarketing,
  ],
  (
    participatingAsPlicyHolder,
    enabledEmailAddressForDocumentReceiving,
    enabledEmailAddressForMarketing
  ) => {
    if (participatingAsPlicyHolder === SIMPLE_ANSWER.NO) {
      return;
    }

    return (
      enabledEmailAddressForDocumentReceiving === SIMPLE_ANSWER.YES ||
      enabledEmailAddressForMarketing === SIMPLE_ANSWER.YES
    );
  }
);
