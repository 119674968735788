import {InteractionStatus} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import LoadingButton, {LoadingButtonProps} from '@mui/lab/LoadingButton';
import {LogoutIcon} from 'common/styles/icons/LogoutIcon';
import React from 'react';
import {ACCESS_TOKEN} from 'v2/common/providers/AuthProvider/utils';
import {webStorage} from 'v2/common/services/WebStorage';

type LogOutProps = {
   sx?: LoadingButtonProps['sx'];
   variant?: LoadingButtonProps['variant'];
   color?: LoadingButtonProps['color'];
   children?: React.ReactNode;
   onClick?: () => void;
};

const LogoutButton = ({sx, variant, color, children, onClick}: LogOutProps) => {
   const {instance, inProgress} = useMsal();

   const handleClick = async () => {
      onClick?.();
      webStorage.removeProducerAccountNumber();
      sessionStorage.removeItem(ACCESS_TOKEN);
      await instance.logoutRedirect();
   };
   return (
      <LoadingButton
         sx={sx}
         variant={variant}
         color={color}
         loading={inProgress === InteractionStatus.Logout}
         onClick={handleClick}
      >
         {children ?? <LogoutIcon fontSize="small" />}
      </LoadingButton>
   );
};

export default LogoutButton;
