import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Grid from '@mui/material/Grid';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Field, reduxForm} from 'redux-form';
import ActionButton from '../../shared/components/ActionButton';
import {AdultToRemoveDialog} from '../../shared/components/AdultToRemoveDialog';
import InfoPanel from '../../shared/components/InfoPanel';
import {FormattedMessage} from '../../shared/components/FormattedMessage';
import ResidentialAddressFields from '../../shared/components/ResidentialAddressFields';
import {PRODUCT_VERSIONS, SIMPLE_ANSWER} from '../../shared/constants';
import * as fields from '../../shared/constants/fields';
import {fetchMunicipalities, fetchStreets, validatePostalCode} from '../../shared/references';
import {
  selectContactLanguages,
  selectIsFetchingMunicipalities,
  selectMunicipalities,
  selectYesNo,
} from '../../shared/references/selectors';
import {
  asyncValidator,
  composeAsyncValidators,
  scrollToInvalidInput,
} from '../../shared/services/utils';
import {
  checkSuspiciousSymbolsFirstname,
  checkSuspiciousSymbolsSurname,
  required,
} from '../../shared/services/validators';
import {fetchCompleteness} from '../NavigationPane/reducer';
import {AddInsuredAdultButton} from '../../shared/components/AddInsuredAdultButton';
// CheckboxControl should be imported before PremiumBox and InsuredAdultFields to be resolved correctly
import CheckboxControl from '../../shared/components/reduxFormField/Checkbox';
import PremiumBox from '../PremiumBox';
import InsuredAdultFields from '../../shared/components/InsuredAdultFields';
import {
  checkIfCalculationOutdated,
  fetchCalculationData,
  updatePersonalInformation,
} from '../PremiumBox/reducer';
import RemoveParty from '../../v2/features/dialogs/RemoveParty';
import {setServerErrors} from '../ProposalDetails/reducer';
import {
  containerId as offerContainerId,
  fetchOffer,
  setPreviousPaymentFrequency,
  shareByEmail,
  shareByPrinting,
} from './reducer';
import {
  makeSelectInitialValues,
  makeSelectIsOneAdult,
  makeSelectIsOneTimeOnly,
  makeSelectIsOneTimeOnlyViolation,
  makeSelectPolicyHolderCopyMap,
  selectFormErrors,
  selectIsFormFetching,
  selectIsHouseNumberDisabled,
  selectIsMunicipalityDisabled,
  selectIsPostalCodeValid,
  selectIsPrintingFailedStatus,
  selectIsPrintingStatus,
  selectIsSaving,
  selectIsSendingStatus,
  selectIsShowBirthDateInfo,
  selectIsStreetNameDisabled,
  selectMetadata,
  selectMunicipalityFormValue,
  selectOffer,
  selectPaymentFrequencies,
  selectPaymentFrequencyFormValue,
  selectPreviousPaymentFrequency,
  selectSharedByEmailFormValue,
  selectSharedByPrintingFormValue,
  selectEmailDeliveryStatus,
  selectEmailDeliveryUndeliveredStatus,
  selectFailedStatusAndEmailDeliveryStatus,
  selectIsEmailNotificationShown,
  selectInsuredDuplicationError,
  selectInsuredIsPolicyHolder,
  selectHasResidentialAddressSaveError,
  selectFirstInsuredAdultNationalRegisterNumberFormValue,
  selectSecondInsuredAdultNationalRegisterNumberFormValue
} from './selectors';
import './styles.scss';
import FormWrapper from '../FormWrapper';
import EmailField from '../../shared/components/EmailField';
import PersonalEmailInfo from '../../shared/components/EmailField/PersonalEmailInfo';
import TextInputControl from '../../shared/components/reduxFormField/TextInput';
import DropdownControl from '../../shared/components/reduxFormField/Dropdown';
import InsuredIsPolicyHolder from '../../v2/features/dialogs/InsuredIsPolicyHolder';
import RadioButtonGroupControl from '../../shared/components/reduxFormField/RadioButtonGroup';

export const POSTAL_CODE_FIELD_NAME = 'policyHolder.residentialAddress.postalCode';
const EMAIL_FIELD_NAME = 'policyHolder.contactInformation.email';
const RESIDENTIAL_ADDRESS_FIELD_NAME = 'policyHolder.residentialAddress';

const asyncValidate = composeAsyncValidators({
  [POSTAL_CODE_FIELD_NAME]: asyncValidator(validatePostalCode.action),
});

export const INITIAL_STATE = {
  showModal: false,
  showInsuredIsPolicyHolderModal: false,
  insuredIndex: null,
  applyCallback: null,
};

export class OfferDetails extends React.PureComponent {
  state = INITIAL_STATE;

  componentWillUnmount() {
    this.clearOfferDetails();
  }

  componentDidMount() {
      const {id} = this.props.match.params;
      const params = {id, endpoint: 'offers'};
    this.props.clearNavigationPanel();
    this.props.clearServerErrors();
    this.props.clearMunicipalities();
    this.fetchData(params);
  }

   componentDidUpdate({paymentFrequency, match}) {
      const {id} = this.props.match.params;
    if (match.params.id !== id) {
         const params = {id, endpoint: 'offers'};
      this.fetchData(params);
    }
    if (paymentFrequency !== this.props.paymentFrequency) {
      this.props.setPreviousPaymentFrequency(paymentFrequency);
    }
  }

  fetchData = (params) => {
    this.props.fetchOffer(params.id);
    this.props.fetchCompleteness(params);
    this.props.fetchCalculationData(params);
  };

  updateInsuredPersonalInfo = (id) => {
      const {formErrors} = this.props;
    const isInvalid = get(formErrors, `insuredAdults[${id}].personalInformation`, false);
    if (!isInvalid) {
      this.props.updatePersonalInformation(id, offerContainerId);
    }
  };

  handlePaymentFrequencyChange = (e, newValue) => {
      const {change} = this.props;
    change('paymentFrequency', newValue);
    this.touchPaymentFrequencyDependentProps();
    setTimeout(this.onCalculationDataChange);
  };

  onCalculationDataChange = () => {
    if (this.isCalculationFieldsValid()) {
      this.props.checkIfCalculationOutdated(offerContainerId);
    }
  };

  isCalculationFieldsValid() {
      const {formErrors} = this.props;
    return (
      !formErrors.paymentFrequency &&
      (!formErrors.insuredAdults ||
        formErrors.insuredAdults.reduce((result, insured) => {
          result =
            result &&
            (!insured ||
              isEmpty(insured) ||
              (Object.keys(insured).length === 1 &&
                !!insured.personalInformation &&
                !insured.personalInformation.birthDate));
          return result;
        }, true))
    );
  }

  renderRemoveAdultSuggestionDialog() {
    const {
         array: {remove},
      isOneTimeOnlyViolation,
      change,
      previousPaymentFrequency,
    } = this.props;

    return (
      isOneTimeOnlyViolation && (
        <Grid item xs={8}>
          <AdultToRemoveDialog
            optionId="offer-details"
            remove={(i) => {
              remove('insuredAdults', i);
              change('careClause', false);
              setTimeout(this.onCalculationDataChange);
            }}
            cancel={() => change('paymentFrequency', previousPaymentFrequency)}
          />
        </Grid>
      )
    );
  }

  touchCareClauseDependentProps = () => {
      const {touch} = this.props;
    touch('insuredAdults[0].benefit.amount');
    touch('insuredAdults[1].benefit.amount');
    touch('insuredAdults[0].personalInformation.birthDate');
    touch('insuredAdults[1].personalInformation.birthDate');
  };

  touchPaymentFrequencyDependentProps = () => {
      const {touch} = this.props;
    touch('insuredAdults[0].payUntilAge');
    touch('insuredAdults[0].personalInformation.birthDate');
  };

  resolvePanelName = () => {
      const {isShowUndelivered} = this.props;

    if (isShowUndelivered) {
      return 'offerDetails.notDelivered';
    }

    return 'offerDetails.cannotSendEmail';
  };

  resolveButtonName = () => {
      const {isResendButton} = this.props;

    if (isResendButton) {
      return 'offerDetails.resend';
    }
    return 'offerDetails.send';
  };

  handleAction = (action) => {
    const { intl, metadata, formErrors } = this.props;

    this.touchPaymentFrequencyDependentProps();

    if (isEmpty(formErrors)) {
      action({
        id: metadata.offerId,
        fileName: intl.formatMessage({
          id: 'misc.offer',
        }),
      });
    } else {
      scrollToInvalidInput(formErrors);
    }
  };

  touchInsuredPersonalFields = () => {
      const {touch} = this.props;
    touch(`insuredAdults[1].personalInformation.firstName`);
    touch(`insuredAdults[1].personalInformation.surname`);
    touch(`insuredAdults[0].personalInformation.birthDate`);
    touch(`insuredAdults[1].personalInformation.birthDate`);
  };

   handleSharedByPrintingClick = (e, newValue) => {
      const {initialValues, change, fetchMunicipalities} = this.props;
    const initialResidentialAddress = get(initialValues, RESIDENTIAL_ADDRESS_FIELD_NAME);
    const isPostalCodeValid = initialResidentialAddress?.postalCode?.length === 4;

    if (!newValue) {
      change(RESIDENTIAL_ADDRESS_FIELD_NAME, initialResidentialAddress);
      if (isPostalCodeValid) {
            fetchMunicipalities({
          postalCode: initialResidentialAddress.postalCode,
          offerContainerId,
        });
      }
    }
  };

   handleSharedByEmailClick = (e, newValue) => {
      const {initialValues, change} = this.props;
    const initialEmail = get(initialValues, EMAIL_FIELD_NAME, null);

    if (!newValue) {
      change(EMAIL_FIELD_NAME, initialEmail);
    }
  };

   onHideModal = () => this.setState({showModal: false, applyCallback: null});

  onRemoveInsuredAdult = (index, callback) => {
      const {change} = this.props;
    const applyCallback = () => {
      callback();
      if (index === 0) {
        change('policyHolder.participatingAsInsuredAdult', SIMPLE_ANSWER.NO);
      }
    };
      this.setState({insuredIndex: index + 1, showModal: true, applyCallback});
  };

  onModalApply = () => {
    this.state.applyCallback();
    this.onHideModal();
  };

  clearOfferDetails() {
    this.props.clearOffer();
    this.props.clearShareByPrinting();
    this.props.clearShareByEmail();
  }

   hideInsuredIsPolicyHolderModal = () => this.setState({showInsuredIsPolicyHolderModal: false});

  onParticipatingAsInsuredAdultReject = () => {
    this.props.change('policyHolder.participatingAsInsuredAdult', SIMPLE_ANSWER.YES);
    this.hideInsuredIsPolicyHolderModal();
  };

  onParticipatingAsInsuredAdultChange = (e) => {
    if (e.target.value === SIMPLE_ANSWER.NO) {
         this.setState({showInsuredIsPolicyHolderModal: true});
      return;
    }
      const {policyHolderCopyMap, change, touch} = this.props;
    for (const key in policyHolderCopyMap) {
      if (policyHolderCopyMap.hasOwnProperty(key)) {
        change(key, policyHolderCopyMap[key]);
        touch(key);
      }
    }
    this.touchInsuredPersonalFields();
    this.updateInsuredPersonalInfo(0);
  };

  onParticipatingAsInsuredAdultBlur = (e) => {
    if (e.target.value === SIMPLE_ANSWER.NO) {
      e.preventDefault();
    }
  };

  changePolicyHolderName = (fieldName, value) => {
      const {insuredIsPolicyHolder, change} = this.props;
    if (insuredIsPolicyHolder) {
      change(`policyHolder.personalInformation.${fieldName}`, value);
    }
  };

  render() { // NOSONAR
    const {
         array: {push, remove},
      offer,
      metadata,
      fetching,
      saving,
      printingStatus,
      sendingStatus,
      isOneAdult,
      isOneTimeOnly,
      isOneTimeOnlyViolation,
      paymentFrequencies,
      change,
      touch,
      dispatch,
      isSharedByPrinting,
      shareByPrinting,
      isSharedByEmail,
      shareByEmail,
      contactLanguages,
      intl,
      isPostalCodeValid,
      municipalities,
      isFetchingMunicipalities,
      clearMunicipalities,
      selectedMunicipality,
      isMunicipalityDisabled,
      isStreetNameDisabled,
      isHouseNumberDisabled,
      showBirthDateInfo,
      printingFailedStatus,
      isShowInfoPanel,
      isEmailInfoShown,
      insuredsDuplicationError,
      yesNo,
      insuredIsPolicyHolder,
      hasResidentialAddressSaveError,
      firstInsuredAdultNationalRegisterNumber,
      secondInsuredAdultNationalRegisterNumber
    } = this.props;

    const addAdult = () => {
      push('insuredAdults', {
        id: null,
        personalInformation: {
          firstName: null,
          surname: null,
          gender: null,
          nationalRegisterNumber: null
        },
        email: null,
      });
    };

    const addButton = metadata && !metadata.blocked && !isOneTimeOnly && (
      <div className="add-insured-wrapper">
        <AddInsuredAdultButton onClick={addAdult}>
          <FormattedMessage id="misc.addAdult" />
        </AddInsuredAdultButton>
      </div>
    );

      const shareButtonDisabled =
         saving || isOneTimeOnlyViolation || (metadata && metadata.blocked);

    const printingArea = isSharedByPrinting && (
      <Grid item xs={12} className="residential-address">
        <Grid container columnSpacing={3}>
          <Grid item xs={8}>
            <ResidentialAddressFields
              disabled={metadata.blocked}
              isOffer
              isPostalCodeValid={isPostalCodeValid}
              municipalities={municipalities}
              isFetchingMunicipalities={isFetchingMunicipalities}
              selectedMunicipality={selectedMunicipality}
              clearMunicipalities={clearMunicipalities}
              isMunicipalityDisabled={isMunicipalityDisabled}
              isStreetNameDisabled={isStreetNameDisabled}
              isHouseNumberDisabled={isHouseNumberDisabled}
              fetchStreets={fetchStreets}
              hasResidentialAddressSaveError={hasResidentialAddressSaveError}
              touch={touch}
              dispatch={dispatch}
              change={change}
            />
          </Grid>
          <Grid item xs={4}>
            <ActionButton
              className="form-action"
              action={printingStatus}
              disabled={shareButtonDisabled || sendingStatus}
              onClick={() => this.handleAction(shareByPrinting)}
            >
              <FormattedMessage id="offerDetails.print" />
            </ActionButton>
          </Grid>
        </Grid>
        {printingFailedStatus && (
          <Grid container className="printing-failed">
            <Grid item xs={6}>
              <InfoPanel highlight>
                <FormattedMessage id="offerDetails.cannotPrint" />
              </InfoPanel>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
    const emailArea = isSharedByEmail && (
      <Grid item xs={12} className="email-field">
        <Grid container columnSpacing={3}>
          <Grid item xs={4}>
            <EmailField
              name={EMAIL_FIELD_NAME}
              label={intl.formatMessage({
                id: 'fields.email.label',
              })}
              disabled={metadata.blocked}
              metaHeightAuto={isEmailInfoShown || isShowInfoPanel}
            />
          </Grid>
          <Grid item xs={4}>
            <ActionButton
              className="form-action"
              action={sendingStatus}
              disabled={shareButtonDisabled || printingStatus}
              onClick={() => this.handleAction(shareByEmail)}
            >
              <FormattedMessage id={this.resolveButtonName()} />
            </ActionButton>
          </Grid>
        </Grid>
        {isEmailInfoShown && (
          <Grid container className="email-info">
            <Grid item xs={6}>
              <PersonalEmailInfo />
            </Grid>
          </Grid>
        )}
        {isShowInfoPanel && (
          <Grid container className="email-info">
            <Grid item xs={6}>
              <InfoPanel highlight>
                <FormattedMessage id={this.resolvePanelName()} />
              </InfoPanel>
            </Grid>
          </Grid>
        )}
      </Grid>
    );

    const offerForm = offer && metadata && (
      <>
        <RemoveParty
          show={this.state.showModal}
          onApply={this.onModalApply}
          onReject={this.onHideModal}
        >
          <FormattedMessage
            id="dialogs.removeParty.insuredAdultContent"
                  values={{number: this.state.insuredIndex}}
          />
        </RemoveParty>
        <InsuredIsPolicyHolder
          show={this.state.showInsuredIsPolicyHolderModal}
          onApply={this.hideInsuredIsPolicyHolderModal}
          onReject={this.onParticipatingAsInsuredAdultReject}
        />
        <FormWrapper formName={offerContainerId}>
          <Grid container>
            <Grid item xs={12}>
              <h3>
                <FormattedMessage id="misc.policyHolder" />
              </h3>
            </Grid>
            <Grid item xs={12}>
              <Grid container item xs={4}>
                <Field
                  name="policyHolder.participatingAsInsuredAdult"
                  label={intl.formatMessage({
                              id: `fields.makeInsuredAsPolicyHolder.${
                                 isOneAdult ? 'oneAdult' : 'twoAdults'
                              }`,
                  })}
                  component={RadioButtonGroupControl}
                  options={yesNo}
                  onChange={this.onParticipatingAsInsuredAdultChange}
                  onBlur={this.onParticipatingAsInsuredAdultBlur}
                  validate={required}
                  disabled={isOneTimeOnlyViolation || metadata.blocked}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={4}>
                <Field
                  name="policyHolder.personalInformation.firstName"
                  label={intl.formatMessage({
                    id: 'fields.firstName.label',
                  })}
                  required
                  maxLength={50}
                           disabled={
                              isOneTimeOnlyViolation || metadata.blocked || insuredIsPolicyHolder
                           }
                  component={TextInputControl}
                  placeholder={intl.formatMessage({
                    id: 'fields.firstName.placeholder',
                  })}
                  validate={[required, checkSuspiciousSymbolsFirstname]}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="policyHolder.personalInformation.surname"
                  label={intl.formatMessage({
                    id: 'fields.surname.label',
                  })}
                  required
                  maxLength={50}
                           disabled={
                              isOneTimeOnlyViolation || metadata.blocked || insuredIsPolicyHolder
                           }
                  component={TextInputControl}
                  placeholder={intl.formatMessage({
                    id: 'fields.surname.placeholder',
                  })}
                  validate={[required, checkSuspiciousSymbolsSurname]}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className="offer-details-hr" />
            </Grid>
            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={4}>
                <InsuredAdultFields
                  index={0}
                  isOneAdult={isOneAdult}
                  isOneTimeOnly={isOneTimeOnly}
                  disabled={metadata.blocked || isOneTimeOnlyViolation}
                  remove={remove}
                  change={change}
                  touch={touch}
                  onCalculationDataChange={this.onCalculationDataChange}
                  onPersonalInfoChange={this.updateInsuredPersonalInfo}
                  onRemoveInsuredAdult={this.onRemoveInsuredAdult}
                  showBirthDateInfo={showBirthDateInfo}
                  showNationalRegisterNumberInfo={!!firstInsuredAdultNationalRegisterNumber}
                  titleError={insuredsDuplicationError}
                  changePolicyHolderName={this.changePolicyHolderName}
                />
                <Field
                  name="paymentFrequency"
                  label={intl.formatMessage({
                    id: 'fields.paymentFrequency.label',
                  })}
                  required
                  disabled={isOneTimeOnlyViolation || metadata.blocked}
                           hint={intl.formatMessage(
                              {id: fields.paymentFrequencyHint},
                              {br: <br />}
                           )}
                  component={DropdownControl}
                  options={paymentFrequencies}
                  validate={fields.paymentFrequency.validate}
                  onChange={this.handlePaymentFrequencyChange}
                  backspaceRemovesValue={false}
                />
              </Grid>
              <Grid item xs={4}>
                {isOneAdult ? (
                  addButton
                ) : (
                  <InsuredAdultFields
                    index={1}
                    isOneAdult={isOneAdult}
                    isOneTimeOnly={isOneTimeOnly}
                    disabled={metadata.blocked || isOneTimeOnlyViolation}
                    remove={remove}
                    change={change}
                    touch={touch}
                    onCalculationDataChange={this.onCalculationDataChange}
                    onPersonalInfoChange={this.updateInsuredPersonalInfo}
                    onRemoveInsuredAdult={this.onRemoveInsuredAdult}
                    titleError={insuredsDuplicationError}
                    showNationalRegisterNumberInfo={!!secondInsuredAdultNationalRegisterNumber}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <PremiumBox
                  disabled={
                              isOneTimeOnlyViolation ||
                              !this.isCalculationFieldsValid() ||
                              metadata.blocked
                  }
                />
              </Grid>
            </Grid>
            {metadata.productVersion === PRODUCT_VERSIONS.V_2019_10 && !isOneAdult && (
              <Grid item xs={12}>
                <Field
                  name="careClause"
                  label={intl.formatMessage({
                    id: 'insured.includeCareClause',
                  })}
                  disabled={isOneTimeOnlyViolation || metadata.blocked}
                  hint={
                              <div style={{maxWidth: '183px'}}>
                      {intl.formatMessage({
                        id: 'insured.includeCareClauseHint',
                      })}
                    </div>
                  }
                  component={CheckboxControl}
                  type="checkbox"
                  validate={fields.careClause.validate}
                  onChange={(e, newValue) => {
                    this.touchCareClauseDependentProps();
                    change('careClause', newValue);
                    setTimeout(this.onCalculationDataChange);
                  }}
                  hideMeta
                />
              </Grid>
            )}
            {this.renderRemoveAdultSuggestionDialog()}
            <Grid item xs={12}>
              <hr className="offer-details-hr" />
            </Grid>
            <Grid item xs={12} className="share-offer">
              <h3>
                <FormattedMessage id="offerDetails.shareOffer" />
              </h3>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="policyHolder.contactInformation.contactLanguage"
                label={intl.formatMessage({
                  id: 'fields.contactLanguagePrinting.label',
                })}
                className="contact-language"
                labelClassName="contact-language-label"
                disabled={metadata.blocked}
                component={DropdownControl}
                options={contactLanguages}
              />
            </Grid>
            <Grid item xs={12}>
              <span className="share-offer-label">
                <FormattedMessage id="offerDetails.shareOptionsLabel" />
              </span>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="sharedByPrinting"
                label={intl.formatMessage({
                  id: 'fields.sharedByPrinting.label',
                })}
                disabled={metadata.blocked}
                component={CheckboxControl}
                type="checkbox"
                onChange={this.handleSharedByPrintingClick}
                hideMeta
              />
            </Grid>
            {printingArea}
            {/* TODO: Enable when connective bug is fixed (BROKP-2912) */}
            <Grid item xs={12}>
              <Field
                name="sharedByEmail"
                label={intl.formatMessage({
                  id: 'fields.sharedByEmail.label',
                })}
                disabled={metadata.blocked}
                component={CheckboxControl}
                type="checkbox"
                onChange={this.handleSharedByEmailClick}
                hideMeta
              />
            </Grid>
            {emailArea}
          </Grid>
        </FormWrapper>
      </>
    );
    return (
      <div className="offer-details">
        {fetching && <div className="loader" />}
        {!fetching && offerForm}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  return (rootState) => {
    const selectIsOneAdult = makeSelectIsOneAdult();
    const selectIsOneTimeOnly = makeSelectIsOneTimeOnly();
    const selectIsOneTimeOnlyViolation = makeSelectIsOneTimeOnlyViolation();
    const selectInitialValues = makeSelectInitialValues();
    const selectPolicyHolderCopyMap = makeSelectPolicyHolderCopyMap();
    return {
      fetching: selectIsFormFetching(rootState),
      saving: selectIsSaving(rootState),
      printingStatus: selectIsPrintingStatus(rootState),
      sendingStatus: selectIsSendingStatus(rootState),
      printingFailedStatus: selectIsPrintingFailedStatus(rootState),
      offer: selectOffer(rootState),
      isResendButton: selectEmailDeliveryStatus(rootState),
      metadata: selectMetadata(rootState),
      contactLanguages: selectContactLanguages(rootState),
      formErrors: selectFormErrors(rootState),
      isSharedByPrinting: selectSharedByPrintingFormValue(rootState),
      isSharedByEmail: selectSharedByEmailFormValue(rootState),
      previousPaymentFrequency: selectPreviousPaymentFrequency(rootState),
      paymentFrequency: selectPaymentFrequencyFormValue(rootState),
      isOneAdult: selectIsOneAdult(rootState),
      isOneTimeOnly: selectIsOneTimeOnly(rootState),
      isOneTimeOnlyViolation: selectIsOneTimeOnlyViolation(rootState),
      initialValues: selectInitialValues(rootState),
      paymentFrequencies: selectPaymentFrequencies(rootState),
      policyHolderCopyMap: selectPolicyHolderCopyMap(rootState),
      isPostalCodeValid: selectIsPostalCodeValid(rootState),
      municipalities: selectMunicipalities(rootState),
      isFetchingMunicipalities: selectIsFetchingMunicipalities(rootState),
      selectedMunicipality: selectMunicipalityFormValue(rootState),
      isMunicipalityDisabled: selectIsMunicipalityDisabled(rootState),
      isStreetNameDisabled: selectIsStreetNameDisabled(rootState),
      isHouseNumberDisabled: selectIsHouseNumberDisabled(rootState),
      showBirthDateInfo: selectIsShowBirthDateInfo(rootState),
      isShowUndelivered: selectEmailDeliveryUndeliveredStatus(rootState),
      isShowInfoPanel: selectFailedStatusAndEmailDeliveryStatus(rootState),
      isEmailInfoShown: selectIsEmailNotificationShown(rootState),
      insuredsDuplicationError: selectInsuredDuplicationError(rootState),
      yesNo: selectYesNo(rootState),
      insuredIsPolicyHolder: selectInsuredIsPolicyHolder(rootState),
      hasResidentialAddressSaveError: selectHasResidentialAddressSaveError(rootState),
      firstInsuredAdultNationalRegisterNumber: selectFirstInsuredAdultNationalRegisterNumberFormValue(rootState),
      secondInsuredAdultNationalRegisterNumber: selectSecondInsuredAdultNationalRegisterNumberFormValue(rootState)
    };
  };
};

export default withRouter(
  injectIntl(
    connect(makeMapStateToProps, {
      fetchOffer: fetchOffer.request,
      shareByPrinting: shareByPrinting.request,
      shareByEmail: shareByEmail.request,
      fetchCalculationData: fetchCalculationData.request,
      fetchCompleteness: fetchCompleteness.request,
      clearOffer: fetchOffer.clear,
      clearServerErrors: setServerErrors.clear,
      clearNavigationPanel: fetchCompleteness.clear,
      setPreviousPaymentFrequency: setPreviousPaymentFrequency.action,
      checkIfCalculationOutdated: checkIfCalculationOutdated.action,
      updatePersonalInformation: (insuredId, formId) =>
            updatePersonalInformation.action({insuredId, formId}),
         fetchMunicipalities: fetchMunicipalities.request,
      clearMunicipalities: fetchMunicipalities.clear,
      clearShareByPrinting: shareByPrinting.clear,
      clearShareByEmail: shareByEmail.clear,
    })(
      reduxForm({
        form: offerContainerId,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        asyncValidate,
        asyncChangeFields: [POSTAL_CODE_FIELD_NAME],
        onSubmitFail: scrollToInvalidInput,
        shouldAsyncValidate: (params) => ({
          ...params,
          trigger: 'change',
          syncValidationPasses: true,
        }),
      })(OfferDetails)
    )
  )
);
