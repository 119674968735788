import { Link } from 'react-router-dom';
import classNames from 'classnames';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

import { formatCurrency } from 'shared/services/utils';

import {
  AK_CODE_SORT_PARAMETER,
  PORTFOLIO_TYPES,
  PROCESS_STATUS_CLASSNAME,
  STEPS,
} from '../../../constants';
import { FormattedMessage } from '../../FormattedMessage';
import OverflowTooltip from '../../OverflowTooltip';

import { SortIcon } from './SortIcon';

import './styles.scss';

export const ROUTE = {
  [PORTFOLIO_TYPES.OFFER]: '/sales-process/offer/',
  [PORTFOLIO_TYPES.PROPOSAL]: '/sales-process/proposals/',
  [PORTFOLIO_TYPES.POLICY]: '/sales-process/policies/',
  DEFAULT: '/sales-process/portfolio/',
};

export const STATE = {
  OFFER: 'OFFER',
  PROPOSAL: 'PROPOSAL',
  POLICY: 'POLICY',
};

export const setName = (name) => (name === 'N/A' ? <FormattedMessage id="misc.na" /> : name);

export const nameCell = ({ policyHolderFirstName, policyHolderSurname }) => (
  <OverflowTooltip>
    {setName(policyHolderFirstName)} {setName(policyHolderSurname)}
  </OverflowTooltip>
);

export const statusCell = (status) => {
  if (!status) {
    return null;
  }
  const className = get(PROCESS_STATUS_CLASSNAME, status);

  return (
    <span className={classNames('status', className && `status-${className}`)}>
      <FormattedMessage id={`salesProcess.statuses.${status}`} />
    </span>
  );
};

export const productNameCell = (name) => {
  const messageId = name === null ? 'misc.na' : `policyDetails.productName.${name}`;

  return (
    <span>
      <FormattedMessage id={messageId} />
    </span>
  );
};

const isValueHiddenBasedOnAkCode = (akCode) => akCode === null || akCode === 0 || akCode === 1;

const akCodeCell = (akCode) => isValueHiddenBasedOnAkCode(akCode) ? null : akCode;

const premiumBalanceCell = (premiumBalance, akCode) => {
  if (isValueHiddenBasedOnAkCode(akCode)) {
    return null;
  }

  return premiumBalance ? formatCurrency(premiumBalance) : premiumBalance;
};

export const stateCell = (state) => (
  <span>
    <FormattedMessage id={`misc.${String(state).toLowerCase()}`} />
  </span>
);

export const daysBeforeDiscardCell = (daysBeforeDiscard) => {
  if (!isNumber(daysBeforeDiscard)) {
    return null;
  }
  const count = daysBeforeDiscard === 1 ? 1 : 2;

  return (
    <div className="days-before-discard">
      <div className="days-before-discard-alert">
        {daysBeforeDiscard} <FormattedMessage values={{ count }} id="table.days" />
      </div>
      <FormattedMessage id="table.beforeDiscard" />
    </div>
  );
};

export const getProposalRoute = (id, step) =>
  step && STEPS.hasOwnProperty(step)
    ? `${ROUTE[PORTFOLIO_TYPES.PROPOSAL]}${id}/${STEPS[step]}`
    : `${ROUTE[PORTFOLIO_TYPES.PROPOSAL]}${id}`;

export const getPath = ({ state, id, offerId, step }) => {
  switch (state) {
    case STATE.OFFER: {
      return ROUTE[PORTFOLIO_TYPES.OFFER] + offerId;
    }
    case STATE.PROPOSAL: {
      return getProposalRoute(id, step);
    }
    case STATE.POLICY: {
      return ROUTE[PORTFOLIO_TYPES.POLICY] + id;
    }
    default: {
      return ROUTE.DEFAULT;
    }
  }
};

export const getLink = (path, children) => <Link to={path}>{children}</Link>;

const createColumn = ({ messageProps, id, className }) => {
  const Header = messageProps?.id ? <FormattedMessage id={messageProps.id} /> : '';

  return {
    Header,
    id,
    className,
  };
};

const createColumnWithIcon = ({ messageProps, id, className, iconComponent }) => {
  const Header = (
    <span className="table-header">
      {messageProps?.id ? <FormattedMessage id={messageProps.id} /> : ''}
      {iconComponent}
    </span>
  );

  return {
    Header,
    id,
    className,
  };
};

const idColumn = createColumn({
  messageProps: { id: 'table.id' },
  id: 'number',
  className: 'Number',
});
const stateColumn = createColumn({ messageProps: { id: 'table.type' }, id: 'state' });
const nameColumn = createColumn({ messageProps: { id: 'table.name' }, id: 'name' });
const productNameColumn = createColumn({
  messageProps: { id: 'table.productName' },
  id: 'productName',
});
const akCodeColumn = createColumnWithIcon({
  messageProps: { id: 'table.akCode' },
  id: 'akCode',
  iconComponent: <SortIcon sortingParameter={AK_CODE_SORT_PARAMETER} />,
});
const premiumBalanceColumn = createColumn({
  messageProps: { id: 'table.premiumBalance' },
  id: 'premiumBalance',
});
const statusColumn = createColumn({
  messageProps: { id: 'table.status' },
  id: 'status',
  className: 'Status',
});
const daysBeforeDiscardColumn = createColumn({
  id: 'daysBeforeDiscard',
  className: 'DaysBeforeDiscard',
});

const offerColumns = [
  {
    ...idColumn,
    accessor: ({ offerId, number }) => getLink(getPath({ state: STATE.OFFER, offerId }), number),
  },
  {
    ...nameColumn,
    accessor: ({ offerId, policyHolderFirstName, policyHolderSurname }) =>
      getLink(
        getPath({ state: STATE.OFFER, offerId }),
        nameCell({ policyHolderFirstName, policyHolderSurname })
      ),
  },
  {
    ...statusColumn,
    accessor: ({ offerId, status }) =>
      getLink(getPath({ state: STATE.OFFER, offerId }), statusCell(status)),
  },
];

const proposalColumns = [
  {
    ...idColumn,
    accessor: ({ id, step, number }) =>
      getLink(getPath({ state: STATE.PROPOSAL, id, step }), number),
  },
  {
    ...nameColumn,
    accessor: ({ id, step, policyHolderFirstName, policyHolderSurname }) =>
      getLink(
        getPath({ state: STATE.PROPOSAL, id, step }),
        nameCell({ policyHolderFirstName, policyHolderSurname })
      ),
  },
  {
    ...statusColumn,
    accessor: ({ id, step, status }) =>
      getLink(getPath({ state: STATE.PROPOSAL, id, step }), statusCell(status)),
  },
  {
    ...daysBeforeDiscardColumn,
    accessor: ({ id, step, daysBeforeDiscard }) =>
      getLink(
        getPath({ state: STATE.PROPOSAL, id, step }),
        daysBeforeDiscardCell(daysBeforeDiscard)
      ),
  },
];

const policyColumns = [
  {
    ...idColumn,
    accessor: ({ id, number }) => getLink(getPath({ state: STATE.POLICY, id }), number),
  },
  {
    ...nameColumn,
    accessor: ({ id, policyHolderFirstName, policyHolderSurname }) =>
      getLink(
        getPath({ state: STATE.POLICY, id }),
        nameCell({ policyHolderFirstName, policyHolderSurname })
      ),
  },
  {
    ...productNameColumn,
    accessor: ({ id, productName }) =>
      getLink(getPath({ state: STATE.POLICY, id }), productNameCell(productName)),
  },
  {
    ...akCodeColumn,
    accessor: ({ id, akCode }) => getLink(getPath({ state: STATE.POLICY, id }), akCodeCell(akCode)),
  },
  {
    ...premiumBalanceColumn,
    accessor: ({ id, premiumBalance, akCode }) =>
      getLink(getPath({ state: STATE.POLICY, id }), premiumBalanceCell(premiumBalance, akCode)),
  },
  {
    ...statusColumn,
    accessor: ({ id, policyStatus }) =>
      getLink(getPath({ state: STATE.POLICY, id }), statusCell(policyStatus)),
  },
];

const allColumns = [
  {
    ...idColumn,
    accessor: ({ state, step, id, offerId, number }) =>
      getLink(getPath({ state, offerId, id, step }), number),
  },
  {
    ...stateColumn,
    accessor: ({ state, step, id, offerId }) =>
      getLink(getPath({ state, offerId, id, step }), stateCell(state)),
  },
  {
    ...nameColumn,
    accessor: ({ state, step, id, offerId, policyHolderFirstName, policyHolderSurname }) =>
      getLink(
        getPath({ state, offerId, id, step }),
        nameCell({ policyHolderFirstName, policyHolderSurname })
      ),
  },
  {
    ...statusColumn,
    accessor: ({ state, step, id, offerId, policyStatus, status }) =>
      getLink(
        getPath({ state, offerId, id, step }),
        statusCell(state === STATE.POLICY ? policyStatus : status)
      ),
  },
];

export const PORTFOLIO_COLUMNS_MAP = {
  [PORTFOLIO_TYPES.OFFER]: offerColumns,
  [PORTFOLIO_TYPES.PROPOSAL]: proposalColumns,
  [PORTFOLIO_TYPES.POLICY]: policyColumns,
  [PORTFOLIO_TYPES.ALL]: allColumns,
};
