import AppBar from '@mui/material/AppBar';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';

type AppToolbarProps = {
   inProgress?: boolean;
   children: React.ReactNode;
};

const AppToolbar = ({inProgress = false, children}: AppToolbarProps) => {
   return (
      <>
         <AppBar>
            <Toolbar sx={{paddingX: 1}} disableGutters>
               {children}
            </Toolbar>
            {inProgress && <LinearProgress />}
         </AppBar>
         {/*https://material-ui.com/components/app-bar/#fixed-placement*/}
         <Toolbar />
      </>
   );
};

export default AppToolbar;
