import {OnErrorFn} from '@formatjs/intl/src/types';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/fr';
import React from 'react';
import {IntlProvider} from 'react-intl';
import {Locale} from 'v2/common/providers/LocalizationProvider/types';
import {getMessages, handleLocalizationError} from 'v2/common/providers/LocalizationProvider/utils';

type LocalizationProviderProps = {
   locale: Locale;
   children: React.ReactNode;
};

const LocalizationProvider = ({locale, children}: LocalizationProviderProps) => {
   React.useEffect(() => {
      moment.locale(locale);
   }, [locale]);

   const handleIntlProviderError: OnErrorFn = (error) => {
      handleLocalizationError({locale, error});
   };

   const messages = React.useMemo(() => getMessages(locale), [locale]);

   return (
      <IntlProvider locale={locale} messages={messages} onError={handleIntlProviderError}>
         {children}
      </IntlProvider>
   );
};

export default LocalizationProvider;
