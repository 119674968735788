import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {logIn as logInAction} from 'containers/App/reducer';
import {selectIsLoginInProgress, selectIsPostponedProcessFailed} from 'containers/App/selectors';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {SavedCalculatePremiumService} from 'shared/services/SavedCalculatePremium';
import {ProducerAccount, useAgent} from 'v2/common/api/queries/agent';

const ProducerAccounts = () => {
   const intl = useIntl();
   const [selectedOption, setSelectedOption] = React.useState<Nullable<ProducerAccount>>(null);
   const isLoginInProgress = useSelector(selectIsLoginInProgress);
   const postponedProcessFailed = useSelector(selectIsPostponedProcessFailed);
   const {data, isLoading} = useAgent();
   const dispatch = useDispatch();
   const handleClick = () => {
      if (selectedOption) {
         dispatch(logInAction.request(selectedOption));
      }
   };

   return (
      <Box flex="1" paddingTop={10} paddingX={5}>
         <Typography marginBottom={2}>
            <FormattedMessage id="login.producerAccounts.title" />
         </Typography>
         <Autocomplete
            disablePortal
            loading={isLoading}
            value={selectedOption}
            renderOption={(props, option: ProducerAccount) => (
               <Box
                  component="li"
                  sx={{
                     flexDirection: 'column',
                     [`&.${autocompleteClasses.option}`]: {
                        alignItems: 'flex-start',
                     },
                  }}
                  {...props}
                  key={option.value}
               >
                  <Typography>
                     {option.label} ({option.value})
                  </Typography>
                  {option.type !== 'OWN' && (
                     <Typography fontWeight="bold">
                        <FormattedMessage id={`accountTypes.${option.type}`} />
                     </Typography>
                  )}
               </Box>
            )}
            renderInput={(params) => (
               <TextField
                  {...params}
                  placeholder={intl.formatMessage({id: 'login.producerAccounts.placeholder'})}
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <>
                           {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                           {params.InputProps.endAdornment}
                        </>
                     ),
                  }}
               />
            )}
            options={data?.producerAccounts || []}
            onChange={(_, value) => setSelectedOption(value)}
            noOptionsText={
               <>
                  <Typography>
                     <FormattedMessage id="login.producerAccounts.noData" />
                  </Typography>
                  <Typography>
                     <FormattedMessage id="login.producerAccounts.contactBroker" />
                  </Typography>
               </>
            }
         />
         <LoadingButton
            variant="contained"
            color="secondary"
            fullWidth
            sx={{marginTop: 3}}
            loading={isLoginInProgress}
            disabled={!selectedOption}
            onClick={handleClick}
         >
            <FormattedMessage id="login.producerAccounts.continue" />
         </LoadingButton>
         {postponedProcessFailed && <SkipButton onClick={handleClick} />}
      </Box>
   );
};

const SkipButton = ({onClick}: {onClick: () => void}) => {
   const type = SavedCalculatePremiumService.getSavedDataType();
   const labelId =
      type === 'offer'
         ? 'login.producerAccounts.continueWithoutCreatingOffer'
         : 'login.producerAccounts.continueWithoutCreatingProposal';

   const handleClick = () => {
      SavedCalculatePremiumService.clear();
      onClick();
   };

   return (
      <Stack direction="row" marginTop={2} alignItems="center" spacing={1}>
         <Typography variant="body2">
            <FormattedMessage id={'login.producerAccounts.or'} />
         </Typography>
         <Link component="button" variant="body2" onClick={handleClick}>
            <FormattedMessage id={labelId} />
         </Link>
      </Stack>
   );
};

export default ProducerAccounts;
