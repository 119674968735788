import {call, put, takeLatest} from 'redux-saga/effects';
import {history} from '../../../history';
import {api, toastHandler} from '../../../shared/services';
import {closeDrawer} from '../../../v2/features/app-drawer/appDrawerSlice';
import {initiateSalesProcess, setSalesProcessType} from './reducer';

const route = (type, id) =>
   type === 'proposal' ? `/sales-process/proposals/${id}` : `/sales-process/offer/${id}`;

function* initiateSalesProcessSaga({payload}) {
   const {type, ...request} = payload;

   try {
      yield put(setSalesProcessType.action(type));
      const {id} = yield call(api[type].initiate, request);
      yield put(initiateSalesProcess.success());
      yield call(finalizeCreationProccess, type, id);
   } catch (error) {
      yield put(initiateSalesProcess.failure(error));
      toastHandler.unexpectedIssue();
   }
}

export function* finalizeCreationProccess(type, id) {
   yield put(closeDrawer());
   history.push(route(type, id));
}

export default function* initiateSalesProcessSagas() {
   yield takeLatest(initiateSalesProcess.REQUEST, initiateSalesProcessSaga);
}
