import React from 'react';
import { FormattedMessage } from 'react-intl';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import Box from '@mui/material/Box';

import { DELActiveIcon } from 'common/styles/icons/DelActiveIcon';
import LocaleSwitcher from 'v2/common/components/LocaleSwitcher';
import LogoutButton from 'v2/common/components/LogoutButton/LogoutButton';
import { ContactsContent } from 'v2/features/app-drawer/components/ContactsContent/ContactsContent';
import { DELActiveContent } from 'v2/features/app-drawer/components/DelActiveContent/DelActiveContent';
import MenuPanel from 'v2/features/app-drawer/components/MenuPanel';
import ProductInfoContent from 'v2/features/app-drawer/components/ProductInfoContent';
import ToolsContent from 'v2/features/app-drawer/components/ToolsContent';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

import { ProducerAccountInfo } from './ProducerAccountInfo';

enum MenuPanelType {
  Product,
  Tools,
  Contacts,
  DELActive,
}

export function Menu() {
  const hasProductInfoPermission = usePermissions(Permission.ProductInfo);
  const hasToolsPermission = usePermissions(Permission.Tools);
  const hasContactsPermission = usePermissions(Permission.Contacts);
  const hasDelaActivePermisson = usePermissions(Permission.DELActive);

  const [panel, setPanel] = React.useState<MenuPanelType | false>(MenuPanelType.Product);
  const handleChange =
    (nextPanel: MenuPanelType) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setPanel(isExpanded ? nextPanel : false);
    };

  return (
    <>
      <ProducerAccountInfo />
      {hasProductInfoPermission && (
        <MenuPanel
          expanded={panel === MenuPanelType.Product}
          icon={<InfoRoundedIcon />}
          title={<FormattedMessage id="appDrawer.productInfo.header" />}
          onChange={handleChange(MenuPanelType.Product)}
        >
          <ProductInfoContent />
        </MenuPanel>
      )}
      {hasToolsPermission && (
        <MenuPanel
          expanded={panel === MenuPanelType.Tools}
          icon={<BusinessCenterIcon />}
          title={<FormattedMessage id="appDrawer.tools.header" />}
          onChange={handleChange(MenuPanelType.Tools)}
        >
          <ToolsContent />
        </MenuPanel>
      )}
      {hasContactsPermission && (
        <MenuPanel
          expanded={panel === MenuPanelType.Contacts}
          icon={<PhoneRoundedIcon />}
          title={<FormattedMessage id="appDrawer.contacts.header" />}
          onChange={handleChange(MenuPanelType.Contacts)}
        >
          <ContactsContent />
        </MenuPanel>
      )}
      {hasDelaActivePermisson && (
        <MenuPanel
          expanded={panel === MenuPanelType.DELActive}
          icon={<DELActiveIcon sx={{ width: 'auto', height: '0.75em' }} />}
          onChange={handleChange(MenuPanelType.DELActive)}
        >
          <DELActiveContent />
        </MenuPanel>
      )}
      <Box marginTop={3} display="flex" justifyContent="space-between" justifySelf="flex-end">
        <LocaleSwitcher size="small" />
        <LogoutButton sx={{ flexGrow: 1, marginLeft: 2 }} variant="contained" color="secondary">
          <FormattedMessage id="appDrawer.logOut" />
        </LogoutButton>
      </Box>
    </>
  );
}
