import isArray from 'lodash/isArray';
import {take} from 'redux-saga/effects';

export const createTab = () => {
   const tab = window.open();
   const style = document.createElement('style');

   const css = `
        .loader {
            position: fixed;
            left: 50%;
            top: 50%;
            display: block;
            border: 5px solid #ebebeb;
            border-top: 5px solid #007fa4;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            margin: -80px 0 0 -25px;
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }`;
   tab.document.head.appendChild(style);
   style.appendChild(document.createTextNode(css));
   tab.document.body.innerHTML = `<div class="loader" />`;
   return tab;
};

export const hasMsSaveOrOpenBlobMethod = () =>
   window.navigator && window.navigator.msSaveOrOpenBlob;

export const showFile = (blob, tab, fileName) => {
   const newBlob = new Blob([blob], {type: 'application/pdf'});
   if (hasMsSaveOrOpenBlobMethod()) {
      window.navigator.msSaveOrOpenBlob(newBlob, `${fileName}.pdf`);
      return;
   }
   tab.document.title = fileName;
   const data = URL.createObjectURL(newBlob);
   const embed = document.createElement('embed');
   embed.src = data;
   embed.type = 'application/pdf';
   embed.width = '100%';
   embed.height = '100%';

   const generatingInfo = tab.document.body.querySelector('.loader');
   tab.document.body.removeChild(generatingInfo);
   tab.document.body.style.margin = 0;
   tab.document.body.appendChild(embed);
};

export const downloadFile = (blob, fileName) => {
   let newBlob = new Blob([blob]);
   if (!!hasMsSaveOrOpenBlobMethod()) {
      window.navigator.msSaveOrOpenBlob(newBlob, fileName);
   } else {
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.click();
      setTimeout(() => {
         window.URL.revokeObjectURL(data);
      }, 100);
   }
};

export const takeAction = (action) => {
   const actions = isArray(action) ? action : [action];

   const registeredActionTypes = actions.reduce((acc, action) => {
      const actionTypes = action.actionToHandlerMap.keys();
      acc.push(...actionTypes);
      return acc;
   }, []);
   return take(registeredActionTypes);
};
