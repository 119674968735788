import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import React from 'react';

export const LogoutIcon = (props: SvgIconProps) => {
   return (
      <SvgIcon {...props} viewBox="0 0 20 24">
         <g>
            <g transform="translate(-1328 -21)">
               <g transform="translate(1309)">
                  <g transform="translate(19 21)">
                     <path d="m14.7 6.0396e-14c0.60656 0 1.1025 0.53697 1.1025 1.1933v4.1368h-2.205v-2.3468c0-0.33809-0.23887-0.59663-0.55125-0.59663h-8.7832l4.9429 2.7445c0.34912 0.19888 0.588 0.61652 0.588 1.054v10.123h3.2524c0.31237 0 0.55125-0.25854 0.55125-0.59663v-4.7333h2.205v6.5232c0 0.65649-0.49594 1.1933-1.1025 1.1933h-4.9245v4.5742c0 0.45762-0.441 0.73585-0.8085 0.53697l-8.379-4.6937c-0.34912-0.19888-0.588-0.61652-0.588-1.0538v-16.865c0-0.65629 0.49612-1.1933 1.1025-1.1933zm1.7609 5.1152 3.5391 2.7174-3.5391 2.6414v-1.4818h-3.3745v-2.3735h3.3745v-1.5036z" />
                  </g>
               </g>
            </g>
         </g>
      </SvgIcon>
   );
};
