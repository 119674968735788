import React from 'react';

export function useToggle(initialState: boolean) {
   const [visible, setVisible] = React.useState(initialState);
   const show = () => setVisible(true);
   const hide = () => setVisible(false);
   const toggle = () => setVisible((prevState) => !prevState);

   return {visible, show, hide, toggle};
}

export function useDialog(initialState: boolean) {
   const [open, setOpen] = React.useState(initialState);
   return React.useMemo(
      () => ({
         open,
         show: () => setOpen(true),
         close: () => setOpen(false),
      }),
      [open]
   );
}
