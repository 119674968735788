import React from 'react';
import { PORTFOLIO_TYPES } from 'shared/constants';
import { PortfolioParams } from 'v2/common/api/queries/portfolio';
import { useSearchParams } from 'v2/common/hooks/router';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

export function useInitialPortfolioType() {
  const hasProposalPermissions = usePermissions(Permission.ProposalList);
  return hasProposalPermissions ? PORTFOLIO_TYPES.PROPOSAL : PORTFOLIO_TYPES.POLICY;
}

export function useAvailablePortfolioTypes() {
  let types = [];
  const hasOfferPermissions = usePermissions(Permission.OfferList);
  const hasProposalPermissions = usePermissions(Permission.ProposalList);
  const hasPolicyPermissions = usePermissions(Permission.PolicyList);
  if (hasOfferPermissions) {
    types.push(PORTFOLIO_TYPES.OFFER);
  }
  if (hasProposalPermissions) {
    types.push(PORTFOLIO_TYPES.PROPOSAL);
  }
  if (hasPolicyPermissions) {
    types.push(PORTFOLIO_TYPES.POLICY);
  }
  return types;
}

export function usePortfolioParams(): [PortfolioParams, (params: PortfolioParams) => void] {
  const [{ type, search, sort, page }, setSearchParams] = useSearchParams<PortfolioParams>();
  const initialPortfolioType = useInitialPortfolioType();
  const params = React.useMemo(
    () => ({
      type: type || initialPortfolioType,
      search: search || '',
      page: page ? +page : 1,
      sort: sort || '',
    }),
    [initialPortfolioType, page, search, sort, type]
  );

  return [params, setSearchParams];
}
