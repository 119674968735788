import {useIsAuthenticated} from '@azure/msal-react';
import {yupResolver} from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {selectYesNo} from 'shared/references/selectors';
import {PremiumCalculationParams} from 'v2/common/api/queries/premiumCalculation';
import ErrorMessage from 'v2/common/components/ErrorMessage';
import RadioGroupField from 'v2/common/components/RadioGroupField';
import TextField from 'v2/common/components/TextField';
import Yup from 'v2/common/utils/yup';
import {usePermissions} from 'v2/features/permissions/hooks';
import {Permission} from 'v2/features/permissions/types';

export enum SalesProcessType {
   Offer = 'salesProcessOffer',
   Proposal = 'salesProcessProposal',
}

type InitiateSalesProcessProps = {
   premiumValues: PremiumCalculationParams;
   isOneAdult: boolean;
   onSuccess: () => void;
   onSalesProcessInitiate: (
      type: SalesProcessType,
      values: InitiateSalesProcessFormValues
   ) => Promise<void>;
   isOfferLoading?: boolean;
   isProposalLoading?: boolean;
};

const premiumCalculationSchema = Yup.object().shape({
   policyHolder: Yup.object().shape({
      participatingAsInsuredAdult: Yup.string().nullable().required(),
      personalInformation: Yup.object().shape({
         firstName: Yup.string().nullable().checkSuspiciousSymbolsFirstname().required().trimWhitespaces(),
         surname: Yup.string().nullable().checkSuspiciousSymbolsSurname().required().trimWhitespaces(),
      }),
   }),
});

export type InitiateSalesProcessFormValues = PremiumCalculationParams & {
   policyHolder: {
      participatingAsInsuredAdult: 'YES' | 'NO';
      personalInformation: {
         firstName: string;
         surname: string;
      };
   };
};

const InitiateSalesProcess = ({
   premiumValues,
   isOneAdult,
   onSuccess,
   onSalesProcessInitiate,
   isOfferLoading,
   isProposalLoading,
}: InitiateSalesProcessProps) => {
   const yesNoReference = useSelector(selectYesNo);
   const isAuthenticated = useIsAuthenticated();
   const hasCreateOfferPermission = usePermissions(Permission.CreateOffer) || !isAuthenticated;
   const hasCreateProposalPermission =
      usePermissions(Permission.CreateProposal) || !isAuthenticated;
   const {control, handleSubmit} = useForm<InitiateSalesProcessFormValues>({
      defaultValues: {
         ...premiumValues,
         policyHolder: {
            participatingAsInsuredAdult: 'YES',
            personalInformation: {
               firstName: '',
               surname: '',
            },
         },
      },
      mode: 'onChange',
      resolver: yupResolver(premiumCalculationSchema),
      shouldFocusError: true,
   });

   const createOffer = handleSubmit(async (data) => {
      await onSalesProcessInitiate(SalesProcessType.Offer, data);
      onSuccess();
   });

   const createProposal = handleSubmit(async (data) => {
      await onSalesProcessInitiate(SalesProcessType.Proposal, data);
      onSuccess();
   });

   const firstNameRef = React.useRef<HTMLInputElement | null>(null);
   React.useLayoutEffect(() => {
      if (firstNameRef.current) {
         firstNameRef.current.scrollIntoView({
            behavior: 'smooth',
         });
         // Workaround for Safari
         setTimeout(() => firstNameRef.current?.focus({preventScroll: true}), 500);
      }
   }, []);

   return (
      <form autoComplete="off">
         <Grid container columnSpacing={2}>
            <Grid item xs={12}>
               <Typography variant="h6">
                  <FormattedMessage id="misc.policyHolder" />
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <Controller
                  name="policyHolder.participatingAsInsuredAdult"
                  control={control}
                  render={({field}) => (
                     <RadioGroupField
                        {...field}
                        label={
                           <FormattedMessage
                              id={`fields.makeInsuredAsPolicyHolder.${
                                 isOneAdult ? 'oneAdult' : 'twoAdults'
                              }`}
                           />
                        }
                        name="policyHolder.participatingAsInsuredAdult"
                        options={yesNoReference}
                        row
                     />
                  )}
               />
            </Grid>
            <Grid item xs={6}>
               <Controller
                  name="policyHolder.personalInformation.firstName"
                  control={control}
                  render={({field, fieldState}) => (
                     <TextField
                        label={<FormattedMessage id="fields.firstName.label" />}
                        error={fieldState.invalid}
                        helperText={<ErrorMessage value={fieldState.error?.message} />}
                        inputProps={{maxLength: 50}}
                        {...field}
                        ref={(instance) => {
                           field.ref(instance);
                           firstNameRef.current = instance;
                        }}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={6}>
               <Controller
                  name="policyHolder.personalInformation.surname"
                  control={control}
                  render={({field, fieldState}) => (
                     <TextField
                        label={<FormattedMessage id="fields.surname.label" />}
                        error={fieldState.invalid}
                        helperText={<ErrorMessage value={fieldState.error?.message} />}
                        inputProps={{maxLength: 50}}
                        {...field}
                     />
                  )}
               />
            </Grid>
            <Grid item xs={6}>
               {hasCreateOfferPermission && (
                  <LoadingButton
                     fullWidth
                     type="button"
                     variant="contained"
                     color="secondary"
                     loading={isOfferLoading}
                     disabled={isProposalLoading}
                     data-test-id={SalesProcessType.Offer}
                     onClick={createOffer}
                  >
                     <FormattedMessage id="calculatePremium.createOffer" />
                  </LoadingButton>
               )}
            </Grid>
            <Grid item xs={6}>
               {hasCreateProposalPermission && (
                  <LoadingButton
                     fullWidth
                     type="button"
                     variant="contained"
                     color="secondary"
                     loading={isProposalLoading}
                     disabled={isOfferLoading}
                     data-test-id={SalesProcessType.Proposal}
                     onClick={createProposal}
                  >
                     <FormattedMessage id="calculatePremium.createProposal" />
                  </LoadingButton>
               )}
            </Grid>
         </Grid>
      </form>
   );
};

export default InitiateSalesProcess;
