import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {formatCurrency} from 'shared/services/utils';
import {PAYMENT_FREQUENCY} from '../../../../../shared/constants';
import {PaymentFrequency, PremiumDetails} from 'v2/common/api/queries/premiumCalculation';
import LegalInfoLink from 'shared/components/LegalInfoLink';
import Box from '@mui/material/Box';

type PremiumDetailsItemProps = {
   label: React.ReactNode;
   value: React.ReactNode;
   primary?: boolean;
};

const PremiumDetailsItem = ({label, value, primary = false}: PremiumDetailsItemProps) => {
   const typographyVariant = primary ? 'body1' : 'body2';
   const fontWeight = primary ? 'bold' : undefined;
   return (
      <Box display="flex" justifyContent="space-between">
         <Typography variant={typographyVariant} fontWeight={fontWeight}>
            {label}
         </Typography>
         <Typography variant={typographyVariant} fontWeight={fontWeight}>
            {value}
         </Typography>
      </Box>
   );
};

type PremiumDetailsResultProps = {
   results: PremiumDetails[];
   paymentFrequency: PaymentFrequency;
   isOneAdult: boolean;
};

const PremiumDetailsResult = ({
   results,
   paymentFrequency,
   isOneAdult,
}: PremiumDetailsResultProps) => {
   const isOneTimeOnlyViolation =
      !isOneAdult && paymentFrequency === PAYMENT_FREQUENCY.ONE_TIME_ONLY;

   const calculateTotal = (key: keyof PremiumDetails) => {
      const value = results.reduce((total, result) => {
         return total + result[key].amount;
      }, 0);
      return formatCurrency(value);
   };

   if (results === null || isOneTimeOnlyViolation) {
      return null;
   }

   return (
      <Paper
         sx={{
            marginY: 4,
            padding: 2,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
         }}
      >
         <Stack spacing={1}>
            <PremiumDetailsItem
               label={<FormattedMessage id="fields.paymentFrequency.label" />}
               value={<FormattedMessage id={`references.paymentFrequencies.${paymentFrequency}`} />}
               primary
            />
            {!isOneAdult &&
               results.map((result, index) => (
                  <PremiumDetailsItem
                     key={index}
                     label={
                        <FormattedMessage
                           id="calculatePremium.insuredAdult"
                           values={{index: ` ${index + 1}`}}
                        />
                     }
                     value={formatCurrency(result.premium.amount)}
                     primary
                  />
               ))}
         </Stack>
         <Divider sx={{marginY: 2}} light />
         <Stack spacing={1}>
            <PremiumDetailsItem
               label={<FormattedMessage id="calculatePremium.acquisitionCost" />}
               value={calculateTotal('acquisitionCosts')}
            />
            <PremiumDetailsItem
               label={<FormattedMessage id="calculatePremium.administrativeCost" />}
               value={calculateTotal('administrativeCosts')}
            />
            <PremiumDetailsItem
               label={<FormattedMessage id="calculatePremium.taxCost" />}
               value={calculateTotal('tax')}
            />
            <LegalInfoLink />
            <PremiumDetailsItem
               label={<FormattedMessage id="calculatePremium.grandTotal" />}
               value={calculateTotal('premium')}
               primary
            />
         </Stack>
      </Paper>
   );
};

export default PremiumDetailsResult;
