import { arrayOf, bool, elementType, node, number, object, oneOf, shape, string } from 'prop-types';

import { beneficiaryClauseType, warrantyType } from '../../common/enums';
import {
  confirmationTypes,
  funeralInsuranceStatuses,
  insuranceIntroducer,
  paymentFrequencies,
  paymentMethods,
  policyProductNames,
  yesNo,
} from '../references/types';

import {
  ACTION_STATUS,
  COVERAGE_STATUS,
  DOCUMENT_STATUS,
  LANGUAGES,
  LOCALES,
  PRODUCT_VERSIONS,
  SIGNING_METHODS,
} from './main';

export const personalInformationShape = shape({
  firstName: string,
  surname: string,
  gender: string,
  birthDate: string,
});
export const paymentFrequencyShape = oneOf(paymentFrequencies);
export const paymentFrequenciesShape = arrayOf(
  shape({
    label: string,
    value: paymentFrequencyShape,
  }).isRequired
);

export const funeralInsuranceStatusShape = oneOf([...funeralInsuranceStatuses, null]);

export const productVersionShape = oneOf(Object.values(PRODUCT_VERSIONS));

export const benefitItemShape = shape({
  amount: number,
});

export const benefitShape = shape({
  acquisitionCosts: benefitItemShape,
  administrativeCosts: benefitItemShape,
  capital: benefitItemShape,
  premium: benefitItemShape,
  tax: benefitItemShape,
});

const commonMetadataProps = {
  policyHolderFirstName: string,
  policyHolderSurname: string,
  state: string,
  status: string,
  blocked: bool,
  careClause: bool,
  paymentFrequency: paymentFrequencyShape,
  offerId: number,
  proposalId: number,
  policyId: number,
  salesProcessId: number,
  number: string,
  needsAndDesiresCompleted: bool,
  identityConfirmationType: string,
  policyHolderIdentityConfirmed: bool,
  insuredChildrenPresent: bool,
  oneAdult: bool,
  productVersion: productVersionShape,
};

export const OfferMetadataShape = shape({
  ...commonMetadataProps,
  indexation: bool,
});

export const ProposalMetadataShape = shape({
  ...commonMetadataProps,
  shouldHaveInsuredChildren: oneOf([...yesNo, null]),
  indexation: bool,
  idCardRequired: bool,
  paymentFrequencyDisabled: bool,
});

export const PolicyMetadataShape = shape({
  ...commonMetadataProps,
  policyStatus: string,
});

export const premiumsShape = shape({
  payUntilAge: number,
  benefit: benefitItemShape,
  premium: benefitItemShape,
});

export const paymentMethodsShape = oneOf(paymentMethods);

export const yesNoShape = oneOf(yesNo);

export const yesNoOptionsShape = arrayOf(
  shape({
    label: string,
    value: yesNoShape,
  })
);

export const insuranceIntroducerShape = oneOf(insuranceIntroducer);

export const insuranceIntroducerOptionsShape = arrayOf(
  shape({
    label: string,
    value: insuranceIntroducerShape,
  })
);

export const localeShape = oneOf(Object.values(LOCALES));

export const localesShape = arrayOf(
  shape({ label: oneOf(Object.keys(LOCALES)), value: localeShape })
);

export const policyProductNameShape = oneOf(policyProductNames);

export const serverErrorsShape = arrayOf(
  shape({
    errorCode: string,
    reason: string,
    errorUUID: string,
  })
);

export const producerAccountLabelShape = shape({
  value: string,
  label: string,
  type: string,
  permissions: arrayOf(string),
});

export const confirmationTypesShape = oneOf(confirmationTypes);

export const confirmationTypesOptionsShape = arrayOf(
  shape({
    label: string,
    value: confirmationTypesShape,
  })
);

export const signerDocumentShape = shape({
  id: number,
  documentName: string,
  documentId: string,
  status: oneOf(Object.values(DOCUMENT_STATUS)),
  proposalId: number,
  printable: bool,
});

export const idCardDocumentShape = shape({
  content: oneOf([object, null]),
  id: number,
  documentName: string,
  documentId: string,
  documentUri: string,
  status: oneOf(Object.values(DOCUMENT_STATUS)),
  printable: bool,
});

export const signerShape = shape({
  absentPolicyParty: bool,
  hasUnsignedDocuments: bool,
  documents: arrayOf(signerDocumentShape),
  personalInfo: shape({
    firstName: string,
    surname: string,
    email: string,
  }),
  language: oneOf(Object.values(LANGUAGES)),
  pendingSignedDocuments: bool,
  canSignDocuments: bool,
  hasPostponedDocuments: bool,
  signingError: string,
  status: oneOf(Object.values(DOCUMENT_STATUS)),
  sentToEmail: bool,
  expirationDate: string,
  signingMethod: oneOf(Object.values(SIGNING_METHODS)),
});

export const tooltipPlacementShape = oneOf(['top', 'right', 'bottom', 'left']);

export const actionStatusShape = oneOf(Object.values(ACTION_STATUS));

export const insuredAdultCoverageShape = shape({
  startDate: string,
  status: oneOf(Object.values(COVERAGE_STATUS)),
  warrantyTypes: arrayOf(oneOf(Object.values(warrantyType))),
  insuredBenefit: number,
  premium: number,
  deathBenefit: number,
  afterCareCapitalBenefit: number,
  payUntil: string,
  indexation: yesNoShape,
  waitingPeriodApplied: yesNoShape,
  extraPremiumApplied: yesNoShape,
});

export const policyInsuredAdultShape = shape({
  personalInformation: personalInformationShape,
  coverage: insuredAdultCoverageShape,
});

export const policyBeneficiaryShape = shape({
  personalInformation: personalInformationShape,
  clauseValue: string,
  clauseType: oneOf(Object.values(beneficiaryClauseType)),
  share: number,
});

export const svgIconPropsTypes = {
  children: node,
  classes: object,
  className: string,
  color: oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
  component: elementType,
  fontSize: oneOf(['default', 'inherit', 'large', 'small']),
  htmlColor: string,
  shapeRendering: string,
  titleAccess: string,
  viewBox: string,
};
