import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ActionButton from '../../../../../shared/components/ActionButton';
import { Widget } from '../Widget';

type StartSalesProcessButtonProps = {
  goToCalculator: () => void;
};

export const StartSalesProcessButton = ({ goToCalculator }: StartSalesProcessButtonProps) => (
  <Widget classPrefix="start-button">
    <ActionButton type="submit" className="action" onClick={goToCalculator}>
      <FormattedMessage id="homepage.startSalesProcess" />
    </ActionButton>
  </Widget>
);

StartSalesProcessButton.propTypes = {
  goToCalculator: PropTypes.func,
};
