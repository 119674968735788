import CssBaseline from '@mui/material/CssBaseline';
import {Theme, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import mainTheme from 'common/providers/ThemeProvider/themes/main';
import React from 'react';

type ThemeProviderProps = {
   theme?: Theme;
   children: React.ReactNode;
};

const ThemeProvider = ({theme = mainTheme, children}: ThemeProviderProps) => {
   return (
      <MuiThemeProvider theme={theme}>
         <CssBaseline />
         {children}
      </MuiThemeProvider>
   );
};

export default ThemeProvider;
