import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SalesProcessTitle } from '../../../shared/components/SalesProcessTitle';
import { OfferMetadataShape, ProposalMetadataShape } from '../../../shared/constants/propTypes';
import { selectMetadata, selectTitle } from '../selectors';

export const FormWrapperTitle = ({ metadata, title }) => {
  const intl = useIntl();

  if (!metadata) {
    return null;
  }

  const { policyHolderFirstName, policyHolderSurname, number, status } = metadata;

  return (
    <SalesProcessTitle
      firstName={policyHolderFirstName}
      id={number}
      status={status}
      surname={policyHolderSurname}
      title={intl.formatMessage({ id: title })}
    />
  );
};

FormWrapperTitle.propTypes = {
  metadata: PropTypes.oneOfType([ProposalMetadataShape, OfferMetadataShape]),
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  title: selectTitle(state),
});

export default connect(mapStateToProps)(FormWrapperTitle);
