import omit from 'lodash/omit';
import values from 'lodash/values';

import {
  CONFIRMATION_TYPES,
  GENDER,
  INSURANCE_INTRODUCER,
  INSURED_PRODUCT_BASIS_TYPES,
  LANGUAGE_TO_LOCALE_MAP,
  PAYMENT_FREQUENCY,
  PAYMENT_METHODS,
  PHONE_CODES,
  POLICY_PRODUCT_NAME,
  SIGNING_METHODS,
  SIMPLE_ANSWER,
  SMOKING_ANSWER,
  SOURCE_OT_INFORMATION_TYPES,
} from '../constants';

export const paymentFrequencies = values(PAYMENT_FREQUENCY);
export const confirmationTypes = values(CONFIRMATION_TYPES);
export const signingMethods = values(omit(SIGNING_METHODS, 'DEFAULT'));
export const phoneCodes = values(PHONE_CODES);

export const genders = [GENDER.MALE, GENDER.FEMALE];
export const yesNo = values(SIMPLE_ANSWER);
export const fillHealthDeclarationAnswers = values(SIMPLE_ANSWER);
export const smokingAnswers = values(SMOKING_ANSWER);

export const funeralInsuranceStatuses = ['TERMINATED', 'ONGOING'];

export const contactLanguages = Object.keys(LANGUAGE_TO_LOCALE_MAP);

export const insuranceIntroducer = values(INSURANCE_INTRODUCER);

export const paymentMethods = values(PAYMENT_METHODS);

export const desiredDays = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
];

export const insuredProductBasis = [
  INSURED_PRODUCT_BASIS_TYPES.PREVIOUS_ANALYSIS_REGARDING_LIFE_INSURANCE,
  INSURED_PRODUCT_BASIS_TYPES.PREVIOUS_ANALYSIS_AND_ANALYSIS_OF_PRODUCTS_ON_MARKET,
];

export const adviceType = [
  INSURED_PRODUCT_BASIS_TYPES.COLLABORATION_WITH_CERTAIN_INSURANCE_COMPANIES,
  INSURED_PRODUCT_BASIS_TYPES.PREVIOUS_ANALYSIS_AND_ANALYSIS_OF_PRODUCTS_ON_MARKET,
];

export const sourceOfInformation = [
  SOURCE_OT_INFORMATION_TYPES.BROKER_WEBSITE,
  SOURCE_OT_INFORMATION_TYPES.ATTACHED_DOCUMENT,
];

export const policyProductNames = values(POLICY_PRODUCT_NAME);
