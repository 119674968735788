import {INotification} from 'v2/common/api/queries/notifications';

export function createNotification(values?: Partial<INotification>) {
   return {
      id: 'fc00f2e2-ad3f-492c-b988-81831331221e',
      type: 'SHARE_OFFER_EMAIL_UNDELIVERED',
      recipientId: '1',
      offerId: 13312338,
      proposalId: 13312338,
      salesProcessId: 13312338,
      number: '13312338',
      salesProcessState: 'PROPOSAL',
      salesProcessStep: 'POLICY_HOLDER',
      notificationStatus: 'READ',
      severity: 'WARN',
      data: {
         recipients: [132],
         policyPartyType: 'FIRST_INSURED_ADULT',
         discardDate: '23/01/2021',
      },
      created: '2021-07-29T10:33:28.699713',
      modified: null,
      producerAccount: {
         number: 1,
         type: 'OWN',
         partyId: '3597357',
         roles: ['CONSULT_COMMISSIONS', 'POLICY_CONSULTATION', 'POLICY_MANAGEMENT', 'PRODUCTION'],
         isActive: true,
      },
      policyHolder: {
         firstName: 'Test',
         surname: 'Test',
      },
      ...values,
   };
}
