import {func, string} from 'prop-types';
import {useServerError} from './hook';

const ServerError = ({path, children}) => {
   const [error, clearServerError] = useServerError(path);
   return children(error, clearServerError);
};

ServerError.propTypes = {
   path: string.isRequired,
   children: func.isRequired,
};
export default ServerError;
