import {createReducer} from '../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../shared/services/ActionHelpers';

export const containerId = 'Application';
export const INCREASE_IN_PREMIUM_ID = 'premium-increase';

export const logIn = new AsyncAction(`${containerId}/LOG_IN`);
export const setGeneralError = new Action(`${containerId}/GENERAL_ERROR_SET`);
export const startPostponedProcess = new AsyncAction(`${containerId}/START_POSTPONED_PROCESS`);

const actionToHandlerMap = new Map([
   [
      setGeneralError.ACTION,
      (draft, {payload}) => {
         const {status, errors} = payload;
         draft[setGeneralError.type] = {
            status,
            errors,
            userTime: Date.now(),
            userAgent: navigator.userAgent,
         };
      },
   ],
]);

export default createReducer(
   [logIn, setGeneralError, startPostponedProcess],
   null,
   actionToHandlerMap
);
