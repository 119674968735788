import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../Spinner';
import styles from './styles.module.scss';

const AsyncComponent = ({loading, loadingElement, failed, children, height, className}) =>
   loading ? (
      <div className={styles.Wrapper} style={{height}}>
         <Spinner className={className} />
         {loadingElement}
      </div>
   ) : (
      !failed && children
   );

AsyncComponent.propTypes = {
   loading: PropTypes.bool.isRequired,
   loadingElement: PropTypes.node,
   failed: PropTypes.bool,
   children: PropTypes.node,
   height: PropTypes.string,
   className: PropTypes.string,
};

export default AsyncComponent;
