import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DATE_FORMAT } from 'shared/constants';
import { selectPaymentFrequencies } from 'shared/references/selectors';
import { DropdownOption } from 'v2/common/components/DropdownField/DropdownField';
import { PremiumCalculationFormValues } from 'v2/features/PremiumCalculation/components/PremiumCalculation/PremiumCalculation';
import {
  premiumCalculationSchema,
  premiumCalculationOldFormatSchema,
} from 'v2/features/PremiumCalculation/schema';
import { PAYMENT_FREQUENCY } from '../../../shared/constants';

export function usePaymentFrequencies(
  isOneAdult: boolean,
  isFirstAdultInRangeOfOneTimeOnly: boolean
) {
  const paymentFrequenciesReference = useSelector(selectPaymentFrequencies);
  return React.useMemo(() => {
    const paymentFrequencies = paymentFrequenciesReference.filter(
      ({ value }: DropdownOption) =>
        value !== PAYMENT_FREQUENCY.QUARTERLY && value !== PAYMENT_FREQUENCY.HALF_YEARLY
    );
    if (isOneAdult) {
      return isFirstAdultInRangeOfOneTimeOnly
        ? paymentFrequencies.map((option: DropdownOption) =>
            option.value === PAYMENT_FREQUENCY.ONE_TIME_ONLY
              ? option
              : { ...option, disabled: true }
          )
        : paymentFrequencies;
    }
    return paymentFrequencies.map((option: DropdownOption) =>
      option.value !== PAYMENT_FREQUENCY.ONE_TIME_ONLY ? option : { ...option, disabled: true }
    );
  }, [isFirstAdultInRangeOfOneTimeOnly, isOneAdult, paymentFrequenciesReference]);
}

export function usePrefilledPremiumParams(): PremiumCalculationFormValues | undefined {
  const { search } = useLocation();
  return React.useMemo(() => {
    const premiumParamsEncoded = new URLSearchParams(search).get('premiumParams');
    if (!premiumParamsEncoded) {
      return;
    }
    try {
      const premiumParamsDecoded = atob(premiumParamsEncoded);
      const premiumParams = JSON.parse(premiumParamsDecoded);

      // TODO Should be removed in https://delanet.atlassian.net/browse/BROKP-8873
      let premiumParamsNewFormat = premiumParams;

      if (premiumCalculationOldFormatSchema.isValidSync(premiumParams)) {
        const { insuredAdults, ...rest } = premiumParams;
        premiumParamsNewFormat = {
          insuredAdults: insuredAdults?.map((adult) => ({
            benefit: adult.benefit,
            payUntilAge: adult.payUntilAge,
            personalInformation: {
              birthDate: adult.birthDate,
            },
          })),
          ...rest,
        };
      }

      const { insuredAdults: insuredAdultsValidated, ...restParams } = premiumCalculationSchema.validateSync(
        premiumParamsNewFormat,
        {
          abortEarly: false,
          stripUnknown: true,
        }
      );

      return {
        insuredAdults: insuredAdultsValidated?.map((adult: any) => ({
          ...adult,
          personalInformation: {
            birthDate: moment(adult.personalInformation.birthDate).format(DATE_FORMAT),
          },
        })),
        ...restParams,
      } as PremiumCalculationFormValues;
    } catch (e) {
      console.warn('Invalid premium params have been ignored');
    }
  }, [search]);
}
