import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
   formValueSelector,
   getFormMeta,
   getFormSyncErrors,
   isSubmitting,
   isInvalid,
} from 'redux-form';
import {createSelector} from '@reduxjs/toolkit';
import {
   PARTY_TYPES,
   SECTIONS,
   PERSONAL_INFO_KEYS,
   HANDLED_SERVER_ERRORS,
   SERVER_ERROR_TYPES,
} from '../../../shared/constants';
import {
   selectActionData,
   selectAsyncActionData,
   selectIsFinishedStatus,
   selectIsInProgressStatus,
} from '../../../shared/reducers/createReducer';
import {getStateByContainerId} from '../../../shared/reducers/selectors';
import {
   adaptInsuredChildrenPersonalInfo,
   adaptInsuredPersonalInfo,
   adaptPolicyHolderPersonalInfo,
   areRequiredFieldsTouched,
   checkPersonalEquality,
   findTouchedFormErrors,
   getInsuredAdultIndex,
   hasTruthyValue,
} from '../../../shared/services/utils';
import {
   selectFirstInsuredAdult as selectFirstInsuredAdultInfo,
   selectSecondInsuredAdult as selectSecondInsuredAdultInfo,
} from '../../PremiumBox/selectors';
import {selectMetadata, selectServerErrors} from '../selectors';
import {
   containerId,
   fetchBeneficiaries,
   saveBeneficiaries,
   setBeneficiariesTabKey,
} from './reducer';
import {
   setFormSavingAction,
   getCurrentState as getFormWrapperState,
} from '../../FormWrapper/reducer';
import {checkBeneficiariesErrors} from '../../NavigationPane/selectors';

import {containerId as policyHolderContainerId, fetchPolicyHolder} from '../PolicyHolder/reducer';
import {
   selectFirstInsuredAdult,
   selectSecondInsuredAdult,
   selectInsuredAdults as selectInsuredData,
} from '../Insured/selectors';
import {fetchInsuredChildren, containerId as insuredContainerId} from '../Insured/reducer';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormErrorsValues = getFormSyncErrors(containerId);
const selectFormValues = formValueSelector(containerId);
const selectFormMeta = getFormMeta(containerId);

const getInsuredState = getStateByContainerId(insuredContainerId);

const selectInsuredChildren = (state) =>
   selectAsyncActionData(getInsuredState(state), fetchInsuredChildren.type);

const getPolicyHolderState = getStateByContainerId(policyHolderContainerId);

const selectPolicyHolder = (state) =>
   selectAsyncActionData(getPolicyHolderState(state), fetchPolicyHolder.type);

export const selectIsFetching = (state) =>
   selectIsInProgressStatus(getCurrentState(state), fetchBeneficiaries.type);

export const selectIsFetched = (state) =>
   selectIsFinishedStatus(getCurrentState(state), fetchBeneficiaries.type);

export const selectInsuredAdults = createSelector(
   [selectFirstInsuredAdultInfo, selectSecondInsuredAdultInfo],
   (firstInsured, secondInsured) => {
      return {
         [SECTIONS.FIRST_INSURED_ADULT]: firstInsured,
         [SECTIONS.SECOND_INSURED_ADULT]: secondInsured,
      };
   }
);

export const selectIsSavingFormInProgress = (state) =>
   selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsFormFetching = createSelector(
   [selectIsFetched, selectIsSavingFormInProgress],
   (isFetched, isSavingFormInProgress) => !isFetched && !isSavingFormInProgress
);

export const selectIsSaving = (state) =>
   selectIsInProgressStatus(getCurrentState(state), saveBeneficiaries.type);

export const selectIsInvalid = (state) => isInvalid(containerId)(state);

export const selectIsSubmitting = (state) => isSubmitting(containerId)(state);

export const selectIsSavingButtonDisabled = createSelector(
   [selectIsSaving, selectIsSubmitting, selectIsInvalid],
   (isSaving, isSubmitting, isInvalid) => isSubmitting || isSaving || isInvalid
);

export const selectBeneficiaries = (state) =>
   selectAsyncActionData(getCurrentState(state), fetchBeneficiaries.type);

export const selectFirstInsuredBeneficiaries = (state) =>
   selectAsyncActionData(
      getCurrentState(state),
      fetchBeneficiaries.type,
      `${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries`
   );

export const selectSecondInsuredBeneficiaries = (state) =>
   selectAsyncActionData(
      getCurrentState(state),
      fetchBeneficiaries.type,
      `${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries`
   );

export const selectIsOneAdult = (state) => get(selectMetadata(state), 'oneAdult');

export const selectFirstInsuredAdultBeneficiariesFormValue = (state) =>
   selectFormValues(state, `${SECTIONS.FIRST_INSURED_ADULT}.beneficiaries`) || [];

export const selectSecondInsuredAdultBeneficiariesFormValue = (state) =>
   selectFormValues(state, `${SECTIONS.SECOND_INSURED_ADULT}.beneficiaries`) || [];

export const selectFirstInsuredAdultPaymentToUndertakerValue = (state) =>
   selectFormValues(state, `${SECTIONS.FIRST_INSURED_ADULT}.paymentToUndertaker`);

export const selectSecondInsuredAdultPaymentToUndertakerValue = (state) =>
   selectFormValues(state, `${SECTIONS.SECOND_INSURED_ADULT}.paymentToUndertaker`);

export const selectFormErrors = (state) => selectFormErrorsValues(state);

export const selectBeneficiaryActiveTabKeys = (state) =>
   selectActionData(getCurrentState(state), setBeneficiariesTabKey.type);

export const makeSelectInitialValues = () => {
   return createSelector([selectMetadata, selectBeneficiaries], (metadata, beneficiaries) => {
      if (!metadata) {
         return;
      }
      return beneficiaries;
   });
};

export const convertBeneficiaryShareFieldToNumber = (beneficiaries) =>
   beneficiaries.map((beneficiary) => {
      const share = get(beneficiary, 'share');
      if (!share) {
         return beneficiary;
      }

      if (share === '0') {
         return {
            ...beneficiary,
            share: null,
         };
      }
      return {
         ...beneficiary,
         share: parseFloat(share),
      };
   });

export const makeSelectBeneficiaries = () => {
   return createSelector(
      [
         selectFirstInsuredAdultBeneficiariesFormValue,
         selectSecondInsuredAdultBeneficiariesFormValue,
      ],
      (firstInsuredAdultBeneficiaries, secondInsuredAdultBeneficiaries) => ({
         [SECTIONS.FIRST_INSURED_ADULT]: convertBeneficiaryShareFieldToNumber(
            firstInsuredAdultBeneficiaries
         ),
         [SECTIONS.SECOND_INSURED_ADULT]: convertBeneficiaryShareFieldToNumber(
            secondInsuredAdultBeneficiaries
         ),
      })
   );
};

export const selectBeneficiariesBySection = (state, sectionName) =>
   makeSelectBeneficiaries()(state)[sectionName];

export const makeSelectNoPaymentsToUndertaker = () => {
   return createSelector(
      [
         selectFirstInsuredAdultPaymentToUndertakerValue,
         selectSecondInsuredAdultPaymentToUndertakerValue,
      ],
      (firstInsuredAdultNoPaymentsToUndertaker, secondInsuredAdultNoPaymentsToUndertaker) => ({
         [SECTIONS.FIRST_INSURED_ADULT]: firstInsuredAdultNoPaymentsToUndertaker,
         [SECTIONS.SECOND_INSURED_ADULT]: secondInsuredAdultNoPaymentsToUndertaker,
      })
   );
};

export const selectTouchedFields = (state) => getFormMeta(containerId)(state);

export const selectMappedFormErrors = (errorsPath) =>
   createSelector([selectFormErrors, selectTouchedFields], (formErrors, touchedFields) =>
      findTouchedFormErrors(formErrors, touchedFields, errorsPath)
   );

export const selectTabServerErrors = (tabName) =>
   createSelector(selectServerErrors, (serverErrors) =>
      HANDLED_SERVER_ERRORS.some((errorType) => {
         const errors = serverErrors[errorType];
         const {share, ...beneficiaryErrors} = get(errors, tabName, {});
         return hasTruthyValue(Object.values(beneficiaryErrors));
      })
   );

export const selectTabFormErrors = (errorPath) =>
   createSelector(selectMappedFormErrors(errorPath), (formErrors) => {
      if (isEmpty(formErrors)) {
         return false;
      }

      const {share, ...beneficiariesErrors} = get(formErrors, errorPath);

      return !isEmpty(beneficiariesErrors);
   });

export const selectMappedPolicyHolder = createSelector([selectPolicyHolder], (policyHolder) => ({
   type: PARTY_TYPES.POLICY_HOLDER,
   ...adaptPolicyHolderPersonalInfo(policyHolder),
}));

export const mapInsuredAdult = (isFirstInsured, insured) => {
   const insuredAdultPartyType = isFirstInsured
      ? PARTY_TYPES.SECOND_INSURED_ADULT
      : PARTY_TYPES.FIRST_INSURED_ADULT;
   const insuredIndex = isFirstInsured ? 2 : 1;

   return {
      type: insuredAdultPartyType,
      index: insuredIndex,
      ...adaptInsuredPersonalInfo(insured),
   };
};

export const selectMappedCurrentInsuredAdult = (isFirstInsuredCurrent) =>
   createSelector(
      [selectFirstInsuredAdult, selectSecondInsuredAdult],
      (firstInsured, secondInsured) => {
         const insured = isFirstInsuredCurrent ? firstInsured : secondInsured;
         return mapInsuredAdult(isFirstInsuredCurrent, insured);
      }
   );

export const selectMappedOtherInsuredAdult = (isFirstInsuredCurrent) =>
   createSelector(
      [selectFirstInsuredAdult, selectSecondInsuredAdult],
      (firstInsured, secondInsured) => {
         const insured = isFirstInsuredCurrent ? secondInsured : firstInsured;
         return mapInsuredAdult(isFirstInsuredCurrent, insured);
      }
   );

export const selectMappedInsuredChildren = createSelector(selectInsuredChildren, (children) => {
   if (!children) {
      return [];
   }
   return children.map((child, index) => ({
      type: PARTY_TYPES.INSURED_CHILD,
      index: index + 1,
      ...adaptInsuredChildrenPersonalInfo(child),
   }));
});

const makeSelectNormalizedCurrentBeneficiaries = (isFirstInsuredCurrent) => {
   const selectBeneficiariesFormValues = isFirstInsuredCurrent
      ? selectFirstInsuredAdultBeneficiariesFormValue
      : selectSecondInsuredAdultBeneficiariesFormValue;

   return createSelector([selectBeneficiariesFormValues], (beneficiaries) =>
      beneficiaries.map((beneficiary) => adaptInsuredChildrenPersonalInfo(beneficiary))
   );
};

const makeSelectNormalizedOtherBeneficiaries = (isFirstInsuredCurrent) => {
   const selectBeneficiariesFormValues = isFirstInsuredCurrent
      ? selectSecondInsuredAdultBeneficiariesFormValue
      : selectFirstInsuredAdultBeneficiariesFormValue;

   return createSelector([selectBeneficiariesFormValues], (beneficiaries) =>
      beneficiaries
         .map((beneficiary) => ({
            type: PARTY_TYPES.BENEFICIARY,
            ...adaptInsuredChildrenPersonalInfo(beneficiary),
         }))
         .sort(
            (firstBeneficiary, secondBeneficiary) =>
               firstBeneficiary.index - secondBeneficiary.index
         )
   );
};

export const makeSelectAllPolicyPartyOptions = (isFirstInsuredCurrent) =>
   createSelector(
      [
         selectMappedPolicyHolder,
         selectMappedCurrentInsuredAdult(isFirstInsuredCurrent),
         selectMappedOtherInsuredAdult(isFirstInsuredCurrent),
         selectMappedInsuredChildren,
         makeSelectNormalizedOtherBeneficiaries(isFirstInsuredCurrent),
      ],
      (policyHolder, currentInsuredAdult, otherInsuredAdult, insuredChildren, beneficiaries) => {
         if (!checkPersonalEquality(policyHolder, currentInsuredAdult)) {
            return [policyHolder, otherInsuredAdult, ...insuredChildren, ...beneficiaries];
         }
         return [otherInsuredAdult, ...insuredChildren, ...beneficiaries];
      }
   );

export const makeSelectCopyPolicyParty = (section) => {
   const isFirstInsuredCurrent = section === SECTIONS.FIRST_INSURED_ADULT;

   return createSelector(
      [
         makeSelectAllPolicyPartyOptions(isFirstInsuredCurrent),
         makeSelectNormalizedCurrentBeneficiaries(isFirstInsuredCurrent),
      ],
      (allPolicyParties, beneficiariesFormValues) =>
         allPolicyParties.filter((policyParty) => {
            return beneficiariesFormValues.every(
               (beneficiary) => !checkPersonalEquality(policyParty, beneficiary)
            );
         })
   );
};

export const makeSelectCopyPolicyPartyOptions = (section) =>
   createSelector([makeSelectCopyPolicyParty(section)], (copyPolicyParties) => {
      return copyPolicyParties
         .filter(
            (policyParty) =>
               policyParty[PERSONAL_INFO_KEYS.FIRST_NAME] && policyParty[PERSONAL_INFO_KEYS.SURNAME]
         )
         .map((policyParty) => ({
            policyParty: policyParty.type,
            index: policyParty.index,
            value: {
               [PERSONAL_INFO_KEYS.FIRST_NAME]: policyParty[PERSONAL_INFO_KEYS.FIRST_NAME],
               [PERSONAL_INFO_KEYS.SURNAME]: policyParty[PERSONAL_INFO_KEYS.SURNAME],
               [PERSONAL_INFO_KEYS.BIRTH_DATE]: policyParty[PERSONAL_INFO_KEYS.BIRTH_DATE],
               [PERSONAL_INFO_KEYS.GENDER]: policyParty[PERSONAL_INFO_KEYS.GENDER],
            },
         }));
   });

const resolveInsuredTabsServerErrors = (serverErrors, index) =>
   HANDLED_SERVER_ERRORS.some((errorType) => {
      const errors = serverErrors[errorType];
      const insuredErrors = get(errors, `insuredAdults[${index}]`, {});
      return checkBeneficiariesErrors(insuredErrors);
   });
export const selectHasInsuredTabsServerErrors = createSelector(
   selectServerErrors,
   (serverErrors) => {
      return {
         [SECTIONS.FIRST_INSURED_ADULT]: resolveInsuredTabsServerErrors(serverErrors, 0),
         [SECTIONS.SECOND_INSURED_ADULT]: resolveInsuredTabsServerErrors(serverErrors, 1),
      };
   }
);

const createBeneficiaryPersonalFieldPaths = (section, indexes) => {
   const paths = [];
   indexes.forEach((index) => {
      paths.push(`${section}.beneficiaries[${index}].personalInformation.firstName`);
      paths.push(`${section}.beneficiaries[${index}].personalInformation.surname`);
      paths.push(`${section}.beneficiaries[${index}].personalInformation.birthDate`);
   });
   return paths;
};

export const selectDuplicatedBeneficiariesIndexes = (index, section) => {
   const isFirstInsuredCurrent = section === SECTIONS.FIRST_INSURED_ADULT;
   const selectNormalizedBeneficiaries = makeSelectNormalizedCurrentBeneficiaries(
      isFirstInsuredCurrent
   );
   return createSelector(selectNormalizedBeneficiaries, (beneficiaries) => {
      const result = [];
      if (!beneficiaries) {
         return result;
      }
      const currentBeneficiary = beneficiaries[index];

      beneficiaries.forEach((child, childIndex) => {
         if (childIndex === index) {
            return;
         }
         if (!child || !currentBeneficiary) {
            return;
         }
         if (checkPersonalEquality(child, currentBeneficiary)) {
            result.push(childIndex);
         }
      });
      return result;
   });
};

const selectIsBeneficiariesDuplicationErrorShown = (index, section) =>
   createSelector(
      [selectDuplicatedBeneficiariesIndexes(index, section), selectServerErrors, selectFormMeta],
      (duplicatedIndexes, serverErrors, meta) => {
         const insuredAdultIndex = getInsuredAdultIndex(section);

         const serverError = get(
            serverErrors,
            `${SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES}.insuredAdults[${insuredAdultIndex}].beneficiaries[${index}]`,
            false
         );

         if (!isEmpty(serverError)) {
            return true;
         }

         if (!duplicatedIndexes.length) {
            return false;
         }

         return areRequiredFieldsTouched(
            createBeneficiaryPersonalFieldPaths(section, [index, ...duplicatedIndexes]),
            meta
         );
      }
   );

const selectAreInsuredAndBeneficiaryEqual = (index, section) => {
   const isFirstInsuredCurrent = section === SECTIONS.FIRST_INSURED_ADULT;

   const selectBeneficiariesFormValues = isFirstInsuredCurrent
      ? selectFirstInsuredAdultBeneficiariesFormValue
      : selectSecondInsuredAdultBeneficiariesFormValue;

   return createSelector(
      [selectBeneficiariesFormValues, selectInsuredData],
      (beneficiaries, insuredAdults) => {
         const insured = get(insuredAdults, section);
         const insuredObject = adaptInsuredPersonalInfo(insured);

         const currentBeneficiary = beneficiaries[index];
         const beneficiaryObject = adaptInsuredChildrenPersonalInfo(currentBeneficiary);
         return checkPersonalEquality(beneficiaryObject, insuredObject);
      }
   );
};

const selectIsInsuredAndBeneficiaryDuplicationErrorShown = (index, section) =>
   createSelector(
      [selectAreInsuredAndBeneficiaryEqual(index, section), selectServerErrors, selectFormMeta],
      (isErrorExist, errors, meta) => {
         const insuredIndex = getInsuredAdultIndex(section);
         const serverError = get(
            errors,
            `${SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES}.insuredAdults[${insuredIndex}].beneficiaries[${index}]`,
            false
         );
         if (!isEmpty(serverError)) {
            return true;
         }

         if (!isErrorExist) {
            return false;
         }

         return areRequiredFieldsTouched(
            createBeneficiaryPersonalFieldPaths(section, [index]),
            meta
         );
      }
   );

export const selectBeneficiariesDuplicationError = (index, section) =>
   createSelector(
      [
         selectIsBeneficiariesDuplicationErrorShown(index, section),
         selectIsInsuredAndBeneficiaryDuplicationErrorShown(index, section),
      ],
      (isBeneficiariesDuplicationErrorShown, isInsuredAndBeneficiaryDuplicationErrorShown) => {
         if (isBeneficiariesDuplicationErrorShown) {
            return 'validate.beneficiaryAreEqual';
         }
         if (isInsuredAndBeneficiaryDuplicationErrorShown) {
            return 'validate.insuredAdultsAssignedAsBeneficiaries';
         }
         return null;
      }
   );
