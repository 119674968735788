import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Field} from 'redux-form';
import {getAgentDataCache} from '../../../v2/common/api/queries/agent';
import * as fields from '../../constants/fields';
import TextInputControl from '../reduxFormField/TextInput';

const EmailField = ({validate, ...rest}) => {
   const agent = getAgentDataCache();

   const fieldValidator = validate || fields.email.validate;

   const emailValidator = useMemo(() => fieldValidator(agent.contactInformation.email), [
      agent.contactInformation.email,
      fieldValidator,
   ]);

   return <Field component={TextInputControl} validate={emailValidator} {...rest} />;
};

EmailField.propTypes = {
   validate: PropTypes.func,
};

export default EmailField;
