import React from 'react';
import {string, element, oneOfType} from 'prop-types';

import classes from './styles.module.scss';

const FieldInfo = ({message}) => {
   return (
      <div className={classes.root}>
         <i className="iconfont icon-circle-exclamation-filled" />
         {message}
      </div>
   );
};

FieldInfo.propTypes = {
   message: oneOfType([element, string]).isRequired,
};

export default FieldInfo;
