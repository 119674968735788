import {createReducer} from '../../../shared/reducers/createReducer';
import {Action, AsyncAction} from '../../../shared/services/ActionHelpers';

export const containerId = 'PolicyHolder';
export const setPolicyHolderSaving = new Action(`${containerId}/POLICY_HOLDER_SET_SAVING`, false);
export const fetchPolicyHolder = new AsyncAction(`${containerId}/POLICY_HOLDER_FETCH`);

export const savePolicyHolder = new AsyncAction(`${containerId}/POLICY_HOLDER_SAVE`);
export const validateIban = new Action(`${containerId}/POLICY_HOLDER_IBAN_VALIDATE`);
export const removeFirstInsuredAdultFormValues = new Action(
   `${containerId}/FIRST_INSURED_ADULT_FORM_VALUES_REMOVE`
);
export const handleSavePolicyHolder = new Action(`${containerId}/POLICY_HOLDER_SAVE_HANDLE`);

export default createReducer([fetchPolicyHolder, savePolicyHolder, setPolicyHolderSaving]);
