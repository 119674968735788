import React from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {LEGAL_INFO_LINKS} from '../../constants';
import {FormattedMessage, useIntl} from 'react-intl';

const LegalInfoLink = () => {
   const intl = useIntl();
   return (
      <Box display="flex" alignItems="center">
         <InfoRoundedIcon sx={{marginRight: '5px', fontSize: 14}} />
         <Typography fontSize={10}>
            <Link
               color="inherit"
               href={LEGAL_INFO_LINKS[intl.locale]}
               target="_blank"
               rel="noopener noreferrer"
               underline="always"
            >
               <FormattedMessage id="links.legalInfo" />
            </Link>
         </Typography>
      </Box>
   );
};

export default LegalInfoLink;
