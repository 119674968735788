import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import {change} from 'redux-form';
import {all, call, put, take, takeLatest} from 'redux-saga/effects';
import {api, toastHandler} from '../services';
import {fetchMunicipalities, fetchStreets, validatePostalCode} from './index';
import {clearServerError} from '../../containers/ProposalDetails/reducer';

export const INITIAL_RESIDENTIAL_ADDRESS = {
   postalCode: null,
   municipality: null,
   streetName: null,
   boxNumber: null,
   houseNumber: null,
};

const sortAlphabetically = ({value: a}, {value: b}) => {
   return a.localeCompare(b);
};

export const prepareStreetOptions = (response) => {
   return response.map((street) => ({
      value: street.name,
      label: street.name,
   }));
};

export const prepareMunicipalityOptions = (response) => {
   const {id, names} = response;
   return Object.keys(names).reduce((total, locale) => {
      const values = names[locale].map((municipality) => ({
         value: municipality,
         label: municipality,
         id,
      }));

      return [...total, ...values];
   }, []);
};

const makeMunicipalitiesUniqueAndSorted = (municipalities) =>
   municipalities
      .reduce((total, element) => {
         const hasValue = total.some(({value}) => value === element.value);
         if (!hasValue) {
            total.push(element);
         }
         return total;
      }, [])
      .sort(sortAlphabetically);

export function* fetchMunicipalitiesSaga({payload}) {
   const {postalCode, containerId} = payload;
   try {
      const response = yield call(api.references.getMunicipalityByPostalCode, postalCode);

      if (!isEmpty(response)) {
         const municipalities = prepareMunicipalityOptions(response);
         const uniqueMunicipalities = makeMunicipalitiesUniqueAndSorted(municipalities);

         if (uniqueMunicipalities.length === 1) {
            const municipality = get(uniqueMunicipalities[0], 'value');
            yield put(
               change(containerId, 'policyHolder.residentialAddress.municipality', municipality)
            );
            yield put(clearServerError.action('policyHolder.residentialAddress.municipality'));
         }
         yield put(fetchMunicipalities.success(uniqueMunicipalities));
      } else {
         yield put(fetchMunicipalities.success([]));
      }
   } catch (error) {
      const {status} = error;
      if (status >= 500) {
         yield put(change('policyHolder.residentialAddress', INITIAL_RESIDENTIAL_ADDRESS));
         toastHandler.uniqueWarning({id: 'toasts.warning.residentialAddressUnavailableError'});
      }
      yield put(fetchMunicipalities.failure(error));
   }
}

export function* validatePostalCodeSaga({payload}) {
   const {values, resolve, reject, props} = payload;
   let {asyncErrors, touch, form: containerId} = props;
   const POSTALCODE_FIELD_NAME = 'policyHolder.residentialAddress.postalCode';
   const isPostalCodeAsyncErrorExists = get(asyncErrors, POSTALCODE_FIELD_NAME, false);

   try {
      const {postalCode} = values.policyHolder.residentialAddress;
      if (postalCode && postalCode.length === 4) {
         yield put(fetchMunicipalities.request({postalCode, containerId}));
         const {payload: municipalities} = yield take(fetchMunicipalities.SUCCESS);
         if (isEmpty(municipalities)) {
            reject(
               merge(asyncErrors, {
                  policyHolder: {
                     residentialAddress: {
                        postalCode: {id: 'postalCodeIncorrect'},
                     },
                  },
               })
            );
            yield put(touch(POSTALCODE_FIELD_NAME));
         } else if (isPostalCodeAsyncErrorExists) {
            asyncErrors = omit(asyncErrors, POSTALCODE_FIELD_NAME);
         }
      }
      if (asyncErrors) {
         reject(asyncErrors);
      }
      resolve();
   } catch (error) {
      reject(asyncErrors);
   }
}

export function* fetchStreetsSaga({payload}) {
   const {searchQuery, municipalityId, cb, change} = payload;
   try {
      const response = yield call(api.references.getStreetByMunicipalityIdAndName, {
         municipalityId,
         impreciseStreetName: searchQuery,
      });
      const streets = prepareStreetOptions(response);
      cb(streets);
   } catch (error) {
      const {status} = error;

      if (status >= 500) {
         yield put(change('policyHolder.residentialAddress', INITIAL_RESIDENTIAL_ADDRESS));
         toastHandler.uniqueWarning({id: 'toasts.warning.residentialAddressUnavailableError'});
      }
      cb([]);
   }
}

export default function* referencesSagas() {
   yield all([
      takeLatest(fetchMunicipalities.REQUEST, fetchMunicipalitiesSaga),
      takeLatest(fetchStreets.ACTION, fetchStreetsSaga),
      takeLatest(validatePostalCode.ACTION, validatePostalCodeSaga),
   ]);
}
