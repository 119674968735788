import Axios from 'axios';
import React from 'react';
import {useSelector} from 'react-redux';
import {API_URL} from 'shared/constants';
import {getToken} from 'v2/common/providers/AuthProvider/utils';
import {selectProducerAccount} from 'v2/pages/Login/producerAccountSlice';
import {AxiosContext} from './context';

type AxiosProviderProps = {
   children: React.ReactNode;
};

export default function AxiosProvider({children}: AxiosProviderProps) {
   const producerAccount = useSelector(selectProducerAccount);
   const axios = React.useMemo(() => {
      const axiosInstance = Axios.create({
         baseURL: API_URL,
      });

      axiosInstance.interceptors.request.use(async (config) => {
         if (producerAccount) {
            config.headers['Agent-Number'] = producerAccount.value;
         }

         const token = await getToken();

         config.headers.Authorization = `Bearer ${token}`;

         return config;
      });

      return axiosInstance;
   }, [producerAccount]);

   return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
}
