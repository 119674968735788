import {call, put, takeLatest} from 'redux-saga/effects';
import {api} from '../../shared/services';
import {fetchCompleteness} from './reducer';

export function* fetchCompletenessSaga({payload: {id, endpoint = 'proposals'}}) {
   try {
      const data = yield call(api.navigationPane.getCompleteness, endpoint, id);
      yield put(fetchCompleteness.success(data));
   } catch (err) {
      yield put(fetchCompleteness.failure(err));
   }
}

export default function* navigationPaneSagas() {
   yield takeLatest(fetchCompleteness.REQUEST, fetchCompletenessSaga);
}
