import {InteractionStatus} from '@azure/msal-browser';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import LoadingButton from '@mui/lab/LoadingButton';
import {outlinedInputClasses} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import logo from 'assets/images/logo.svg';
import image from 'assets/images/negotiation.svg';
import {delaBlue} from 'common/styles/colors';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {SavedCalculatePremiumService} from 'shared/services/SavedCalculatePremium';
import {appConfig} from 'v2/appConfig';
import Image from 'v2/common/components/Image/Image';
import LocaleSwitcher from 'v2/common/components/LocaleSwitcher/LocaleSwitcher';
import LogoutButton from 'v2/common/components/LogoutButton/LogoutButton';
import ProducerAccounts from 'v2/pages/Login/components/ProducerAccounts';
import AnonymousPremiumCalculation from './components/AnonymousPremiumCalculation';

const Login = () => {
   const {instance, inProgress} = useMsal();
   const isAuthenticated = useIsAuthenticated();

   const signIn = async () => {
      SavedCalculatePremiumService.clear();
      await instance.loginRedirect({scopes: [appConfig.auth.clientId], prompt: 'select_account'});
   };

   if (
      inProgress === InteractionStatus.Startup ||
      inProgress === InteractionStatus.HandleRedirect
   ) {
      return null;
   }

   return (
      <Grid container height="100vh">
         <Grid item container xs={8} sx={{backgroundColor: delaBlue.light}}>
            {isAuthenticated ? (
               <Image src={image} alt="producer-account" margin="auto" width="60%" />
            ) : (
               <AnonymousPremiumCalculation />
            )}
         </Grid>
         <Grid item container xs={4} direction="column" paddingX={2} paddingBottom={2}>
            <Box display="flex" marginLeft="auto" marginBottom={4}>
               <LocaleSwitcher
                  sx={{
                     backgroundColor: delaBlue.light,
                     borderTopRightRadius: 0,
                     borderBottomRightRadius: 0,
                     [`& .${outlinedInputClasses.notchedOutline}`]: {
                        border: 'none',
                     },
                  }}
               />
               {isAuthenticated && (
                  <LogoutButton
                     sx={{borderBottomLeftRadius: 0, borderTopLeftRadius: 0}}
                     variant="contained"
                     onClick={SavedCalculatePremiumService.clear}
                  />
               )}
            </Box>
            {isAuthenticated ? (
               <ProducerAccounts />
            ) : (
               <Box
                  sx={{
                     flex: '1',
                     textAlign: 'center',
                     paddingX: 5,
                  }}
               >
                  <Image src={logo} alt="dela connect" width={150} marginBottom={4} />
                  <LoadingButton
                     sx={{display: 'flex', maxWidth: 400, margin: 'auto'}}
                     variant="contained"
                     color="secondary"
                     fullWidth
                     loading={inProgress === InteractionStatus.Login}
                     onClick={signIn}
                  >
                     <FormattedMessage id="login.signIn" />
                  </LoadingButton>
               </Box>
            )}
            <ContactInfo />
         </Grid>
      </Grid>
   );
};

const ContactInfo = () => {
   const intl = useIntl();
   const email = intl.formatMessage({id: 'login.contactInfo.email'});
   const phoneNumber = intl.formatMessage({id: 'login.contactInfo.phoneNumber'});

   return (
      <Box textAlign="center">
         <Typography fontWeight="bold">
            <FormattedMessage id="login.contactInfo.label" />
         </Typography>
         <Typography variant="body2">
            <FormattedMessage id="login.contactInfo.salesSupport" />
         </Typography>
         <Typography variant="body2">
            <FormattedMessage
               id="login.contactInfo.contacts"
               values={{
                  email: (
                     <Link href={`mailto:${email}`} underline="always">
                        {email}
                     </Link>
                  ),
                  phoneNumber: (
                     <Link href={`tel:${phoneNumber}`} underline="always">
                        {phoneNumber}
                     </Link>
                  ),
               }}
            />
         </Typography>
         <Typography variant="body2">
            <FormattedMessage id="login.contactInfo.workingHours" />
         </Typography>
      </Box>
   );
};

export default Login;
