import {createSelector} from '@reduxjs/toolkit';
import {selectIsStatusSaving as selectInitiateSalesProcessStatus} from 'containers/CalculatePremium/InitiateSalesProcess/selectors';
import {selectIsSaving as selectOfferDetailsIsSaving} from 'containers/OfferDetails/selectors';
import {selectIsSaving as selectAdditionalInformationIsSaving} from 'containers/ProposalDetails/AdditionalInformation/selectors';
import {selectIsSaving as selectBeneficiariesIsSaving} from 'containers/ProposalDetails/Beneficiaries/selectors';
import {selectIsSaving as selectHealthDeclarationIsSaving} from 'containers/ProposalDetails/HealthDeclaration/selectors';
import {selectIsSaving as selectInsuredIsSaving} from 'containers/ProposalDetails/Insured/selectors';
import {selectIsSaving as selectNeedsAndDesiresIsSaving} from 'containers/ProposalDetails/NeedsAndDesires/selectors';
import {selectIsSaving as selectPolicyHolderIsSaving} from 'containers/ProposalDetails/PolicyHolder/selectors';

export const selectIsSaving = createSelector(
   [
      selectOfferDetailsIsSaving,
      selectNeedsAndDesiresIsSaving,
      selectPolicyHolderIsSaving,
      selectInsuredIsSaving,
      selectBeneficiariesIsSaving,
      selectHealthDeclarationIsSaving,
      selectAdditionalInformationIsSaving,
      selectInitiateSalesProcessStatus,
   ],
   (
      isOfferDetailsSaving,
      isNeedsAndDesiresSaving,
      isPolicyHolderSaving,
      isInsuredSaving,
      isBeneficiariesSaving,
      isHealthDeclarationSaving,
      isAdditionalInformationSaving,
      isInitiateSalesProcessSaving
   ) => {
      return (
         isOfferDetailsSaving ||
         isNeedsAndDesiresSaving ||
         isPolicyHolderSaving ||
         isInsuredSaving ||
         isBeneficiariesSaving ||
         isHealthDeclarationSaving ||
         isAdditionalInformationSaving ||
         isInitiateSalesProcessSaving
      );
   }
);
