import {FormHelperText} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectProps} from '@mui/material/Select';
import React from 'react';
import {RefCallBack} from 'react-hook-form';

export type DropdownOption = {
   label: string;
   value: string;
   disabled?: boolean;
};

type DropdownFieldProps = SelectProps & {
   label?: React.ReactNode;
   helperText?: React.ReactNode;
   options: DropdownOption[];
};

const DropdownField = React.forwardRef<RefCallBack, DropdownFieldProps>(
   ({label, helperText, options, name, error, ...selectProps}, ref) => {
      return (
         <FormControl fullWidth size="small" error={error} margin="normal">
            {label ? <FormLabel htmlFor={`${name}-id`}>{label}</FormLabel> : null}
            <Select
               {...selectProps}
               labelId={`${name}-id`}
               aria-describedby={`${name}-helper-text`}
               size="small"
               error={error}
               inputRef={ref}
            >
               {options.map((option) => (
                  <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                     {option.label}
                  </MenuItem>
               ))}
            </Select>
            <FormHelperText id={`${name}-helper-text`} sx={{margin: 0}}>
               {helperText ?? <>&nbsp;</>}
            </FormHelperText>
         </FormControl>
      );
   }
);

export default DropdownField;
