import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import notFound from '../../../../assets/images/404.svg';
import * as History from '../../../../history';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import ActionButton from '../../ActionButton';

import './styles.scss';

const navigateToHomePage = () => History.history.push('/');

const NotFound = () => {
   const intl = useIntl();
   const {pathname} = useLocation();
   const isShared = pathname.startsWith('/shared');

   return (
      <div className="not-found">
         <img
            className="not-found-image"
            src={notFound}
            alt={intl.formatMessage({
               id: 'errorPage.not-found.title',
            })}
            draggable={false}
         />

         <h2 className="not-found-header">
            <FormattedMessage id="errorPage.not-found.title" />
         </h2>
         <p className="not-found-description">
            <FormattedMessage id="errorPage.not-found.description" values={{br: <br />}} />
         </p>

         {!isShared && (
            <>
               <p className="not-found-homepage">
                  <FormattedMessage id="errorPage.not-found.gotoHomepage" />
               </p>
               <ActionButton className="form-action" onClick={navigateToHomePage}>
                  <FormattedMessage id="errorPage.not-found.homepage" />
               </ActionButton>
            </>
         )}
      </div>
   );
};

export default NotFound;
