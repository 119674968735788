import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

export default styled(Stack)(({theme}) => ({
   maxHeight: window.innerHeight - 525,
   overflowY: 'auto',
   '&::-webkit-scrollbar': {
      width: 5,
   },
   '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.primary.main,
   },
   '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: 10,
   },
}));
