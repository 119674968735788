import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const ButtonsPanel = ({actions, navigations}) => (
   <div className="buttons-panel">
      <div className="actions">{actions}</div>
      {navigations && <div className="navigations">{navigations}</div>}
   </div>
);

ButtonsPanel.propTypes = {
   actions: PropTypes.node,
   navigations: PropTypes.node,
};

export default ButtonsPanel;
