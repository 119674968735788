import {call, put, select, takeLatest} from 'redux-saga/effects';

import {
   containerId as offerDetailsContainerId,
   convertToProposal,
   fetchOffer,
   validateOfferForm,
} from '../OfferDetails/reducer';
import {containerId as policyHolderContainerId} from '../ProposalDetails/PolicyHolder/reducer';

import {containerId as insuredContainerId} from '../ProposalDetails/Insured/reducer';
import {
   containerId as healthDeclarationContainerId,
   fetchHealthDeclaration,
} from '../ProposalDetails/HealthDeclaration/reducer';
import {
   containerId as needAndDesiresContainerId,
   fetchNeedsAndDesires,
} from '../ProposalDetails/NeedsAndDesires/reducer';
import {
   containerId as additionalInformationContainerId,
   fetchAdditionalInformation,
} from '../ProposalDetails/AdditionalInformation/reducer';
import {
   containerId as beneficiariesContainerId,
   fetchBeneficiaries,
} from '../ProposalDetails/Beneficiaries/reducer';

import {
   convertToOfferButtonClickAction,
   convertToProposalButtonClickAction,
   handleConvertToOfferButtonClickAction,
   handleConvertToProposalButtonClickAction,
   handleNavigationButtonClickAction,
   handleSaveButtonClickAction,
   handleSubmitButtonClickAction,
   proposalNavigate,
   saveButtonClickAction,
   setFormSavingAction,
   submitButtonClickAction,
   validateFormAction,
} from './reducer';
import {createDefaultFormSaveSaga, createShowSuccessFormSaveSaga} from './sagas-base';

import {selectActiveFormName, selectId, selectIsOfferDetailsForm} from './selectors';
import {fetchCompleteness} from '../NavigationPane/reducer';
import {convertToOffer, setServerErrors, submitProposal} from '../ProposalDetails/reducer';

const formContainerIdToPreSaveAsyncAction = new Map([
   [offerDetailsContainerId, validateOfferForm],
   [needAndDesiresContainerId, validateFormAction],
   [policyHolderContainerId, validateFormAction],
   [insuredContainerId, validateFormAction],
   [healthDeclarationContainerId, validateFormAction],
   [beneficiariesContainerId, validateFormAction],
   [additionalInformationContainerId, validateFormAction],
]);

const formContainerIdToPostSaveAsyncAction = new Map([
   [offerDetailsContainerId, fetchOffer],
   [needAndDesiresContainerId, fetchNeedsAndDesires],
   [healthDeclarationContainerId, fetchHealthDeclaration],
   [beneficiariesContainerId, fetchBeneficiaries],
   [additionalInformationContainerId, fetchAdditionalInformation],
]);

export const handleNavigationSaga = createShowSuccessFormSaveSaga(
   handleNavigationButtonClickAction,
   proposalNavigate,
   formContainerIdToPreSaveAsyncAction
);

export function* proposalNavigateSaga() {
   yield put(setFormSavingAction.clear());
}

export const handleSaveButtonClickSaga = createShowSuccessFormSaveSaga(
   handleSaveButtonClickAction,
   saveButtonClickAction,
   formContainerIdToPreSaveAsyncAction,
   formContainerIdToPostSaveAsyncAction
);

export function* saveSaga() {
   const id = yield select(selectId);
   const payload = {id};
   const isOfferDetailsForm = yield select(selectIsOfferDetailsForm);
   if (isOfferDetailsForm) {
      payload.endpoint = 'offers';
   }
   yield put(fetchCompleteness.request(payload));
   yield put(setFormSavingAction.clear());
}

export const submitButtonClickSaga = createDefaultFormSaveSaga(
   handleSubmitButtonClickAction,
   submitButtonClickAction,
   formContainerIdToPreSaveAsyncAction,
   formContainerIdToPostSaveAsyncAction
);

export function* handleSubmitButtonClickSaga({payload}) {
   yield put(setServerErrors.clear());
   yield call(submitButtonClickSaga, payload);
}

export function* proposalSubmitSaga() {
   const id = yield select(selectId);
   const activeFormName = yield select(selectActiveFormName);

   yield put(
      fetchCompleteness.request({
         id,
      })
   );
   yield put(
      submitProposal.request({
         id,
         form: activeFormName,
      })
   );
   yield put(setFormSavingAction.clear());
}

export const handleConvertToOfferButtonClickSaga = createDefaultFormSaveSaga(
   handleConvertToOfferButtonClickAction,
   convertToOfferButtonClickAction,
   formContainerIdToPreSaveAsyncAction,
   formContainerIdToPostSaveAsyncAction
);

export const handleConvertToProposalButtonClickSaga = createDefaultFormSaveSaga(
   handleConvertToProposalButtonClickAction,
   convertToProposalButtonClickAction,
   formContainerIdToPreSaveAsyncAction,
   formContainerIdToPostSaveAsyncAction
);

export function* proposalConvertSaga() {
   yield put(setFormSavingAction.clear());

   const id = yield select(selectId);
   const activeFormName = yield select(selectActiveFormName);

   yield put(
      convertToOffer.request({
         id,
         containerId: activeFormName,
      })
   );
}

export function* offerConvertSaga() {
   yield put(setFormSavingAction.clear());

   const id = yield select(selectId);
   const activeFormName = yield select(selectActiveFormName);

   yield put(
      convertToProposal.request({
         id,
         containerId: activeFormName,
      })
   );
}

export default function* formWrapperSagas() {
   yield takeLatest(handleNavigationButtonClickAction.REQUEST, handleNavigationSaga);
   yield takeLatest(proposalNavigate.ACTION, proposalNavigateSaga);

   yield takeLatest(handleSaveButtonClickAction.REQUEST, handleSaveButtonClickSaga);
   yield takeLatest(saveButtonClickAction.ACTION, saveSaga);

   yield takeLatest(handleSubmitButtonClickAction.REQUEST, handleSubmitButtonClickSaga);
   yield takeLatest(submitButtonClickAction.ACTION, proposalSubmitSaga);

   yield takeLatest(
      handleConvertToOfferButtonClickAction.REQUEST,
      handleConvertToOfferButtonClickSaga
   );
   yield takeLatest(
      handleConvertToProposalButtonClickAction.REQUEST,
      handleConvertToProposalButtonClickSaga
   );
   yield takeLatest(convertToOfferButtonClickAction.ACTION, proposalConvertSaga);
   yield takeLatest(convertToProposalButtonClickAction.ACTION, offerConvertSaga);
}
