import {all, call, put, takeLatest} from 'redux-saga/effects';
import {toastHandler} from '../../shared/services';
import {takeAction} from '../../shared/services/sagaHelpers';
import {SavedCalculatePremiumService} from '../../shared/services/SavedCalculatePremium';
import {getAgentDataCache} from '../../v2/common/api/queries/agent';
import {webStorage} from '../../v2/common/services/WebStorage';
import {Permission} from '../../v2/features/permissions/types';
import {setProducerAccount} from '../../v2/pages/Login/producerAccountSlice';
import {initiateSalesProcess} from '../CalculatePremium/InitiateSalesProcess/reducer';
import {logIn, setGeneralError, startPostponedProcess} from './reducer';

export const createLogInSaga = (takeActionHandler) => {
   return function* ({payload}) {
      const {value} = payload;
      webStorage.setProducerAccountNumber(value);
      yield put(startPostponedProcess.request(payload));
      const action = yield takeActionHandler([startPostponedProcess, logIn]);

      if (action.type !== startPostponedProcess.SUCCESS) {
         yield put(logIn.failure());
         return;
      }
      SavedCalculatePremiumService.clear();
      yield put(setProducerAccount(payload));
      yield put(logIn.success());
   };
};

export const logInSaga = createLogInSaga(takeAction);

export function* startPostponedProcessSaga({payload: producerAccount}) {
   const calculationData = SavedCalculatePremiumService.getData();

   if (!calculationData) {
      yield put(startPostponedProcess.success());
      return;
   }
   const requiredPermission =
      calculationData.type === 'offer' ? Permission.CreateOffer : Permission.CreateProposal;
   if (producerAccount.permissions.includes(requiredPermission)) {
      toastHandler.closeAll();
      yield put(initiateSalesProcess.request(calculationData));
      yield put(startPostponedProcess.success());
      return;
   }

   const agent = yield call(getAgentDataCache);
   if (!agent) {
      yield put(setGeneralError.action({errors: ['No agent']}));
   }

   let errorId;
   if (agent.producerAccounts.length === 1) {
      errorId = 'toasts.error.landingCalculation.oneProducerAccount';
      yield put(startPostponedProcess.failure());
   } else {
      errorId = 'toasts.error.landingCalculation.severalProducerAccounts';
      yield put(startPostponedProcess.failure());
   }

   return toastHandler.uniqueError({id: errorId});
}

export default function* applicationSaga() {
   yield all([
      takeLatest(startPostponedProcess.REQUEST, startPostponedProcessSaga),
      takeLatest(logIn.REQUEST, logInSaga),
   ]);
}
