import get from 'lodash/get';
import omit from 'lodash/omit';
import { change, initialize } from 'redux-form';
import { delay } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { history } from '../../history';
import {
  DOCUMENT_GENERATION_POLLING_STATUSES,
  POLLING_INTERVAL,
  POLLING_MAX_RETRY,
  SIMPLE_ANSWER,
} from '../../shared/constants';
import { fetchMunicipalities } from '../../shared/references';
import { api, toastHandler } from '../../shared/services';
import {
  createTab,
  hasMsSaveOrOpenBlobMethod,
  showFile,
  takeAction,
} from '../../shared/services/sagaHelpers';
import { scrollToField } from '../../shared/services/utils';
import { setGeneralError } from '../App/reducer';
import { handleSaveButtonClickAction, validateFormAction } from '../FormWrapper/reducer';
import { selectActiveFormValues } from '../FormWrapper/selectors';
import { fetchCompleteness } from '../NavigationPane/reducer';
import {
  hasResidentialAddressError,
  setResidentialAddressSaveError,
} from '../ProposalDetails/PolicyHolder/sagas';

import {
  containerId,
  convertToProposal,
  fetchOffer,
  saveOffer,
  setOfferMetadata,
  shareByEmail,
  shareByPrinting,
  validateOfferForm,
} from './reducer';
import {
  makeSelectIsOneTimeOnly,
  makeSelectIsOneTimeOnlyViolation,
  selectFirstInsuredAdultEmail,
  selectInsuredAdultsFormValue,
  selectMetadata,
  selectOffer,
  selectParticipatingAsInsuredAdultFormValue,
} from './selectors';

export const POLLING_MAX_RETRY_ERROR = 'Retry number exceeded maximum value';
export const DOCUMENT_GENERATOR_UNAVAILABLE = 'Document generator is unavailable';

export function* updateEmptyInsuredAdultId(currentValues, index, offer) {
  const path = index === 0 ? 'firstInsuredAdult' : 'secondInsuredAdult';
  if (currentValues && currentValues.id === null) {
    yield put(change(containerId, `insuredAdults[${index}].id`, get(offer, `${path}.id`, null)));
  }
}

export function* fetchOfferSaga({ payload }) {
  try {
    let id = payload;
    if (!id) {
      const metadata = yield select(selectMetadata);
      id = metadata.offerId;
    }
    const [offer, metadata] = yield all([call(api.offer.get, id), call(api.offer.getMetadata, id)]);
    yield put(setOfferMetadata.action(metadata));
    const policyHolder = get(offer, 'policyHolder');
    const firstInsuredAdult = get(offer, 'firstInsuredAdult');
    const secondInsuredAdult = get(offer, 'secondInsuredAdult');
    const { postalCode } = policyHolder?.residentialAddress;

    const [firstInsuredAdultFormValues, secondInsuredAdultFormValues] = yield select(
      selectInsuredAdultsFormValue
    );
    yield call(updateEmptyInsuredAdultId, firstInsuredAdultFormValues, 0, offer);
    yield call(updateEmptyInsuredAdultId, secondInsuredAdultFormValues, 1, offer);
    const fetchData = {
      ...offer,
      policyHolder: {
        ...policyHolder,
        residentialAddress: { ...omit(policyHolder?.residentialAddress, 'country') },
      },
      firstInsuredAdult: {
        ...firstInsuredAdult,
        participatingAsPolicyHolder: firstInsuredAdult.participatingAsPolicyHolder
          ? SIMPLE_ANSWER.YES
          : SIMPLE_ANSWER.NO,
      },
      secondInsuredAdult: null,
    };
    if (secondInsuredAdult) {
      fetchData.secondInsuredAdult = {
        ...secondInsuredAdult,
        participatingAsPolicyHolder: SIMPLE_ANSWER.NO,
      };
    }
    yield put(fetchOffer.success(fetchData));
    if (postalCode && postalCode.length === 4) {
      yield put(fetchMunicipalities.request({ postalCode, containerId }));
    }
  } catch (error) {
    yield put(fetchOffer.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* prepareDataSaveOfferDetailsSaga(values) {
  const offer = yield select(selectOffer);
  const isOneTimeOnly = yield select(makeSelectIsOneTimeOnly());
  const participatingAsInsuredAdult = yield select(selectParticipatingAsInsuredAdultFormValue);
  const firstInsuredAdultEmail = yield select(selectFirstInsuredAdultEmail);

  return {
    ...omit(values, 'insuredAdults'),
    firstInsuredAdult: {
      ...values.insuredAdults[0],
      participatingAsPolicyHolder:
        values.insuredAdults[0].participatingAsPolicyHolder === SIMPLE_ANSWER.YES,
      payUntilAge: isOneTimeOnly ? null : values.insuredAdults[0].payUntilAge,
      // Including first insured adult's email to not override it with undefined if it was set before (BROKP-8164)
      email: firstInsuredAdultEmail,
    },
    secondInsuredAdult: values.insuredAdults[1],
    policyHolder: {
      ...values.policyHolder,
      participatingAsInsuredAdult: participatingAsInsuredAdult === SIMPLE_ANSWER.YES,
      financialInformation: get(offer.policyHolder, 'financialInformation', null),
    },
  };
}

export function* saveOfferSaga() {
  const formValues = yield select(selectActiveFormValues);
  const data = yield call(prepareDataSaveOfferDetailsSaga, formValues);
  const { offerId } = yield select(selectMetadata);
  try {
    yield call(api.offer.put, offerId, data);
    yield put(saveOffer.success());
  } catch (error) {
    yield put(saveOffer.failure(error));
    if (hasResidentialAddressError(error)) {
      yield call(setResidentialAddressSaveError, containerId);
      return;
    }
    yield put(setGeneralError.action(error));
  }
}

export function* convertToProposalSaga({ payload: { id } }) {
  try {
    const { proposalId } = yield call(api.offer.convertToProposal, id);
    yield put(convertToProposal.success());
    yield put(initialize(containerId));
    history.push(`/sales-process/proposals/${proposalId}`);
  } catch (error) {
    yield put(convertToProposal.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* retrievePrintingDocumentSaga({ id, tab, fileName }) {
  const blob = yield call(api.offer.getDocument, id);
  showFile(blob, tab, fileName);
  const metadata = yield call(api.offer.getMetadata, id);
  yield put(setOfferMetadata.action(metadata));
  yield put(
    fetchCompleteness.request({
      id,
      endpoint: 'offers',
    })
  );
  yield put(shareByPrinting.success());
}

export function* shareByPrintingSaga({ payload: { id, fileName } }) {
  const failure = yield call(trySaveOfferFormSaga);
  if (failure) {
    yield put(shareByPrinting.clear());
    return;
  }
  const tab = !hasMsSaveOrOpenBlobMethod() && (yield call(createTab));
  try {
    yield call(api.offer.generateDocument, id);
    yield call(pollDocumentGenerationSaga, id);
    yield call(retrievePrintingDocumentSaga, { id, tab, fileName });
  } catch (error) {
    if (tab) {
      tab.close();
    }
    yield put(shareByPrinting.failure(error));
    toastHandler.uniqueWarning({ id: 'toasts.warning.documentsTechnicalIssue' });
  }
}

export function* trySaveOfferFormSaga() {
  yield put(handleSaveButtonClickAction.request());
  const actionResult = yield takeAction(handleSaveButtonClickAction);

  return actionResult.type !== handleSaveButtonClickAction.SUCCESS;
}

export function* shareByEmailSaga({ payload: { id } }) {
  const failure = yield call(trySaveOfferFormSaga);
  if (failure) {
    yield put(shareByEmail.clear());
    return;
  }
  try {
    yield call(api.offer.shareByEmail, id);
    yield call(pollDocumentGenerationSaga, id);
    yield call(fetchOfferSaga, { payload: id });
    yield put(
      fetchCompleteness.request({
        id,
        endpoint: 'offers',
      })
    );
    yield put(shareByEmail.success());
    toastHandler.uniqueSuccess({ id: 'toasts.success.offer.successSent' });
  } catch (error) {
    yield put(shareByEmail.failure(error));
    toastHandler.uniqueWarning({ id: 'toasts.warning.documentsTechnicalIssue' });
  }
}

export function* pollDocumentGenerationSaga(offerId) {
  let retryNum = 1;
  while (retryNum <= POLLING_MAX_RETRY) {
    const status = yield call(api.offer.getDocumentGenerationStatus, offerId);

    if (
      status === DOCUMENT_GENERATION_POLLING_STATUSES.FAILED ||
      status === DOCUMENT_GENERATION_POLLING_STATUSES.POSTPONED
    ) {
      throw new Error(DOCUMENT_GENERATOR_UNAVAILABLE);
    } else if (status === DOCUMENT_GENERATION_POLLING_STATUSES.COMPLETED) {
      break;
    }

    retryNum++;
    if (retryNum > POLLING_MAX_RETRY) {
      throw new Error(POLLING_MAX_RETRY_ERROR);
    }

    yield call(delay, POLLING_INTERVAL);
  }
}

export const createValidateOfferFormSaga = (
  scrollToField,
  takeActionHandler,
  selectIsOneTimeOnlyViolation
) =>
  function* () {
    const isOneTimeOnlyViolation = yield select(selectIsOneTimeOnlyViolation);
    if (isOneTimeOnlyViolation) {
      yield put(validateOfferForm.failure());
      scrollToField('insuredAdultToRemove');
      return;
    }

    yield put(validateFormAction.request());
    const validateActionResult = yield takeActionHandler([validateFormAction, validateOfferForm]);

    if (validateActionResult.type !== validateFormAction.SUCCESS) {
      yield put(validateOfferForm.failure());
      return;
    }

    yield put(validateOfferForm.success());
  };

const validateOfferFormSaga = createValidateOfferFormSaga(
  scrollToField,
  takeAction,
  makeSelectIsOneTimeOnlyViolation()
);

export default function* offerDetailsSagas() {
  yield all([
    takeLatest(saveOffer.REQUEST, saveOfferSaga),
    takeLatest(fetchOffer.REQUEST, fetchOfferSaga),
    takeLatest(convertToProposal.REQUEST, convertToProposalSaga),
    takeLatest(shareByPrinting.REQUEST, shareByPrintingSaga),
    takeLatest(shareByEmail.REQUEST, shareByEmailSaga),
    takeLatest(validateOfferForm.REQUEST, validateOfferFormSaga),
  ]);
}
