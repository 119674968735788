import classNames from 'classnames';

import Spinner from '../../../../../shared/components/Spinner';

type WidgetProps = {
  classPrefix: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  fetching?: boolean;
};

export const Widget = ({ classPrefix, header, children, fetching }: WidgetProps) => (
  <div className={classNames('widget', classPrefix)} data-testid="widget">
    {header && <div className={`${classPrefix}-header`}>{header}</div>}
    {fetching ? (
      <Spinner />
    ) : (
      children && <div className={`${classPrefix}-content`}>{children}</div>
    )}
  </div>
);
