import React from 'react';
import {object} from 'prop-types';

const HiddenInput = ({input}) => {
   return <input {...input} type="hidden" />;
};

HiddenInput.propTypes = {
   input: object,
};

export default HiddenInput;
