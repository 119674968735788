import React from 'react';
import classNames from 'classnames';
import {string, object, shape} from 'prop-types';
import {FormattedMessage} from '../../FormattedMessage';
import classes from './styles.module.scss';

const FieldError = ({className, message}) => {
   return (
      <div className={classNames(classes.root, className)}>
         <FormattedMessage {...message} />
      </div>
   );
};

FieldError.propTypes = {
   className: string,
   message: shape({
      id: string.isRequired,
      values: object,
   }).isRequired,
};

export default FieldError;
