import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {
   ListItemButton,
   ListItemIcon,
   ListItemSecondaryAction,
   listItemSecondaryActionClasses,
   ListItemText,
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, {SyntheticEvent} from 'react';
import {FormattedMessage} from 'react-intl';
import {
   Notification as NotificationType,
   NotificationSeverity,
   useRemoveNotification,
} from 'v2/common/api/queries/notifications';
import {useNotificationDescription, useNotificationRoute} from 'v2/features/Notifications/hooks';
import {usePermissions} from 'v2/features/permissions/hooks';
import {Permission} from 'v2/features/permissions/types';

type NotificationProps = {
   data: NotificationType;
   hasError: boolean;
   onClick: () => void;
};

const Notification = ({data, hasError, onClick}: NotificationProps) => {
   const hasNotificationsDeletePermission = usePermissions(Permission.DeleteNotifications);
   const description = useNotificationDescription(data);
   const {mutate: remove} = useRemoveNotification(data.id);
   const navigate = useNotificationRoute(data, onClick);
   const {
      id,
      producerAccountNumber,
      producerAccountType,
      policyHolderName,
      created,
      number,
      severity,
   } = data;
   const isWarning = severity === NotificationSeverity.Warn;
   const createdFromNow = moment.utc(created).local().startOf('second').fromNow();
   const handleRemove = (e: SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      remove();
   };
   return (
      <ListItemButton
         sx={{
            '&:hover, &:active': {
               [`& .${listItemSecondaryActionClasses.root}`]: {
                  right: 10,
               },
            },
         }}
         data-testid={id}
         disableRipple
         disabled={hasError}
         onClick={navigate}
      >
         {isWarning && (
            <ListItemIcon data-testid="warning-icon">
               <WarningAmberRoundedIcon fontSize="large" color="warning" />
            </ListItemIcon>
         )}
         <ListItemText primaryTypographyProps={{color: 'primary'}} secondary={createdFromNow}>
            <Stack marginBottom={1} spacing={2} direction="row">
               <Typography variant="caption">
                  <FormattedMessage id={`accountTypes.${producerAccountType}`} /> (
                  {producerAccountNumber})
               </Typography>
               <Typography variant="caption" noWrap maxWidth={200}>
                  <FormattedMessage id="misc.proposal" /> #{number}
               </Typography>
               <Typography variant="caption">{policyHolderName}</Typography>
            </Stack>
            {description}
         </ListItemText>
         {hasNotificationsDeletePermission && !hasError && (
            <ListItemSecondaryAction
               sx={{
                  paddingY: '10px',
                  height: '100%',
                  right: -30,
                  transition: (theme) => theme.transitions.create('right'),
               }}
            >
               <Button
                  sx={{
                     minWidth: 'auto',
                     height: '100%',
                     padding: 0.5,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleRemove}
               >
                  <DeleteRoundedIcon fontSize="small" />
               </Button>
            </ListItemSecondaryAction>
         )}
      </ListItemButton>
   );
};

export default Notification;
