import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {Locale} from 'v2/common/providers/LocalizationProvider';
import {getInitialLocale} from 'v2/common/providers/LocalizationProvider/utils';

type SliceState = {
   locale: Locale;
   error: null;
   loading: boolean;
};

const initialState: SliceState = {
   locale: getInitialLocale(),
   error: null,
   loading: false,
};

const appSlice = createSlice({
   name: 'app',
   initialState,
   reducers: {
      setLocale: (state, action: PayloadAction<SliceState['locale']>) => {
         state.locale = action.payload;
      },
   },
});

export const selectLocale = (state: RootState) => state.app.locale;
export const {setLocale} = appSlice.actions;

export default appSlice;
