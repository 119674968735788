import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {clearServerError} from '../../../containers/ProposalDetails/reducer';
import {selectServerErrors} from '../../../containers/ProposalDetails/selectors';
import {HANDLED_SERVER_ERRORS, SERVER_ERROR_TYPES} from '../../constants';

export const useServerError = (path) => {
   const dispatch = useDispatch();
   const currentErrors = useSelector(selectServerErrors);

   const error = HANDLED_SERVER_ERRORS.find((type) => get(currentErrors, `${type}.${path}`));

   const clearServerErrorHandler = () => {
      if (error) {
         const clearServerErrorAction = clearServerError.action;

         if (error === SERVER_ERROR_TYPES.VALIDATION_ERRORS) {
            dispatch(clearServerErrorAction(path));
            return;
         }
         dispatch(clearServerErrorAction(error));
      }
   };

   return [error, clearServerErrorHandler];
};
