import { FormattedMessage } from 'react-intl';

import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';

type PortfolioNoDataProps = {
  goToCalculator: () => void;
};

export const PortfolioNoData = ({ goToCalculator }: PortfolioNoDataProps) => {
  const hasPremiumCalculationPermission = usePermissions(Permission.PremiumCalculation);

  return (
    <div className="portfolio-no-data">
      <div className="portfolio-no-data-content">
        <div className="portfolio-no-data-content-title">
          <FormattedMessage id="homepage.portfolioIsEmpty" />
        </div>
        {hasPremiumCalculationPermission && (
          <div className="portfolio-no-data-content-text">
            <FormattedMessage
              id="homepage.creatingInfo"
              values={{
                calculatePremiumLink: (
                  <button type="button" className="link" onClick={goToCalculator}>
                    <FormattedMessage id="misc.calculatePremium" />
                  </button>
                ),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
